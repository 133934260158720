import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type SupplierInsuranceOptionListItem = ResultOf<
  typeof supplierInsuranceOptionListQuery
>["supplierInsuranceOptions"]["data"][number];
export const supplierInsuranceOptionListQuery = graphql(`
  query SupplierInsuranceOptionList(
    $first: Int!
    $page: Int
    $supplierId: ID
    $search: String
    $id: ID
    $orderBy: [QuerySupplierInsuranceOptionsOrderByOrderByClause!]
  ) {
    supplierInsuranceOptions(
      first: $first
      page: $page
      supplier_id: $supplierId
      search: $search
      orderBy: $orderBy
      id: $id
    ) {
      data {
        id
        name
        is_default
        bond_amount
        standard_liability_amount
        liability_reduction_options
        created_at
        updated_at
        supplier {
          id
          currency
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
