import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { ContactType, CreateContactInput } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { ContactDetailsForm } from "@/app/Contacts/Forms/ContactDetailsForm";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { ContactEmailsForm } from "@/app/Contacts/Forms/ContactEmailsForm";
import { contactValidation } from "@/app/Contacts/Forms/contactValidation";
import { ContactPhonesForm } from "@/app/Contacts/Forms/ContactPhonesForm";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createContactMutation } from "@/app/Contacts/GraphQL/contactMutations";
import { AddressInput } from "@/app/Address/Components/AddressInput";

export type ContactCreateDialogProps = {
  initialValues: Partial<CreateContactInput>;
};
export function ContactCreateDialog({
  initialValues,
}: ContactCreateDialogProps) {
  const { mutateAsync } = useGqlMutation(createContactMutation);
  const { close, resolve } = useDialogState();

  return (
    <GenericDialog title={"Add contact"}>
      <GenericForm<CreateContactInput>
        validationSchema={contactValidation}
        initialValues={{
          name: "",
          type: ContactType.Guest,
          billingAddress: {
            upsert: {
              name: "",
            },
          },
          ...initialValues,
        }}
        onSubmit={async (values) => {
          const res = await mutateAsync({
            input: values,
          });

          resolve?.(res.createContact);

          close();
        }}
      >
        <ContactDetailsForm />
        <ContactEmailsForm label="Emails" />
        <FormDivider />
        <ContactPhonesForm label="Phones" />
        <FormDivider />
        <AddressInput name="billingAddress.upsert" label="Address" />
        <DialogSubmitButton text={"Add contact"} />
      </GenericForm>
    </GenericDialog>
  );
}
