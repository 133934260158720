import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { ModelType } from "@/gql/graphql";
import { cn } from "@/lib/utils";
import * as React from "react";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { upsertIntegrationLink } from "@/app/Settings/Settings/GraphQL/integrationLinkMutations";
import { QueryClient } from "@/lib/ReactQuery/QueryClient";
import { IntegrationRecord } from "@/app/Settings/Settings/GraphQL/integrationQueries";
import { IntegrationLinksList } from "@/app/Settings/Settings/GraphQL/integrationLinksQueries";
import { SupplierOfficeListItem } from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { SupplierInsuranceOptionListItem } from "@/app/Suppliers/GraphQL/supplierInsuranceOptionListQuery";
import { VehicleListItem } from "@/app/Vehicles/GraphQL/vehicleListQuery";

export function JucyCodeComboBox({
  integrationLink,
  externalIds,
  jucyIntegration,
  rowItem,
  type,
}: {
  integrationLink?: IntegrationLinksList[number];
  externalIds: { label: string; value: string }[];
  jucyIntegration: IntegrationRecord;
  rowItem:
    | SupplierOfficeListItem
    | SupplierInsuranceOptionListItem
    | VehicleListItem;
  type: ModelType;
}) {
  const [open, setOpen] = React.useState(false);
  const { mutateAsync, isPending } = useGqlMutation(upsertIntegrationLink, {
    onSuccess: () => {
      return QueryClient.invalidateQueries();
    },
  });

  return (
    <div>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn(
              `w-[200px] justify-between truncate`,
              integrationLink
                ? "bg-green-100"
                : "bg-red-100 text-red-700 hover:bg-red-100 hover:text-red-800",
            )}
          >
            {integrationLink
              ? externalIds.find(
                  (id) => id.value === integrationLink.external_id,
                )?.label
              : "Missing code"}
            {isPending ? (
              <Spinner className="ml-2 h-4 w-4 shrink-0 opacity-50"></Spinner>
            ) : (
              <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandInput placeholder="Search codes" className="h-9" />
            <CommandList>
              <CommandEmpty>No options found.</CommandEmpty>
              <CommandGroup>
                {externalIds
                  ? externalIds.map((externalId) => (
                      <CommandItem
                        key={externalId.value}
                        value={externalId.value}
                        onSelect={async (currentValue) => {
                          await mutateAsync({
                            id: integrationLink?.id,
                            input: {
                              external_id: currentValue,
                              object: {
                                connect: {
                                  id: rowItem.id,
                                  type: type,
                                },
                              },
                              integration: {
                                connect: jucyIntegration.id,
                              },
                            },
                          });
                          setOpen(false);
                        }}
                      >
                        {externalId.label}
                        <CheckIcon
                          className={cn(
                            "ml-auto h-4 w-4",
                            integrationLink?.external_id === externalId.value
                              ? "opacity-100"
                              : "opacity-0",
                          )}
                        />
                      </CommandItem>
                    ))
                  : null}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
