import { Dialog as HeadlessUiDialog, Transition } from "@headlessui/react";
import { Fragment, ReactNode, useDeferredValue, useEffect } from "react";
import { useDialogState } from "./Hooks/useDialogState";
import classNames from "classnames";
import { DialogProvider } from "@/lib/Components/Dialog/Providers/DialogProvider";

export interface DismissibleDialogProps {
  children: ReactNode;
  isLoading?: boolean;
  onClose?: () => void;
}

export function DismissibleDialog({
  children,
  onClose,
}: DismissibleDialogProps) {
  const { isOpen: isOpenOptimistic, depth, remove, close } = useDialogState();

  const isOpen = useDeferredValue(isOpenOptimistic);

  useEffect(() => {
    if (!isOpen) {
      onClose?.();
    }
  }, [isOpen]);

  return (
    <Transition
      appear
      show={isOpen}
      as={Fragment}
      afterLeave={() => {
        remove();
      }}
    >
      <HeadlessUiDialog
        onClose={() => {
          close();
        }}
        as="div"
        className="relative z-10"
      >
        <DialogProvider depth={depth + 1}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="scroll-gutter-stable fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <HeadlessUiDialog.Panel
                  as="div"
                  className={classNames(
                    "w-full transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all",
                  )}
                  style={{ maxWidth: `${1000 + -20 * depth}px` }}
                >
                  <Transition
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    {children}
                  </Transition>
                </HeadlessUiDialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </DialogProvider>
      </HeadlessUiDialog>
    </Transition>
  );
}
