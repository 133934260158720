import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { Fuel } from "@/gql/graphql";
import { SelectOption } from "@/lib/Components/Form/Components/Select";

export function FuelInput(props: Omit<SelectInputProps<any>, "options">) {
  const options: {
    [key in Fuel]: SelectOption;
  } = {
    [Fuel.Diesel]: {
      value: Fuel.Diesel,
      label: "Diesel",
    },
    [Fuel.Electric]: {
      value: Fuel.Electric,
      label: "Electric",
    },
    [Fuel.Gasoline]: {
      value: Fuel.Gasoline,
      label: "Gasoline",
    },
    [Fuel.Hybrid]: {
      value: Fuel.Hybrid,
      label: "Hybrid",
    },
  };

  return (
    <SelectInput
      options={[
        {
          label: "Combustion",
          options: [options[Fuel.Diesel], options[Fuel.Gasoline]],
        },
        {
          label: "Electric",
          options: [options[Fuel.Electric], options[Fuel.Hybrid]],
        },
      ]}
      {...props}
    />
  );
}
