import { TimelineFilterOption } from "@/gql/graphql";
import { useState, useTransition } from "react";
import {
  filterOptions,
  getDatesFromFilterOption,
} from "@/app/Dashboard/Utils/filterOptions";
import { cn } from "@/lib/utils";
import {
  Area,
  AreaChart,
  CartesianGrid,
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { adminDashboardStatsQuery } from "@/app/Dashboard/GraphQL/adminDashboardStatsQuery";
import { toDateTimeTzString } from "@/lib/Formatters/toDateTimeString";
import dayjs from "dayjs";
import colors from "tailwindcss/colors";
import { max } from "lodash";
import { formatStatistic } from "@/lib/Formatters/formatStatistic";

export function AdminDashboardStatBlocks() {
  const [filter, setFilter] = useState(TimelineFilterOption.SevenDays);
  const [isPending, startTransition] = useTransition();
  const { start, end } = getDatesFromFilterOption(filter);
  const { data } = useSuspenseGqlQuery(adminDashboardStatsQuery, {
    start: toDateTimeTzString(start),
    end: toDateTimeTzString(end),
  });

  return (
    <div
      className={cn({
        "animate-pulse": isPending,
      })}
    >
      <Card className="h-full">
        <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
          <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
            <CardTitle>Bookings</CardTitle>
            <CardDescription>
              Showing total bookings for{" "}
              <select
                className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={filter}
                onChange={(e) => {
                  startTransition(() => {
                    setFilter(e.target.value as TimelineFilterOption);
                  });
                }}
              >
                {Object.entries(filterOptions).map(([option, label]) => (
                  <option key={option} value={option}>
                    {label}
                  </option>
                ))}
              </select>
            </CardDescription>
          </div>

          <div className="flex">
            <div className="relative flex min-w-36 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-l sm:border-t-0 sm:px-8 sm:py-6">
              <span className="text-muted-foreground text-xs">Bookings</span>
              <span className="whitespace-nowrap text-lg font-bold leading-none sm:text-3xl">
                {formatStatistic(data.adminDashboardStats.bookings_count)}
              </span>
            </div>
            <div className="relative flex min-w-36 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-l sm:border-t-0 sm:px-8 sm:py-6">
              <span className="text-muted-foreground text-xs">
                New Waitlists
              </span>
              <span className="whitespace-nowrap text-lg font-bold leading-none sm:text-3xl">
                {formatStatistic(data.adminDashboardStats.waitlists_count)}
              </span>
            </div>
            <div className="relative flex min-w-36 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-l sm:border-t-0 sm:px-8 sm:py-6">
              <span className="text-muted-foreground text-xs">
                Notifications
              </span>
              <span className="whitespace-nowrap text-lg font-bold leading-none sm:text-3xl">
                {formatStatistic(
                  data.adminDashboardStats.waitlist_notifications_count,
                )}
              </span>
            </div>
            <div className="relative flex min-w-36 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-l sm:border-t-0 sm:px-8 sm:py-6">
              <span className="text-muted-foreground text-xs">
                Paid drivers
              </span>
              <span className="whitespace-nowrap text-lg font-bold leading-none sm:text-3xl">
                {formatStatistic(data.adminDashboardStats.paid_drivers_count)}
              </span>
            </div>
          </div>
        </CardHeader>
        <CardContent className="mt-6 grid grid-cols-3">
          <div className="col-span-2">
            <BookingAreaChart start={start} end={end} />
          </div>
          <div className="col-span-1 flex items-center">
            <BookingRegionChart start={start} end={end} />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

function BookingRegionChart({ start, end }: { start: string; end: string }) {
  const { data } = useSuspenseGqlQuery(adminDashboardStatsQuery, {
    start: toDateTimeTzString(start),
    end: toDateTimeTzString(end),
  });
  const chartData = data.adminDashboardStats.regions.filter(
    (data) => data.relocations_count > 0,
  );

  const chartConfig = {
    relocations_count: {
      label: "Relocations",
      color: "hsl(var(--chart-2))",
    },
    bookings_count: {
      label: "Bookings",
      color: "hsl(var(--chart-1))",
    },
    waitlists_count: {
      label: "Waitlists",
      color: "hsl(var(--chart-3))",
    },
  } satisfies ChartConfig;

  return (
    <ChartContainer
      config={chartConfig}
      className="aspect-square max-h-[250px] w-full"
    >
      <RadarChart
        data={chartData}
        margin={{
          top: -40,
          bottom: -10,
        }}
      >
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent indicator="line" />}
        />
        <PolarAngleAxis dataKey="region" />
        <PolarGrid />
        <Radar dataKey="waitlists_count" fill="var(--color-waitlists_count)" />
        <Radar
          dataKey="relocations_count"
          fill="var(--color-relocations_count)"
          fillOpacity={0.8}
        />
        <Radar
          dataKey="bookings_count"
          fill="var(--color-bookings_count)"
          fillOpacity={0.6}
        />
        <ChartLegend className="mt-8" content={<ChartLegendContent />} />
      </RadarChart>
    </ChartContainer>
  );
}

function BookingAreaChart({ start, end }: { start: string; end: string }) {
  const { data } = useSuspenseGqlQuery(adminDashboardStatsQuery, {
    start: toDateTimeTzString(start),
    end: toDateTimeTzString(end),
  });

  const startDate = dayjs(start);
  const endDate = dayjs(end);
  const daysBetween = endDate.diff(startDate, "day");

  const chartData = Array.from({ length: daysBetween + 1 }, (_, index) => {
    const date = startDate.add(index, "day").format("YYYY-MM-DD");
    const dayData = data.adminDashboardStats.bookings.find(
      (day) => day.date === date,
    );

    return {
      date,
      count_cancelled: dayData?.count_cancelled || 0,
      count_pending: dayData?.count_pending || 0,
      count_confirmed: dayData?.count_confirmed || 0,
      count_completed: dayData?.count_completed || 0,
      count_vip: dayData?.count_vip || 0,
    };
  });

  const chartConfig = {
    count_cancelled: {
      label: "Cancelled",
      color: colors["red"]["500"],
    },
    count_vip: {
      label: "VIP",
      color: colors["purple"]["500"],
    },
    count_pending: {
      label: "Pending",
      color: colors["yellow"]["500"],
    },
    count_confirmed: {
      label: "Confirmed",
      color: colors["green"]["500"],
    },
    count_completed: {
      label: "Completed",
      color: colors["blue"]["500"],
    },
  } satisfies ChartConfig;

  return (
    <ChartContainer config={chartConfig}>
      <AreaChart
        accessibilityLayer
        data={chartData}
        margin={{
          left: -20,
          right: 12,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="date"
          tickLine={false}
          axisLine={false}
          tickMargin={10}
          tickFormatter={(value) => dayjs(value).format("DD MMM")}
        />
        <YAxis
          axisLine={false}
          tickSize={10}
          type="number"
          domain={[
            0,
            (dataMax: number) =>
              max([Math.ceil((500 / 7 + 1) / 10) * 10, dataMax]),
          ]}
        />
        <ReferenceLine
          y={500 / 7}
          label="Target (500/week)"
          stroke="hsl(var(--chart-2))"
        />
        <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
        <Area
          dataKey="count_completed"
          type="basis"
          fill="var(--color-count_completed)"
          fillOpacity={0.4}
          stroke="var(--color-count_completed)"
          stackId="a"
        />
        <Area
          dataKey="count_confirmed"
          type="basis"
          fill="var(--color-count_confirmed)"
          fillOpacity={0.4}
          stroke="var(--color-count_confirmed)"
          stackId="a"
        />
        <Area
          dataKey="count_pending"
          type="basis"
          fill="var(--color-count_pending)"
          fillOpacity={0.4}
          stroke="var(--color-count_pending)"
          stackId="a"
        />
        <Area
          dataKey="count_vip"
          type="basis"
          fill="var(--color-count_vip)"
          fillOpacity={0.4}
          stroke="var(--color-count_vip)"
          stackId="a"
        />
        <Area
          dataKey="count_cancelled"
          type="basis"
          fill="var(--color-count_cancelled)"
          fillOpacity={0.4}
          stroke="var(--color-count_cancelled)"
          stackId="a"
        />
      </AreaChart>
    </ChartContainer>
  );
}
