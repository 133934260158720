import {
  Description,
  Fieldset,
  Label,
  Legend,
} from "@/components/catalyst/fieldset";
import { Radio, RadioField, RadioGroup } from "@/components/catalyst/radio";
import { Text } from "@/components/catalyst/text";

export function OnboardingPricingScreen() {
  return (
    <div>
      <Fieldset>
        <Legend>Pricing</Legend>
        <Text>Determine which package is best for your business</Text>
        <RadioGroup name="resale" defaultValue="permit">
          <RadioField>
            <Radio value="permit" />
            <Label>Small business</Label>
            <Description>
              Customers can resell or transfer their tickets if they can’t make
              it to the event.
            </Description>
          </RadioField>
          <RadioField>
            <Radio value="forbid" />
            <Label>Enterprise</Label>
            <Description>
              Tickets cannot be resold or transferred to another person.
            </Description>
          </RadioField>
        </RadioGroup>
      </Fieldset>
    </div>
  );
}
