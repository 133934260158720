import { useMatch } from "@tanstack/react-router";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { relocationRecordQuery } from "@/app/Relocations/GraphQL/relocationRecordQuery";

export function useRelocationRecord() {
  const match = useMatch({
    from: "/_app/relocations/_relocationRecordLayout/$relocationId",
  });
  const { data } = useSuspenseGqlQuery(relocationRecordQuery, {
    id: match.params.relocationId,
  });

  return data.relocation;
}
