import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { InvoicePaymentDialog } from "@/app/Invoices/Components/InvoicePaymentDialog";
import { InvoicePaymentType } from "@/gql/graphql";
import { SplitActionButton } from "@/lib/Components/Actions/SplitActionButton";
import { Action } from "@/lib/Components/Actions/ActionGroupButtons";
import {
  ArrowPathIcon,
  BanknotesIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import { useInvoiceRecord } from "@/app/Invoices/Hooks/useInvoiceRecord";

export function AddInvoicePaymentButton() {
  const invoice = useInvoiceRecord();

  const { open } = useDialog(InvoicePaymentDialog);

  const primaryAction: Action = {
    label: "Add payment",
    Icon: CurrencyDollarIcon,
    handleAction: () => {
      open({
        invoice,
        type: InvoicePaymentType.Payment,
      });
    },
  };

  const secondaryActions: Action[] = [
    {
      label: "Issue credit",
      Icon: BanknotesIcon,
      handleAction: () => {
        open({
          invoice,
          type: InvoicePaymentType.Credit,
        });
      },
    },
    {
      label: "Issue refund",
      Icon: ArrowPathIcon,
      handleAction: () => {
        open({
          invoice,
          type: InvoicePaymentType.Refund,
        });
      },
    },
  ];

  return (
    <SplitActionButton
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
    />
  );
}
