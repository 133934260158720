import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierPurchasableExtraCreateDialog } from "@/app/Suppliers/Components/SupplierPurchasableExtraCreateDialog";
import { Supplier } from "@/gql/graphql";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";

import { Button } from "@/components/ui/button";
import {
  PurchasableExtraListResult,
  purchasableExtrasQuery,
} from "@/app/Suppliers/GraphQL/supplierPurchasableExtrasQuery";
import { useSupplierPurchasableExtrasHook } from "@/app/Suppliers/Hooks/useSupplierPurchasableExtrasHook";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";

export function SupplierPurchasableExtrasTable({
  supplier,
}: {
  supplier: Pick<Supplier, "currency" | "id">;
}) {
  const { open: openCreatePurchasableExtra } = useDialog(
    SupplierPurchasableExtraCreateDialog,
  );

  const getActions = useSupplierPurchasableExtrasHook();
  const columns: DataTableColDef<PurchasableExtraListResult, any, any>[] = [
    {
      id: "name",
      header: "Name",
      accessorFn: (data) => data.name,
    },
    {
      id: "count",
      header: "Units Available",
      accessorFn: (data) => data.units_purchasable,
    },
    {
      id: "price",
      header: "Unit Price",
      accessorFn: (data) => formatCurrency(data.unit_price, supplier.currency),
    },
  ];

  return (
    <DataTable
      id="purchasable-extras"
      getActions={getActions}
      document={purchasableExtrasQuery}
      accessor={(data) => data.supplierPurchasableExtras}
      columns={columns}
      getQueryVariables={({ pagination }) => {
        return {
          first: pagination.pageSize,
          page: pagination.pageIndex,
        };
      }}
      rightButtons={
        <Button
          type="button"
          onClick={() => {
            openCreatePurchasableExtra({ supplier });
          }}
        >
          Add purchasable extra
        </Button>
      }
    />
  );
}
