import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import { ActivityLogFeed } from "@/app/ActivityLogs/Components/ActivityLogFeed";
import { ModelType } from "@/gql/graphql";
import { useQuoteRecord } from "@/app/Quotes/hooks/useQuoteRecord";

export function QuoteActivityScreen() {
  const quote = useQuoteRecord();
  return (
    <div className="py-12">
      <RecordScreenCard title={"Activity log"}>
        <ActivityLogFeed modelId={quote.id} modelType={ModelType.Quote} />
      </RecordScreenCard>
    </div>
  );
}
