import { graphql } from "@/gql";

export const relocationViewCountListQuery = graphql(`
  query RelocationViews($id: ID!, $start: DateTimeTz!, $end: DateTimeTz!) {
    relocationViews(id: $id, start: $start, end: $end) {
      relocation {
        view_count
        hour
      }
      region_average {
        view_count
        hour
      }
    }
  }
`);
