import { ReactNode } from "react";
import { cn } from "@/lib/utils";

type DescriptionListCardProps = {
  title: string;
  subtitle: string;
  items: DescriptionListItem[];
  banner?: ReactNode;
  className?: string;
};

export type DescriptionListItem = {
  key: string;
  value?: ReactNode;
  isHidden?: boolean;
};

export function DescriptionListCard({
  title,
  subtitle,
  items,
  banner,
  className,
}: DescriptionListCardProps) {
  return (
    <div
      className={cn(
        "border-b border-t border-gray-200 bg-white p-6 shadow-sm sm:rounded-lg sm:border",
        className,
      )}
    >
      <div className="">
        <h3 className="text-base font-semibold leading-7 text-gray-900">
          {title}
        </h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
          {subtitle}
        </p>
        {banner}
      </div>
      <div className="mt-6 border-t border-gray-100">
        <DescriptionList items={items} />
      </div>
    </div>
  );
}

export function DescriptionList({
  items,
  className,
}: {
  items: DescriptionListItem[];
  className?: string;
}) {
  return (
    <dl className={cn("divide-y divide-gray-100", className)}>
      {items.map((item, idx) => {
        if (item?.isHidden) {
          return null;
        }

        return (
          <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4" key={idx}>
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {item.key}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {item.value ?? "--"}
            </dd>
          </div>
        );
      })}
    </dl>
  );
}
