import { VariablesOf } from "@graphql-typed-document-node/core";
import {
  BookingChangeRequestListItem,
  bookingChangeRequestListQuery,
} from "@/app/Bookings/GraphQL/bookingChangeRequestListQuery";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import { useBookingChangeRequestActions } from "@/app/Bookings/Hooks/useBookingChangeRequestActions";
import { TableId } from "@/app/Common/Utils/tableIds";
import { ModelLinkCell } from "@/lib/Components/DataTable/Cells/ModelLinkCell";
import { bookingChangeRequestStatusColorMap } from "@/app/Bookings/Utils/bookingChangeRequestStatusColorMap";
import { Badge } from "@/components/catalyst/badge";
import { useTranslations } from "use-intl";
import { TimeSinceNowCell } from "@/lib/Components/DataTable/TimeSinceNowCell";

type BookingChangeRequestColumnId =
  | "id"
  | "status"
  | "request"
  | "created_at"
  | "booking";
export function ChangeRequestTable({
  queryVariables,
  id,
}: {
  id: TableId;
  queryVariables?: Partial<VariablesOf<typeof bookingChangeRequestListQuery>>;
}) {
  const t = useTranslations("bookingChangeRequest");
  const getActions = useBookingChangeRequestActions();

  const columns: DataTableColDef<
    BookingChangeRequestListItem,
    any,
    BookingChangeRequestColumnId
  >[] = [
    {
      id: "booking",
      header: "Booking",
      size: 100,
      cell: ({ row }) => (
        <ModelLinkCell
          to={"/bookings/$bookingId"}
          params={{
            bookingId: row.original.booking.id,
          }}
        >
          {row.original.booking.reference}
        </ModelLinkCell>
      ),
    },
    {
      id: "status",
      header: "Status",
      size: 50,
      cell: ({ row }) => (
        <Badge
          color={bookingChangeRequestStatusColorMap[row.original.status]}
          className="capitalize"
        >
          {t(`status.${row.original.status}`)}
        </Badge>
      ),
    },
    {
      id: "request",
      header: "Request",
      cell: ({ row }) => {
        return <div className="text-gray-500">{row.original.request}</div>;
      },
    },
    {
      id: "created_at",
      header: "Created",
      accessorFn: (row) => row.created_at,
      cell: TimeSinceNowCell,
    },
  ];

  return (
    <DataTable
      id={id}
      title="Change requests"
      searchable={false}
      document={bookingChangeRequestListQuery}
      columnToggles={false}
      columns={columns}
      getActions={getActions}
      getQueryVariables={({ pagination }) => {
        return {
          first: pagination.pageSize,
          page: pagination.pageIndex,
          ...queryVariables,
        };
      }}
      accessor={(data) => data.bookingChangeRequests}
    />
  );
}
