import { useMatch } from "@tanstack/react-router";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { contactRecordQuery } from "@/app/Contacts/GraphQL/contactRecordQuery";

export function useContactRecord() {
  const match = useMatch({
    from: "/_app/contacts/$id",
  });

  const { data } = useSuspenseGqlQuery(contactRecordQuery, {
    id: match?.params.id,
  });

  return data.contact;
}
