import { TaskListItem, taskListQuery } from "@/app/Tasks/GraphQL/taskListQuery";
import { CellContext } from "@tanstack/react-table";
import {
  QueryTasksOrderByColumn,
  QueryTasksOrderByOrderByClause,
  SortOrder,
  TaskListQueryVariables,
  TaskStatus,
  TaskType,
} from "@/gql/graphql";
import { formatDateTimeFromNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { Link } from "@tanstack/react-router";
import { cn } from "@/lib/utils";
import { Text } from "@/components/catalyst/text";
import { useTranslations } from "use-intl";
import {
  DataTable,
  DataTableColDef,
  DataTableColumnHeader,
} from "@/lib/Components/DataTable/DataTable";
import { TableId } from "@/app/Common/Utils/tableIds";
import { useTaskActions } from "@/app/Tasks/Hooks/useTaskActions";
import { Badge } from "@/components/catalyst/badge";
import { taskStatusMap } from "@/app/Tasks/Utils/taskStatusMap";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { Button } from "@/components/catalyst/button";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { RelocationCompletedTaskDialog } from "@/app/Tasks/Components/RelocationCompletedTaskDialog";

type TaskListColumnId =
  | "item"
  | "contact"
  | "due_at"
  | "status"
  | "related_to_type"
  | "related_to_id"
  | "relatedTo"
  | "type"
  | "description"
  | "created_at"
  | "updated_at"
  | "actions";

export function TaskList({
  queryVariables,
  id,
}: {
  id: TableId;
  queryVariables?: Partial<Omit<TaskListQueryVariables, "page" | "first">>;
}) {
  const t = useTranslations("task");
  const getActions = useTaskActions();
  const { isAdmin } = useTenant();

  const columns: DataTableColDef<TaskListItem, any, TaskListColumnId>[] = [
    {
      id: "item",
      header: "Item",
      cell: RelatedToCell,
    },
    {
      id: "contact",
      header: "Supplier",
      cell: ({ row }) => {
        if (row.original.relatedTo?.__typename === "Booking") {
          return (
            <div>
              <Link
                className="text-blue-500 underline"
                to="/suppliers/$id"
                params={{
                  id: row.original.relatedTo.relocation.supplier.id,
                }}
              >
                {row.original.relatedTo.relocation.supplier.name}
              </Link>
            </div>
          );
        }

        return "--";
      },
      isHidden: !isAdmin,
    },
    {
      id: "status",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      accessorFn: (task) => task.status,
      cell: ({ row }) => {
        const task = row.original;
        return (
          <Badge color={taskStatusMap[task.status]} className="capitalize">
            {t(`status.${task.status}`)}
          </Badge>
        );
      },
    },
    {
      id: "description",
      header: "Description",
      accessorFn: (task) => task.description,
      size: 300,
      cell: ({ row }) => {
        return (
          <Text className="max-w-lg whitespace-normal text-xs">
            {row.original.description}
          </Text>
        );
      },
    },
    {
      id: "due_at",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Due" />
      ),
      accessorFn: (task) => task.due_at,
      cell: ({ row }) => {
        const task = row.original;
        return (
          <span
            className={cn("text-gray-500", {
              "font-semibold text-red-500": task.status === TaskStatus.Overdue,
            })}
          >
            {formatDateTimeFromNow(task.due_at)}
          </span>
        );
      },
    },
    {
      id: "actions",
      cell: TaskActionCell,
    },
  ];

  return (
    <DataTable
      id={id}
      columns={columns}
      document={taskListQuery}
      searchable={false}
      columnToggles={false}
      title="Tasks"
      accessor={(res) => res.tasks}
      getActions={getActions}
      getQueryVariables={({ pagination, sorting }) => {
        const order: QueryTasksOrderByOrderByClause[] = [];

        sorting.map((sort) => {
          if (sort.id === "status") {
            order.push({
              column: QueryTasksOrderByColumn.Status,
              order: sort.desc ? SortOrder.Desc : SortOrder.Asc,
            });
          }

          if (sort.id === "due_at") {
            order.push({
              column: QueryTasksOrderByColumn.DueAt,
              order: sort.desc ? SortOrder.Desc : SortOrder.Asc,
            });
          }
        });

        return {
          first: pagination.pageSize,
          page: pagination.pageIndex,
          orderBy: order.length ? order : undefined,
          ...queryVariables,
        };
      }}
    />
  );
}

function TaskActionCell({ row }: CellContext<TaskListItem, any>) {
  const { open } = useDialog(RelocationCompletedTaskDialog);

  if (row.original.type !== TaskType.ConfirmRelocationWasCompleted) {
    return null;
  }

  return (
    <div className="flex gap-2">
      <Button
        color="green"
        onClick={() => {
          open({
            task: row.original,
            tab: "completed",
          });
        }}
      >
        Complete
      </Button>
      <Button
        onClick={() => {
          open({
            task: row.original,
            tab: "not_completed",
          });
        }}
        color="red"
      >
        Cancel
      </Button>
    </div>
  );
}

function RelatedToCell({ row }: CellContext<TaskListItem, any>) {
  const task = row.original;

  if (task.relatedTo?.__typename === "Booking") {
    return (
      <Link
        className="text-blue-500 underline"
        to={"/bookings/$bookingId"}
        params={{
          bookingId: task.relatedTo.id,
        }}
        search={() => {
          return {};
        }}
      >
        {task.relatedTo.reference}
      </Link>
    );
  }
}
