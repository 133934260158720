import { GenericFieldArray } from "@/lib/Components/Form/FieldArray/GenericFieldArray";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { IllustrationMobilePost } from "@/assets/Illustrations";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { ReactNode } from "react";
import { MailIcon } from "lucide-react";
import {
  Description,
  ErrorMessage,
  Fieldset,
  Label,
} from "@/components/catalyst/fieldset";
import {
  Checkbox,
  CheckboxField,
  CheckboxGroup,
} from "@/components/catalyst/checkbox";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { UpsertContactEmailInput } from "@/gql/graphql";

type ContactEmailsFormProps = {
  label?: ReactNode;
};
export function ContactEmailsForm({ label }: ContactEmailsFormProps) {
  const { setFieldValue, isEditing, getFieldMeta } = useForm();

  return (
    <GenericFieldArray<UpsertContactEmailInput>
      label={label}
      fieldArrayKey={"emails.upsert"}
      lineItemLabel="email address"
      Icon={MailIcon}
      newLineItemValues={{
        email: "",
        is_billing: false,
        is_primary: false,
        is_invoice_reminder: false,
      }}
      emptyState={
        <EmptyState
          Icon={IllustrationMobilePost}
          header="No emails"
          subheader="Add a email for the contact"
        />
      }
    >
      {(email, index) => {
        const { error: billingError } = getFieldMeta(
          `emails.upsert.${index}.is_billing`,
        );

        return (
          <>
            <TextInput
              type="email"
              name={`emails.upsert.${index}.email`}
              label={`Email`}
            />
            <Fieldset className="col-span-9">
              <CheckboxGroup>
                <CheckboxField>
                  <Checkbox
                    disabled={!isEditing}
                    checked={!!email.is_primary}
                    onChange={(val) => {
                      setFieldValue(`emails.upsert.${index}.is_primary`, val);
                    }}
                  />
                  <Label>System events</Label>
                  <Description>
                    Receive system events and notifications about your account.
                  </Description>
                </CheckboxField>
                <CheckboxField>
                  <Checkbox
                    disabled={!isEditing}
                    checked={!!email.is_billing}
                    onChange={(val) => {
                      setFieldValue(`emails.upsert.${index}.is_billing`, val);
                    }}
                  />
                  <Label>Billing</Label>
                  <Description>
                    Receive billing notifications and invoices.
                  </Description>

                  {billingError && (
                    <div className="col-span-full">
                      <ErrorMessage>{billingError}</ErrorMessage>
                    </div>
                  )}
                </CheckboxField>

                <CheckboxField>
                  <Checkbox
                    disabled={!isEditing}
                    checked={!!email.is_invoice_reminder}
                    onChange={(val) => {
                      setFieldValue(
                        `emails.upsert.${index}.is_invoice_reminder`,
                        val,
                      );
                    }}
                  />
                  <Label>Invoice Reminders</Label>
                  <Description>
                    Receive reminders about overdue invoices.
                  </Description>
                </CheckboxField>
              </CheckboxGroup>
            </Fieldset>
          </>
        );
      }}
    </GenericFieldArray>
  );
}
