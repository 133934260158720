import { createRoute } from "@tanstack/react-router";
import { ReportScreen } from "@/app/Reports/Screens/ReportScreen";
import { ReportSelectionScreen } from "@/app/Reports/Screens/ReportSelectionScreen";
import { ReportRelocationScreen } from "@/app/Reports/Screens/ReportRelocationScreen";
import { ReportBookingScreen } from "@/app/Reports/Screens/ReportBookingScreen";
import { appLayoutRoute } from "@/routes";
import { ReportInvoiceScreen } from "@/app/Reports/Screens/ReportInvoiceScreen";
import { ReportSupplierScreen } from "../Screens/ReportSupplierScreen";
import { ReportPaymentScreen } from "@/app/Reports/Screens/ReportPaymentScreen";
import { ReportSalesScreen } from "@/app/Reports/Screens/ReportSalesScreen";
import { ReportMovementScreen } from "@/app/Reports/Screens/ReportMovementScreen";

export const reportRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/reports",
  component: ReportScreen,
});
export const reportSelectionRoute = createRoute({
  getParentRoute: () => reportRoute,
  path: "/",
  component: ReportSelectionScreen,
});
export const reportBookingsRoute = createRoute({
  getParentRoute: () => reportRoute,
  path: "/bookings",
  component: ReportBookingScreen,
});

export const reportCancellationsRoute = createRoute({
  getParentRoute: () => reportRoute,
  path: "/cancellations",
  component: ReportBookingScreen,
});

export const reportRelocationsRoute = createRoute({
  getParentRoute: () => reportRoute,
  path: "/relocations",
  component: ReportRelocationScreen,
});

export const reportInvoicesRoutes = createRoute({
  getParentRoute: () => reportRoute,
  path: "/invoices",
  component: ReportInvoiceScreen,
});

export const reportSupplierRoute = createRoute({
  getParentRoute: () => reportRoute,
  path: "/suppliers",
  component: ReportSupplierScreen,
});

export const reportPaymentRoute = createRoute({
  getParentRoute: () => reportRoute,
  path: "/payments",
  component: ReportPaymentScreen,
});

export const reportSalesRoute = createRoute({
  getParentRoute: () => reportRoute,
  path: "/sales",
  component: ReportSalesScreen,
});

export const reportMovementRoute = createRoute({
  getParentRoute: () => reportRoute,
  path: "/movements",
  component: ReportMovementScreen,
});
