import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { StripePaymentMethod } from "@/gql/graphql";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { deleteStripePaymentMethodMutation } from "@/app/Stripe/StripePaymentMethods/GraphQL/deleteStripePaymentMethodMutation";
import { StarIcon } from "@heroicons/react/20/solid";
import { updateStripePaymentMethodMutation } from "@/app/Stripe/StripePaymentMethods/GraphQL/updateStripePaymentMethodMutation";

export function useStripePaymentMethodActions(): ActionsHook<
  Pick<StripePaymentMethod, "id" | "is_primary">
> {
  const { mutateAsync } = useGqlMutation(deleteStripePaymentMethodMutation);
  const { mutateAsync: updateStripePaymentMethod } = useGqlMutation(
    updateStripePaymentMethodMutation,
  );

  return (paymentMethod) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                intent: "primary",
                isHidden: paymentMethod.is_primary,
                label: "Make Primary",
                Icon: StarIcon,
                handleAction: () => {
                  return updateStripePaymentMethod({
                    id: paymentMethod.id,
                    input: {
                      is_primary: true,
                    },
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                isHidden: paymentMethod.is_primary,
                intent: "danger",
                label: "Delete",
                Icon: TrashIcon,
                handleAction: () => {
                  return mutateAsync({
                    id: paymentMethod.id,
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
