import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { CreateSupplierInsuranceOptionInput } from "@/gql/graphql";
import { SupplierInsuranceOptionForm } from "@/app/Suppliers/Forms/SupplierInsuranceOptionForm";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { supplierInsuranceOptionValidationZod } from "@/app/Suppliers/Validation/supplierInsuranceOptionValidation";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createSupplierInsuranceOptionMutation } from "@/app/Suppliers/GraphQL/supplierInsuranceOptionMutations";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";

export type SupplierInsuranceOptionCreateDialogProps = {
  initialValues?: Partial<CreateSupplierInsuranceOptionInput>;
};
export function SupplierInsuranceOptionCreateDialog({
  initialValues,
}: SupplierInsuranceOptionCreateDialogProps) {
  const { mutateAsync } = useGqlMutation(createSupplierInsuranceOptionMutation);
  const { close, resolve } = useDialogState();

  return (
    <GenericDialog title="Add Insurance Option">
      <GenericForm<CreateSupplierInsuranceOptionInput>
        validationSchema={toFormikValidationSchema(
          supplierInsuranceOptionValidationZod,
        )}
        initialValues={{
          name: "",
          bond_amount: null,
          standard_liability_amount: null,
          liability_reduction_options: "",
          supplier: null!,
          ...initialValues,
        }}
        onSubmit={async (values) => {
          const res = await mutateAsync({
            input: {
              ...values,
            },
          });

          resolve?.(res.createSupplierInsuranceOption);

          close();
        }}
      >
        <SupplierInsuranceOptionForm type="create" />
        <DialogSubmitButton text={"Add insurance option"} />
      </GenericForm>
    </GenericDialog>
  );
}
