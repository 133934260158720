import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { TaxInclusionType } from "@/gql/graphql";

export function TaxInclusionInput(
  props: Omit<SelectInputProps<any>, "options">,
) {
  return (
    <SelectInput
      {...props}
      options={[
        {
          label: "Tax inclusion",
          options: [
            {
              value: TaxInclusionType.Exclusive,
              label: "Exclusive",
            },
            {
              value: TaxInclusionType.Inclusive,
              label: "Inclusive",
            },
            {
              value: TaxInclusionType.NoTax,
              label: "No tax",
            },
          ],
        },
      ]}
    />
  );
}
