import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { SupplierInsuranceOptionForm } from "../Forms/SupplierInsuranceOptionForm";
import { UpdateSupplierInsuranceOptionInput } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierInsuranceOptionMutation } from "@/app/Suppliers/GraphQL/supplierInsuranceOptionMutations";
import { supplierInsuranceOptionRecord } from "@/app/Suppliers/GraphQL/supplierInsuranceOptionRecord";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";

type SupplierInsuranceOptionEditDialogProps = {
  insuranceOptionId: string;
};
export function SupplierInsuranceOptionEditDialog({
  insuranceOptionId,
}: SupplierInsuranceOptionEditDialogProps) {
  const { mutateAsync } = useGqlMutation(updateSupplierInsuranceOptionMutation);
  const { data } = useSuspenseGqlQuery(supplierInsuranceOptionRecord, {
    id: insuranceOptionId,
  });
  const { close } = useDialogState();

  return (
    <GenericDialog title={"Edit Insurance Option"}>
      <GenericForm<UpdateSupplierInsuranceOptionInput>
        initialValues={{
          name: data.supplierInsuranceOption.name,
          bond_amount: data.supplierInsuranceOption.bond_amount,
          standard_liability_amount:
            data.supplierInsuranceOption.standard_liability_amount,
          liability_reduction_options:
            data.supplierInsuranceOption.liability_reduction_options,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: insuranceOptionId,
            input: values,
          });
          close();
        }}
      >
        <SupplierInsuranceOptionForm type="update" />
        <DialogSubmitButton text={"Update insurance option"} />
      </GenericForm>
    </GenericDialog>
  );
}
