import { SettingCard } from "@/app/Settings/Settings/Components/SettingFormCard";
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
} from "@/components/catalyst/description-list";
import { useState } from "react";
import { Currency } from "@/gql/graphql";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { metaQuery } from "@/app/Meta/GraphQL/metaQuery";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";

export function SettingsCurrencyScreen() {
  const [currency, setCurrency] = useState(Currency.Aud);
  const { data } = useSuspenseGqlQuery(metaQuery, {});

  const selectedRate = data?.meta.default_rates.find(
    (rate) => rate.currency === currency,
  );

  if (!selectedRate) {
    throw new Error(`Currency ${currency} not found`);
  }

  return (
    <SettingCard
      title="Default rates"
      description="The default rates for each currency"
    >
      <DescriptionList>
        <DescriptionTerm>Currency</DescriptionTerm>
        <DescriptionDetails>
          <select
            className="border-0"
            value={currency}
            onChange={(e) => {
              setCurrency(e.target.value as Currency);
            }}
          >
            {Object.values(Currency).map((currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </select>
        </DescriptionDetails>

        <DescriptionTerm>Booking fee</DescriptionTerm>
        <DescriptionDetails>
          {formatCurrency(selectedRate.booking_fee_amount, currency)}
        </DescriptionDetails>

        <DescriptionTerm>Charge per move</DescriptionTerm>
        <DescriptionDetails>
          {formatCurrency(selectedRate.charge_per_move_amount, currency)}
        </DescriptionDetails>

        <DescriptionTerm>Holding deposit</DescriptionTerm>
        <DescriptionDetails>
          {formatCurrency(selectedRate.holding_deposit_amount, currency)}
        </DescriptionDetails>

        <DescriptionTerm>Relocation rate</DescriptionTerm>
        <DescriptionDetails>
          {formatCurrency(selectedRate.hire_unit_rate, currency)}
        </DescriptionDetails>

        <DescriptionTerm>Full price deposit</DescriptionTerm>
        <DescriptionDetails>
          {formatCurrency(selectedRate.extra_hire_unit_deposit_rate, currency)}
        </DescriptionDetails>
      </DescriptionList>
    </SettingCard>
  );
}
