import { Link } from "@/components/catalyst/link";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";

export function NoSupplierEmptyState() {
  return (
    <Link
      to={"/settings/suppliers"}
      className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      <BuildingOfficeIcon className="mx-auto h-12 w-12 text-gray-400" />
      <span className="mt-2 block text-sm font-semibold text-gray-900">
        No supplier selected
      </span>
    </Link>
  );
}
