import { graphql } from "@/gql";

export const createCityMutation = graphql(`
  mutation CreateCity($input: CreateCityInput!) {
    createCity(input: $input) {
      id
    }
  }
`);

export const updateCityMutation = graphql(`
  mutation UpdateCity($id: ID!, $input: UpdateCityInput!) {
    updateCity(id: $id, input: $input) {
      id
    }
  }
`);
