import * as yup from "yup";

export const officeValidation = yup.object({
  name: yup.string().required(),
  city: yup.object().required(),
  supplier: yup.object().required(),
  email: yup.string().email().nullable().optional(),
  phone: yup.string().nullable().optional(),
  address: yup
    .object({
      upsert: yup
        .object({
          name: yup.string().min(2).required(),
        })
        .required(),
    })
    .required(),
});
