import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import { useRelocationRecord } from "@/app/Relocations/Hooks/useRelocationRecord";
import { WaitlistTable } from "@/app/Waitlists/Components/WaitlistTable";
import { QueryWaitlistsOrderColumn, SortOrder } from "@/gql/graphql";

export function RelocationWaitlistScreen() {
  const record = useRelocationRecord();

  return (
    <RecordScreenCard title="Waitlists">
      <WaitlistTable
        id="relocation-waitlists"
        searchable={false}
        getQueryVariables={({ pagination }) => {
          return {
            page: pagination.pageIndex,
            first: pagination.pageSize,
            relocationId: record.id,
            order: [
              {
                order: SortOrder.Desc,
                column: QueryWaitlistsOrderColumn.CreatedAt,
              },
            ],
          };
        }}
      />
    </RecordScreenCard>
  );
}
