import { z } from "zod";
import { InvoiceStatus } from "@/gql/graphql";
import { createRoute, Outlet } from "@tanstack/react-router";
import { InvoiceRecordScreen } from "@/app/Invoices/Screens/InvoiceRecordScreen";
import { InvoiceDetailScreen } from "@/app/Invoices/Screens/InvoiceDetailScreen";
import { InvoiceActivityScreen } from "@/app/Invoices/Screens/InvoiceActivityScreen";
import { InvoiceCreateScreen } from "@/app/Invoices/Screens/InvoiceCreateScreen";
import { appLayoutRoute } from "@/routes";
import { getInvoiceListQueryVariables } from "@/app/Invoices/Utils/getInvoiceListQueryVariables";
import {
  fetchQuery,
  prefetchInfiniteQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { invoiceListQuery } from "@/app/Invoices/GraphQL/invoiceListQuery";
import { invoiceRecordQuery } from "@/app/Invoices/GraphQL/invoiceRecordQuery";
import { InvoiceTableScreen } from "@/app/Invoices/Screens/InvoiceTableScreen";
import { InvoiceListScreen } from "@/app/Invoices/Screens/InvoiceListScreen";

const invoiceListSearchParams = z.object({
  invoiceStatus: z
    .array(z.nativeEnum(InvoiceStatus))
    .optional()
    .catch(undefined),
});

export type InvoiceListSearchParams = z.infer<typeof invoiceListSearchParams>;
export const invoicesRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/invoices",
  component: Outlet,
});

export const invoiceCreateRoute = createRoute({
  getParentRoute: () => invoicesRoute,
  path: "/create",
  component: InvoiceCreateScreen,
});

export const invoiceTableRoute = createRoute({
  getParentRoute: () => invoicesRoute,
  path: "/",
  component: InvoiceTableScreen,
  validateSearch: invoiceListSearchParams,
  loaderDeps: ({ search: { invoiceStatus } }) => ({
    invoiceStatus,
  }),
});

export const invoiceRecordLayoutRoute = createRoute({
  getParentRoute: () => invoicesRoute,
  id: "_invoiceRecordLayout",
  component: InvoiceListScreen,
  loader: ({ deps, context: { auth } }) => {
    return prefetchInfiniteQuery(
      invoiceListQuery,
      getInvoiceListQueryVariables(deps),
      auth,
    );
  },
});

export const invoiceRoute = createRoute({
  getParentRoute: () => invoiceRecordLayoutRoute,
  path: "$id",
  component: InvoiceRecordScreen,
  loader: ({ params: { id }, context: { auth } }) => {
    return fetchQuery(invoiceRecordQuery, { id }, auth);
  },
});

export const invoiceDetailRoute = createRoute({
  getParentRoute: () => invoiceRoute,
  path: "/",
  component: InvoiceDetailScreen,
});

export const invoiceActivityRoute = createRoute({
  getParentRoute: () => invoiceRoute,
  path: "/activity",
  component: InvoiceActivityScreen,
});
