import { CellContext } from "@tanstack/react-table";
import { WaitlistListItem } from "@/app/Waitlists/GraphQL/waitlistListQuery";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

export function WaitlistMatchesCell({
  row,
}: CellContext<WaitlistListItem, any>) {
  if (row.original.relocations_count === 0) {
    return <div className="text-gray-500">0</div>;
  }

  return (
    <div>
      <a
        href={row.original.relocationLink}
        target="_blank"
        className="flex items-center space-x-2 text-blue-500 underline"
        rel="noreferrer"
      >
        <span>{row.original.relocations_count}</span>
        <ArrowTopRightOnSquareIcon className="h-4 w-4" />
      </a>
    </div>
  );
}
