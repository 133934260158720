import { EmptyRecordScreen } from "@/lib/Components/Screens/EmptyRecordScreen";
import { PlusIcon } from "@heroicons/react/24/outline";

export function VehicleEmptyScreen() {
  return (
    <EmptyRecordScreen
      title="Select a vehicle"
      flair="Vehicles"
      description="Manage you vehicle details here"
      items={[
        {
          name: "Create a vehicle",
          description: "Need to list a different vehicle?",
          Icon: PlusIcon,
          toOptions: {
            to: "/vehicles/create",
            search: (search) => search,
          },
        },
      ]}
    />
  );
}
