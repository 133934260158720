import { ContactRecord } from "@/app/Contacts/GraphQL/contactRecordQuery";
import { Avatar } from "@/components/catalyst/avatar";
import { getInitials } from "@/lib/Utils/getInitials";

export function ContactIcon({
  model,
  className,
}: {
  model: ContactRecord;
  className?: string;
}) {
  const logo = model?.profilePicture;

  return (
    <Avatar
      className={className}
      media={logo}
      initials={getInitials(model.name ?? "")}
    />
  );
}
