import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { Region, State } from "@/gql/graphql";
import { useTranslations } from "use-intl";

export function StateInput({
  region,
  ...props
}: Omit<SelectInputProps<any>, "options"> & {
  region?: Region | null;
}) {
  const t = useTranslations("city");

  const sections = [
    {
      label: "Australia",
      region: Region.Au,
      states: [
        State.AuAct,
        State.AuNsw,
        State.AuNt,
        State.AuQld,
        State.AuSa,
        State.AuTas,
        State.AuVic,
        State.AuWa,
      ],
    },
    {
      label: "United States",
      region: Region.Us,
      states: [
        State.UsAk,
        State.UsAl,
        State.UsAr,
        State.UsAz,
        State.UsCa,
        State.UsCo,
        State.UsCt,
        State.UsDe,
        State.UsFl,
        State.UsGa,
        State.UsHi,
        State.UsIa,
        State.UsId,
        State.UsIl,
        State.UsIn,
        State.UsKs,
        State.UsKy,
        State.UsLa,
        State.UsMa,
        State.UsMd,
        State.UsMe,
        State.UsMi,
        State.UsMn,
        State.UsMo,
        State.UsMt,
        State.UsNc,
        State.UsNd,
        State.UsNe,
        State.UsNh,
        State.UsNj,
        State.UsNm,
        State.UsNv,
        State.UsNy,
        State.UsOh,
        State.UsOk,
        State.UsOr,
        State.UsPa,
        State.UsRi,
        State.UsSc,
        State.UsSd,
        State.UsTn,
        State.UsTx,
        State.UsUt,
        State.UsVa,
        State.UsVt,
        State.UsWa,
        State.UsWi,
        State.UsWv,
        State.UsWy,
        State.UsMs,
        State.UsDc,
      ],
    },
    {
      label: "Canada",
      region: Region.Ca,
      states: [
        State.CaAb,
        State.CaBc,
        State.CaMb,
        State.CaNb,
        State.CaNl,
        State.CaNs,
        State.CaNt,
        State.CaNu,
        State.CaOn,
        State.CaPe,
        State.CaQc,
        State.CaSk,
        State.CaYt,
      ],
    },
    {
      label: "New Zealand",
      region: Region.Nz,
      states: [
        State.NzAuk,
        State.NzBop,
        State.NzCan,
        State.NzGis,
        State.NzHkb,
        State.NzMbh,
        State.NzMwt,
        State.NzNtl,
        State.NzStl,
        State.NzTas,
        State.NzTki,
        State.NzWgn,
        State.NzWko,
        State.NzWtc,
        State.NzNsn,
        State.NzOta,
      ],
    },
    {
      label: "Japan",
      region: Region.Jp,
      states: [State.Jp_01, State.Jp_02, State.Jp_03],
    },
    {
      label: "Europe",
      region: Region.Eu,
      states: [
        State.Al,
        State.Am,
        State.At,
        State.Bg,
        State.Ba,
        State.Be,
        State.By,
        State.Ch,
        State.Cy,
        State.Cz,
        State.De,
        State.Dk,
        State.Ee,
        State.Es,
        State.Fi,
        State.Fr,
        State.Gb,
        State.Gr,
        State.Hr,
        State.Hu,
        State.Ie,
        State.It,
        State.Lt,
        State.Lv,
        State.Me,
        State.Mk,
        State.Nl,
        State.No,
        State.Pl,
        State.Pt,
        State.Ro,
        State.Rs,
        State.Se,
        State.Si,
        State.Sk,
        State.Xk,
      ],
    },
    {
      label: "South Africa",
      region: Region.Sacu,
      states: [
        State.Ls,
        State.ZaEc,
        State.ZaFs,
        State.ZaGp,
        State.ZaKzn,
        State.ZaLp,
        State.ZaMp,
        State.ZaNc,
        State.ZaNw,
        State.ZaWc,
      ],
    },
    {
      label: "Botswana",
      region: Region.Sacu,
      states: [
        State.BwCe,
        State.BwCh,
        State.BwGh,
        State.BwKg,
        State.BwKl,
        State.BwKw,
        State.BwNe,
        State.BwNw,
        State.BwSe,
        State.BwSo,
      ],
    },
    {
      label: "Lesotho",
      region: Region.Sacu,
      states: [State.Ls],
    },
    {
      label: "Swaziland",
      region: Region.Sacu,
      states: [State.SzHh, State.SzLu, State.SzMa, State.SzSh],
    },
    {
      label: "Namibia",
      region: Region.Sacu,
      states: [
        State.NaCa,
        State.NaEr,
        State.NaHa,
        State.NaKa,
        State.NaKe,
        State.NaKh,
        State.NaKu,
        State.NaKw,
        State.NaOd,
        State.NaOh,
        State.NaOn,
        State.NaOs,
        State.NaOt,
        State.NaOw,
      ],
    },
  ];

  const options = sections
    .filter((section) => {
      if (region) {
        return section.region === region;
      }
      return true;
    })
    .map((section) => ({
      label: section.label,
      options: section.states
        .map((state) => ({
          value: state,
          label: t(`state.${state}`) as string,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    }));

  return (
    <SelectInput
      options={options}
      filter={(value, search) => {
        const label: string = t(`state.${value as State}`);
        return label.toLowerCase().includes(search.toLowerCase()) ? 1 : 0;
      }}
      {...props}
    />
  );
}
