import { CityInput } from "@/app/Cities/Cities/Components/CityInput";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";

export function WaitlistForm() {
  return (
    <>
      <DateInput
        name={"earliest_departure_date"}
        label={"Earliest departure date"}
      />
      <DateInput
        name={"latest_departure_date"}
        label={"Latest departure date"}
      />

      <div className="col-span-6" />
      <CityInput name="departureCity.connect" label="Depart from" />
      <CityInput name="deliveryCity.connect" label="Deliver to" />
    </>
  );
}
