import {
  DataTable,
  DataTableColDef,
  DataTableColumnHeader,
} from "@/lib/Components/DataTable/DataTable";
import {
  OrganisationListItem,
  organisationListQueryGraphql,
} from "@/app/Organisations/GraphQL/organisationListQuery.graphql";
import { TableId } from "@/app/Common/Utils/tableIds";
import { useOrganisationActions } from "@/app/Organisations/Hooks/useOrganisationActions";
import { DeEmphasisedCell } from "@/lib/Components/DataTable/DeEmphasisedCell";
import { TimeSinceNowCell } from "@/lib/Components/DataTable/TimeSinceNowCell";
import { QueryOrganisationsOrderByColumn, SortOrder } from "@/gql/graphql";

type OrganisationTableColumnId =
  | "name"
  | "is_verified"
  | "sales_agent"
  | "created_at";

const sortingColumn = {
  name: QueryOrganisationsOrderByColumn.Name,
  is_verified: QueryOrganisationsOrderByColumn.IsVerified,
  created_at: QueryOrganisationsOrderByColumn.CreatedAt,
  sales_agent: QueryOrganisationsOrderByColumn.Name,
};

export function OrganisationTable({ id }: { id: TableId }) {
  const getActions = useOrganisationActions();

  const columns: DataTableColDef<
    OrganisationListItem,
    any,
    OrganisationTableColumnId
  >[] = [
    {
      id: "name",
      accessorFn: (data) => data.name,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
    },
    {
      id: "is_verified",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Verified" />
      ),
      accessorFn: (data) => (data.is_verified ? "Yes" : "No"),
      cell: DeEmphasisedCell,
    },
    {
      id: "sales_agent",
      header: "Sales Agent",
      accessorFn: (data) => data.salesAgent?.name,
      cell: DeEmphasisedCell,
    },
    {
      id: "created_at",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Created" />
      ),
      accessorFn: (data) => data.created_at,
      cell: TimeSinceNowCell,
    },
  ];

  return (
    <DataTable
      columns={columns}
      getActions={getActions}
      document={organisationListQueryGraphql}
      initialSorting={[
        {
          id: "is_verified",
          desc: false,
        },
      ]}
      getQueryVariables={({ pagination, search, sorting }) => {
        const orderBy = sorting.map((sort) => ({
          order: sort.desc ? SortOrder.Desc : SortOrder.Asc,
          column: sortingColumn[sort.id],
        }));

        return {
          search,
          page: pagination.pageIndex,
          first: pagination.pageSize,
          orderBy: !search ? orderBy : undefined,
        };
      }}
      accessor={(data) => data.organisations}
      id={id}
    />
  );
}
