import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";
import { RelocationMultiTable } from "@/app/Relocations/Components/RelocationMultiTable";
import { useMatchRoute, useNavigate, useSearch } from "@tanstack/react-router";

export function SupplierRelocationScreen() {
  const record = useSupplierRecord();
  const { relocationStatus } = useSearch({
    from: "/_app/suppliers/_supplierRecordLayout/$id/relocations",
  });
  const navigate = useNavigate({
    from: "/suppliers/$id/relocations",
  });

  const matchRoute = useMatchRoute();

  return (
    <div className="py-6">
      <RelocationMultiTable
        tableId={"supplier-relocations"}
        supplierId={record.id}
        statuses={relocationStatus}
        isRouteMatched={(statuses) => {
          return !!matchRoute({
            to: "/suppliers/$id/relocations",
            search: {
              relocationStatus: statuses,
            },
            includeSearch: true,
          });
        }}
        onStatusChange={(newStatuses) => {
          navigate({
            search: (old) => {
              return {
                ...old,
                relocationStatus: newStatuses,
              };
            },
          });
        }}
      />
    </div>
  );
}
