import { SettingCard } from "@/app/Settings/Settings/Components/SettingFormCard";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { SupplierNotificationSettingEventButtons } from "@/app/Suppliers/Components/SupplierNotificationSettingEventButtons";
import { redirect } from "@tanstack/react-router";

export function SettingsNotificationScreen() {
  const { supplier } = useGlobalSupplier();
  if (!supplier) {
    throw redirect({
      to: "/settings",
    });
  }

  return (
    <div className="space-y-12">
      <SettingCard
        title="Events"
        description="Subscribe to events in the system and recieve email notifications when they occur."
      >
        <SupplierNotificationSettingEventButtons
          supplier={supplier}
          title={null}
          className="p-0"
        />
      </SettingCard>
    </div>
  );
}
