import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { CreatePaymentInput, ModelType, PaymentType } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { createSupplierPaymentValidation } from "@/app/Payments/Validators/createSupplierPaymentValidation";
import { PaymentForm } from "@/app/Payments/Forms/PaymentForm";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createPaymentMutation } from "@/app/Payments/GraphQL/paymentMutations";
import { BookingRecord } from "@/app/Bookings/GraphQL/bookingRecordQuery";

type BookingPaymentCreateDialogProps = {
  booking: BookingRecord;
};
export function BookingPaymentCreateDialog({
  booking,
}: BookingPaymentCreateDialogProps) {
  const { mutateAsync } = useGqlMutation(createPaymentMutation);
  const { close } = useDialogState();

  return (
    <GenericDialog title={"Add supplier payment"}>
      <GenericForm<CreatePaymentInput>
        validationSchema={createSupplierPaymentValidation}
        initialValues={{
          type: PaymentType.SupplierPayment,
          amount: null!,
          attributedTo: {
            connect: {
              type: ModelType.Booking,
              id: booking.id,
            },
          },

          due_date: null,

          payee: {
            connect: booking.relocation.supplier.billingEntity?.id ?? null,
          },
          description: "",

          currency: booking.relocation.supplier.billing_currency,
          is_tax_included: booking.relocation.supplier.is_tax_included,
          tax_type: booking.relocation.supplier.tax_type,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            input: values,
          });

          close();
        }}
      >
        <PaymentForm
          manager={booking.relocation.supplier.managedBy ?? undefined}
        />
        <DialogSubmitButton text={"Add payment"} />
      </GenericForm>
    </GenericDialog>
  );
}
