import { BookingTable } from "@/app/Dashboard/Components/BookingTable";
import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { useMatch, useNavigate } from "@tanstack/react-router";
import { BookingStatus } from "@/gql/graphql";
import { ReactNode } from "react";
import { getBookingCancelledStatuses } from "@/app/Bookings/Utils/getBookingCancelledStatuses";
import { bookingStatusCountsQuery } from "@/app/Bookings/GraphQL/bookingStatusCountsQuery";
import {
  Navbar,
  NavbarItemLink,
  NavbarSection,
} from "@/components/catalyst/navbar";
import { Badge, BadgeColor } from "@/components/catalyst/badge";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";

export function BookingTableScreen() {
  const navigate = useNavigate();
  const { search } = useMatch({
    from: "/_app/bookings/",
  });

  return (
    <FullPageScreen>
      <Header />
      <BookingTable
        id="bookings"
        statuses={search?.bookingStatus ?? []}
        onStatusChange={(newStatuses) => {
          navigate({
            to: "/bookings",
            search: {
              ...search,
              bookingStatus: newStatuses,
            },
          });
        }}
      />
    </FullPageScreen>
  );
}

type Filter = {
  label: ReactNode;
  statuses: BookingStatus[];
  badgeColor?: BadgeColor;
  hideBadge?: boolean;
  isAdmin?: boolean;
};

const filters: Filter[] = [
  {
    label: "All",
    statuses: [],
  },
  {
    label: "Pending",
    statuses: [
      BookingStatus.AwaitingConfirmation,
      BookingStatus.Vip,
      BookingStatus.Accepted,
    ],
    badgeColor: "yellow",
  },
  {
    label: "Confirmed",
    statuses: [BookingStatus.Confirmed],
    badgeColor: "green",
  },
  {
    label: "Completed",
    statuses: [BookingStatus.Completed],
    hideBadge: true,
  },
  {
    label: "Cancelled",
    statuses: getBookingCancelledStatuses(),
    hideBadge: true,
  },

  {
    label: "Abandoned checkout",
    statuses: [BookingStatus.AwaitingPayment],
    hideBadge: true,
    isAdmin: true,
  },
];

function Header() {
  const { isAdmin } = useTenant();
  const { supplierId } = useGlobalSupplier();
  const { data } = useGqlQuery(bookingStatusCountsQuery, {
    supplierId: supplierId ?? undefined,
  });

  return (
    <div className="relative border-gray-200 sm:border-b sm:pb-0">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        Bookings
      </h3>
      <Navbar className="-mb-px">
        <NavbarSection className="max-lg:hidden">
          {filters.map(
            (
              {
                label,
                statuses,
                hideBadge,
                isAdmin: onlyShowForAdmin,
                badgeColor,
              },
              idx,
            ) => {
              if (onlyShowForAdmin && !isAdmin) {
                return null;
              }

              const count = statuses.reduce((acc, status) => {
                return (
                  acc +
                  (data?.bookingStatusCounts.find(
                    (counts) => counts.status === status,
                  )?.count ?? 0)
                );
              }, 0);

              return (
                <NavbarItemLink
                  key={idx}
                  to="/bookings"
                  search={() => {
                    return {
                      bookingStatus: statuses,
                    };
                  }}
                >
                  {label}

                  {count && !hideBadge ? (
                    <Badge className="py-0" color={badgeColor}>
                      {count}
                    </Badge>
                  ) : null}
                </NavbarItemLink>
              );
            },
          )}
        </NavbarSection>
      </Navbar>
    </div>
  );
}
