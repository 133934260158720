import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { TrashIcon } from "@heroicons/react/24/outline";
import {
  InvoicePaymentFieldsFragment,
  InvoicePaymentMethod,
} from "@/gql/graphql";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { MailIcon } from "lucide-react";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { mailInvoicePaymentMutation } from "@/app/Invoices/GraphQL/mailInvoicePaymentMutation";
import { deleteInvoicePaymentMutation } from "@/app/Invoices/GraphQL/invoicePaymentMutations";

export function useInvoicePaymentActions(): ActionsHook<InvoicePaymentFieldsFragment> {
  const { mutateAsync: deletePayment } = useGqlMutation(
    deleteInvoicePaymentMutation,
  );
  const { mutateAsync: mailPayment } = useGqlMutation(
    mailInvoicePaymentMutation,
  );

  return (invoicePayment) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "View",
                Icon: ArrowTopRightOnSquareIcon,
                intent: "neutral",
                isHidden: invoicePayment.method !== InvoicePaymentMethod.Stripe,
                handleAction: () => {
                  window.open(
                    new URL(
                      `payments/${invoicePayment.stripePayment?.payment_intent_id}`,
                      "https://dashboard.stripe.com",
                    ),
                  );
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Email receipt",
                intent: "neutral",
                Icon: MailIcon,
                handleAction: async () => {
                  await mailPayment({
                    id: invoicePayment.id,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                isHidden: invoicePayment.method === InvoicePaymentMethod.Stripe,
                label: "Delete",
                intent: "danger",
                Icon: TrashIcon,
                handleAction: async () => {
                  await deletePayment({
                    id: invoicePayment.id,
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
