import { graphql } from "@/gql";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";

export type RelocationLineReferenceListItem = ResultOf<
  typeof relocationLineReferenceListQuery
>["relocationLineReferences"]["data"][0];

export type RelocationLineReferenceListVariables = VariablesOf<
  typeof relocationLineReferenceListQuery
>;

export const relocationLineReferenceListQuery = graphql(`
  query RelocationLineReferenceList(
    $page: Int
    $first: Int!
    $orderByReference: Boolean
    $orderBy: [QueryRelocationLineReferencesOrderByOrderByClause!]
    $where: QueryRelocationLineReferencesWhereWhereConditions
    $whereLine: QueryRelocationLineReferencesWhereLineWhereHasConditions
  ) {
    relocationLineReferences(
      page: $page
      first: $first
      orderByReference: $orderByReference
      orderBy: $orderBy
      where: $where
      whereLine: $whereLine
    ) {
      data {
        ...RelocationLineReferenceFields
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);

export const relocationLineFieldsFragment = graphql(`
  fragment RelocationLineReferenceFields on RelocationLineReference {
    id
    reference
    created_at
    sold_at
    line {
      id
      expires_at
      relocations {
        id
        vehicle {
          id
          name
          code
          heroImage
        }
        currency

        inclusions {
          id
          type
          value
          description
        }

        departureOffice {
          id
          name
        }
        deliveryOffice {
          name
        }
        available_from_date
        available_to_date
      }
      supplier {
        id
        relocation_lines_valid_until
      }
    }
    booking {
      id
      name
      reference
    }
  }
`);
