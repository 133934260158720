import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { RelocationMultiTable } from "@/app/Relocations/Components/RelocationMultiTable";
import { useMatchRoute, useNavigate, useSearch } from "@tanstack/react-router";

export function RelocationTableScreen() {
  const { supplierId } = useGlobalSupplier();
  const { relocationStatus } = useSearch({
    from: "/_app/relocations",
  });
  const navigate = useNavigate({
    from: "/relocations",
  });

  const matchRoute = useMatchRoute();

  return (
    <FullPageScreen>
      <RelocationMultiTable
        supplierId={supplierId ?? undefined}
        tableId="relocations"
        statuses={relocationStatus ?? []}
        isRouteMatched={(statuses) => {
          return !!matchRoute({
            to: "/relocations",
            search: {
              relocationStatus: statuses,
            },
          });
        }}
        onStatusChange={(newStatus) => {
          navigate({
            search: (old) => {
              return {
                ...old,
                relocationStatus: newStatus,
              };
            },
          });
        }}
      />
    </FullPageScreen>
  );
}
