import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { Transmission } from "@/gql/graphql";
import { SelectOption } from "@/lib/Components/Form/Components/Select";

export function TransmissionInput(
  props: Omit<SelectInputProps<any>, "options">,
) {
  const options: {
    [key in Transmission]: SelectOption;
  } = {
    [Transmission.Automatic]: {
      value: Transmission.Automatic,
      label: "Automatic",
    },
    [Transmission.Manual]: {
      value: Transmission.Manual,
      label: "Manual",
    },
  };

  return (
    <SelectInput
      options={[
        {
          label: "Transmission",
          options: Object.values(options),
        },
      ]}
      {...props}
    />
  );
}
