import { graphql } from "@/gql";

export const createVehicleMutation = graphql(`
  mutation CreateVehicle($input: CreateVehicleInput!) {
    createVehicle(input: $input) {
      ...VehicleFields
    }
  }
`);

export const updateVehicleMutation = graphql(`
  mutation UpdateVehicle($id: ID!, $input: UpdateVehicleInput!) {
    updateVehicle(id: $id, input: $input) {
      id
    }
  }
`);

export const deleteVehicleMutation = graphql(`
  mutation DeleteVehicle($id: ID!) {
    deleteVehicle(id: $id) {
      id
    }
  }
`);

export const archiveVehicleMutation = graphql(`
  mutation ArchiveVehicle($id: ID!) {
    archiveVehicle(id: $id) {
      id
    }
  }
`);
