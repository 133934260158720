import { graphql } from "@/gql";

export const metaQuery = graphql(`
  query Meta {
    meta {
      build_sha
      is_verified
      overdue_invoice_count
      pending_task_count
      pending_change_request_count
      pending_payment_request_count
      default_rates {
        currency
        booking_fee_amount
        charge_per_move_amount
        holding_deposit_amount
        hire_unit_rate
        extra_hire_unit_deposit_rate
      }
      feature_flags {
        type
        enabled
      }
    }

    me {
      id
      name
      primary_email
      primary_phone
    }
  }
`);
