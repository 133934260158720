import { TaskList } from "@/app/Tasks/Components/TaskList";
import { TaskStatus } from "@/gql/graphql";

export function DashboardTaskList() {
  return (
    <TaskList
      id="dashboard-task-list"
      queryVariables={{
        status: [TaskStatus.Pending, TaskStatus.Overdue],
      }}
    />
  );
}
