import { memo, useCallback, useState } from "react";
import { GoogleMapsLoader } from "@/lib/GoogleMaps/Components/GoogleMapsLoader";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
} from "@react-google-maps/api";
import { ClockIcon, GaugeIcon } from "lucide-react";
import { Measurement } from "@/gql/graphql";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { supplierOfficeRecordQuery } from "@/app/Offices/GraphQL/supplierOfficeRecordQuery";

type DirectionRendererProps = {
  deliveryOfficeId: string;
  departureOfficeId: string;
  measurement: Measurement;
  onChange?: (values: { hours: number; distance: number }) => void;
};

const DirectionsServiceMemo = memo(DirectionsService, (oldProps, newProps) => {
  return JSON.stringify(oldProps.options) === JSON.stringify(newProps.options);
});

export function Directions({
  departureOfficeId,
  deliveryOfficeId,
  measurement,
}: DirectionRendererProps) {
  const { data: departureData } = useSuspenseGqlQuery(
    supplierOfficeRecordQuery,
    {
      id: departureOfficeId,
    },
  );

  const { data: deliveryData } = useSuspenseGqlQuery(
    supplierOfficeRecordQuery,
    {
      id: deliveryOfficeId,
    },
  );

  const [response, setResponse] = useState<google.maps.DirectionsResult | null>(
    null,
  );

  const directionsCallback = useCallback(
    (
      result: google.maps.DirectionsResult | null,
      status: google.maps.DirectionsStatus,
    ) => {
      if (result !== null && status === "OK") {
        setResponse(result);
      } else {
        setResponse(null);
      }
    },
    [departureData, deliveryData, measurement],
  );

  const origin = {
    lat: departureData.supplierOffice.address.lat!,
    lng: departureData.supplierOffice.address.lng!,
  };

  const destination = {
    lat: deliveryData.supplierOffice.address.lat!,
    lng: deliveryData.supplierOffice.address.lng!,
  };

  return (
    <GoogleMapsLoader>
      <GoogleMap
        mapContainerClassName="h-60"
        options={{
          scrollwheel: false,
        }}
        zoom={13}
        center={response ? undefined : origin}
      >
        {origin && destination && (
          <DirectionsServiceMemo
            options={{
              travelMode: "DRIVING" as any,
              origin,
              destination,
              unitSystem: measurement === Measurement.Metric ? 0 : 1,
            }}
            callback={directionsCallback}
          />
        )}

        {response ? (
          <DirectionsRenderer options={{ directions: response }} />
        ) : null}
      </GoogleMap>

      <div className="flex gap-x-6">
        <div className="flex items-center space-x-2">
          <ClockIcon className="h-5 w-5" />
          <span>{response?.routes[0].legs[0].duration?.text}</span>
        </div>
        <div className="flex items-center space-x-2">
          <GaugeIcon className="h-5 w-5" />
          <span>{response?.routes[0].legs[0].distance?.text}</span>
        </div>
      </div>
    </GoogleMapsLoader>
  );
}
