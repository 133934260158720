import { graphql } from "@/gql";

export const reportRelocationDownloadMutationGraphql = graphql(`
  mutation CreateReportRelocationsDownloadUrl(
    $rangeStartDate: String!
    $rangeEndDate: String!
    $supplierId: ID
    $vehicleId: ID
    $departureOfficeId: ID
    $deliveryOfficeId: ID
  ) {
    createReportRelocationsDownloadUrl(
      range_start_date: $rangeStartDate
      range_end_date: $rangeEndDate
      supplier_id: $supplierId
      vehicle_id: $vehicleId
      departure_office_id: $departureOfficeId
      delivery_office_id: $deliveryOfficeId
    )
  }
`);
