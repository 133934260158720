import { OrganisationTable } from "@/app/Organisations/Components/OrganisationTable";
import { SettingCard } from "@/app/Settings/Settings/Components/SettingFormCard";

export function SettingsOrganisationsScreen() {
  return (
    <SettingCard
      title="Organisations"
      description="An organisation manages which users can see which information.
       An organisation can have many suppliers."
    >
      <OrganisationTable id="settings-organisations" />
    </SettingCard>
  );
}
