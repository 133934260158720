import { TableId } from "@/app/Common/Utils/tableIds";
import { useWaitlistActions } from "@/app/Waitlists/Actions/useWaitlistActions";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import {
  WaitlistListItem,
  waitlistListQuery,
} from "@/app/Waitlists/GraphQL/waitlistListQuery";
import { formatDate } from "@/lib/Formatters/formatDate";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { WaitlistListQueryVariables } from "@/gql/graphql";
import { CellContext } from "@tanstack/react-table";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { UserActivityDialog } from "@/app/Users/Components/UserActivityDialog";
import { PhoneIcon } from "lucide-react";
import { UserProfilePicture } from "@/app/Users/Components/UserProfilePicture";
import { TimeSinceNowCell } from "@/lib/Components/DataTable/TimeSinceNowCell";
import { Button } from "@/components/catalyst/button";
import { WaitlistMatchesCell } from "@/app/Waitlists/Components/Tables/WaitlistMatchesCell";

type WaitlistColumnId =
  | "customer"
  | "trip"
  | "dates"
  | "matches"
  | "last_called"
  | "created"
  | "actions";

type GetWaitlistTableVariables = ({
  pagination,
  sorting,
  search,
}: {
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  sorting: {
    desc: boolean;
    id: WaitlistColumnId;
  }[];
  search: string;
}) => WaitlistListQueryVariables;

export function WaitlistTable({
  id,
  getQueryVariables,
  searchable,
}: {
  id: TableId;
  getQueryVariables: GetWaitlistTableVariables;
  searchable?: boolean;
}) {
  const getActions = useWaitlistActions();

  const columns: DataTableColDef<WaitlistListItem, any, WaitlistColumnId>[] = [
    {
      id: "customer",
      header: "Customer",
      cell: CustomerCell,
    },
    {
      id: "trip",
      header: "Trip",
      accessorFn: (row) =>
        `${row.departureCity.name} to ${row.deliveryCity.name}`,
    },
    {
      id: "dates",
      header: "Dates",
      accessorFn: (row) =>
        `${formatDate(row.earliest_departure_date)} - ${formatDate(
          row.latest_departure_date,
        )}`,
    },
    {
      id: "matches",
      header: "Matches",
      cell: WaitlistMatchesCell,
    },
    {
      id: "last_called",
      header: "Last called",
      accessorFn: (row) => row.user.latestCallActivity?.created_at,
      cell: TimeSinceNowCell,
    },
    {
      id: "created",
      header: "Created",
      accessorFn: (row) => row.created_at,
      cell: TimeSinceNowCell,
    },
    {
      id: "actions",
      header: "",
      cell: WaitlistActions,
    },
  ];

  return (
    <DataTable<
      ResultOf<typeof waitlistListQuery>,
      VariablesOf<typeof waitlistListQuery>,
      WaitlistColumnId,
      WaitlistListItem,
      any
    >
      getActions={getActions}
      id={id}
      document={waitlistListQuery}
      columns={columns}
      accessor={(data) => data.waitlists}
      getQueryVariables={getQueryVariables}
      searchable={searchable}
    />
  );
}

function WaitlistActions({ row }: CellContext<WaitlistListItem, any>) {
  const waitlist = row.original;

  const { open } = useDialog(UserActivityDialog);

  return (
    <Button
      className="flex-shrink-0"
      outline
      onClick={() => {
        open({
          userId: waitlist.user.id,
        });
      }}
    >
      <PhoneIcon className="size-3.5" />
      Log
    </Button>
  );
}

function CustomerCell({ row }: CellContext<WaitlistListItem, any>) {
  const user = row.original.user;

  return (
    <div className="flex items-center space-x-3">
      <UserProfilePicture model={user} />
      <div>
        <p>{user.name?.trim() || "--"}</p>
        <p>
          {user.primary_phone ? (
            <a
              className="text-blue-500 underline"
              href={`tel:${user.primary_phone}`}
            >
              {user.primary_phone}
            </a>
          ) : (
            "--"
          )}
        </p>
        <p>
          {user.primary_email ? (
            <a
              className="text-blue-500 underline"
              href={`mailto:${user.primary_email}`}
            >
              {user.primary_email}
            </a>
          ) : (
            "--"
          )}
        </p>
      </div>
    </div>
  );
}
