import { graphql } from "@/gql";

export const callLogCountQuery = graphql(`
  query CallLogCount($start: DateTimeTz!, $end: DateTimeTz!, $userId: ID!) {
    callLogCount(start: $start, end: $end, user_id: $userId) {
      date
      note_count
      phone_call_count
    }
  }
`);
