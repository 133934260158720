import { useContext } from "react";
import { useStore } from "zustand";
import { SupplierContext } from "@/app/Suppliers/Components/GlobalSupplierContext";
import { useNullableSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";

export function useGlobalSupplier() {
  const context = useContext(SupplierContext);

  if (context === undefined) {
    throw new Error(
      "useGlobalSupplier must be used within a GlobalSupplierProvider",
    );
  }

  const state = useStore(context, (state) => state);

  const { data } = useNullableSuspenseGqlQuery(
    supplierRecordQuery,
    {
      id: state.supplierId!,
    },
    !!state.supplierId,
  );

  const supplier = data?.supplier;

  return {
    ...state,
    supplier: supplier ?? null,
  };
}
