import { graphql } from "@/gql";

export const createSupplierOfficeMutation = graphql(`
  mutation CreateSupplierOffice($input: CreateSupplierOfficeInput!) {
    createSupplierOffice(input: $input) {
      ...SupplierOfficeFields
    }
  }
`);

export const updateSupplierOfficeMutation = graphql(`
  mutation UpdateSupplierOffice($id: ID!, $input: UpdateSupplierOfficeInput!) {
    updateSupplierOffice(id: $id, input: $input) {
      id
    }
  }
`);

export const deleteSupplierOfficeMutation = graphql(`
  mutation DeleteSupplierOffice($id: ID!) {
    deleteSupplierOffice(id: $id) {
      id
    }
  }
`);

export const archiveSupplierOfficeMutation = graphql(`
  mutation ArchiveSupplierOffice($id: ID!) {
    archiveSupplierOffice(id: $id) {
      id
    }
  }
`);
