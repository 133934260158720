import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import {
  PurchasableExtraListResult,
  updateSupplierPurchasableExtraMutation,
} from "@/app/Suppliers/GraphQL/supplierPurchasableExtrasQuery";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { Currency, UpdateSupplierPurchasableExtraInput } from "@/gql/graphql";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { SupplierPurchasableExtraForm } from "@/app/Suppliers/Components/SupplierPurchasableExtraCreateDialog";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";

export function SupplierPurchasableExtraUpdateDialog({
  supplierPurchasableExtra,
  currency,
}: {
  supplierPurchasableExtra: PurchasableExtraListResult;
  currency: Currency;
}) {
  const { close } = useDialogState();
  const { mutateAsync } = useGqlMutation(
    updateSupplierPurchasableExtraMutation,
  );

  return (
    <GenericDialog title={`Update ${supplierPurchasableExtra.name}`}>
      <GenericForm<UpdateSupplierPurchasableExtraInput>
        initialValues={{
          name: supplierPurchasableExtra.name,
          description: supplierPurchasableExtra.description,
          units_purchasable: supplierPurchasableExtra.units_purchasable,
          unit_price: supplierPurchasableExtra.unit_price,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: supplierPurchasableExtra.id,
            input: values,
          });
          close();
        }}
      >
        <SupplierPurchasableExtraForm currency={currency} />
        <DialogSubmitButton text={`Update purchasable extra`} />
      </GenericForm>
    </GenericDialog>
  );
}
