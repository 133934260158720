import { useOrganization } from "@clerk/clerk-react";

type TenantType = "admin" | "supplier" | "individual";
export function useTenant() {
  const { organization } = useOrganization();

  let tenantType: TenantType = "individual";

  if (organization) {
    tenantType =
      organization?.id === import.meta.env.VITE_CLERK_ADMIN_ORG_ID
        ? "admin"
        : "supplier";
  }

  return {
    tenantType,
    isAdmin: tenantType === "admin",
    isSupplier: tenantType === "supplier",
    isIndividual: tenantType === "individual",
  };
}
