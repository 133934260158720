import { useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from "lexical";

interface OnFocusPluginProps {
  onFocus?: () => void;
  onBlur?: () => void;
  setHasFocus?: (hasFocus: boolean) => void;
}

export function LexicalOnFocusPlugin({
  onFocus,
  onBlur,
  setHasFocus,
}: OnFocusPluginProps) {
  const hasFocus = useEditorFocus();
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    setHasFocus?.(hasFocus);

    if (hasFocus) {
      setTouched(true);
      onFocus?.();
    } else if (touched) {
      onBlur?.();
    }
  }, [hasFocus]);

  return null;
}

function useEditorFocus() {
  const [editor] = useLexicalComposerContext();
  // Possibly use useRef for synchronous updates but no re-rendering effect
  const [hasFocus, setFocus] = useState(false);

  useEffect(
    () =>
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          setFocus(false);
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    [],
  );

  useEffect(
    () =>
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          setFocus(true);
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    [],
  );

  return hasFocus;
}
