import dayjs from "dayjs";

export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_TIME_TZ_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
export const DATE_TIME_LOCAL_FORMAT = "YYYY-MM-DDTHH:mm:ss";

export function toDateTimeString(
  date?: string | null | Date | undefined | dayjs.Dayjs,
) {
  if (!date) {
    return null;
  }
  return dayjs(date).format(DATE_TIME_FORMAT);
}

export function toDateTimeTzString(
  date?: string | null | Date | undefined | dayjs.Dayjs,
  withTimezone = true,
) {
  if (!date) {
    return null;
  }

  if (!withTimezone) {
    return dayjs(date).format(DATE_TIME_LOCAL_FORMAT);
  }

  return dayjs(date).format(DATE_TIME_TZ_FORMAT);
}
