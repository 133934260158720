import { QuoteTable } from "@/app/Quotes/Components/QuoteTable";
import { Button } from "@/lib/Components/Button/Button";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { CreateQuoteDialog } from "@/app/Quotes/Components/CreateQuoteDialog";
import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { PlusIcon } from "@heroicons/react/24/outline";

export function QuoteTableScreen() {
  const { open } = useDialog(CreateQuoteDialog);
  return (
    <FullPageScreen>
      <QuoteTable
        buttons={
          <Button
            LeadingIcon={PlusIcon}
            onClick={() => {
              open({});
            }}
          >
            Add quote
          </Button>
        }
      />
    </FullPageScreen>
  );
}
