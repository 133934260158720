import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { ActivityLogFeed } from "@/app/ActivityLogs/Components/ActivityLogFeed";
import { ModelType } from "@/gql/graphql";

export function ActivityLogDialog({
  modelId,
  modelType,
}: {
  modelId: string;
  modelType: ModelType;
}) {
  return (
    <GenericDialog title="Activity">
      <ActivityLogFeed modelId={modelId} modelType={modelType} />
    </GenericDialog>
  );
}
