import { IconMenu } from "@/lib/Components/Menu/IconMenu";
import {
  ActionGroupButton,
  ActionItemGroup,
} from "@/lib/Components/Actions/ActionGroupButtons";

export function IconMenuActions({ actions }: { actions: ActionGroupButton[] }) {
  const items = actions
    .flatMap((item) => {
      return (item.items ?? []).filter((actionItem) => {
        return (
          actionItem.actions.filter((action) => !action.isHidden).length > 0
        );
      });
    })
    .filter(Boolean) as ActionItemGroup[];

  if (items.length === 0) return null;

  return <IconMenu actionGroups={items} />;
}
