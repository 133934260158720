import { graphql } from "@/gql";

export const userRecordQuery = graphql(`
  query UserRecord($id: ID!) {
    user(id: $id) {
      ...UserFields
    }
  }
`);

export const userRecordFields = graphql(`
  fragment UserFields on User {
    id
    name
    primary_email
    primary_phone
    clerk_user_id
    updated_at
    created_at
    waitlistPreference {
      id
      number_of_travellers
      email_frequency
      vehicle_category
      notification_type
    }
    contact {
      id
      name
      phones {
        id
        phone
      }
      emails {
        id
        email
      }
      profilePicture {
        ...MediaFields
      }
    }
  }
`);
