import { MediaConversionType, MediaFieldsFragment } from "@/gql/graphql";
import { ComponentPropsWithoutRef } from "react";

export function Image({
  media,
  ...props
}: { media: MediaFieldsFragment } & ComponentPropsWithoutRef<"img">) {
  const smallImageConversion = media.conversions.find(
    (conversion) => conversion.type === MediaConversionType.Small,
  );

  return (
    <img
      src={smallImageConversion?.url ?? media.url}
      srcSet={smallImageConversion?.srcset}
      {...props}
    />
  );
}
