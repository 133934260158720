import { graphql } from "@/gql";

export const updateImportSettingsMutation = graphql(`
  mutation UpdateImportSettings($input: ImportSettingInput!) {
    upsertImportSetting(input: $input) {
      id
    }
  }
`);

export const importSettingRecord = graphql(`
  query ImportSettingRecord($supplierId: ID!) {
    importSetting(supplierId: $supplierId) {
      id
      grid_state
      ignore_header_row
      ignore_missing_line
      ignore_columns
      date_format
    }
  }
`);
