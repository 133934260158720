import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { useTranslations } from "use-intl";
import { useQuoteRecord } from "@/app/Quotes/hooks/useQuoteRecord";
import { TaxInclusionType } from "@/gql/graphql";

export function QuoteLineItemTable() {
  const quote = useQuoteRecord();
  const tC = useTranslations("common");

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
          >
            Description
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
          >
            Qty
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
          >
            Rate
          </th>
          {quote.tax_inclusion_type !== TaxInclusionType.NoTax ? (
            <th
              scope="col"
              className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Tax
            </th>
          ) : null}
          <th
            scope="col"
            className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
          >
            Amount
          </th>
        </tr>
      </thead>
      <tbody>
        {quote.lineItems.map((lineItem) => {
          return (
            <tr key={lineItem.id} className="border-b border-gray-200">
              <td className="py-4 pr-3 text-sm sm:pl-6 md:pl-0">
                <div className="font-medium text-gray-900">
                  {lineItem.description}
                </div>
                <div className="mt-0.5 text-gray-500 sm:hidden">
                  {lineItem.quantity} {lineItem.unit_amount} at{" "}
                  {formatCurrency(lineItem.unit_amount, quote.currency)}
                </div>
              </td>
              <td className="hidden px-3 py-4 text-right text-sm text-gray-500 sm:table-cell">
                {lineItem.quantity}
              </td>
              <td className="hidden px-3 py-4 text-right text-sm text-gray-500 sm:table-cell">
                {formatCurrency(lineItem.unit_amount, quote.currency)}
              </td>
              {quote.tax_inclusion_type !== TaxInclusionType.NoTax ? (
                <td className="hidden px-3 py-4 text-right text-sm text-gray-500 sm:table-cell">
                  {tC(`tax_type.${lineItem.tax_type}`)}
                </td>
              ) : null}
              <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                {formatCurrency(
                  lineItem.unit_amount * lineItem.quantity,
                  quote.currency,
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        {quote.tax_inclusion_type !== TaxInclusionType.NoTax ? (
          <>
            <tr>
              <th
                scope="row"
                colSpan={4}
                className="pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 md:pl-0"
              >
                Subtotal
              </th>
              <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                {formatCurrency(quote.amount_exc_tax, quote.currency)}
              </td>
            </tr>
            <tr>
              <th
                scope="row"
                colSpan={4}
                className="pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 md:pl-0"
              >
                GST
              </th>
              <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                {formatCurrency(quote.amount_tax, quote.currency, {
                  showZero: true,
                })}
              </td>
            </tr>
          </>
        ) : null}
        <tr>
          <th
            scope="row"
            colSpan={
              quote.tax_inclusion_type === TaxInclusionType.NoTax ? 3 : 4
            }
            className="pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 md:pl-0"
          >
            Total
          </th>
          <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
            {formatCurrency(quote.amount_inc_tax, quote.currency)}
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
