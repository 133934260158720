import { RecordScreen } from "@/lib/Components/Screens/RecordScreen/RecordScreen";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { Link } from "@tanstack/react-router";
import { ActivityIcon } from "lucide-react";
import { useInvoiceRecord } from "@/app/Invoices/Hooks/useInvoiceRecord";
import { useInvoiceActions } from "@/app/Invoices/Hooks/useInvoiceActions";
import { Badge } from "@/components/catalyst/badge";
import { useTranslations } from "use-intl";
import { invoiceStatusIntentMap } from "@/app/Invoices/Utils/invoiceStatusIntentMap";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { Button } from "@/components/catalyst/button";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { billingCreateOffSessionPaymentMethodCheckoutUrlMutation } from "@/app/Stripe/StripePaymentMethods/GraphQL/billingCreateOffSessionPaymentMethodCheckoutUrlMutation";

export function InvoiceRecordScreen() {
  const invoice = useInvoiceRecord();
  const getActions = useInvoiceActions();
  const t = useTranslations("invoice");

  return (
    <RecordScreen
      title={invoice.reference}
      actionsGroups={getActions(invoice)}
      badges={
        <Badge color={invoiceStatusIntentMap[invoice.status]}>
          {t(`status.${invoice.status}`)}
        </Badge>
      }
      breadcrumbs={
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink to="/invoices">Invoices</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{invoice.reference}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      }
      banner={
        <>
          <BillingEmailMissingBanner />

          <PaymentMethodMissingBanner />
        </>
      }
      tabs={[
        {
          name: "Details",
          to: "/invoices/$id",
          Icon: DocumentTextIcon,
        },
        {
          name: "Activity",
          to: "/invoices/$id/activity",
          Icon: ActivityIcon,
        },
      ]}
    />
  );
}

function BillingEmailMissingBanner() {
  const record = useInvoiceRecord();

  if (record.payee.billingEmail) {
    return null;
  }

  return (
    <WarningBanner className="mt-6">
      A billing email is missing for {record.payee.name}.{" "}
      <Link
        to="/contacts/$id"
        params={{
          id: record.payee.id,
        }}
        className="underline"
      >
        fix now
      </Link>
    </WarningBanner>
  );
}

function PaymentMethodMissingBanner() {
  const record = useInvoiceRecord();
  const { mutateAsync } = useGqlMutation(
    billingCreateOffSessionPaymentMethodCheckoutUrlMutation,
  );

  const returnUrl = new URL(
    `/invoices/${record.id}`,
    import.meta.env.VITE_ADMIN_URL,
  ).toString();

  if (
    !record.payee.billingEmail ||
    record.payee.stripeCustomer?.paymentMethods?.length
  ) {
    return null;
  }

  return (
    <div className="mt-4 rounded-md bg-blue-50 px-4 py-2">
      <div className="flex items-center">
        <div className="shrink-0">
          <InformationCircleIcon
            aria-hidden="true"
            className="size-5 text-blue-400"
          />
        </div>
        <div className="ml-3 flex flex-1 items-center md:flex md:justify-between">
          <p className="text-sm text-blue-700">
            👋 Hey! Save time by setting up automatic billing. We only charge
            for <b>completed</b> bookings.
          </p>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <Button
              className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
              plain
              onClick={async () => {
                const res = await mutateAsync({
                  input: {
                    contact_id: record.payee.id,
                    cancel_url: returnUrl,
                    success_url: returnUrl,
                  },
                });

                window.location.href =
                  res.billingCreateOffSessionPaymentMethodCheckoutUrl;
              }}
            >
              Add payment method
              <span aria-hidden="true"> &rarr;</span>
            </Button>
          </p>
        </div>
      </div>
    </div>
  );
}
