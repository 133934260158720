import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import {
  BaseComboboxInputProps,
  ComboboxInput,
} from "@/lib/Components/Form/Inputs/ComboboxInput";
import { CreateSupplierInsuranceOptionInput } from "@/gql/graphql";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import {
  SupplierInsuranceOptionListItem,
  supplierInsuranceOptionListQuery,
} from "@/app/Suppliers/GraphQL/supplierInsuranceOptionListQuery";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import {
  SupplierInsuranceOptionCreateDialog,
  SupplierInsuranceOptionCreateDialogProps,
} from "@/app/Suppliers/Components/SupplierInsuranceOptionCreateDialog";
import { supplierInsuranceOptionRecord } from "@/app/Suppliers/GraphQL/supplierInsuranceOptionRecord";
import { Link } from "@tanstack/react-router";

export function SupplierInsuranceOptionInput({
  initialFixtureValues,
  ...props
}: BaseComboboxInputProps<
  ResultOf<typeof supplierInsuranceOptionListQuery>,
  VariablesOf<typeof supplierInsuranceOptionListQuery>,
  SupplierInsuranceOptionListItem,
  ResultOf<typeof supplierInsuranceOptionRecord>,
  VariablesOf<typeof supplierInsuranceOptionRecord>
> & {
  initialFixtureValues?: Partial<CreateSupplierInsuranceOptionInput>;
}) {
  const { openAsPromise } = useDialog<
    SupplierInsuranceOptionCreateDialogProps,
    SupplierInsuranceOptionListItem
  >(SupplierInsuranceOptionCreateDialog);

  return (
    <ComboboxInput
      document={supplierInsuranceOptionListQuery}
      accessor={(data) => data.supplierInsuranceOptions.data}
      getQueryVariables={(search) => ({
        page: 1,
        first: 20,
        search,
      })}
      recordDocument={supplierInsuranceOptionRecord}
      recordAccessor={(data) => data.supplierInsuranceOption}
      getViewNode={(item) =>
        item ? (
          <Link
            className="text-blue-500"
            to={"/suppliers/$id/insurance"}
            params={{
              id: item.supplier.id,
            }}
          >
            {item.name}
          </Link>
        ) : (
          "--"
        )
      }
      getInputNode={(item) => item?.name ?? "--"}
      getKey={(item) => item.id}
      getListItemNode={(item) => <Node model={item} />}
      fixture={(search) => {
        return {
          label: `Create ${search}`,

          onClick: async () => {
            return await openAsPromise({
              initialValues: {
                name: search,
                ...initialFixtureValues,
              },
            });
          },
        };
      }}
      {...props}
    />
  );
}

function Node({ model }: { model: SupplierInsuranceOptionListItem }) {
  return (
    <div>
      <p>{model.name}</p>
      <p className="text-xs text-gray-500">
        {formatCurrency(model.bond_amount, model.supplier.currency)} /{" "}
        {formatCurrency(
          model.standard_liability_amount,
          model.supplier.currency,
        )}
      </p>
    </div>
  );
}
