import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { relocationRecordQuery } from "@/app/Relocations/GraphQL/relocationRecordQuery";
import { RelocationAdditionalDestinationsTable } from "@/app/Relocations/Components/Tables/RelocationAdditionalDestinationsTable";
import { RelocationSummaryCard } from "@/app/Relocations/Components/RelocationSummaryCard";
import { Button } from "@/lib/Components/Button/Button";
import { GitBranchPlusIcon } from "lucide-react";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { AddDestinationsDialog } from "@/app/Relocations/Components/AddDestinationsDialog";

export function RelocationAdditionalDestinationDialog({
  relocationId,
}: {
  relocationId: string;
}) {
  const { data } = useSuspenseGqlQuery(relocationRecordQuery, {
    id: relocationId,
  });
  const { open } = useDialog(AddDestinationsDialog);

  return (
    <GenericDialog title="Additional destinations">
      <RelocationSummaryCard relocation={data.relocation} />

      <RelocationAdditionalDestinationsTable
        id="relocation-additional-destinations"
        relocationId={relocationId}
        rightButtons={
          <>
            <Button
              LeadingIcon={GitBranchPlusIcon}
              onClick={() => {
                open({
                  relocationId,
                });
              }}
            >
              Add destinations
            </Button>
          </>
        }
      />
    </GenericDialog>
  );
}
