import { RadioButton } from "@/lib/Components/Form/Inputs/RadioButton";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import {
  CreateRelocationInput,
  RelocationInclusionInput,
  RelocationInclusionType,
} from "@/gql/graphql";
import { useState } from "react";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";

type FuelOffer = "no_fuel" | "free_tank" | "refund";

export function RelocationFuelInclusionInput() {
  const { values, setFieldValue } = useForm<CreateRelocationInput>();

  const fuelInclusionIdx = values.inclusions?.upsert?.findIndex(
    (inclusion) => inclusion.type === RelocationInclusionType.Fuel,
  );
  const fuelInclusion = values.inclusions?.upsert?.find(
    (inclusion) => inclusion.type === RelocationInclusionType.Fuel,
  );

  let initialFuelOffer: FuelOffer = "no_fuel";
  if (fuelInclusion) {
    if (fuelInclusion.value === null) {
      initialFuelOffer = "free_tank";
    } else {
      initialFuelOffer = "refund";
    }
  }

  const [fuelOffer, setFuelOffer] = useState<FuelOffer>(initialFuelOffer);

  function updateInclusion(newValue: RelocationInclusionInput) {
    if (!fuelInclusion) {
      setFieldValue("inclusions.upsert", [
        ...(values.inclusions?.upsert ?? []),
        newValue,
      ]);
    } else {
      //Ensure that we only have 1 fuel inclusion
      const inclusions =
        values.inclusions?.upsert?.filter((inclusion) => {
          return inclusion.type !== RelocationInclusionType.Fuel;
        }) ?? [];

      const fuelInclusionsToDelete = values.inclusions?.upsert?.filter(
        (inclusion) => {
          if (inclusion.type !== RelocationInclusionType.Fuel) return false;
          return inclusion.id !== newValue.id;
        },
      );

      setFieldValue("inclusions.upsert", [...inclusions, newValue]);
      setFieldValue("inclusions.delete", [
        ...(values.inclusions?.delete ?? []),
        ...(fuelInclusionsToDelete?.map((inclusion) => inclusion.id) ?? []),
      ]);
    }
  }

  async function deleteInclusion() {
    if (fuelInclusion?.id) {
      await setFieldValue("inclusions.delete", [
        ...(values.inclusions?.delete ?? []),
        fuelInclusion.id,
      ]);
    }

    await setFieldValue(
      "inclusions.upsert",
      values.inclusions?.upsert?.filter(
        (inclusion) => inclusion.type !== RelocationInclusionType.Fuel,
      ),
    );
  }

  return (
    <div className="grid grid-cols-12 gap-6">
      <RadioButton<FuelOffer>
        className="col-span-full"
        name="fuel_offer"
        label="Fuel"
        value={fuelOffer}
        onChange={(value) => {
          setFuelOffer(value);
          if (value === "no_fuel") {
            return deleteInclusion();
          }

          if (value === "refund") {
            return updateInclusion({
              id: fuelInclusion?.id,
              type: RelocationInclusionType.Fuel,
              description: "Refund against receipts",
              value: null!,
            });
          }

          if (value === "free_tank") {
            return updateInclusion({
              id: fuelInclusion?.id,
              type: RelocationInclusionType.Fuel,
              description: "1 free tank",
              value: null!,
            });
          }
        }}
        options={[
          {
            label: "No fuel",
            value: "no_fuel",
            description:
              "No fuel is included in the rental. The driver must refuel the vehicle before returning it",
          },
          {
            label: "1 free tank",
            value: "free_tank",
            description:
              "The vehicle will be provided with a full tank and can be returned empty",
          },
          {
            label: "Refund against receipts",
            value: "refund",
            description:
              "The driver will be refunded for fuel costs against receipts",
          },
        ]}
      />

      {fuelOffer === "refund" ? (
        <>
          <CurrencyInput
            className="col-span-3"
            name={`inclusions.upsert.${fuelInclusionIdx}.value`}
            label="Fuel amount"
            currency={values.currency}
          />
          <TextAreaInput
            className="col-span-9"
            name={`inclusions.upsert.${fuelInclusionIdx}.description`}
            label="Refund conditions"
          />
        </>
      ) : null}
    </div>
  );
}
