import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type IntegrationLinksList = ResultOf<
  typeof integrationLinksList
>["integrationLinks"]["data"];

export const integrationLinksList = graphql(`
  query integrationLinks($integration_id: ID) {
    integrationLinks(integration_id: $integration_id) {
      data {
        id
        object_id
        object_type
        external_id
      }
    }
  }
`);
