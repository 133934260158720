import { VehicleTable } from "@/app/Vehicles/Components/VehicleTable";
import { Button } from "@/lib/Components/Button/Button";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { VehicleCreateDialog } from "@/app/Vehicles/Components/VehicleCreateDialog";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { SettingCard } from "@/app/Settings/Settings/Components/SettingFormCard";
import { PlusIcon } from "@heroicons/react/24/outline";

export function SettingsVehicleScreen() {
  const { open } = useDialog(VehicleCreateDialog);
  const { supplierId } = useGlobalSupplier();

  return (
    <SettingCard
      title="Vehicles"
      description="Add the vehicles you wish to relocate, they can be generic (i.e. mystery vehicle) or specific"
    >
      <VehicleTable
        id="settings-vehicles"
        rightButtons={
          <Button
            LeadingIcon={PlusIcon}
            onClick={() => {
              open({
                initialValues: {
                  supplier: {
                    connect: supplierId,
                  },
                },
              });
            }}
          >
            Add vehicle
          </Button>
        }
      />
    </SettingCard>
  );
}
