import { graphql } from "@/gql";

export const resolveChangeRequestMutation = graphql(`
  mutation ResolveBookingChangeRequestMutation(
    $id: ID!
    $input: ResolveBookingChangeRequestInput!
  ) {
    resolveBookingChangeRequest(id: $id, input: $input) {
      id
    }
  }
`);
