import { graphql } from "@/gql";

export const quoteRecordQuery = graphql(`
  query QuoteRecord($id: ID!) {
    quote(id: $id) {
      id
      reference
      currency
      quote_date
      expiry_date
      tax_inclusion_type
      additional_notations

      amount_tax
      amount_exc_tax
      amount_inc_tax

      payee {
        ...ContactRecordFields
      }

      lineItems {
        id
        unit_amount
        quantity
        tax_type
        description

        amount
        amount_inc_tax
        amount_exc_tax
        amount_tax
        created_at
        updated_at
      }

      ...QuoteActionFields
    }
  }
`);

const quoteActionsFragment = graphql(`
  fragment QuoteActionFields on Quote {
    id
    status
    uuid
  }
`);
