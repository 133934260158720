import { graphql } from "@/gql";

export const createRelocationHighlightMutation = graphql(`
  mutation CreateRelocationHighlight($input: CreateRelocationHighlightInput!) {
    createRelocationHighlight(input: $input) {
      id
    }
  }
`);

export const updateRelocationHighlightMutation = graphql(`
  mutation UpdateRelocationHighlight(
    $id: ID!
    $input: UpdateRelocationHighlightInput!
  ) {
    updateRelocationHighlight(id: $id, input: $input) {
      id
    }
  }
`);

export const deleteRelocationHighlightMutation = graphql(`
  mutation DeleteRelocationHighlight($id: ID!) {
    deleteRelocationHighlight(id: $id) {
      id
    }
  }
`);
