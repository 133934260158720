import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { VehicleType } from "@/gql/graphql";
import { useTranslations } from "use-intl";

export function VehicleTypeInput(
  props: Omit<SelectInputProps<any>, "options" | "getViewNode">,
) {
  const t = useTranslations("vehicle");

  return (
    <SelectInput
      options={[
        {
          label: "Cars",
          options: [
            VehicleType.Car,
            VehicleType.LuxuryCar,
            VehicleType.MiniVan,
            VehicleType.Suv,
            VehicleType.FourWheelDrive,
          ].map((vt) => {
            return {
              value: vt,
              label: t(`type.${vt}`),
            };
          }),
        },
        {
          label: "RVs",
          options: [
            VehicleType.CamperVan,
            VehicleType.MotorHome,
            VehicleType.FourWheelDriveCamper,
            VehicleType.Caravan,
          ].map((vt) => {
            return {
              value: vt,
              label: t(`type.${vt}`),
            };
          }),
        },
        {
          label: "Trucks",
          options: [VehicleType.BoxTruck, VehicleType.Ute, VehicleType.Van].map(
            (vt) => {
              return {
                value: vt,
                label: t(`type.${vt}`),
              };
            },
          ),
        },
      ]}
      {...props}
    />
  );
}
