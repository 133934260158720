import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { CreateBookingPaymentRequestInput } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createBookingPaymentRequestMutation } from "@/app/Bookings/GraphQL/bookingPaymentRequestMutations";
import { BookingRecord } from "@/app/Bookings/GraphQL/bookingRecordQuery";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";

type BookingRequestPaymentDialogProps = {
  booking: BookingRecord;
};
export function BookingPaymentRequestCreateDialog({
  booking,
}: BookingRequestPaymentDialogProps) {
  const { mutateAsync } = useGqlMutation(createBookingPaymentRequestMutation);
  const { close } = useDialogState();

  return (
    <GenericDialog title="Request payment">
      <GenericForm<CreateBookingPaymentRequestInput>
        initialValues={{
          booking: {
            connect: booking.id,
          },
          amount: null!,
          currency: booking.currency,
          description: null!,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            input: values,
          });
          close();
        }}
      >
        <CurrencyInput
          currency={booking.currency}
          name={"amount"}
          label={"Amount"}
        />

        <TextAreaInput
          name={"description"}
          label={"Description"}
          className="col-span-full"
        />
        <DialogSubmitButton text={"Request payment"} />
      </GenericForm>
    </GenericDialog>
  );
}
