import { createRoute } from "@tanstack/react-router";
import { CityDetailScreen } from "@/app/Cities/Cities/Screens/CityDetailScreen";
import { CityListScreen } from "@/app/Cities/Cities/Screens/CityListScreen";
import { CityRecordScreen } from "@/app/Cities/Cities/Screens/CityRecordScreen";
import {
  fetchQuery,
  prefetchInfiniteQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { CityCreateScreen } from "@/app/Cities/Cities/Screens/CityCreateScreen";
import { appLayoutRoute } from "@/routes";
import { z } from "zod";
import { cityRecordQuery } from "@/app/Cities/Cities/GraphQL/cityRecordQuery";
import { cityListQuery } from "@/app/Cities/Cities/GraphQL/cityListQuery";
import { getCityListQueryVariables } from "@/app/Cities/Cities/Utils/getCityListQueryVariables";
import { CityTripScreen } from "@/app/Cities/Cities/Screens/CityTripScreen";

const cityListSearchParams = z.object({
  citySearch: z.string().optional(),
});

export type CityListSearchParams = z.infer<typeof cityListSearchParams>;
export const cityListRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/cities",
  component: CityListScreen,
  validateSearch: cityListSearchParams,
  loaderDeps: ({ search }) => search,
  loader: ({ deps, context: { auth } }) => {
    return prefetchInfiniteQuery(
      cityListQuery,
      getCityListQueryVariables(deps),
      auth,
    );
  },
});
export const cityCreateRoute = createRoute({
  getParentRoute: () => cityListRoute,
  path: "/",
  component: CityCreateScreen,
});
export const cityRoute = createRoute({
  getParentRoute: () => cityListRoute,
  path: "$id",
  component: CityRecordScreen,
  loader: ({ params: { id }, context: { auth } }) => {
    return fetchQuery(cityRecordQuery, { id }, auth);
  },
});
export const cityDetailRoute = createRoute({
  getParentRoute: () => cityRoute,
  path: "/",
  component: CityDetailScreen,
});

export const cityTripRoute = createRoute({
  getParentRoute: () => cityRoute,
  path: "/trips",
  component: CityTripScreen,
});
