import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { HireUnitType } from "@/gql/graphql";

export function HireUnitTypeInput(
  props: Omit<SelectInputProps<any>, "options">,
) {
  const options: {
    [key in HireUnitType]: {
      label: string;
      value: HireUnitType;
    };
  } = {
    [HireUnitType.Day]: {
      label: "Days",
      value: HireUnitType.Day,
    },
    [HireUnitType.Night]: {
      label: "Nights",
      value: HireUnitType.Night,
    },
    [HireUnitType.TwentyFourHours]: {
      label: "24hrs",
      value: HireUnitType.TwentyFourHours,
    },
  };

  return (
    <SelectInput
      options={[
        {
          label: "unit",
          options: Object.values(options),
        },
      ]}
      {...props}
    />
  );
}
