import { QuoteDetailForm } from "@/app/Quotes/Components/Forms/QuoteDetailForm";
import { UpdateQuoteInput } from "@/gql/graphql";
import { useQuoteRecord } from "@/app/Quotes/hooks/useQuoteRecord";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateQuoteMutation } from "@/app/Quotes/GraphQL/quoteMutations";
import { RecordScreenFormCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenFormCard";
import { QuoteLineItemTable } from "@/app/Quotes/Components/QuoteLineItemsTable";
import { QuoteLineItemsForm } from "@/app/Quotes/Components/QuoteLineItemsForm";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { quoteLineItemValidation } from "@/app/Quotes/validations/quoteLineItemValidation";

export function QuoteDetailScreen() {
  const quote = useQuoteRecord();
  const { mutateAsync } = useGqlMutation(updateQuoteMutation);
  return (
    <div className="space-y-12 py-6">
      <RecordScreenFormCard<UpdateQuoteInput>
        title="Details"
        initialValues={{
          quote_date: quote.quote_date,
          expiry_date: quote.expiry_date,
          currency: quote.currency,
          tax_inclusion_type: quote.tax_inclusion_type,
          payee: {
            connect: quote.payee.id,
          },
          additional_notations: quote.additional_notations,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: quote.id,
            input: values,
          });
        }}
      >
        <QuoteDetailForm />
      </RecordScreenFormCard>

      <RecordScreenFormCard<UpdateQuoteInput>
        initialValues={{
          currency: quote.currency,
          tax_inclusion_type: quote.tax_inclusion_type,
          lineItems: {
            upsert: quote.lineItems.map((item) => {
              return {
                id: item.id,
                unit_amount: item.unit_amount,
                description: item.description!,
                quantity: item.quantity,
                tax_type: item.tax_type,
              };
            }),
            delete: [],
          },
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: quote.id,
            input: values,
          });
        }}
        title="Line items"
        validationSchema={toFormikValidationSchema(quoteLineItemValidation)}
        view={<QuoteLineItemTable />}
      >
        <QuoteLineItemsForm />
      </RecordScreenFormCard>
    </div>
  );
}
