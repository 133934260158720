import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { graphql } from "@/gql";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";

const mailchimpCustomerJourneyQuery = graphql(`
  query MailchimpCustomerJourneyList {
    mailchimpCustomerJourneys {
      id
      journey_name
    }
  }
`);

export function MailchimpCustomerJourneySelectInput(
  props: Omit<SelectInputProps, "options">,
) {
  const { data } = useGqlQuery(mailchimpCustomerJourneyQuery, {});

  const options = data?.mailchimpCustomerJourneys.map((journey) => ({
    label: journey.journey_name,
    value: journey.id,
  }));

  return (
    <SelectInput
      options={[
        {
          label: "Customer journey",
          options: options || [],
        },
      ]}
      {...props}
    />
  );
}
