import { GenericFieldArray } from "@/lib/Components/Form/FieldArray/GenericFieldArray";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { DescriptionList } from "@/lib/Components/DescriptionList/DescriptionListCard";
import { ClosedDaysSchema } from "@/app/Suppliers/Utils/mapClosedDays";
import dayjs from "dayjs";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";

export function ClosedDatesForm({ emptyState }: { emptyState: ReactNode }) {
  const { isEditing, values } = useForm<{ closed_dates: ClosedDaysSchema[] }>();

  const repeatingDates = values.closed_dates.filter((date) => date.repeating);
  const nonRepeatingDates = values.closed_dates.filter(
    (date) => !date.repeating,
  );

  if (!isEditing) {
    if (values.closed_dates.length === 0) {
      return <div className="col-span-full">{emptyState}</div>;
    }

    return (
      <DescriptionList
        className="col-span-full"
        items={[
          ...(repeatingDates.length > 0
            ? [
                {
                  key: "Repeating dates",
                  value: (
                    <ul>
                      {repeatingDates.map((date) => {
                        return (
                          <li key={date.date}>
                            <span className="font-semibold">
                              {dayjs(date.date).format("DD MMM")}
                            </span>{" "}
                            (repeating)
                          </li>
                        );
                      })}
                    </ul>
                  ),
                },
              ]
            : []),
          ...(nonRepeatingDates.length > 0
            ? [
                {
                  key: "One off dates",
                  value: (
                    <ul>
                      {nonRepeatingDates.map((date) => {
                        return (
                          <li key={date.date}>
                            <span className="font-semibold">
                              {dayjs(date.date).format("DD MMM YY")}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  ),
                },
              ]
            : []),
        ]}
      />
    );
  }

  return (
    <>
      <GenericFieldArray
        fieldArrayKey={"closed_dates"}
        label="Closed dates"
        newLineItemValues={{
          date: dayjs().format("YYYY-MM-DD"),
          repeating: false,
        }}
        Icon={CalendarDaysIcon}
        lineItemLabel={"closed date"}
      >
        {(item, index) => {
          return (
            <>
              <DateInput
                name={`closed_dates.${index}.date`}
                className="col-span-4"
                label="Date"
              />
              <SwitchInput
                name={`closed_dates.${index}.repeating`}
                label="Repeating"
              />
            </>
          );
        }}
      </GenericFieldArray>
    </>
  );
}
