import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { metaQuery } from "@/app/Meta/GraphQL/metaQuery";
import { useEffect } from "react";
import { toast } from "sonner";

let hasShown = false;

export function OutdatedVersionWarning() {
  const { data, isLoading } = useGqlQuery(metaQuery, {});

  useEffect(() => {
    if (
      import.meta.env.DEV ||
      hasShown ||
      isLoading ||
      data?.meta.build_sha === import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA
    ) {
      return;
    }

    toast("A new version of this app is available. Please reload the page.", {
      action: {
        label: "Reload",
        onClick: () => window.location.reload(),
      },
      duration: Number.POSITIVE_INFINITY,
    });

    hasShown = true;
  }, [data]);

  return null;
}
