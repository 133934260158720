import {
  Description,
  Fieldset,
  Label,
  Legend,
} from "@/components/catalyst/fieldset";
import { Radio, RadioField, RadioGroup } from "@/components/catalyst/radio";

type RadioButtonOption = {
  value: string;
  label: string;
  description?: string;
};

export function RadioButton<T extends string>({
  label,
  options,
  name,
  className,
  onChange,
  value,
}: {
  label: string;
  name: string;
  options: RadioButtonOption[];
  className?: string;
  onChange?: (value: T) => void;
  value?: T;
}) {
  return (
    <Fieldset className={className}>
      <Legend>{label}</Legend>
      <RadioGroup name={name} value={value} onChange={onChange}>
        {options.map((option) => (
          <RadioField key={option.value}>
            <Radio value={option.value} />
            <Label>{option.label}</Label>
            <Description>{option.description}</Description>
          </RadioField>
        ))}
      </RadioGroup>
    </Fieldset>
  );
}
