import { days, OpenHours } from "@/app/Offices/Utils/days";
import dayjs from "dayjs";
import { cn } from "@/lib/utils";

export function OpenHoursViewState({
  openHours,
  parentOpenHours,
}: {
  openHours: OpenHours;
  parentOpenHours?: OpenHours;
}) {
  return (
    <div className="col-span-full grid grid-cols-7 gap-x-2">
      {days.map((day) => {
        const isClosed = openHours[day]?.length === 0;

        const isDifferentFromParent =
          parentOpenHours &&
          JSON.stringify(parentOpenHours[day]) !==
            JSON.stringify(openHours[day]);

        const parentHours = parentOpenHours?.[day].at(0);

        return (
          <div
            key={day}
            className={cn("flex flex-col rounded-md p-1", {
              "bg-red-50": isClosed,
            })}
          >
            <div>
              <div className="flex items-center space-x-2 text-xs capitalize">
                {day}
              </div>
              <div>
                {isClosed ? (
                  <div className="font-bold text-red-500">CLOSED</div>
                ) : null}
                {openHours[day]?.map((hours) => {
                  return (
                    <div className="flex flex-col font-semibold" key={hours}>
                      <div>
                        {isDifferentFromParent && parentHours ? (
                          <time className="mr-2 text-red-500 line-through">
                            {dayjs(parentHours.split("-")[0], "HH:mm").format(
                              "h:mma",
                            )}
                          </time>
                        ) : null}
                        <time>
                          {dayjs(hours.split("-")[0], "HH:mm").format("h:mma")}
                        </time>
                      </div>
                      <div>
                        {isDifferentFromParent && parentHours ? (
                          <time className="mr-2 text-red-500 line-through">
                            {dayjs(parentHours.split("-")[1], "HH:mm").format(
                              "h:mma",
                            )}
                          </time>
                        ) : null}
                        <time>
                          {dayjs(hours.split("-")[1], "HH:mm").format("h:mma")}
                        </time>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
