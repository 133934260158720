import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import {
  ConfirmBookingInput,
  ModelType,
  Payment,
  PaymentStatus,
} from "@/gql/graphql";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { RichTextInput } from "@/lib/Components/Form/Inputs/RichTextInput";
import { TrashIcon } from "@heroicons/react/24/outline";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { confirmBookingMutation } from "@/app/Bookings/GraphQL/cancelBookingMutation";
import { bookingRecordQuery } from "@/app/Bookings/GraphQL/bookingRecordQuery";
import { useCallback } from "react";
import confetti from "canvas-confetti";
import { toFormikValidate } from "zod-formik-adapter";
import { confirmBookingValidation } from "@/app/Bookings/Validation/bookingChangeStatusSchema";
import { PaymentItem } from "@/app/Bookings/Components/PaymentItem";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";

type BookingConfirmDialogProps = {
  bookingId: string;
};
export function BookingConfirmDialog({ bookingId }: BookingConfirmDialogProps) {
  const { mutateAsync } = useGqlMutation(confirmBookingMutation);
  const { close } = useDialogState();
  const { isAdmin } = useTenant();

  const { data } = useSuspenseGqlQuery(bookingRecordQuery, {
    id: bookingId,
  });
  const fireConfetti = useFireConfetti();

  const booking = data.booking;

  //Sum the customer payments
  const totalPaid = booking.customerPayments.reduce((acc, payment) => {
    if (payment.status === PaymentStatus.Paid) {
      acc += payment.amount;
    }

    return acc;
  }, 0);

  const pendingPayments = booking.customerPayments.filter((payment) => {
    return (
      payment.status === PaymentStatus.Pending &&
      payment.fulfilledBy?.__typename === "StripePayment" &&
      (payment.fulfilledBy.attributed_to_type === ModelType.Booking ||
        payment.fulfilledBy.attributed_to_type ===
          ModelType.BookingPaymentRequest)
    );
  });

  return (
    <GenericDialog title="Confirm booking">
      {isAdmin ? (
        <div className="mb-6 space-y-6">
          <div>
            {pendingPayments.length > 0 ? (
              <div className="grid w-full grid-cols-3 gap-6">
                {pendingPayments.map((payment) => {
                  //Only show the pending payments for the booking
                  return <PaymentItem payment={payment} key={payment.id} />;
                })}
              </div>
            ) : (
              <WarningBanner>
                There are no pending payments for this booking
              </WarningBanner>
            )}
          </div>
          <ul className="space-y-1.5">
            <li>
              <span className="">Due: </span>
              {formatCurrency(booking.pay_now_total_amount, booking.currency)}
            </li>
            {totalPaid ? (
              <li>
                <span className="">Total paid: </span>
                {formatCurrency(totalPaid, booking.currency)}
              </li>
            ) : null}
            <li className="">
              <span className="font-bold">Remaining: </span>
              {formatCurrency(
                booking.pay_now_total_amount - totalPaid,
                booking.currency,
              )}
            </li>
          </ul>
        </div>
      ) : null}

      <GenericForm<ConfirmBookingInput>
        validate={toFormikValidate(confirmBookingValidation)}
        initialValues={{
          customer_note: booking.customer_note,
          supplier_reference: booking.supplier_reference,
        }}
        onSubmit={async (values) => {
          fireConfetti();

          await mutateAsync({
            id: booking.id,
            input: values,
          });

          close();
        }}
      >
        <TextInput
          name="supplier_reference"
          label={`${booking.relocation.supplier.name} reference`}
        />

        <RichTextInput
          tooltip="This will appear on the customers booking pdf"
          name="customer_note"
          label="Customer note"
          className="col-span-full"
          optionalLabel
        />

        {booking.relocation.is_ferry_required ? (
          <>
            <WarningBanner className="col-span-full mb-6">
              This booking has a ferry crossing. Please add the ferry
              confirmation (if {"it's"} included) and any other relevant
              information to the ferry note.
            </WarningBanner>
          </>
        ) : null}

        <DialogSubmitButton text="Confirm" />
      </GenericForm>
    </GenericDialog>
  );
}

function useFireConfetti() {
  return useCallback(() => {
    const end = Date.now() + 1000; // 1 seconds

    const frame = () => {
      if (Date.now() > end) {
        return;
      }

      confetti({
        particleCount: 4,
        angle: 60,
        spread: 65,
        startVelocity: 45,
        zIndex: 0,
        origin: { x: 0, y: 0.5 },
        colors: ["#FF0000", "#00FF00", "#0000FF", "#FF00FF"],
      });
      confetti({
        particleCount: 4,
        angle: 120,
        spread: 65,
        startVelocity: 45,
        zIndex: 0,
        origin: { x: 1, y: 0.5 },
        colors: ["#FF0000", "#00FF00", "#0000FF", "#FF00FF"],
      });

      requestAnimationFrame(frame);
    };

    frame();
  }, []);
}

export function ChangeRequestWarning({ payment }: { payment: Payment }) {
  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            Attention needed
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
              This payment is part of a change request. Please ensure you do one
              of the following:
            </p>
            <ul className="mt-1 list-inside list-decimal">
              <li>
                Capture the payment to <strong>approve</strong> the change
                request
              </li>
              <li>
                Capture{" "}
                {formatCurrency(0, payment.currency, {
                  showZero: true,
                })}{" "}
                to <strong>decline</strong> the change request
              </li>
              <li>
                Leave the change request as <strong>pending</strong> by removing{" "}
                <TrashIcon className="inline h-4 w-4" /> the payment
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
