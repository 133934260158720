import { InvoiceStatus } from "@/gql/graphql";
import { BadgeColor } from "@/components/catalyst/badge";

export const invoiceStatusIntentMap: Record<InvoiceStatus, BadgeColor> = {
  [InvoiceStatus.Draft]: "zinc",
  [InvoiceStatus.Issued]: "yellow",
  [InvoiceStatus.Paid]: "green",
  [InvoiceStatus.Voided]: "purple",
  [InvoiceStatus.Overdue]: "red",
};
