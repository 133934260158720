import dayjs from "dayjs";
import { ReportRelocations } from "@/gql/graphql";

type ReportingRelocationTableProps = {
  rangeStartDate: string;
  rangeEndDate: string;
  data: ReportRelocations["daily_stats"];
};

export function ReportingRelocationTable({
  rangeStartDate,
  rangeEndDate,
  data,
}: ReportingRelocationTableProps) {
  const localStart = dayjs(rangeStartDate);
  const localEnd = dayjs(rangeEndDate);
  const days = localEnd.diff(localStart, "day") + 1;

  const items = Array.from({ length: days }).map((_, i) => {
    const date = localStart.add(i, "day");

    const foundStat = data.find(
      (item) => item.date === date.format("YYYY-MM-DD"),
    );

    return {
      date: date.format("YYYY-MM-DD"),
      count_bookings_confirmed: foundStat?.successful_bookings_count ?? 0,
      count_bookings_cancelled: foundStat?.cancelled_bookings_count ?? 0,
      count_relocations_ready: foundStat?.counts.active_count ?? 0,
      count_relocations_paused: foundStat?.counts.paused_count ?? 0,
      count_relocations_draft: foundStat?.counts.draft_count ?? 0,
      count_relocations_archived: foundStat?.counts.archived_count ?? 0,
      count_relocations_sound_out: foundStat?.counts.sold_out_count ?? 0,
    };
  });

  return (
    <div className="h-[500px] w-full">
      <h2 className="text-2xl font-bold">Relocations</h2>

      <div>TODO</div>
    </div>
  );
}
