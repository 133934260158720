import { useMatch } from "@tanstack/react-router";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { bookingRecordQuery } from "@/app/Bookings/GraphQL/bookingRecordQuery";

export function useBookingRecord() {
  const match = useMatch({
    from: "/_app/bookings/_bookingRecordLayout/$bookingId",
  });

  const { data } = useSuspenseGqlQuery(bookingRecordQuery, {
    id: match.params.bookingId,
  });

  return data.booking;
}
