import {
  VehicleImageCell,
  VehicleTable,
} from "@/app/Vehicles/Components/VehicleTable";
import {
  DataTableColDef,
  DataTableColumnHeader,
} from "@/lib/Components/DataTable/DataTable";
import { VehicleListItem } from "@/app/Vehicles/GraphQL/vehicleListQuery";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { ModelLinkCell } from "@/lib/Components/DataTable/Cells/ModelLinkCell";
import { QueryVehiclesOrderByColumn, SortOrder } from "@/gql/graphql";
import { RcmVehicleCodeCell } from "@/app/Settings/Integrations/Rcm/Components/RcmVehicleCodeCell";

export function RcmVehicleTable() {
  const { supplierId } = useGlobalSupplier();
  const columns: DataTableColDef<VehicleListItem, any, any>[] = [
    {
      id: "image",
      header: "Image",
      cell: VehicleImageCell,
    },
    {
      id: "name",
      accessorFn: (data) => data.name,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      cell: ({ row }) => {
        return (
          <ModelLinkCell
            to={"/vehicles/$id"}
            params={{
              id: row.original.id,
            }}
          >
            {row.original.name}
          </ModelLinkCell>
        );
      },
    },
    {
      id: "code",
      accessorFn: (data) => data.code,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Code" />
      ),
    },
    {
      id: "rcm",
      header: "RCM Vehicle Type",
      cell: RcmVehicleCodeCell,
    },
  ];

  return (
    <VehicleTable
      id="settings-integrations-jucy-vehicles"
      columns={columns}
      queryVariables={{
        supplier_id: supplierId,
        archived: false,
        orderBy: [
          {
            order: SortOrder.Asc,
            column: QueryVehiclesOrderByColumn.Name,
          },
        ],
      }}
    />
  );
}
