import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type IntegrationRecord = ResultOf<
  typeof integrationRecordQuery
>["integration"];

export const integrationRecordQuery = graphql(`
  query integration($id: ID!) {
    integration(id: $id) {
      id
      type
      supplier {
        id
      }
      metadata
    }
  }
`);

export const supplierIntegrationList = graphql(`
  query integrations($supplier_id: ID) {
    integrations(supplier_id: $supplier_id) {
      data {
        id
        supplier {
          id
        }
        type
        metadata
      }
    }
  }
`);

export const jucyIntegrationList = graphql(`
  query jucyIntegrations($supplier_id: ID) {
    integrations(supplier_id: $supplier_id, type: JUCY) {
      data {
        id
        supplier {
          id
        }
      }
    }
  }
`);
