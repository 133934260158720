import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Field, Label } from "@/components/catalyst/fieldset";
import { CirclePlusIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";
import { Select } from "@/components/catalyst/select";

export type SelectFilterOption<T extends string> = {
  label: string;
  value: T;
};

export function SelectFilter<T extends string>({
  options,
  onChange,
  selected,
  label,
}: {
  options: SelectFilterOption<T>[];
  onChange: (value: T | null) => void;
  selected: NoInfer<T> | null;
  label: ReactNode;
}) {
  const selectedLabel = options.find(
    (option) => option.value === selected,
  )?.label;

  return (
    <Popover>
      <PopoverTrigger className="inline-flex items-center space-x-1 rounded-full border border-dashed border-gray-300 px-2 py-[1px] text-xs transition-all hover:border-gray-500 hover:bg-gray-100">
        <CirclePlusIcon
          onClick={(e) => {
            e.preventDefault();

            onChange(null);
          }}
          className={cn("h-3 w-3 transition-all", {
            "rotate-0": selected === null,
            "rotate-45 hover:text-red-500": selected !== null,
          })}
        />
        <span className="font-semibold text-gray-700">{label}</span>

        {selected !== null ? (
          <span className="border-l border-gray-300 px-1 font-semibold text-indigo-500">
            {selectedLabel}
          </span>
        ) : null}
      </PopoverTrigger>
      <PopoverContent align="start">
        <Field className="relative">
          <Label>{label}</Label>
          <Select
            value={selected ?? ""}
            onChange={(e) => onChange(e.target.value as T)}
          >
            <option value="" disabled selected>
              Select an option
            </option>
            {options.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label}
              </option>
            ))}
          </Select>
        </Field>
      </PopoverContent>
    </Popover>
  );
}
