import * as yup from "yup";
import { Fuel, Transmission } from "@/gql/graphql";

export const createVehicleSchema = yup.object({
  supplier: yup.object().required(),
  name: yup.string().required(),
  type: yup.string().required(),
  hire_unit_rate: yup.number().required(),
  extra_hire_unit_rate: yup.number().required(),
  seatbelts: yup.number().min(1).required(),
  transmission: yup
    .mixed<Transmission>()
    .oneOf(Object.values(Transmission))
    .required(),
  fuel: yup.mixed<Fuel>().oneOf(Object.values(Fuel)).required(),
});
