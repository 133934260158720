import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { SupplierInput } from "@/app/Suppliers/Components/SupplierInput";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { RichTextInput } from "@/lib/Components/Form/Inputs/RichTextInput";
import { VehicleTypeInput } from "@/app/Vehicles/Components/VehicleTypeInput";
import { FuelInput } from "@/app/Vehicles/Components/FuelInput";
import { TransmissionInput } from "@/app/Vehicles/Components/TransmissionInput";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { HireUnitTypeInput } from "@/app/Common/Components/HireUnitType";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import {
  CreateVehicleInput,
  Currency,
  HireUnitType,
  VehicleType,
} from "@/gql/graphql";
import { TrueFalseNullInput } from "@/lib/Components/Form/Inputs/TrueFalseNullInput";
import { VehicleAgeInput } from "@/app/Vehicles/Components/VehicleAgeInput";
import { useTranslations } from "use-intl";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";

export function VehicleForm() {
  const { values, setFieldValue, initialValues } =
    useForm<CreateVehicleInput>();
  const t = useTranslations();

  const isSleeper = [
    VehicleType.FourWheelDriveCamper,
    VehicleType.CamperVan,
    VehicleType.MotorHome,
  ].includes(values.type);
  const { isAdmin } = useTenant();

  const { data } = useGqlQuery(
    supplierRecordQuery,
    {
      id: values.supplier?.connect!,
    },
    {
      enabled: !!values.supplier?.connect,
    },
  );

  const currency = data?.supplier.currency ?? Currency.Usd;

  return (
    <>
      {!initialValues.supplier?.connect ? (
        <>
          <SupplierInput
            name={"supplier.connect"}
            label={"Supplier"}
            className="col-span-3"
            onChange={(newSupplier) => {
              if (!newSupplier) return;

              setFieldValue("hire_unit_type", newSupplier?.hire_unit_type);
            }}
          />
          <FormDivider />
        </>
      ) : null}

      {values.supplier?.connect ? (
        <>
          <TextInput
            name={"name"}
            label={"Name"}
            tooltip="The name the customer will see"
          />
          <TextInput
            name={"code"}
            label={"Code"}
            optionalLabel
            tooltip="The code the supplier has on their import lists"
          />
          <FormDivider />

          <RichTextInput
            optionalLabel
            name={"description"}
            label={"Description"}
            className="col-span-12"
          />

          <FormDivider />
          <VehicleTypeInput name="type" label="Vehicle type" />
          <FuelInput name={"fuel"} label={"Fuel"} />
          <TransmissionInput name={"transmission"} label={"Transmission"} />
          <VehicleAgeInput
            optionalLabel
            name={"approximate_age"}
            label={"Vehicle age"}
          />
          <NumberInput
            name="seatbelts"
            label="Seatbelts"
            inputProps={{
              min: 0,
            }}
          />
          <NumberInput
            name={"child_seat_anchor_points"}
            label={"Child seat anchors"}
            optionalLabel
            inputProps={{
              min: 0,
            }}
          />
          <TextInput name="brand" label="Brand" optionalLabel />
          <TextInput name="model" label="Model" optionalLabel />
          <TrueFalseNullInput
            name={"is_pet_friendly"}
            label={"Pet friendly"}
            optionalLabel
          />

          {values.is_pet_friendly ? (
            <CurrencyInput
              name={"pet_cleaning_fee"}
              label="Pet cleaning fee"
              currency={currency}
              optionalLabel
            />
          ) : null}

          {isSleeper ? (
            <>
              <FormDivider />
              <NumberInput
                name={"sleeps"}
                label={"Sleeps"}
                inputProps={{
                  min: 0,
                }}
              />
              <div className="col-span-8" />

              <TrueFalseNullInput
                label="Self contained"
                name="is_self_contained"
              />
              <TrueFalseNullInput label="Kitchen" name="has_kitchen" />
              <TrueFalseNullInput
                label="Shower & toilet"
                name="has_shower_and_toilet"
              />
              <TrueFalseNullInput label="Heating" name="has_heating" />
            </>
          ) : null}

          {isAdmin ? (
            <>
              <FormDivider />
              <CurrencyInput
                name="hire_unit_rate"
                label="Relocation rate"
                currency={currency}
              />
            </>
          ) : null}

          <FormDivider />
          <HireUnitTypeInput name={"hire_unit_type"} label={"Charged per"} />
          <CurrencyInput
            isEditable={false}
            name="extra_hire_unit_rate"
            currency={currency}
            label={`Extra ${t(
              `relocation.hire_unit_type.${
                values.hire_unit_type ?? HireUnitType.Day
              }`,
            )} rate`}
          />
          <CurrencyInput
            currency={currency}
            onChange={(newValue) => {
              if (newValue === null) return;

              setFieldValue(
                "extra_hire_unit_rate",
                newValue + (values?.extra_hire_unit_deposit_rate ?? 0),
              );
            }}
            name="extra_hire_unit_supplier_net_rate"
            label="Supplier net"
          />
          <CurrencyInput
            currency={currency}
            isEditable={isAdmin}
            name="extra_hire_unit_deposit_rate"
            onChange={(value) => {
              setFieldValue(
                "extra_hire_unit_rate",
                (value ?? 0) + (values?.extra_hire_unit_supplier_net_rate ?? 0),
              );
            }}
            label="Imoova net"
          />
        </>
      ) : null}
    </>
  );
}
