import { createRoute } from "@tanstack/react-router";
import { ContactListScreen } from "@/app/Contacts/Screens/ContactListScreen";
import { ContactType } from "@/gql/graphql";
import { ContactDetailsScreen } from "@/app/Contacts/Screens/ContactDetailsScreen";
import { ContactRecordScreen } from "@/app/Contacts/Screens/ContactRecordScreen";
import { ContactInvoicesScreen } from "@/app/Contacts/Screens/ContactInvoicesScreen";
import { z } from "zod";
import { ContactEntityScreen } from "@/app/Contacts/Screens/ContactEntityScreen";
import { appLayoutRoute } from "@/routes";
import { getContactListQueryVariables } from "@/app/Contacts/Utils/getContactListQueryVariables";
import { contactListQuery } from "@/app/Contacts/GraphQL/contactListQuery";
import {
  fetchQuery,
  prefetchInfiniteQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { contactRecordQuery } from "@/app/Contacts/GraphQL/contactRecordQuery";
import { ContactStatementScreen } from "@/app/Contacts/Screens/ContactStatementScreen";

const contactsSearchParams = z.object({
  contactType: z.array(z.nativeEnum(ContactType)).optional().catch(undefined),
  contactSearch: z.string().optional(),
});
export type ContactsSearchParams = z.infer<typeof contactsSearchParams>;
export const contactsRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/contacts",
  component: ContactListScreen,
  validateSearch: contactsSearchParams,
  loaderDeps: ({ search: { contactType, contactSearch } }) => ({
    contactType,
    contactSearch,
  }),
  loader: ({ deps, context: { auth } }) => {
    return prefetchInfiniteQuery(
      contactListQuery,
      getContactListQueryVariables(deps),
      auth,
    );
  },
});
export const contactRoute = createRoute({
  getParentRoute: () => contactsRoute,
  path: "$id",
  component: ContactRecordScreen,
  loader: ({ params: { id }, context: { auth } }) => {
    return fetchQuery(contactRecordQuery, { id }, auth);
  },
});
export const contactDetailRoute = createRoute({
  getParentRoute: () => contactRoute,
  path: "/",
  component: ContactDetailsScreen,
});
export const contactEntityRoute = createRoute({
  getParentRoute: () => contactRoute,
  path: "/entities",
  component: ContactEntityScreen,
});
export const contactInvoiceRoute = createRoute({
  getParentRoute: () => contactRoute,
  path: "/invoices",
  component: ContactInvoicesScreen,
});

export const contactStatementRoute = createRoute({
  getParentRoute: () => contactRoute,
  path: "/statements",
  component: ContactStatementScreen,
});
