import { z } from "zod";
import { createRoute } from "@tanstack/react-router";
import { SupplierListScreen } from "@/app/Suppliers/Screens/SupplierListScreen";
import { SupplierCreateScreen } from "@/app/Suppliers/Screens/SupplierCreateScreen";
import { SupplierRecordScreen } from "@/app/Suppliers/Screens/SupplierRecordScreen";
import { SupplierDetailScreen } from "@/app/Suppliers/Screens/SupplierDetailScreen";
import { SupplierVehicleScreen } from "@/app/Suppliers/Screens/SupplierVehicleScreen";
import { SupplierOfficeScreen } from "@/app/Suppliers/Screens/SupplierOfficeScreen";
import { SupplierLogisticsScreen } from "@/app/Suppliers/Screens/SupplierLogisticsScreen";
import { SupplierRelocationScreen } from "@/app/Suppliers/Screens/SupplierRelocationScreen";
import { SupplierBookingScreen } from "@/app/Suppliers/Screens/SupplierBookingScreen";
import { SupplierActiveLinesScreen } from "@/app/Suppliers/Screens/SupplierActiveLinesScreen";
import { SupplierDefaultOpenHoursScreen } from "@/app/Suppliers/Screens/SupplierDefaultOpenHoursScreen";
import { SupplierActivityScreen } from "@/app/Suppliers/Screens/SupplierActivityScreen";
import { getSupplierListQueryVariables } from "@/app/Suppliers/Utils/getSupplierListQueryVariables";
import { appLayoutRoute } from "@/routes";
import {
  fetchQuery,
  prefetchInfiniteQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { supplierListQuery } from "@/app/Suppliers/GraphQL/supplierListQuery";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { SupplierInsuranceScreen } from "@/app/Suppliers/Screens/SupplierInsuranceScreen";
import { SupplierBillingScreen } from "@/app/Suppliers/Screens/SupplierBillingScreen";
import { RelocationStatus } from "@/gql/graphql";
import { SupplierTableScreen } from "@/app/Suppliers/Screens/SupplierTableScreen";

const supplierSearchParams = z.object({});
export type SupplierListSearchParams = z.infer<typeof supplierSearchParams>;

export const suppliersRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/suppliers",
});

export const supplierTableRoute = createRoute({
  getParentRoute: () => suppliersRoute,
  path: "/",
  component: SupplierTableScreen,
});

export const supplierRecordLayout = createRoute({
  getParentRoute: () => suppliersRoute,
  id: "_supplierRecordLayout",
  component: SupplierListScreen,
  validateSearch: supplierSearchParams,
  loader: ({ context: { auth } }) => {
    const variables = getSupplierListQueryVariables({});
    return prefetchInfiniteQuery(supplierListQuery, variables, auth);
  },
});

export const supplierRecordRoute = createRoute({
  getParentRoute: () => supplierRecordLayout,
  path: "$id",
  component: SupplierRecordScreen,
  loader: ({ params: { id }, context: { auth } }) => {
    return fetchQuery(
      supplierRecordQuery,
      {
        id: id,
      },
      auth,
    );
  },
});

export const supplierCreateRoute = createRoute({
  getParentRoute: () => suppliersRoute,
  path: "/create",
  component: SupplierCreateScreen,
});

export const supplierDetailRoute = createRoute({
  getParentRoute: () => supplierRecordRoute,
  path: "/",
  component: SupplierDetailScreen,
});

export const supplierInsuranceRoute = createRoute({
  getParentRoute: () => supplierRecordRoute,
  path: "/insurance",
  component: SupplierInsuranceScreen,
});

export const supplierBillingRoute = createRoute({
  getParentRoute: () => supplierRecordRoute,
  path: "/billing",
  component: SupplierBillingScreen,
});

export const supplierVehiclesRoute = createRoute({
  getParentRoute: () => supplierRecordRoute,
  path: "/vehicles",
  component: SupplierVehicleScreen,
});

export const supplierOfficesRoute = createRoute({
  getParentRoute: () => supplierRecordRoute,
  path: "/offices",
  component: SupplierOfficeScreen,
});

export const supplierLogisticsRoute = createRoute({
  getParentRoute: () => supplierRecordRoute,
  path: "/logistics",
  component: SupplierLogisticsScreen,
});

const supplierRelocationSearchParams = z.object({
  relocationStatus: z.array(z.nativeEnum(RelocationStatus)).optional(),
});
export const supplierRelocationsRoute = createRoute({
  getParentRoute: () => supplierRecordRoute,
  path: "/relocations",
  component: SupplierRelocationScreen,
  validateSearch: supplierRelocationSearchParams,
});

export const supplierBookingsRoute = createRoute({
  getParentRoute: () => supplierRecordRoute,
  path: "/bookings",
  component: SupplierBookingScreen,
});

export const supplierActiveLinesRoute = createRoute({
  getParentRoute: () => supplierRecordRoute,
  path: "/active-lines",
  component: SupplierActiveLinesScreen,
});

export const supplierDefaultHoursRoute = createRoute({
  getParentRoute: () => supplierRecordRoute,
  path: "/default-hours",
  component: SupplierDefaultOpenHoursScreen,
});

export const supplierActivityRoute = createRoute({
  getParentRoute: () => supplierRecordRoute,
  path: "/activity",
  component: SupplierActivityScreen,
});
