import { useMatch } from "@tanstack/react-router";
import { paymentRoute } from "@/app/Payments/Routes/paymentRoute";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { paymentRecordQuery } from "@/app/Payments/GraphQL/paymentRecordQuery";

export function usePaymentRecord() {
  const match = useMatch({
    from: paymentRoute.id,
  });

  const { data } = useSuspenseGqlQuery(paymentRecordQuery, {
    id: match.params.id,
  });

  return data.payment;
}
