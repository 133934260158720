import {
  QueryRelocationsOrderByColumn,
  RelocationListQueryVariables,
  SortOrder,
} from "@/gql/graphql";
import { RelocationListSearchParams } from "@/app/Relocations/Routes/relocationRoute";
import { getGlobalSupplierId } from "@/app/Suppliers/Utils/getGlobalSupplierId";

export function getRelocationListQueryVariables(
  searchParams: RelocationListSearchParams,
): RelocationListQueryVariables {
  const supplierId = getGlobalSupplierId();

  const baseParams: RelocationListQueryVariables = {
    first: 20,
    page: 1,
    status: searchParams.relocationStatus,
    search: searchParams.relocationSearch || undefined,
    supplier_id: supplierId ?? undefined,
  };

  if (searchParams.relocationSearch) {
    return baseParams;
  }

  return {
    ...baseParams,
    orderBy: [
      {
        order: SortOrder.Desc,
        column: QueryRelocationsOrderByColumn.CreatedAt,
      },
    ],
  };
}
