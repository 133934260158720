const dayMap = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export function sortOpenHours(openHours: Record<string, any> | null) {
  if (!openHours) return null;

  return Object.fromEntries(
    Object.entries(openHours).sort((a, b) => {
      return dayMap.indexOf(a[0]) - dayMap.indexOf(b[0]);
    }),
  );
}
