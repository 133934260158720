import { RelocationStatus } from "@/gql/graphql";
import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { RelocationInput } from "@/app/Relocations/Components/RelocationInput";
import { useState } from "react";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { bookingChangeRelocationMutation } from "@/app/Bookings/GraphQL/bookingMutations";
import { BookingRecord } from "@/app/Bookings/GraphQL/bookingRecordQuery";
import { RelocationListItem } from "@/app/Relocations/GraphQL/relocationListQuery";
import { RelocationSummaryCard } from "@/app/Relocations/Components/RelocationSummaryCard";

export type BookingChangeRelocationDialogProps = {
  booking: BookingRecord;
  newRelocation: RelocationListItem | null;
};
export function BookingChangeRelocationDialog({
  booking,
  newRelocation,
}: BookingChangeRelocationDialogProps) {
  const [relocation, setRelocation] = useState<RelocationListItem | null>(
    newRelocation,
  );
  const { mutateAsync } = useGqlMutation(bookingChangeRelocationMutation);
  const { close, resolve } = useDialogState();

  return (
    <GenericDialog title={"Change Relocation"}>
      <div className="mt-12">
        <GenericForm
          initialValues={{
            relocation: {
              connect: newRelocation?.id ?? null!,
            },
            release_linked_relocation: false,
          }}
          onSubmit={async (values) => {
            await mutateAsync({
              id: booking.id,
              input: {
                release_linked_relocation: values.release_linked_relocation,
                relocation_id: values.relocation.connect,
              },
            });
            resolve?.(relocation);
            close();
          }}
        >
          <RelocationInput
            name="relocation.connect"
            label="Relocation"
            getQueryVariables={(search) => ({
              search,
              page: 1,
              first: 20,
              status: [RelocationStatus.Ready],
            })}
            onChange={setRelocation}
          />
          <SwitchInput
            name={"release_linked_relocation"}
            label={`Relist old relocation (ID: ${booking.relocation.id})`}
          />
          <div className="col-span-full">
            <h1>Old</h1>
            <RelocationSummaryCard relocation={booking.relocation} />
          </div>
          {relocation ? (
            <div className="col-span-full mt-3">
              <h1>New</h1>
              <RelocationSummaryCard relocation={relocation} />
            </div>
          ) : null}
          <DialogSubmitButton text={"Change Relocation"} />
        </GenericForm>
      </div>
    </GenericDialog>
  );
}
