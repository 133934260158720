import {
  createRoute,
  lazyRouteComponent,
  Outlet,
  redirect,
} from "@tanstack/react-router";
import { appLayoutRoute } from "@/routes";
import { ImportUploadScreen } from "@/app/Import/Screens/ImportUploadScreen";
import { ImportSelectSupplierScreen } from "@/app/Import/Screens/ImportSelectSupplierScreen";
import { getGlobalSupplierId } from "@/app/Suppliers/Utils/getGlobalSupplierId";
import { ImportSupplierLayout } from "@/app/Import/Screens/ImportSupplierLayout";
import { fetchQuery } from "@/lib/GraphQLCodegen/fetcher";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";

export const importParentRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/import",
  component: Outlet,
});

export const importSelectSupplierRoute = createRoute({
  getParentRoute: () => importParentRoute,
  beforeLoad: async () => {
    const supplierId = getGlobalSupplierId();
    if (supplierId) {
      throw redirect({
        to: "/import/$supplierId",
        params: {
          supplierId: supplierId,
        },
      });
    }
  },
  path: "/",
  component: ImportSelectSupplierScreen,
});

export const importSupplierRoute = createRoute({
  getParentRoute: () => importParentRoute,
  path: "/$supplierId",
  component: ImportSupplierLayout,
  loader: ({ params: { supplierId }, context: { auth } }) => {
    return fetchQuery(
      supplierRecordQuery,
      {
        id: supplierId,
      },
      auth,
    );
  },
});

export const importUploadRoute = createRoute({
  getParentRoute: () => importSupplierRoute,
  path: "/",
  component: ImportUploadScreen,
});

export const importSpreadsheetRoute = createRoute({
  getParentRoute: () => importSupplierRoute,
  path: "/spreadsheet",
  component: lazyRouteComponent(
    () => import("../Screens/ImportSpreadsheetScreen"),
  ),
});
