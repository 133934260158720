import { InProgressBanner } from "@/app/Reports/Screens/ReportInvoiceScreen";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import dayjs from "dayjs";
import { Button } from "@/lib/Components/Button/Button";
import { FileSpreadsheetIcon } from "lucide-react";
import { PaymentsTable } from "@/app/Payments/Components/PaymentsTable";
import { CurrencyTypeInput } from "@/app/Common/Components/CurrencyTypeInput";
import { TimezoneInput } from "@/lib/Components/Form/Inputs/TimezoneInput";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createReportPaymentMutationGraphql } from "@/app/Reports/GraphQL/createReportPaymentMutation.graphql";
import { useDownload } from "@/lib/Utils/useDownload";
import {
  Currency,
  PaymentStatus,
  QueryPaymentsWhereColumn,
  SqlOperator,
} from "@/gql/graphql";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";

type ReportPaymentFormValues = {
  start: string;
  end: string;
  timezone: string;
  currency: Currency | null;
};

export function ReportPaymentScreen() {
  const { mutateAsync } = useGqlMutation(createReportPaymentMutationGraphql);
  const downloadFn = useDownload();

  return (
    <>
      <InProgressBanner />

      <GenericForm<ReportPaymentFormValues>
        initialValues={{
          start: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
          end: dayjs().format("YYYY-MM-DD"),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          currency: null,
        }}
        onSubmit={async (values) => {
          const res = await mutateAsync({
            currency: values.currency,
            startDate: dayjs(values.start).toISOString(),
            endDate: dayjs(values.end).toISOString(),
            statues: [
              PaymentStatus.Paid,
              PaymentStatus.PartiallyRefunded,
              PaymentStatus.Refunded,
            ],
          });

          downloadFn(res.createReportPaymentDownloadUrl);
        }}
      >
        <Form />
      </GenericForm>
    </>
  );
}

function Form() {
  const { values, isSubmitting } = useForm<ReportPaymentFormValues>();

  const { start, end } = values;
  const startDateTime = dayjs(start).tz(values.timezone).startOf("day");
  const endDateTime = dayjs(end).tz(values.timezone).endOf("day");

  return (
    <>
      <DateInput
        name={"start"}
        label="From"
        max={dayjs().format("YYYY-MM-DD")}
      />
      <DateInput
        name={"end"}
        label="To"
        min={values.end}
        max={dayjs().format("YYYY-MM-DD")}
      />

      <TimezoneInput name="timezone" label="Timezone" />

      <div className="col-span-3 flex justify-end">
        <Button
          LeadingIcon={FileSpreadsheetIcon}
          htmlButtonType={"submit"}
          isLoading={isSubmitting}
        >
          Export
        </Button>
      </div>

      <CurrencyTypeInput name={"currency"} label={"Currency"} clearable />

      <div className="col-span-full">
        <PaymentsTable
          id={"report-payments"}
          queryVariables={{
            currency: values.currency,
            where: {
              AND: [
                {
                  column: QueryPaymentsWhereColumn.CreatedAt,
                  operator: SqlOperator.Between,
                  value: [startDateTime, endDateTime],
                },
              ],
            },
            status: [
              PaymentStatus.Paid,
              PaymentStatus.Refunded,
              PaymentStatus.Paid,
            ],
          }}
        />
      </div>
    </>
  );
}
