import { SettingCard } from "@/app/Settings/Settings/Components/SettingFormCard";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { FeatureFlagType, IntegrationType } from "@/gql/graphql";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { supplierIntegrationList } from "@/app/Settings/Settings/GraphQL/integrationQueries";
import {
  createIntegrationMutation,
  deleteIntegrationrMutation,
} from "@/app/Settings/Settings/GraphQL/integrationMutations";
import { QueryClient } from "@/lib/ReactQuery/QueryClient";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { Button } from "@/lib/Components/Button/Button";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import JucyLogoSvg from "@/app/Settings/Integrations/Jucy/Icons/JucyLogoSvg";
import { FC } from "react";
import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { useFeatureFlag } from "@/app/Meta/utils/useFeatureFlag";
import { Link } from "@tanstack/react-router";
import { Badge } from "@/components/catalyst/badge";
import { IconButton } from "@/lib/Components/Button/IconButton";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import RcmLogoSvg from "@/app/Settings/Integrations/Rcm/Icons/RcmLogoSvg";
import { ConstructionIcon, FileSpreadsheet } from "lucide-react";

type Integration = {
  type: IntegrationType;
  description: string;
  logo: FC<any>;
};

const integrations: Integration[] = [
  {
    type: IntegrationType.Jucy,
    description: "Enable integration to automate booking requests",
    logo: JucyLogoSvg,
  },
  {
    type: IntegrationType.Rcm,
    description: "Sync relocations with your rental car manager system",
    logo: RcmLogoSvg,
  },
  {
    type: IntegrationType.Camperman,
    description:
      "Enable integration to automate pull relocations from a spreadsheet",
    logo: FileSpreadsheet,
  },
];

export function SettingsIntegrationsScreen() {
  const { supplier } = useGlobalSupplier();
  if (!supplier) {
    throw new Error("No supplier active");
  }

  return (
    <div className="space-y-6">
      <AddIntegrationCard supplier={supplier} />
      <ActiveIntegrationsCard supplier={supplier} />
    </div>
  );
}

function AddIntegrationCard({ supplier }: { supplier: SupplierRecord }) {
  const isJucyEnabled = useFeatureFlag(FeatureFlagType.JucyIntegration);
  const isRcmEnabled = useFeatureFlag(FeatureFlagType.RcmIntegration);
  const isCampermanEnabled = useFeatureFlag(
    FeatureFlagType.CampermanIntegration,
  );
  const { data, isLoading } = useSuspenseGqlQuery(supplierIntegrationList, {
    supplier_id: supplier?.id,
  });

  const { mutateAsync, isPending } = useGqlMutation(createIntegrationMutation, {
    onSuccess: () => {
      return QueryClient.invalidateQueries();
    },
  });

  if (isLoading) {
    return null;
  }

  const visibleIntegrations = integrations.filter((integration) => {
    if (data.integrations.data.some((item) => item.type === integration.type)) {
      return false;
    }

    if (integration.type === IntegrationType.Jucy) {
      return isJucyEnabled;
    }

    if (integration.type === IntegrationType.Rcm) {
      return isRcmEnabled;
    }

    if (integration.type === IntegrationType.Camperman) {
      return isCampermanEnabled;
    }

    return true;
  });

  return (
    <SettingCard title="Add Integrations" description="">
      <ul className="space-y-6">
        {visibleIntegrations.map((integration, idx) => {
          const Logo = integration.logo;
          return (
            <div
              key={idx}
              className="flex items-center space-x-2 rounded-md border border-gray-100 p-6"
            >
              <Logo className="size-[60px] rounded-md" />
              <div className="flex flex-col">
                <span className="text-base font-semibold capitalize leading-6 text-gray-900">
                  {integration.type.toLowerCase()}
                </span>
                <span className="text-sm text-gray-500">
                  {integration.description}
                </span>
              </div>
              <div className="grid grow justify-items-end">
                <Button
                  LeadingIcon={PlusIcon}
                  isLoading={isPending}
                  onClick={async () => {
                    await mutateAsync({
                      input: {
                        supplier: { connect: supplier.id },
                        type: integration.type,
                      },
                    });
                  }}
                >
                  Add Integration
                </Button>
              </div>
            </div>
          );
        })}

        <div className="flex items-center space-x-2 rounded-md border border-gray-100 p-6">
          <ConstructionIcon className="size-[60px] rounded-md text-gray-700" />
          <div className="flex flex-col">
            <span className="text-base font-semibold capitalize leading-6 text-gray-900">
              More coming soon...
            </span>
            <span className="text-sm">
              Do you want us to integrate with your booking platform?{" "}
              <a className="underline" href="mailto:tradepartners@imoova.com">
                Let us know!
              </a>
              {" 🚀"}
            </span>
          </div>
          <div className="grid grow justify-items-end"></div>
        </div>
      </ul>
    </SettingCard>
  );
}

function ActiveIntegrationsCard({ supplier }: { supplier: SupplierRecord }) {
  const { data, isPending } = useSuspenseGqlQuery(supplierIntegrationList, {
    supplier_id: supplier?.id,
  });

  const { mutateAsync } = useGqlMutation(deleteIntegrationrMutation);

  return (
    <SettingCard
      title="Active Integrations"
      description="Current active integrations"
    >
      {isPending ? (
        <div className="flex items-center justify-center p-6">
          <Spinner className="h-full items-center justify-center" />
        </div>
      ) : null}

      {data.integrations.data.map((item) => {
        const integration = integrations.find(
          (integration) => integration.type === item.type,
        );

        const Logo = integration?.logo!;

        return (
          <Link
            key={item.id}
            className="relative flex items-center space-x-2 rounded-md border border-gray-100 p-6 hover:bg-gray-50"
            to="/settings/integrations/$integrationId"
            params={{ integrationId: item.id }}
          >
            <Logo className="h-[60px] w-[60px] rounded-md" />
            <div className="flex flex-col">
              <span className="text-base font-semibold capitalize leading-6 text-gray-900">
                {item.type.toLowerCase()}
              </span>
              <span className="text-sm text-gray-500">
                {integration?.description}
              </span>
            </div>
            <div className="grid grow justify-items-end">
              <Badge color="green">Active</Badge>
            </div>

            <IconButton
              Icon={TrashIcon}
              tooltip="Delete"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await mutateAsync({ id: item.id });
              }}
            />
          </Link>
        );
      })}

      {data.integrations.data.length === 0 ? (
        <WarningBanner>No active integrations</WarningBanner>
      ) : null}
    </SettingCard>
  );
}
