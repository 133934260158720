import { graphql } from "@/gql";

export const updateStripePaymentMethodMutation = graphql(`
  mutation UpdateStripePaymentMethod(
    $id: ID!
    $input: UpdateStripePaymentMethodInput!
  ) {
    updateStripePaymentMethod(id: $id, input: $input) {
      id
      is_primary
    }
  }
`);
