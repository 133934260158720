import { appLayoutRoute } from "@/routes";
import { createRoute, Outlet } from "@tanstack/react-router";
import { BookingRecordScreen } from "@/app/Bookings/Screens/BookingRecordScreen";
import { BookingStatus } from "@/gql/graphql";
import {
  fetchQuery,
  prefetchInfiniteQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { z } from "zod";
import { BookingCreateScreen } from "@/app/Bookings/Screens/BookingCreateScreen";
import { BookingDetailsScreen } from "@/app/Bookings/Screens/BookingDetailsScreen";
import { BookingRelocationScreen } from "@/app/Bookings/Screens/BookingRelocationScreen";
import { BookingFindRelocationScreen } from "@/app/Bookings/Screens/BookingFindRelocationScreen";
import { BookingChangeRequestScreen } from "@/app/Bookings/Screens/BookingChangeRequestScreen";
import { BookingPaymentsScreen } from "@/app/Bookings/Screens/BookingPaymentsScreen";
import { BookingTaskScreen } from "@/app/Bookings/Screens/BookingTaskScreen";
import { bookingRecordQuery } from "@/app/Bookings/GraphQL/bookingRecordQuery";
import { BookingTableScreen } from "@/app/Bookings/Screens/BookingTableScreen";
import { getBookingListQueryVariables } from "../Utils/getBookingListQueryVariables";
import { bookingListQuery } from "../GraphQL/bookingListQuery";
import { BookingListScreen } from "@/app/Bookings/Screens/BookingListScreen";

const bookingsSearchParams = z.object({
  bookingStatus: z
    .array(z.nativeEnum(BookingStatus))
    .optional()
    .catch(undefined),
});

export type BookingListSearchParams = z.infer<typeof bookingsSearchParams>;
export const bookingsRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/bookings",
  component: Outlet,
});

export const bookingTableRoute = createRoute({
  getParentRoute: () => bookingsRoute,
  path: "/",
  component: BookingTableScreen,
  validateSearch: bookingsSearchParams,
  loaderDeps: ({ search }) => search,
  loader: ({ deps, context: { auth } }) => {
    const variables = getBookingListQueryVariables(deps);
    return fetchQuery(bookingListQuery, variables, auth);
  },
});

export const bookingRecordLayout = createRoute({
  getParentRoute: () => bookingsRoute,
  id: "_bookingRecordLayout",
  component: BookingListScreen,
  validateSearch: bookingsSearchParams,
  loaderDeps: ({ search }) => search,
  loader: ({ deps, context: { auth } }) => {
    const variables = getBookingListQueryVariables(deps);
    return prefetchInfiniteQuery(bookingListQuery, variables, auth);
  },
});

export const bookingCreateRoute = createRoute({
  getParentRoute: () => bookingRecordLayout,
  path: "/create",
  component: BookingCreateScreen,
});

export const bookingRoute = createRoute({
  getParentRoute: () => bookingRecordLayout,
  path: "/$bookingId",
  component: BookingRecordScreen,
  loader: ({ params: { bookingId: id }, context: { auth } }) => {
    return Promise.all([fetchQuery(bookingRecordQuery, { id }, auth)]);
  },
});

export const bookingDetailRoute = createRoute({
  getParentRoute: () => bookingRoute,
  path: "/",
  component: BookingDetailsScreen,
});

export const bookingRelocationRoute = createRoute({
  getParentRoute: () => bookingRoute,
  path: "/relocation",
  component: BookingRelocationScreen,
});

export const bookingFindRelocationRoute = createRoute({
  getParentRoute: () => bookingRoute,
  path: "/find-relocation",
  component: BookingFindRelocationScreen,
});

export const bookingChangeRequestsRoute = createRoute({
  getParentRoute: () => bookingRoute,
  path: "/change-requests",
  component: BookingChangeRequestScreen,
});

export const bookingPaymentsRoute = createRoute({
  getParentRoute: () => bookingRoute,
  path: "/payments",
  component: BookingPaymentsScreen,
});

export const bookingTasksRoute = createRoute({
  getParentRoute: () => bookingRoute,
  path: "/tasks",
  component: BookingTaskScreen,
});
