import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type PaymentListItem = ResultOf<
  typeof paymentListQuery
>["payments"]["data"][number];

export const paymentListQuery = graphql(`
  query PaymentList(
    $first: Int!
    $page: Int
    $search: String
    $id: ID
    $type: [PaymentType!]
    $currency: [Currency!]
    $status: [PaymentStatus!]
    $payeeContactId: ID
    $excludeIds: [ID!]
    $where: QueryPaymentsWhereWhereConditions
    $orderBy: [QueryPaymentsOrderByOrderByClause!]
  ) {
    payments(
      first: $first
      page: $page
      id: $id
      where: $where
      type: $type
      status: $status
      currency: $currency
      payee_contact_id: $payeeContactId
      excludeIds: $excludeIds
      orderBy: $orderBy
      search: $search
    ) {
      data {
        id
        type
        description
        status
        amount
        amount_refunded
        amount_tax
        amount_fee
        amount_paid
        amount_inc_tax
        due_date
        currency
        tax_type
        is_tax_included
        created_at
        updated_at
        attributedTo {
          __typename
          ... on Booking {
            id
            reference
          }
        }
        fulfilledBy {
          ... on StripePayment {
            ...StripePaymentFields
          }
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
