import { z } from "zod";
import { RelocationInclusionType } from "@/gql/graphql";

export const createSupplierTripValidator = z.object({
  supplier: z.object({
    connect: z.string(),
  }),
  officeA: z.object({
    connect: z.string(),
  }),
  officeB: z.object({
    connect: z.string(),
  }),
  hire_units_allowed: z.number().min(1),
  minimum_hire_units: z.number().min(1),
  extra_hire_units_allowed: z.number().min(0),
  is_ferry_required: z.boolean(),
  inclusions: z.object({
    upsert: z.array(
      z.object({
        type: z.nativeEnum(RelocationInclusionType),
        description: z.string().min(0),
        value: z.number().nullable(),
      }),
    ),
    delete: z.array(z.string()).nullish(),
  }),
});
