import { graphql } from "@/gql";

export const updateRelocationCompletedTask = graphql(`
  mutation UpdateRelocationCompletedTask(
    $id: ID!
    $input: UpdateRelocationCompletedTaskInput!
  ) {
    updateRelocationCompletedTask(id: $id, input: $input) {
      id
      status
      type
      related_to_id
      related_to_type
    }
  }
`);
