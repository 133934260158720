import { PaidDriverTable } from "@/app/PaidDrivers/PaidDrivers/Components/PaidDriverTable";
import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";

export function PaidDriverListScreen() {
  return (
    <FullPageScreen>
      <PaidDriverTable id="paid-driver-list-screen" />
    </FullPageScreen>
  );
}
