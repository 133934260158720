import { IllustrationIdea } from "@/assets/Illustrations";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Link, LinkProps, Outlet, useSearch } from "@tanstack/react-router";
import React, { ReactNode, Suspense, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { GenericErrorBoundary } from "../Layout/ErrorBoundary/ErrorBoundary";
import { PlusIcon } from "@heroicons/react/24/outline";

import { DropdownMenuCheckboxItemProps } from "@radix-ui/react-dropdown-menu";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { FilterIcon } from "lucide-react";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";

export type SidebarListItemProps<T> = {
  model: T;
};

type SidebarListProps<T> = {
  onClearFilters?: () => void;
  createRoute?: LinkProps["to"];
  listRoute: LinkProps["to"];
  search: string;
  onSearchChange: (search: string) => void;
  fetchNextPage: () => void;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  totalCount: number;
  items?: T[];
  itemNode: (item: T) => ReactNode;
  linkProps: (item: T) => LinkProps;
  filters?: ReactNode;
};

export function SidebarList<T>({
  createRoute,
  listRoute,
  search,
  onSearchChange,
  isFetching,
  isFetchingNextPage,
  fetchNextPage,
  totalCount,
  itemNode,
  linkProps,
  items = [],
  filters,
}: SidebarListProps<T>) {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <div className="flex h-full">
      <aside className="relative hidden h-full min-h-0 w-72 flex-shrink-0 flex-col 2xl:flex">
        <header className="">
          {/** Search */}
          <div className="flex items-center space-x-2 border-b border-gray-200 bg-gray-100 py-[13px] pl-3 pr-2">
            <div className="relative rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                {isFetching ? (
                  <Spinner className="h-5 w-5" />
                ) : (
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </div>

              <input
                name="search"
                type="text"
                autoComplete="off"
                className="block w-full rounded-md border-gray-300 py-2 pl-10 text-sm shadow-sm transition-shadow duration-100 focus:border-indigo-500 focus:ring-indigo-500"
                placeholder={`Search ${totalCount} results`}
                value={search}
                onChange={(e) => {
                  onSearchChange(e.target.value);
                }}
              />
            </div>
            {createRoute ? (
              <div className="flex items-center">
                <Link
                  title="create"
                  to={createRoute}
                  params={{}}
                  search={(s) => s}
                >
                  <div className="rounded-md p-2 transition duration-300 ease-in-out hover:bg-gray-300 focus:outline-none focus:ring focus:ring-inset">
                    <PlusIcon className="h-5 w-5 text-gray-500" />
                  </div>
                </Link>
              </div>
            ) : null}
          </div>
        </header>

        {filters ? (
          <div className="flex justify-between border-b border-gray-200 bg-gray-50 px-4 py-1 text-sm font-medium text-gray-500">
            {filters}
          </div>
        ) : null}

        {totalCount > 0 ? (
          <nav className="relative isolate flex h-full flex-col overflow-y-scroll">
            <ul className="divide-y divide-gray-200">
              {items.map((item: any, index: number, arr: T[]) => (
                <li
                  key={item.id}
                  ref={index === arr.length - 1 ? ref : undefined}
                  className="focus-visible:outline-none"
                >
                  <Link
                    preload="intent"
                    className="block focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 hover:bg-blue-200 focus-visible:outline-none"
                    activeProps={{
                      className: "bg-blue-100",
                    }}
                    {...linkProps(item)}
                    search={(params) => params}
                  >
                    {({ isActive }: { isActive: boolean }) => {
                      return (
                        <BaseSidebarListItem isActive={isActive}>
                          {itemNode(item)}
                        </BaseSidebarListItem>
                      );
                    }}
                  </Link>
                </li>
              ))}
            </ul>

            {isFetchingNextPage ? (
              <div className="sticky bottom-4 z-10 flex w-full justify-center">
                <div className="flex space-x-3 rounded-md bg-gray-900/50 p-3 text-gray-50">
                  <Spinner className="text-white-50" />
                  <span>loading more...</span>
                </div>
              </div>
            ) : null}
          </nav>
        ) : (
          <EmptyState listRoute={listRoute} />
        )}
      </aside>
      <article className="h-full min-w-0 flex-grow">
        <GenericErrorBoundary>
          <Suspense fallback={<div>loading...</div>}>
            <Outlet />
          </Suspense>
        </GenericErrorBoundary>
      </article>
    </div>
  );
}

export function BaseSidebarListItem({
  isActive,
  children,
}: {
  isActive: boolean;
  children: ReactNode;
}) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isActive && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [isActive, ref]);

  return (
    <div className="relative px-4 py-5" ref={ref}>
      {children}
    </div>
  );
}

function EmptyState({ listRoute }: { listRoute: any }) {
  const search = useSearch({ from: listRoute.id });

  const searchCount = Object.keys(search).length;

  return (
    <div className="flex h-full flex-col items-center justify-center text-center">
      <div className="w-1/2">
        <IllustrationIdea />
      </div>

      <h3 className="mt-2 text-sm font-medium text-gray-900">
        No results found
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        {searchCount === 0 ? (
          "No results match your search criteria."
        ) : (
          <Link className="flex items-center underline">
            <span>clear {searchCount} filters</span>
          </Link>
        )}
      </p>
    </div>
  );
}

type Checked = DropdownMenuCheckboxItemProps["checked"];

export function DropdownMenuCheckboxes() {
  const [showStatusBar, setShowStatusBar] = React.useState<Checked>(true);
  const [showActivityBar, setShowActivityBar] = React.useState<Checked>(false);
  const [showPanel, setShowPanel] = React.useState<Checked>(false);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <FilterIcon className="h-4 w-4" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Status</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem
          checked={showStatusBar}
          onCheckedChange={setShowStatusBar}
        >
          Awaiting Payment
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={showActivityBar}
          onCheckedChange={setShowActivityBar}
        >
          Awaiting Confirmation
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={showPanel}
          onCheckedChange={setShowPanel}
        >
          Confirmed
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={showPanel}
          onCheckedChange={setShowPanel}
        >
          Admin Cancelled
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={showPanel}
          onCheckedChange={setShowPanel}
        >
          Customer Cancelled
        </DropdownMenuCheckboxItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
