import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type OrganisationRecord = ResultOf<
  typeof organisationRecordQueryGraphql
>["organisation"];
export const organisationRecordQueryGraphql = graphql(`
  query OrganisationRecord($id: ID!) {
    organisation(id: $id) {
      ...OrganisationFields
    }
  }
`);

export const organisationFieldsFragment = graphql(`
  fragment OrganisationFields on Tenant {
    __typename
    id
    name
    is_verified
    clerk_org_id
    salesAgent {
      id
      name
    }

    featureFlags {
      type
      created_at
    }

    contact {
      ...ContactRecordFields
    }
    created_at
    updated_at
  }
`);
