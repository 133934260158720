import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { TripListItem } from "@/app/Cities/Trips/GraphQL/tripList.graphql";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { TripEditDialog } from "@/app/Cities/Trips/Components/TripEditDialog";
import { ImageIcon } from "lucide-react";
import { UploadMediaDialog } from "@/app/Media/Components/UploadMediaDialog";
import { MediaCollection, ModelType } from "@/gql/graphql";

export function useTripActions(): ActionsHook<TripListItem> {
  const { open } = useDialog(TripEditDialog);
  const { open: openUploadMedia } = useDialog(UploadMediaDialog);

  return (trip) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Edit",
                Icon: PencilIcon,
                handleAction: () => {
                  open({
                    trip,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: `Add ${trip.cityA.name} to ${trip.cityB.name} image`,
                Icon: ImageIcon,
                handleAction: () => {
                  openUploadMedia({
                    objectId: trip.id,
                    objectType: ModelType.Trip,
                    collection: MediaCollection.TripABImage,
                  });
                },
              },
              {
                label: `Add ${trip.cityB.name} to ${trip.cityA.name} image`,
                Icon: ImageIcon,
                handleAction: () => {
                  openUploadMedia({
                    objectId: trip.id,
                    objectType: ModelType.Trip,
                    collection: MediaCollection.TripBAImage,
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
