import {
  ActionGroupButton,
  ActionGroupButtons,
} from "../../../Actions/ActionGroupButtons";
import { ReactNode } from "react";
import { Heading } from "@/components/catalyst/heading";
import { CopyToClipboard } from "@/lib/Components/Common/CopyToClipboard";

type RecordScreenTitleCardProps = {
  title: string;
  badges: ReactNode;
  actionGroups: ActionGroupButton[];
};
export function RecordScreenTitleCard({
  badges,
  title,
  actionGroups,
}: RecordScreenTitleCardProps) {
  return (
    <div className="space-y-6 xl:flex xl:space-y-0">
      <div className="flex-grow">
        <Heading>
          <CopyToClipboard text={title} />
        </Heading>
        <p className="mt-1 space-x-2">{badges}</p>
      </div>
      <ActionGroupButtons buttons={actionGroups} />
    </div>
  );
}
