import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { contactRecordQuery } from "@/app/Contacts/GraphQL/contactRecordQuery";
import { createEntityMutation } from "@/app/Contacts/GraphQL/contactMutations";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";

export function ContactEntityCreateDialog({
  contactId,
}: {
  contactId: string;
}) {
  const { data } = useSuspenseGqlQuery(contactRecordQuery, {
    id: contactId,
  });

  const { mutateAsync } = useGqlMutation(createEntityMutation);

  const { close } = useDialogState();
  const { contact } = data;

  return (
    <GenericDialog title={"Create Entity"}>
      <GenericForm<{
        name: string;
      }>
        initialValues={{
          name: contact.name ?? "",
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            contactId,
            input: {
              name: values.name,
            },
          });

          close();
        }}
      >
        <TextInput name="Name" label="Entity Name" />
        <DialogSubmitButton text={"Create Entity"} />
      </GenericForm>
    </GenericDialog>
  );
}
