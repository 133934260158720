import { TimelineFilterOption } from "@/gql/graphql";
import dayjs from "dayjs";
import { toDateTimeString } from "@/lib/Formatters/toDateTimeString";

export const filterOptions: {
  [key in TimelineFilterOption]: string;
} = {
  [TimelineFilterOption.Today]: "Today",
  [TimelineFilterOption.SevenDays]: "Last 7 days",
  [TimelineFilterOption.ThirtyDays]: "Last 30 days",
  [TimelineFilterOption.ThreeSixFiveDays]: "Last 365 days",
  [TimelineFilterOption.Week]: "This week",
  [TimelineFilterOption.Month]: "This month",
  [TimelineFilterOption.Year]: "This year",
};

type DateTimeRange = {
  start: string;
  end: string;
};

export function getDatesFromFilterOption(
  filter: TimelineFilterOption,
): DateTimeRange {
  let end = dayjs().local().endOf("day");
  let start = dayjs().local().startOf("day"); //Add one day, so it's inclusive (calendar days)

  switch (filter) {
    case TimelineFilterOption.Today:
      break;
    case TimelineFilterOption.SevenDays:
      start = start.subtract(6, "day");
      break;
    case TimelineFilterOption.ThirtyDays:
      start = start.subtract(29, "day");
      break;
    case TimelineFilterOption.ThreeSixFiveDays:
      start = start.subtract(364, "day");
      break;
    case TimelineFilterOption.Month:
      start = start.startOf("month");
      end = end.endOf("day");
      break;
    case TimelineFilterOption.Week:
      start = start.startOf("week");
      end = end.endOf("day");
      break;
    case TimelineFilterOption.Year:
      start = start.startOf("year");
      end = end.endOf("day");
      break;
  }

  return {
    start: toDateTimeString(start)!,
    end: toDateTimeString(end)!,
  };
}
