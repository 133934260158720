import { CityInput } from "@/app/Cities/Cities/Components/CityInput";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";

export function PaidDriverDetailsForm() {
  return (
    <>
      <CityInput name="basedIn.connect" label="Based in" />
      <TextAreaInput name="bio" label="Bio" className="col-span-12" />
    </>
  );
}
