import {
  SettingCard,
  SettingFormCard,
} from "@/app/Settings/Settings/Components/SettingFormCard";
import { IntegrationRecord } from "@/app/Settings/Settings/GraphQL/integrationQueries";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateIntegrationMutation } from "@/app/Settings/Settings/GraphQL/integrationMutations";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { RcmVehicleTable } from "@/app/Settings/Integrations/Rcm/Components/RcmVehicleTable";
import { RcmOfficeTable } from "@/app/Settings/Integrations/Rcm/Components/RcmOfficeTable";

export function RcmIntegrationScreen({
  integration,
}: {
  integration: IntegrationRecord;
}) {
  const { mutateAsync } = useGqlMutation(updateIntegrationMutation);
  const metadata = integration?.metadata;

  return (
    <div className="space-y-6">
      <SettingFormCard
        title="Metadata"
        description=""
        initialValues={{
          apiKey: metadata?.rcmApiKey ?? "",
          secretKey: metadata?.rcmSecretKey ?? "",
        }}
        onSubmit={async (values) => {
          return mutateAsync({
            id: integration?.id,
            input: {
              metadata: {
                ...metadata,
                rcmApiKey: values.apiKey,
                rcmSecretKey: values.secretKey,
              },
            },
          });
        }}
      >
        <TextInput name="apiKey" label="Api key" className="col-span-8" />
        <TextInput name="secretKey" label="Secret key" className="col-span-8" />
      </SettingFormCard>

      {metadata?.rcmApiKey && metadata?.rcmSecretKey ? (
        <>
          <SettingCard title="Vehicles" description="">
            <RcmVehicleTable />
          </SettingCard>
          <SettingCard title="Offices" description="">
            <RcmOfficeTable />
          </SettingCard>
        </>
      ) : (
        <WarningBanner>
          Please enter both API and Secret keys to continue
        </WarningBanner>
      )}
    </div>
  );
}
