import { useJsApiLoader } from "@react-google-maps/api";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { ReactNode } from "react";
import { UseLoadScriptOptions } from "@react-google-maps/api/dist/useJsApiLoader";

interface GoogleMaps {
  children: ReactNode;
}

const config: UseLoadScriptOptions = {
  googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY, // ,
  libraries: ["places"],
  // ...otherOptions
};

export function GoogleMapsLoader({ children }: GoogleMaps) {
  const { isLoaded, loadError } = useJsApiLoader(config);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? <>{children}</> : <Spinner />;
}
