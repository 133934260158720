import { RecordScreenFormCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenFormCard";
import { sortOpenHours } from "@/app/Offices/Utils/sortOpenHours";
import { ClosedDatesForm } from "@/app/Suppliers/Components/ClosedDatesForm";
import {
  fromOpenDaysSchema,
  toOpenDaysSchema,
} from "@/app/Suppliers/Utils/mapClosedDays";
import { OpenHoursInput } from "@/app/Offices/Components/OpenHoursInput";
import { OpenHoursViewState } from "@/app/Offices/Components/OpenHoursViewState";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { IllustrationCalendar } from "@/assets/Illustrations";
import { useGqlMutation, useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";
import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";
import { supplierOfficeOpenHoursList } from "@/app/Offices/GraphQL/supplierOfficeOpenHoursList";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { Link } from "@tanstack/react-router";

export function SupplierDefaultOpenHoursScreen() {
  const { mutateAsync } = useGqlMutation(updateSupplierMutation);
  const record = useSupplierRecord();

  const { data } = useGqlQuery(supplierOfficeOpenHoursList, {
    supplierId: record.id,
  });

  const customPickupHours = data?.supplierOffices.data.filter((office) => {
    return office.departure_hours !== null;
  });

  const customDropoffHours = data?.supplierOffices.data.filter((office) => {
    return office.delivery_hours !== null;
  });

  return (
    <div className="space-y-6 py-6">
      <RecordScreenFormCard
        enableReinitialize={true}
        onSubmit={(values) => {
          return mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        initialValues={{
          departure_hours: sortOpenHours(record.departure_hours),
        }}
        title="Departure hours"
        view={
          <>
            <OpenHoursViewState openHours={record.departure_hours} />
            {customPickupHours?.length ? (
              <WarningBanner className="mt-3">
                <p>
                  {customPickupHours.length} offices have custom pickup hours
                </p>
                <ul className="mt-3 list-inside list-disc">
                  {customPickupHours?.map((office) => {
                    return (
                      <li key={office.id}>
                        <Link
                          className="underline"
                          to="/offices/$id/open-hours"
                          params={{
                            id: office.id,
                          }}
                        >
                          {office.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </WarningBanner>
            ) : null}
          </>
        }
      >
        <OpenHoursInput prefix="departure_hours" />
      </RecordScreenFormCard>

      <RecordScreenFormCard
        enableReinitialize={true}
        onSubmit={(values) => {
          return mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        initialValues={{
          delivery_hours: sortOpenHours(record.delivery_hours),
        }}
        title="Delivery hours"
        view={
          <>
            <OpenHoursViewState openHours={record.delivery_hours} />
            {customDropoffHours?.length ? (
              <WarningBanner className="mt-3">
                <p>
                  {customDropoffHours.length} offices have custom pickup hours
                </p>
                <ul className="mt-3 list-inside list-disc">
                  {customDropoffHours?.map((office) => {
                    return (
                      <li key={office.id}>
                        <Link
                          className="underline"
                          to="/offices/$id/open-hours"
                          params={{
                            id: office.id,
                          }}
                        >
                          {office.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </WarningBanner>
            ) : null}
          </>
        }
      >
        <OpenHoursInput prefix="delivery_hours" />
      </RecordScreenFormCard>

      <RecordScreenFormCard
        onSubmit={async (values) => {
          const foo = toOpenDaysSchema(values.closed_dates);
          await mutateAsync({
            id: record.id,
            input: {
              closed_dates: foo,
            },
          });
        }}
        title="Closed days"
        initialValues={{
          closed_dates: fromOpenDaysSchema(record.closed_dates),
        }}
      >
        <ClosedDatesForm
          emptyState={
            <EmptyState
              Icon={IllustrationCalendar}
              header="No closed dates"
              subheader="Add date(s) you want to close all offices on"
            />
          }
        />
      </RecordScreenFormCard>
    </div>
  );
}
