import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { MarketingEventTriggerInput } from "@/app/Marketing/MarketingEvents/Components/MarketingEventTriggerInput";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { MailchimpCustomerJourneySelectInput } from "@/app/Marketing/MarketingEvents/Components/MailchimpCustomerJourneySelectInput";
import { MailchimpCustomerJourneyStepsSelectInput } from "@/app/Marketing/MarketingEvents/Components/MailchimpCustomerJourneyStepsSelectInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { CreateMarketingEventInput } from "@/gql/graphql";
import { GenericFieldArray } from "@/lib/Components/Form/FieldArray/GenericFieldArray";
import { MarketingEventConditionFieldSelectInput } from "@/app/Marketing/MarketingEvents/Components/MarketingEventConditionFieldSelectInput";
import { MarketingEventConditionOperatorSelectInput } from "@/app/Marketing/MarketingEvents/Components/MarketingEventConditionOperatorSelectInput";
import { AlarmCheckIcon, ListFilterIcon, StepForwardIcon } from "lucide-react";
import { CityInput } from "@/app/Cities/Cities/Components/CityInput";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";

export function MarketingEventForm() {
  const { values } = useForm<CreateMarketingEventInput>();

  return (
    <>
      <TextInput name={"name"} label={"Name"} />

      <FormDivider />

      <div className="col-span-full pb-6">
        <h2 className="flex items-center space-x-2 text-xl text-gray-500">
          <AlarmCheckIcon className="h-5 w-5" />
          <span>When </span>
        </h2>
      </div>

      <MarketingEventTriggerInput name="trigger" label="Event" />
      <NumberInput name="days_after_trigger" label="Days after event" />

      <GenericFieldArray
        Icon={ListFilterIcon}
        lineItemLabel="condition"
        fieldArrayKey="conditions.upsert"
        label="Conditions"
        newLineItemValues={{
          field: null,
          operator: null,
          value: null,
        }}
      >
        {(item, index) => {
          return (
            <>
              <MarketingEventConditionFieldSelectInput
                label="Field"
                name={`conditions.upsert.${index}.field`}
              />
              <MarketingEventConditionOperatorSelectInput
                label="Operator"
                name={`conditions.upsert.${index}.operator`}
              />

              <CityInput
                name={`conditions.upsert.${index}.value`}
                label="Value"
              />
            </>
          );
        }}
      </GenericFieldArray>

      <div className="col-span-full py-6">
        <h2 className="flex items-center space-x-2 text-xl text-gray-500">
          <StepForwardIcon className="h-5 w-5" />
          <span>Then </span>
        </h2>
      </div>

      <MailchimpCustomerJourneySelectInput
        name={"mailchimp_customer_journey_id"}
        label={"Customer journey"}
      />
      {values.mailchimp_customer_journey_id ? (
        <MailchimpCustomerJourneyStepsSelectInput
          name={"mailchimp_customer_journey_step_id"}
          label={"Customer journey step"}
          customerJourneyId={values.mailchimp_customer_journey_id}
        />
      ) : null}
    </>
  );
}
