export function formatStatistic(value: number | undefined | null): string {
  if (value === null || value === undefined) {
    return "--";
  }

  return Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
  }).format(value);
}

export function formatMinutes(value: number) {
  //split the minutes and hours
  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  if (hours === 0) {
    return `${hours}h ${minutes}m`;
  }

  return `${minutes}m`;
}
