import { QueryAllUsersOrderByColumn, SortOrder } from "@/gql/graphql";
import { UserListSearchParams } from "../Routes/userRoutes";
import { AllUserListVariables } from "@/app/Users/GraphQL/allUserListQuery";

export function getUserListQueryVariables(
  searchParams: UserListSearchParams,
): AllUserListVariables {
  const baseParams = {
    page: 1,
    first: 20,
    search: searchParams.userSearch,
  };

  if (searchParams.userSearch) {
    return baseParams;
  }
  return {
    ...baseParams,
    orderBy: [
      {
        order: SortOrder.Desc,
        column: QueryAllUsersOrderByColumn.Id,
      },
    ],
  };
}
