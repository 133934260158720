import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { graphql } from "@/gql";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";

const mailchimpCustomerJourneyStepsQuery = graphql(`
  query MailchimpCustomerJourneyStepsList($id: ID!) {
    mailchimpCustomerJourneySteps(id: $id) {
      id
      display_text
      step_type
      trigger_type
    }
  }
`);

export function MailchimpCustomerJourneyStepsSelectInput({
  customerJourneyId,
  ...props
}: Omit<SelectInputProps, "options"> & { customerJourneyId: string }) {
  const { data } = useGqlQuery(
    mailchimpCustomerJourneyStepsQuery,
    {
      id: customerJourneyId,
    },
    {
      enabled: !!customerJourneyId,
    },
  );

  const options = data?.mailchimpCustomerJourneySteps
    .filter((step) => {
      //Filter the steps to only show the API trigger steps
      return step.step_type === "trigger" && step.trigger_type === "api";
    })
    .map((step) => ({
      label: step.display_text,
      value: step.id,
    }));

  return (
    <SelectInput
      options={[
        {
          label: "Customer journey step",
          options: options || [],
        },
      ]}
      {...props}
    />
  );
}
