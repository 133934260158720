import { FormContext } from "@/lib/Components/Form/Providers/FormContext";
import { useStore } from "zustand";
import { useContext } from "react";
import { FormationContextType } from "@/lib/Components/Form/Providers/Formation";

export function useFormStore(selector: (state: FormationContextType) => any) {
  const store = useContext(FormContext);

  if (!store) {
    throw new Error("Missing FormationProvider");
  }
  return useStore(store, selector);
}
