import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";
import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import { StripePaymentMethodCard } from "@/app/Stripe/StripePaymentMethods/Components/StripePaymentMethodCard";
import { RecordScreenFormCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenFormCard";
import { UpdateSupplierInput } from "@/gql/graphql";
import { SupplierBillingForm } from "@/app/Suppliers/Forms/SupplierBillingForm";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";

export function SupplierBillingScreen() {
  const supplier = useSupplierRecord();
  const { isAdmin } = useTenant();
  const { mutateAsync } = useGqlMutation(updateSupplierMutation);

  return (
    <div className="py-6">
      {isAdmin ? (
        <RecordScreenFormCard<UpdateSupplierInput>
          initialValues={{
            booking_fee_amount: supplier.booking_fee_amount,
            charge_per_move_amount: supplier.charge_per_move_amount,
            is_holding_deposit_required: supplier.is_holding_deposit_required,
            holding_deposit_refunded_at: supplier.holding_deposit_refunded_at,
            holding_deposit_refunded_by: supplier.holding_deposit_refunded_by,
            holding_deposit_amount: supplier.holding_deposit_amount,
            is_tax_included: supplier.is_tax_included,
            tax_type: supplier.tax_type,
            billing_currency: supplier.billing_currency,
            confirm_delivery_before_billing:
              supplier.confirm_delivery_before_billing,
            billingEntity: {
              connect: supplier.billingEntity?.id ?? null!,
            },
          }}
          onSubmit={async (values) => {
            await mutateAsync({
              id: supplier.id,
              input: values,
            });
          }}
          title="Billing"
        >
          <SupplierBillingForm supplier={supplier} />
        </RecordScreenFormCard>
      ) : null}

      {supplier.billingEntity ? (
        <RecordScreenCard title="Payment methods">
          <StripePaymentMethodCard contact={supplier.billingEntity} />
        </RecordScreenCard>
      ) : null}
    </div>
  );
}
