import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import {
  ContactType,
  CreateInvoiceInput,
  Currency,
  ModelType,
} from "@/gql/graphql";
import { CurrencyTypeInput } from "@/app/Common/Components/CurrencyTypeInput";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { ContactInput } from "@/app/Contacts/Components/ContactInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { ContactListItem } from "@/app/Contacts/GraphQL/contactListQuery";
import { InvoiceRecord } from "@/app/Invoices/GraphQL/invoiceRecordQuery";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";

type InvoiceDetailsFormProps = {
  setCurrency?: (currency: Currency) => void;
  setPayee?: (payee: ContactListItem | null) => void;
  invoice?: InvoiceRecord;
};
export function InvoiceDetailsForm({
  setCurrency,
  setPayee,
  invoice,
}: InvoiceDetailsFormProps) {
  const { setFieldValue, touched } = useForm<CreateInvoiceInput>();

  return (
    <>
      <ContactInput
        name="payee.connect"
        label="Payee"
        onChange={(payee) => {
          setPayee?.(payee);

          // Set the PO number if the payee has a PO number
          if (payee?.purchase_order_number && !touched.purchase_order_number) {
            setFieldValue(
              "purchase_order_number",
              payee?.purchase_order_number,
            );
          }
        }}
        getQueryVariables={(search) => ({
          search,
          first: 20,
          page: 1,
          managedById: invoice?.managedBy?.id,
          type: [ContactType.Entity, ContactType.Organisation],
        })}
        initialFixtureValues={
          invoice?.managedBy
            ? {
                name: "",
                type: ContactType.Entity,
                object: {
                  connect: {
                    type: ModelType.Contact,
                    id: invoice.managedBy.contact.id,
                  },
                },
              }
            : undefined
        }
      />
      {!invoice ? (
        <CurrencyTypeInput
          name="currency"
          label="Currency"
          onChange={setCurrency as any}
          isEditable={!invoice}
        />
      ) : null}
      <TextInput
        name={"purchase_order_number"}
        label="Purchase order number"
        optionalLabel
      />

      {invoice ? (
        <>
          <FormDivider />

          <DateInput
            name={"invoice_date"}
            label="Invoice date"
            tooltip="The invoice date is set when the invoice is issued"
          />
          <DateInput
            name={"due_date"}
            label="Due date"
            tooltip="The due date is set when the invoice is issued"
          />
        </>
      ) : null}
    </>
  );
}
