import { PaymentForm } from "@/app/Payments/Forms/PaymentForm";
import { RecordScreenFormCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenFormCard";
import { ModelType, UpdatePaymentInput } from "@/gql/graphql";
import { DescriptionList } from "@/lib/Components/DescriptionList/DescriptionListCard";
import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import { Link } from "@tanstack/react-router";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { ActivityLogFeed } from "@/app/ActivityLogs/Components/ActivityLogFeed";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updatePaymentMutation } from "@/app/Payments/GraphQL/paymentMutations";
import { usePaymentRecord } from "@/app/Payments/Hooks/usePaymentRecord";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { PaymentRecord } from "@/app/Payments/GraphQL/paymentRecordQuery";

export function PaymentDetailScreen() {
  const record = usePaymentRecord();
  const { mutateAsync } = useGqlMutation(updatePaymentMutation);
  const { isAdmin } = useTenant();

  return (
    <div className="space-y-12 py-6">
      <RecordScreenCard title="Activity">
        <ActivityLogFeed modelId={record.id} modelType={ModelType.Payment} />
      </RecordScreenCard>
      <RecordScreenCard title="">
        <DescriptionList
          items={[
            {
              key: "Attributed to",
              value: record.attributedTo ? (
                <Link
                  to="/bookings/$bookingId"
                  params={{
                    bookingId: record.attributedTo?.id,
                  }}
                  search={{}}
                  className="text-blue-500 underline"
                >
                  {record.attributedTo?.reference ?? "--"}
                </Link>
              ) : (
                "--"
              ),
            },
            {
              key: "Fulfilled by",
              value: <FulfilledByLink payment={record} />,
            },
            {
              key: "Amount (inc tax)",
              value: formatCurrency(record.amount_inc_tax, record.currency),
            },
            {
              key: "Amount tax",
              value: formatCurrency(record.amount_tax, record.currency),
            },
            {
              key: "Amount paid",
              value: formatCurrency(record.amount_paid, record.currency),
            },
            {
              key: "Amount refunded",
              value: formatCurrency(record.amount_refunded, record.currency),
            },
            {
              key: "Amount fee",
              value: formatCurrency(record.amount_fee, record.currency),
            },
          ]}
        />
      </RecordScreenCard>
      <RecordScreenFormCard<UpdatePaymentInput>
        title="Details"
        isEditable={isAdmin}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        initialValues={{
          amount: record.amount,
          currency: record.currency,
          is_tax_included: record.is_tax_included,
          tax_type: record.tax_type,
          description: record.description,
          due_date: record.due_date,
          payee: record.payee
            ? {
                connect: record.payee.id,
              }
            : null,
        }}
      >
        <PaymentForm payment={record} />
      </RecordScreenFormCard>
    </div>
  );
}

function FulfilledByLink({ payment }: { payment: PaymentRecord }) {
  if (!payment.fulfilledBy) return "--";

  if (payment.fulfilledBy.__typename === "StripePayment") {
    return (
      <a
        href={`https://dashboard.stripe.com/payments/${payment.fulfilledBy.payment_intent_id}`}
      >
        {payment.fulfilledBy.payment_intent_id}
      </a>
    );
  }

  if (payment.fulfilledBy.__typename === `PayableLineItem`) {
    return (
      <Link
        to={"/invoices/$id"}
        params={{
          id: payment.fulfilledBy.object_id,
        }}
        search={{}}
        className="text-blue-500 underline"
      >
        Invoice #{payment.fulfilledBy.object_id ?? "--"}
      </Link>
    );
  }
}
