import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { useTranslations } from "use-intl";
import { InvoiceStatus } from "@/gql/graphql";

export function InvoiceStatusInput(props: Omit<SelectInputProps, "options">) {
  const t = useTranslations("invoice");
  const options = Object.values(InvoiceStatus).map((status) => {
    return {
      value: status,
      label: t(`status.${status}`),
    };
  });

  return (
    <SelectInput
      options={[
        {
          label: "Status",
          options,
        },
      ]}
      {...props}
    />
  );
}
