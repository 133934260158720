import { ChangeRequestTable } from "@/app/Bookings/Components/ChangeRequestTable";
import {
  BookingChangeRequestStatus,
  QueryBookingChangeRequestsWhereColumn,
} from "@/gql/graphql";

export function DashboardPendingChangeRequests() {
  return (
    <ChangeRequestTable
      id={"dashboard-pending-change-requests"}
      queryVariables={{
        where: {
          column: QueryBookingChangeRequestsWhereColumn.Status,
          value: BookingChangeRequestStatus.Pending,
        },
      }}
    />
  );
}
