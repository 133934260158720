import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { UpdateSupplierOfficeInput } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { SupplierOfficeForm } from "@/app/Offices/Forms/SupplierOfficeForm";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierOfficeMutation } from "@/app/Offices/GraphQL/supplierOfficeMutations";
import { supplierOfficeRecordQuery } from "@/app/Offices/GraphQL/supplierOfficeRecordQuery";

export function OfficeUpdateDialog({ officeId }: { officeId: string }) {
  const { mutateAsync } = useGqlMutation(updateSupplierOfficeMutation);
  const { close } = useDialogState();

  const { data } = useSuspenseGqlQuery(supplierOfficeRecordQuery, {
    id: officeId,
  });

  const office = data.supplierOffice;

  return (
    <GenericDialog title="Update office">
      <GenericForm<UpdateSupplierOfficeInput>
        onSubmit={async (values) => {
          await mutateAsync({
            id: office.id,
            input: values,
          });
          close();
        }}
        initialValues={{
          supplier: {
            connect: office.supplier.id,
          },
          name: office.name,
          code: office.code,
          phone: office.phone,
          email: office.email,
          address: {
            upsert: {
              id: office.address.id,
              name: office.address.name,
              address_line_1: office.address.address_line_1,
              address_line_2: office.address.address_line_2,
              city: office.address.city,
              postcode: office.address.postcode,
              lng: office.address.lng,
              lat: office.address.lat,
              place_id: office.address.place_id,
              country: office.address.country,
              state: office.address.state,
            },
          },
          city: {
            connect: office.city.id,
          },
          pickup_instructions: office.pickup_instructions,
          delivery_hours: office.delivery_hours,
          departure_hours: office.departure_hours,
        }}
      >
        <SupplierOfficeForm />
        <DialogSubmitButton text="Update office" />
      </GenericForm>
    </GenericDialog>
  );
}
