import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { RelocationHighlightUpdateDialog } from "@/app/Tools/RelocationHighlights/Components/RelocationHighlightUpdateDialog";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { deleteRelocationHighlightMutation } from "@/app/Tools/RelocationHighlights/GraphQL/relocationHighlightMutations";
import { RelocationHighlightListItem } from "@/app/Tools/RelocationHighlights/GraphQL/relocationHighlightListQuery";
import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";

export function useRelocationHighlightActions(): ActionsHook<RelocationHighlightListItem> {
  const { mutateAsync } = useGqlMutation(deleteRelocationHighlightMutation);
  const { open } = useDialog(RelocationHighlightUpdateDialog);

  return (model) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Edit",
                Icon: PencilIcon,
                handleAction: () => {
                  open({ relocationHighlight: model });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Delete",
                Icon: TrashIcon,
                intent: "danger",
                handleAction: async () => {
                  await mutateAsync({ id: model.id });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
