import { useQuery } from "@tanstack/react-query";
import { useIntegrationRecord } from "@/app/Settings/Settings/Hooks/useIntegrationRecord";
import { z } from "zod";

async function getVehicleCodes(apiKey: string, apiUrl: string) {
  const res = await fetch(
    new URL(`/api/v3/fleet-categories?accountId=${apiKey}`, apiUrl),
    {
      method: "GET",
      headers: {
        accept: "application/json",
      },
    },
  );
  const json = await res.json();

  return vehicleValidator.parse(json);
}

async function getOfficeCodes(apiKey: string, apiUrl: string) {
  console.log({
    apiKey,
    apiUrl,
  });

  const res = await fetch(
    new URL(`/api/v3/sites?accountId=${apiKey}`, apiUrl),
    {
      method: "GET",
      headers: {
        accept: "application/json",
      },
    },
  );
  const json = await res.json();

  return officeValidator.parse(json);
}

export function useJucyVehicleCodesList() {
  const integration = useIntegrationRecord();
  const metaData = validator.parse(integration.metadata);
  const apiKey = metaData["jucyApiKey"];
  const apiUrl = metaData["apiUrl"];
  return useQuery({
    queryKey: ["jucyVehicleCodes", apiKey, apiUrl],
    queryFn: () => {
      return getVehicleCodes(apiKey, apiUrl);
    },
  });
}

export function useJucyOfficeCodesList() {
  const integration = useIntegrationRecord();
  const metaData = validator.parse(integration.metadata);
  const apiKey = metaData["jucyApiKey"];
  const apiUrl = metaData["apiUrl"];
  return useQuery({
    queryKey: ["jucyOfficeCodes", apiKey, apiUrl],
    queryFn: () => {
      return getOfficeCodes(apiKey, apiUrl);
    },
  });
}

const validator = z
  .object({ jucyApiKey: z.string(), apiUrl: z.string() })
  .catch(() => ({
    apiUrl: "",
    jucyApiKey: "",
  }));

const vehicleValidator = z.array(z.object({ code: z.string() }));
const officeValidator = z.array(z.object({ siteCode: z.string() }));
