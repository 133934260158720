import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { vehicleRecordQuery } from "@/app/Vehicles/GraphQL/vehicleRecordQuery";
import { SortableVehicleImages } from "@/app/Vehicles/Components/SortableVehicleImages";

export function VehicleImageDialog({ vehicleId }: { vehicleId: string }) {
  const { data } = useSuspenseGqlQuery(vehicleRecordQuery, {
    id: vehicleId,
  });

  return (
    <GenericDialog title="Images">
      <SortableVehicleImages record={data.vehicle} />
    </GenericDialog>
  );
}
