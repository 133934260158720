import { integrationLinksList } from "@/app/Settings/Settings/GraphQL/integrationLinksQueries";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { CellContext } from "@tanstack/react-table";
import { useIntegrationRecord } from "@/app/Settings/Settings/Hooks/useIntegrationRecord";
import { ModelType } from "@/gql/graphql";
import { SupplierOfficeListItem } from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { RcmCodeComboBox } from "@/app/Settings/Integrations/Rcm/Components/RcmCodeComboBox";
import { useRcmOfficeCodesList } from "@/app/Settings/Integrations/Rcm/Hooks/useRcmCodes";

export function RcmOfficeCodeCell({
  row,
}: CellContext<SupplierOfficeListItem, any>) {
  const integration = useIntegrationRecord();

  const integrationLinks = useGqlQuery(integrationLinksList, {
    integration_id: integration.id,
  });

  const integrationLink = integrationLinks.data?.integrationLinks.data.find(
    (item) => {
      return (
        item.object_type === ModelType.SupplierOffice &&
        item.object_id === row.original.id
      );
    },
  );

  const { data, isLoading } = useRcmOfficeCodesList();

  const externalIds = data
    ? Array.from(
        new Set(
          data.results.map((office) => {
            return {
              label: office.location,
              value: office.id.toString(),
            };
          }),
        ),
      )
    : [];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <RcmCodeComboBox
      rcmIntegration={integration}
      externalIds={externalIds}
      rowItem={row.original}
      integrationLink={integrationLink}
      type={ModelType.SupplierOffice}
    />
  );
}
