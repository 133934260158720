import { BookingStatus } from "@/gql/graphql";

export const bookingMessages = {
  status: {
    [BookingStatus.AwaitingConfirmation]: "pending",
    [BookingStatus.AwaitingPayment]: "incomplete",
    [BookingStatus.Accepted]: "accepted",
    [BookingStatus.Completed]: "completed",
    [BookingStatus.Confirmed]: "confirmed",
    [BookingStatus.Vip]: "vip",
    [BookingStatus.AdminCancelled]: "admin cancelled",
    [BookingStatus.CustomerCancelled]: "customer cancelled",
    [BookingStatus.CustomerCancelledConfirmationTimeliness]:
      "customer cancelled (timeliness)",
    [BookingStatus.CustomerCancelledNoShow]: "customer cancelled (no show)",
    [BookingStatus.CustomerCancelledRebooked]: "customer cancelled (rebooked)",
    [BookingStatus.SupplierCancelled]: "supplier cancelled",
    [BookingStatus.SupplierCancelledMechanicalFailure]:
      "supplier cancelled (mechanical failure)",
    [BookingStatus.SupplierCancelledNotAvailable]:
      "supplier cancelled (not available)",
    [BookingStatus.SupplierCancelledNoFerry]: "supplier cancelled (no ferry)",
  } satisfies {
    [key in BookingStatus]: string;
  },

  statusShortLabel: {
    [BookingStatus.AwaitingConfirmation]: "pending",
    [BookingStatus.AwaitingPayment]: "incomplete",
    [BookingStatus.Completed]: "completed",
    [BookingStatus.Confirmed]: "confirmed",
    [BookingStatus.Accepted]: "accepted",
    [BookingStatus.Vip]: "vip",
    [BookingStatus.AdminCancelled]: "cancelled",
    [BookingStatus.CustomerCancelled]: "cancelled",
    [BookingStatus.CustomerCancelledConfirmationTimeliness]: "cancelled",
    [BookingStatus.CustomerCancelledNoShow]: "cancelled",
    [BookingStatus.CustomerCancelledRebooked]: "cancelled",
    [BookingStatus.SupplierCancelled]: "cancelled",
    [BookingStatus.SupplierCancelledMechanicalFailure]: "cancelled",
    [BookingStatus.SupplierCancelledNotAvailable]: "cancelled",
    [BookingStatus.SupplierCancelledNoFerry]: "cancelled",
  } satisfies {
    [key in BookingStatus]: string;
  },

  statusInputLabel: {
    [BookingStatus.AwaitingConfirmation]: "pending",
    [BookingStatus.AwaitingPayment]: "incomplete",
    [BookingStatus.Accepted]: "accepted",
    [BookingStatus.Completed]: "completed",
    [BookingStatus.Confirmed]: "confirmed",
    [BookingStatus.Vip]: "vip",
    [BookingStatus.AdminCancelled]: "Other",
    [BookingStatus.CustomerCancelled]: "Other",
    [BookingStatus.CustomerCancelledConfirmationTimeliness]:
      "Confirmation timeliness",
    [BookingStatus.CustomerCancelledNoShow]: "No show",
    [BookingStatus.CustomerCancelledRebooked]: "Rebooked",
    [BookingStatus.SupplierCancelled]: "Other",
    [BookingStatus.SupplierCancelledMechanicalFailure]: "Mechanical failure",
    [BookingStatus.SupplierCancelledNotAvailable]: "Not available",
    [BookingStatus.SupplierCancelledNoFerry]: "No ferry",
  } satisfies {
    [key in BookingStatus]: string;
  },
};
