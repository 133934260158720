import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { useGqlMutation, useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { graphql } from "@/gql";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { MarketingEventForm } from "@/app/Marketing/MarketingEvents/Forms/MarketingEventForm";
import { ResultOf } from "@graphql-typed-document-node/core";
import { UpdateMarketingEventInput } from "@/gql/graphql";
import { updateMarketingEventMutation } from "@/app/Marketing/MarketingEvents/GraphQL/updateMarketingEventMutation";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { createMarketingEventValidation } from "@/app/Marketing/MarketingEvents/Validation/createMarketingEventValidation";

const marketingEventRecordQuery = graphql(`
  query MarketingEventRecord($id: ID!) {
    marketingEvent(id: $id) {
      id
      name
      description
      mailchimp_customer_journey_step_id
      mailchimp_customer_journey_id
      trigger
      days_after_trigger
      conditions {
        id
        field
        operator
        value
      }
    }
  }
`);

type MarketingEventRecord = ResultOf<typeof marketingEventRecordQuery>;

export function EditMarketingEventDialog({ id }: { id: string }) {
  const { data, isLoading } = useGqlQuery(marketingEventRecordQuery, { id });
  return (
    <GenericDialog title={`Edit event`} isLoading={isLoading}>
      {data ? <EditForm data={data} /> : null}
    </GenericDialog>
  );
}

function EditForm({ data }: { data: MarketingEventRecord }) {
  const { mutateAsync } = useGqlMutation(updateMarketingEventMutation);
  const { close } = useDialogState();
  return (
    <GenericForm<UpdateMarketingEventInput>
      validationSchema={toFormikValidationSchema(
        createMarketingEventValidation,
      )}
      initialValues={{
        name: data.marketingEvent.name,
        days_after_trigger: data.marketingEvent.days_after_trigger,
        description: data.marketingEvent.description ?? "",
        mailchimp_customer_journey_id:
          data.marketingEvent.mailchimp_customer_journey_id,
        mailchimp_customer_journey_step_id:
          data.marketingEvent.mailchimp_customer_journey_step_id,
        trigger: data.marketingEvent.trigger,
        conditions: {
          upsert: data.marketingEvent.conditions.map((c) => ({
            id: c.id,
            field: c.field,
            operator: c.operator,
            value: c.value,
          })),
          delete: [],
        },
      }}
      onSubmit={async (values) => {
        await mutateAsync({
          id: data.marketingEvent.id,
          input: values,
        });

        close();
      }}
    >
      <MarketingEventForm />

      <DialogSubmitButton text="Save" />
    </GenericForm>
  );
}
