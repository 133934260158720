import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { DateTimeInput } from "@/lib/Components/Form/Inputs/DateTimeInput";
import { RichTextInput } from "@/lib/Components/Form/Inputs/RichTextInput";

export function BookingUpdateDetailsForm() {
  return (
    <>
      <TextInput
        name={"supplier_reference"}
        label={"Supplier reference"}
        className="col-span-3"
      />
      <FormDivider />
      <DateTimeInput name={"depart_at"} label="Pickup at" />
      <DateTimeInput name={"deliver_at"} label="Drop off at" />
      <FormDivider />
      <RichTextInput
        className="col-span-full"
        name="customer_note"
        label="Customer note"
        tooltip="Notes to be displayed on the booking PDF"
      />
    </>
  );
}
