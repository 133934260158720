import { useBookingRecord } from "@/app/Bookings/Hooks/useBookingRecord";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { Avatar } from "@/components/catalyst/avatar";
import { getInitials } from "@/lib/Utils/getInitials";
import { StarIcon } from "@heroicons/react/20/solid";
import { cn } from "@/lib/utils";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { BookingEditCustomerDetailsDialog } from "@/app/Bookings/Components/Dialogs/BookingEditCustomerDetailsDialog";
import { Button } from "@/components/ui/button";
import { Pencil } from "lucide-react";
import dayjs from "dayjs";
import { CopyToClipboard } from "@/lib/Components/Common/CopyToClipboard";

export function BookingCustomerCard() {
  const booking = useBookingRecord();
  const { open } = useDialog(BookingEditCustomerDetailsDialog);

  return (
    <Card className="">
      <CardHeader className="relative flex h-10 translate-y-2 justify-center">
        <Avatar
          className="mx-auto size-24 border border-gray-300 bg-white shadow-md"
          media={booking.user?.contact?.profilePicture}
          initials={getInitials(booking.name ?? "")}
        />
        <div className="absolute right-3 top-0">
          <Button
            size="icon"
            variant="outline"
            className="h-8 w-8"
            onClick={() => {
              open({
                bookingId: booking.id,
              });
            }}
          >
            <Pencil className="h-3.5 w-3.5" />
          </Button>
        </div>
      </CardHeader>

      <CardContent className="p-6 text-sm">
        <div className="grid gap-3">
          <div className="font-semibold">Customer Information</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Customer</dt>
              <dd>
                <CopyToClipboard text={booking.name} />
              </dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Email</dt>
              <dd>
                <CopyToClipboard text={booking.email} />
              </dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Phone</dt>
              <dd>
                <CopyToClipboard text={booking.phone} />
              </dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Date of birth</dt>
              <dd>
                <CopyToClipboard text={booking.date_of_birth}>
                  {dayjs(booking.date_of_birth).format("LL")}
                </CopyToClipboard>
              </dd>
            </div>
            {booking.address ? (
              <div className="flex items-center justify-between">
                <dt className="text-muted-foreground">Address</dt>
                <dd>
                  <CopyToClipboard text={booking.address} />
                </dd>
              </div>
            ) : null}
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Number of travellers</dt>
              <dd>{booking.number_of_travellers}</dd>
            </div>
          </dl>
        </div>
      </CardContent>
      <CardFooter className="bg-muted/50 flex flex-row items-center border-t px-6 py-3">
        <div className="text-muted-foreground relative flex w-full items-center">
          <span className="text-sm text-gray-700">Rating: 3.9</span>
          <div className="ml-1 flex items-center">
            {[0, 1, 2, 3, 4].map((rating) => (
              <StarIcon
                key={rating}
                aria-hidden="true"
                className={cn(
                  4 > rating ? "text-yellow-400" : "text-gray-200",
                  "h-5 w-5 flex-shrink-0",
                )}
              />
            ))}
          </div>

          <div className="absolute inset-0 flex items-center justify-center backdrop-blur-[1px]">
            <span>🔒 coming soon</span>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}
