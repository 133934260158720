import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type CityListItem = ResultOf<
  typeof cityListQuery
>["cities"]["data"][number];
export const cityListQuery = graphql(`
  query CityList(
    $first: Int!
    $page: Int
    $search: String
    $region: Region
    $orderBy: [QueryCitiesOrderByOrderByClause!]
  ) {
    cities(
      first: $first
      search: $search
      page: $page
      region: $region
      orderBy: $orderBy
    ) {
      data {
        id
        name
        region
        state
        created_at
        updated_at
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
