import { localStorageKeys } from "@/lib/utils";
import { GlobalSupplierStorageObject } from "@/app/Suppliers/Components/GlobalSupplierProvider";

export function getGlobalSupplierId() {
  const json = localStorage.getItem(localStorageKeys.GLOBAL_SUPPLIER);
  if (json === null) return null;

  const obj = JSON.parse(json) as GlobalSupplierStorageObject;

  return obj.type === "single" ? obj.id : null;
}

export function resetGlobalSupplierId() {
  localStorage.removeItem(localStorageKeys.GLOBAL_SUPPLIER);
}
