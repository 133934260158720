import { graphql } from "@/gql";

export const supplierInsuranceOptionRecord = graphql(`
  query SupplierInsuranceOptionRecordQuery($id: ID!) {
    supplierInsuranceOption(id: $id) {
      id
      name
      is_default
      bond_amount
      standard_liability_amount
      liability_reduction_options
      supplier {
        id
        currency
      }
      created_at
      updated_at
    }
  }
`);

export const supplierInsuranceOptionRecordFields = graphql(`
  fragment SupplierInsuranceOptionRecordFields on SupplierInsuranceOption {
    id
    name
    is_default
    bond_amount
    standard_liability_amount
    liability_reduction_options
    created_at
    updated_at
  }
`);
