import { formatDate } from "@/lib/Formatters/formatDate";
import {
  BaseComboboxInputProps,
  ComboboxInput,
} from "@/lib/Components/Form/Inputs/ComboboxInput";
import {
  RelocationListItem,
  relocationListQuery,
} from "@/app/Relocations/GraphQL/relocationListQuery";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { relocationRecordQuery } from "@/app/Relocations/GraphQL/relocationRecordQuery";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";

export function RelocationInput({
  ...props
}: BaseComboboxInputProps<
  ResultOf<typeof relocationListQuery>,
  VariablesOf<typeof relocationListQuery>,
  RelocationListItem,
  ResultOf<typeof relocationRecordQuery>,
  VariablesOf<typeof relocationRecordQuery>
>) {
  const { supplierId } = useGlobalSupplier();

  return (
    <ComboboxInput
      document={relocationListQuery}
      accessor={(data) => data.relocations.data}
      getQueryVariables={(search) => ({
        first: 20,
        page: 1,
        search,
        supplier_id: supplierId ?? undefined,
      })}
      recordDocument={relocationRecordQuery}
      recordAccessor={(data) => data.relocation}
      getKey={(item) => item.id}
      getViewNode={(i) =>
        i ? `${i.departureCity.name} ${i.deliveryCity.name} (${i.id})` : "--"
      }
      getListItemNode={(i) => <RelocationNode model={i} />}
      getInputNode={(i) => {
        return `${i.departureCity.name} ${i.deliveryCity.name} (${i.id})`;
      }}
      {...props}
    />
  );
}

function RelocationNode({ model }: { model: RelocationListItem }) {
  return (
    <div className="">
      <p>
        {model.departureCity.name} {model.deliveryCity.name} ({model.id})
      </p>
      <p className="text-xs text-gray-500">{model.supplier.name}</p>
      <p className="text-xs text-gray-500">{model.vehicle.name}</p>
      <p className="text-xs text-gray-500">
        {formatDate(model.available_from_date)} -{" "}
        {formatDate(model.available_to_date)}
      </p>
    </div>
  );
}
