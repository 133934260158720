import {
  Dialog as HeadlessUiDialog,
  DialogPanel,
  Transition,
} from "@headlessui/react";
import { Fragment, ReactNode, useEffect } from "react";
import { useDialogState } from "./Hooks/useDialogState";
import classNames from "classnames";
import { IconButton } from "@/lib/Components/Button/IconButton";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { DialogProvider } from "@/lib/Components/Dialog/Providers/DialogProvider";
import { cn } from "@/lib/utils";

export interface GenericDialogProps {
  buttons?: ReactNode;
  children: ReactNode;
  isLoading?: boolean;
  title: ReactNode;
  subtitle?: ReactNode;
  /** @deprecated **/
  padding?: string;
  onClose?: () => void;
  hideCloseButton?: boolean;
  initialFocus?: any;
  className?: string;
}

export function GenericDialog({
  children,
  title,
  subtitle,
  padding = "p-6",
  onClose,
  hideCloseButton = false,
  buttons,
  initialFocus,
  className,
}: GenericDialogProps) {
  const { reject, close, isOpen, depth, remove } = useDialogState();

  useEffect(() => {
    if (!isOpen) {
      onClose?.();
    }
  }, [isOpen]);

  return (
    <Transition
      appear
      show={isOpen}
      as={Fragment}
      afterLeave={() => {
        remove();
      }}
    >
      <HeadlessUiDialog
        onClose={() => {
          return;
        }}
        as="div"
        className="relative z-10"
        initialFocus={initialFocus}
      >
        <DialogProvider depth={depth + 1}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="scroll-gutter-stable fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel
                  as="div"
                  className={classNames(
                    "w-full transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all",
                    padding,
                  )}
                  style={{ maxWidth: `${1000 + -20 * depth}px` }}
                >
                  <Transition
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="@container relative">
                      <div className="mb-6">
                        <HeadlessUiDialog.Title
                          as="h3"
                          className="text-xl font-semibold text-gray-900"
                        >
                          {title}
                        </HeadlessUiDialog.Title>
                        {subtitle && (
                          <h4 className="max-w-2xl text-sm text-gray-500">
                            {subtitle}
                          </h4>
                        )}
                      </div>

                      <div className={cn(className)}>{children}</div>

                      {!hideCloseButton && (
                        <div className="absolute right-0 top-0 flex">
                          {buttons}
                          <IconButton
                            tooltip="Close"
                            Icon={XMarkIcon}
                            onClick={() => {
                              reject?.(false);
                              close();
                            }}
                          ></IconButton>
                        </div>
                      )}
                    </div>
                  </Transition>
                </DialogPanel>
              </Transition.Child>
            </div>
          </div>
        </DialogProvider>
      </HeadlessUiDialog>
    </Transition>
  );
}
