import Dropzone from "react-dropzone";
import { useFileUpload } from "@/lib/FileUpload/Hooks/useFileUpload";
import { FileIcon } from "@radix-ui/react-icons";

export type Upload = { file: File; temporary_url: string };
type FileUploaderProps = {
  onDrop?: (files: File[]) => void;
  onUpload: (uploads: Upload[]) => void;
};
export function PdfUploader({ onUpload, onDrop }: FileUploaderProps) {
  const { mutateAsync } = useFileUpload();

  return (
    <Dropzone
      accept={{
        "application/pdf": [],
      }}
      onDrop={async (acceptedFiles, fileRejections, event) => {
        onDrop?.(acceptedFiles);
        const files = await mutateAsync(acceptedFiles);
        onUpload(files);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className="mx-auto flex w-80 cursor-pointer flex-col items-center rounded-md border border-dashed border-gray-200 bg-gray-100/50 p-6"
        >
          <FileIcon className="h-10 w-10 text-blue-500" />
          <input {...getInputProps()} />
          <p className="mt-1">Drag & Drop</p>
          <p>
            or <span className="text-blue-500 hover:underline">browse</span>
          </p>

          <p className="mt-3 text-xs text-gray-500">Supports: PDF</p>
        </div>
      )}
    </Dropzone>
  );
}
