import { MinusIcon, TrendingDownIcon, TrendingUpIcon } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { BadgeGroup } from "@/lib/Components/Badges/BadgeGroup";
import { BadgeProps } from "@/lib/Components/Badges/Badge";
import { FC, ReactNode } from "react";
import { Skeleton } from "@/components/ui/skeleton";

type DashboardCardProps = {
  percentChange?: number;
  badges?: BadgeProps[];
  label: ReactNode;
  primaryFigure: ReactNode;
  supplementaryFigure?: ReactNode;
  tooltip?: ReactNode;
  isNegativeTrendGood?: boolean;
  Icon?: FC;
  isLoading?: boolean;
};
export function DashboardCard({
  percentChange,
  badges = [],
  label,
  tooltip,
  supplementaryFigure,
  primaryFigure,
  Icon: IconProp,
  isLoading = false,
  isNegativeTrendGood = false,
}: DashboardCardProps) {
  let Icon = IconProp ?? MinusIcon;
  let textAccentColor = "text-gray-700";
  let ring = "ring-gray-200";

  if (percentChange) {
    switch (true) {
      case percentChange > 0:
        textAccentColor = isNegativeTrendGood
          ? "text-red-700"
          : "text-green-700";
        Icon = TrendingUpIcon;
        break;
      case percentChange < 0:
        textAccentColor = isNegativeTrendGood
          ? "text-green-700"
          : "text-red-700";
        Icon = TrendingDownIcon;
        break;
    }

    switch (true) {
      case percentChange > 50 && Number.isFinite(percentChange):
        ring = isNegativeTrendGood ? "ring-red-500" : "ring-green-500";
        break;
      case percentChange < -50:
        ring = isNegativeTrendGood ? "ring-green-500" : "ring-red-500";
    }
  }

  return (
    <TooltipProvider>
      <div
        className={cn(
          "flex flex-col overflow-hidden rounded-lg shadow ring-1",
          ring,
        )}
      >
        <div className="flex-grow p-5">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              {isLoading ? (
                <Skeleton className="h-10 w-6" />
              ) : (
                <div className={cn(textAccentColor)}>
                  <Tooltip>
                    <TooltipTrigger>
                      <Icon className="h-6 w-6" aria-hidden="true" />
                      {Number.isNaN(percentChange) ||
                      !Number.isFinite(percentChange) ? null : (
                        <span
                          className={cn(
                            "text-xs text-green-700",
                            textAccentColor,
                          )}
                        >
                          {percentChange?.toFixed(0)}%
                        </span>
                      )}
                    </TooltipTrigger>
                    <TooltipContent>{tooltip}</TooltipContent>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="truncate text-sm font-medium text-gray-500">
                  {label}
                </dt>
                <dd>
                  {isLoading ? (
                    <Skeleton className="h-4 w-[100px]" />
                  ) : (
                    <div className="space-x-2 text-lg font-medium text-gray-900">
                      <span>{primaryFigure}</span>
                      {supplementaryFigure ? (
                        <span className="ml-2 text-xs text-gray-500">
                          {supplementaryFigure}
                        </span>
                      ) : null}
                    </div>
                  )}

                  {badges?.length === 0 ? null : (
                    <div className="mt-1 text-xs text-gray-500">
                      {isLoading ? (
                        <Skeleton className="h-4 w-[200px]" />
                      ) : (
                        <BadgeGroup badges={badges} />
                      )}
                    </div>
                  )}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
}
