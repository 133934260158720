import { graphql } from "@/gql";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";

export type ActivityLogListQueryVariables = VariablesOf<
  typeof activityLogListQuery
>;
export type ActivityLogListItem = ResultOf<
  typeof activityLogListQuery
>["activityLogs"]["data"][number];

export const activityLogListQuery = graphql(`
  query ActivityLogList(
    $page: Int
    $first: Int!
    $where: QueryActivityLogsWhereWhereConditions
    $orderBy: [QueryActivityLogsOrderByOrderByClause!]
  ) {
    activityLogs(page: $page, first: $first, where: $where, orderBy: $orderBy) {
      data {
        id
        description
        event
        properties
        created_at

        actionableItem {
          __typename
          ... on BookingChangeRequest {
            ...BookingChangeRequestFields
          }

          ... on BookingPaymentRequest {
            id
            uuid
            currency
            description
            amount
            created_at
            __BookingPaymentRequest_status: status
            booking {
              id
              uuid
              permalink
            }
            stripePayment {
              ...StripePaymentFields
            }
          }
        }

        causer {
          id
          name
          contact {
            profilePicture {
              ...MediaFields
            }
          }
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
