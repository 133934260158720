import { graphql } from "@/gql";

export const createInvoiceMutation = graphql(`
  mutation CreateInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      id
    }
  }
`);

export const deleteInvoiceMutation = graphql(`
  mutation DeleteInvoice($id: ID!) {
    deleteInvoice(id: $id) {
      id
    }
  }
`);

export const updateInvoiceMutation = graphql(`
  mutation UpdateInvoice($id: ID!, $input: UpdateInvoiceInput!) {
    updateInvoice(id: $id, input: $input) {
      id
    }
  }
`);

export const createInvoicePdfMutation = graphql(`
  mutation GetInvoicePdf($id: ID!) {
    createInvoicePdf(id: $id)
  }
`);

export const issueInvoiceMutation = graphql(`
  mutation IssueInvoice($id: ID!) {
    issueInvoice(id: $id) {
      id
    }
  }
`);

export const resendInvoiceMutation = graphql(`
  mutation ResendInvoice($id: ID!) {
    resendInvoice(id: $id) {
      id
    }
  }
`);

export const createInvoiceCheckoutSessionMutation = graphql(`
  mutation CreateInvoiceCheckoutSession($id: ID!) {
    createInvoiceCheckoutSession(id: $id) {
      id
      checkout_session_url
    }
  }
`);

export const voidInvoiceMutation = graphql(`
  mutation VoidInvoice($id: ID!) {
    voidInvoice(id: $id) {
      id
    }
  }
`);
