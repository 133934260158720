import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { RegionInput } from "@/app/Common/Components/RegionInput";
import { StateInput } from "@/app/Cities/Cities/Components/StateInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { UpdateCityInput } from "@/gql/graphql";

export function CityForm() {
  const { values, setFieldValue } = useForm<UpdateCityInput>();

  return (
    <>
      <TextInput name="name" label="Name" />
      <RegionInput
        name={"region"}
        label={"Region"}
        onChange={async (region) => {
          await setFieldValue("state", region);
        }}
      />
      <StateInput name="state" label="State" region={values.region} />
    </>
  );
}
