import { PaymentListQueryVariables } from "@/gql/graphql";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { formatDate } from "@/lib/Formatters/formatDate";
import {
  PaymentListItem,
  paymentListQuery,
} from "@/app/Payments/GraphQL/paymentListQuery";
import { ModelLinkCell } from "@/lib/Components/DataTable/Cells/ModelLinkCell";
import { Badge } from "@/components/catalyst/badge";
import { paymentStatusColorMap } from "@/app/Payments/Utils/paymentColorMaps";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import { TableId } from "@/app/Common/Utils/tableIds";

type PaymentTableProps = {
  queryVariables?: Partial<PaymentListQueryVariables>;
  id: TableId;
};

type PaymentTableColumnId =
  | "name"
  | "status"
  | "due_date"
  | "amount"
  | "description"
  | "created_at";

export function PaymentsTable({ queryVariables, id }: PaymentTableProps) {
  const columns: DataTableColDef<
    PaymentListItem,
    unknown,
    PaymentTableColumnId
  >[] = [
    {
      id: "name",
      header: "Payment",
      accessorFn: (row) => row,
      cell: ({ row }) => (
        <ModelLinkCell
          to={"/payments/$id"}
          params={{
            id: row.original.id,
          }}
        >
          {row.original.id}
        </ModelLinkCell>
      ),
    },
    {
      id: "status",
      header: "Status",
      accessorFn: (row) => row,
      cell: ({ row }) => (
        <Badge color={paymentStatusColorMap[row.original.status]}>
          {row.original.status}
        </Badge>
      ),
    },
    {
      id: "due_date",
      header: "Due date",
      accessorFn: (row) => formatDate(row.due_date),
    },
    {
      id: "amount",
      header: "Amount",
      accessorFn: (row) => formatCurrency(row.amount, row.currency),
    },
    {
      id: "description",
      header: "Description",
      accessorFn: (row) => row.description,
    },
    {
      id: "created_at",
      header: "Created",
      accessorFn: (row) => formatDateTimeSinceNow(row.created_at),
    },
  ];

  return (
    <DataTable
      document={paymentListQuery}
      getQueryVariables={({ pagination }) => {
        return {
          first: pagination.pageSize,
          page: pagination.pageIndex,
          ...queryVariables,
        };
      }}
      accessor={(data) => data.payments}
      columns={columns}
      id={id}
    />
  );
}
