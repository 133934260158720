import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { InfoBanner } from "@/lib/Components/Banners/InfoBanner";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { relocationRecordQuery } from "@/app/Relocations/GraphQL/relocationRecordQuery";
import { supplierOfficeListQuery } from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { MultiSelectInput } from "@/lib/Components/Form/Inputs/MultiSelectInput";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { addDestinationMutation } from "@/app/Relocations/GraphQL/relocationMutations";
import { relocationListQuery } from "@/app/Relocations/GraphQL/relocationListQuery";
import {
  QueryRelocationsWhereColumn,
  QuerySupplierOfficesOrderByColumn,
  RelocationStatus,
  SortOrder,
  SqlOperator,
} from "@/gql/graphql";

export function AddDestinationsDialog({
  relocationId,
}: {
  relocationId: string;
}) {
  const { close } = useDialogState();
  const { data } = useSuspenseGqlQuery(relocationRecordQuery, {
    id: relocationId,
  });
  const { mutateAsync } = useGqlMutation(addDestinationMutation);

  const supplierId = data.relocation.supplier.id;

  const { data: offices } = useSuspenseGqlQuery(supplierOfficeListQuery, {
    supplier_id: supplierId,
    page: 1,
    first: 200,
    archived: false,
    orderBy: [
      {
        column: QuerySupplierOfficesOrderByColumn.Name,
        order: SortOrder.Asc,
      },
    ],
  });

  const { data: relocations } = useSuspenseGqlQuery(relocationListQuery, {
    first: 200,
    page: 1,
    where: {
      AND: [
        {
          column: QueryRelocationsWhereColumn.RelocationLineId,
          value: data.relocation.line.id,
        },
        {
          column: QueryRelocationsWhereColumn.Id,
          operator: SqlOperator.Neq,
          value: relocationId,
        },
        {
          column: QueryRelocationsWhereColumn.Status,
          value: RelocationStatus.Ready,
        },
      ],
    },
  });

  const disabledOfficeIds = relocations.relocations.data.map((relocation) => {
    return relocation.deliveryOffice.id;
  });

  return (
    <GenericDialog title={`Add destinations to ${data.relocation.reference}`}>
      <div className="space-y-12">
        <InfoBanner>
          Allow the for the vehicle to be dropped off at multiple locations
        </InfoBanner>

        <GenericForm
          initialValues={{
            ids: [],
          }}
          onSubmit={async (values) => {
            await mutateAsync({
              relocationId,
              officeIds: values.ids,
            });

            close();
          }}
          shouldToggleEditOnSubmit={false}
        >
          <MultiSelectInput
            className="col-span-full"
            name="ids"
            label="Destinations"
            options={offices.supplierOffices.data
              .filter((office) => {
                return (
                  office.id !== data.relocation.departureOffice.id &&
                  office.id !== data.relocation.deliveryOffice.id &&
                  !disabledOfficeIds.includes(office.id)
                );
              })
              .map((office) => {
                return {
                  label: office.name,
                  value: office.id,
                };
              })}
          />

          <DialogSubmitButton text="Add destinations" />
        </GenericForm>
      </div>
    </GenericDialog>
  );
}
