import { Measurement, TaxType } from "@/gql/graphql";

export const commonMessages = {
  boolean: {
    true: "Yes",
    false: "No",
    null: "--",
    undefined: "--",
  },
  measurement: {
    [Measurement.Imperial]: "Imperial",
    [Measurement.Metric]: "Metric",
  },
  currencySymbol: "$",
  tax_type: {
    [TaxType.Gst]: "GST",
    [TaxType.TaxExempt]: "NO GST",
  } satisfies {
    [key in TaxType]: string;
  },
};
