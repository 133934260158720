import { graphql } from "@/gql";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";

export const userListQuery = graphql(`
  query UserList($first: Int!, $page: Int, $search: String) {
    users(first: $first, page: $page, search: $search) {
      data {
        id
        name
        primary_phone
        primary_email
        contact {
          profilePicture {
            ...MediaFields
          }
        }
        created_at
        updated_at
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);

export type UserListVariables = VariablesOf<typeof userListQuery>;
export type UserListItem = ResultOf<
  typeof userListQuery
>["users"]["data"][number];
