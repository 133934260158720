import { TaskStatus } from "@/gql/graphql";
import { BadgeColor } from "@/components/catalyst/badge";

export const taskStatusMap: {
  [key in TaskStatus]: BadgeColor;
} = {
  [TaskStatus.Overdue]: "red",
  [TaskStatus.Pending]: "yellow",
  [TaskStatus.Completed]: "green",
  [TaskStatus.Snoozed]: "sky",
};
