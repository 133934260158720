import {
  SupplierNotificationSettingEvent,
  SupplierNotificationSettingType,
} from "@/gql/graphql";

export const supplierMessages = {
  notifications: {
    type: {
      [SupplierNotificationSettingType.Bcc]: "bcc",
      [SupplierNotificationSettingType.Cc]: "cc",
      [SupplierNotificationSettingType.To]: "to",
    },
    event: {
      [SupplierNotificationSettingEvent.BookingPending]: "booking pending",
      [SupplierNotificationSettingEvent.RelocationsExpired]:
        "relocations expired",
      [SupplierNotificationSettingEvent.BookingConfirmed]: "booking confirmed",
      [SupplierNotificationSettingEvent.BookingCancelledAfterConfirmation]:
        "booking cancelled",
      [SupplierNotificationSettingEvent.BookingCancelledBeforeConfirmation]:
        "pending booking cancelled",
      [SupplierNotificationSettingEvent.BookingAccepted]: "booking accepted",
    },
  },
};
