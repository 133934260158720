import { BookingTable } from "@/app/Dashboard/Components/BookingTable";
import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";

export function SupplierBookingScreen() {
  const record = useSupplierRecord();

  return (
    <div className="py-6">
      <RecordScreenCard title="Bookings">
        <BookingTable
          id="supplier-bookings"
          queryVariables={{
            supplierId: record.id,
          }}
        />
      </RecordScreenCard>
    </div>
  );
}
