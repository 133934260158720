import { OfficeTable } from "@/app/Offices/Components/OfficeTable";
import { SettingCard } from "@/app/Settings/Settings/Components/SettingFormCard";
import { SupplierTripsTable } from "@/app/Suppliers/Components/SupplierTripsTable";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { OfficeCreateDialog } from "@/app/Offices/Components/OfficeCreateDialog";
import { Button } from "@/lib/Components/Button/Button";

export function SettingsOfficeScreen() {
  const { supplierId } = useGlobalSupplier();
  const { open } = useDialog(OfficeCreateDialog);

  if (!supplierId) {
    return null;
  }

  return (
    <div className="space-y-12">
      <SettingCard
        title="Offices"
        description="A list of offices you would like to send your vehicles between"
      >
        <OfficeTable
          id="settings-offices"
          rightButtons={
            <Button
              onClick={() => {
                open({
                  initialValues: {
                    supplier: {
                      connect: supplierId,
                    },
                  },
                });
              }}
            >
              Add office
            </Button>
          }
        />
      </SettingCard>

      <SettingCard
        title="Trips"
        description="A list of trips you would like to send your vehicles between"
      >
        <SupplierTripsTable id="settings-trips" supplierId={supplierId} />
      </SettingCard>
    </div>
  );
}
