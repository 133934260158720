import { MultiActionGroupButtonType } from "@/lib/Components/Actions/ActionGroupButtons";

export function flattenActions(actions: MultiActionGroupButtonType[]) {
  return actions
    .flatMap((item) => {
      return (item.items ?? []).filter((actionItem) => {
        return (
          actionItem.actions.filter((action) => !action.isHidden).length > 0
        );
      });
    })
    .filter(Boolean);
}
