import { useVehicleRecord } from "@/app/Vehicles/Hooks/useVehicleRecord";
import { SortableVehicleImages } from "@/app/Vehicles/Components/SortableVehicleImages";

export function VehicleImagesScreen() {
  const vehicle = useVehicleRecord();

  return (
    <div className="py-6">
      <SortableVehicleImages record={vehicle} />
    </div>
  );
}
