import { graphql } from "@/gql";

export const bookingStatusCountsQuery = graphql(`
  query BookingStatusCountsQuery($supplierId: ID) {
    bookingStatusCounts(supplierId: $supplierId) {
      status
      count
    }
  }
`);
