import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierTripEditDialog } from "@/app/SupplierTrips/Components/SupplierTripEditDialog";
import { SupplierTripListItem } from "@/app/SupplierTrips/GraphQL/supplierTripListQuery";

export function useSupplierTripsActions(): ActionsHook<SupplierTripListItem> {
  const { open } = useDialog(SupplierTripEditDialog);

  return (model) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Edit",
                Icon: PencilIcon,
                handleAction: () => {
                  open({
                    officeAId: model.officeA.id,
                    officeBId: model.officeB.id,
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
