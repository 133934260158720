import { graphql } from "@/gql";

export const createRelocationMutation = graphql(`
  mutation CreateRelocation($input: CreateRelocationInput!) {
    createRelocation(input: $input) {
      id
    }
  }
`);

export const updateRelocationMutation = graphql(`
  mutation UpdateRelocation($id: ID!, $input: UpdateRelocationInput!) {
    updateRelocation(id: $id, input: $input) {
      id
    }
  }
`);

export const duplicateRelocationMutation = graphql(`
  mutation DuplicateRelocation($id: ID!) {
    duplicateRelocation(id: $id) {
      id
    }
  }
`);

export const updateRelocationStatuses = graphql(`
  mutation ArchiveRelocations($ids: [ID!]!, $status: RelocationStatus!) {
    updateRelocationStatuses(ids: $ids, status: $status)
  }
`);

export const addDestinationMutation = graphql(`
  mutation AddDestinations($relocationId: ID!, $officeIds: [ID!]!) {
    addRelocationDestinations(
      relocationId: $relocationId
      destinationOfficeIds: $officeIds
    ) {
      id
    }
  }
`);
