import { CityListSearchParams } from "@/app/Cities/Cities/Routes/cityRoutes";
import { CityListQueryVariables } from "@/gql/graphql";
import { QueryCitiesOrderByColumn, SortOrder } from "@/gql/graphql";

export function getCityListQueryVariables(
  searchParams: CityListSearchParams,
): CityListQueryVariables {
  const baseParams = {
    first: 20,
    page: 1,
    search: searchParams.citySearch,
  };
  if (searchParams.citySearch) {
    return baseParams;
  }
  return {
    ...baseParams,
    orderBy: [
      {
        order: SortOrder.Asc,
        column: QueryCitiesOrderByColumn.Name,
      },
    ],
  };
}
