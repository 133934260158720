import { graphql } from "@/gql";

export const createReportPaymentMutationGraphql = graphql(`
  mutation CreatePaymentReportUrl(
    $startDate: DateTimeTz!
    $endDate: DateTimeTz!
    $currency: Currency
    $statues: [PaymentStatus!]
  ) {
    createReportPaymentDownloadUrl(
      start_date_time: $startDate
      end_date_time: $endDate
      statuses: $statues
      currency: $currency
    )
  }
`);
