import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { getInitials } from "@/lib/Utils/getInitials";

export function UserProfilePicture({
  model,
}: {
  model?: {
    name?: string | null;
    profilePicture?: {
      url?: string;
      srcSet?: string;
    } | null;
  };
}) {
  return (
    <Avatar>
      <AvatarImage
        src={model?.profilePicture?.url}
        srcSet={model?.profilePicture?.srcSet}
      />
      <AvatarFallback>{getInitials(model?.name ?? "")}</AvatarFallback>
    </Avatar>
  );
}
