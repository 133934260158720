import { graphql } from "@/gql";

export const clerkUserRecord = graphql(`
  query ClerkUserRecord($id: ID!) {
    clerkUser(id: $id) {
      id
      primary_email_address_id
      primary_phone_number_id
      image_url
      last_sign_in_at
      email_addresses {
        id
        email_address
      }
      phone_numbers {
        id
        phone_number
      }
    }
  }
`);
