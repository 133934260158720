import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { RichTextInput } from "@/lib/Components/Form/Inputs/RichTextInput";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { SupplierInput } from "@/app/Suppliers/Components/SupplierInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { CreateSupplierInsuranceOptionInput, Currency } from "@/gql/graphql";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";

export function SupplierInsuranceOptionForm({
  type,
}: {
  type: "create" | "update";
}) {
  const { values, initialValues } =
    useForm<CreateSupplierInsuranceOptionInput>();
  const { data } = useGqlQuery(
    supplierRecordQuery,
    {
      id: values.supplier?.connect!,
    },
    {
      enabled: !!values.supplier?.connect,
    },
  );

  return (
    <>
      {type === "create" && !initialValues.supplier?.connect ? (
        <>
          <SupplierInput name="supplier.connect" label="Supplier" />
          <FormDivider />
        </>
      ) : null}

      {values.supplier?.connect || type === "update" ? (
        <>
          <TextInput name={"name"} label={"Name"} />
          <CurrencyInput
            name={"bond_amount"}
            label={"Bond Amount"}
            currency={data?.supplier.currency ?? Currency.Usd}
          />
          <CurrencyInput
            name={"standard_liability_amount"}
            label={"Standard Liability Amount"}
            currency={data?.supplier.currency ?? Currency.Usd}
          />
          <RichTextInput
            className="col-span-full"
            name={"liability_reduction_options"}
            label={"Liability Reduction Options"}
          />
        </>
      ) : null}
    </>
  );
}
