import { graphql } from "@/gql";

export const createInvoiceReportMutationGraphql = graphql(`
  mutation CreateInvoiceReport(
    $rangeStartDate: String!
    $rangeEndDate: String!
    $organisationId: ID
    $status: InvoiceStatus
  ) {
    createReportInvoiceDownloadUrl(
      range_start_date: $rangeStartDate
      range_end_date: $rangeEndDate
      status: $status
      organization_id: $organisationId
    )
  }
`);
