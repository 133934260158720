import { DepositRefundedAt, HireUnitType } from "@/gql/graphql";

export const bookingCardMessages = {
  orderCard: {
    relocationDays: `Relocation {type, select, 
         ${HireUnitType.Day} {days}
         ${HireUnitType.Night} {nights}
         ${HireUnitType.TwentyFourHours} {24hrs}
        other {units}
      } x {units}`,
    extraDays: `Extra {type, select,
          ${HireUnitType.Day} {days}
          ${HireUnitType.Night} {nights}
          ${HireUnitType.TwentyFourHours} {24hrs}
          other {units}
        } x {units}
    `,
    subtotal: "Subtotal",
    total: "Total",
    depositRefund: `Deposit refund (on {deposit_refund_at, select,
      ${DepositRefundedAt.Departure} {pickup}
      ${DepositRefundedAt.Delivery} {delivery}
      other {}
     })`,
  },
};
