import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { CityInput } from "@/app/Cities/Cities/Components/CityInput";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";
import { WaitlistTable } from "@/app/Waitlists/Components/WaitlistTable";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import {
  QueryWaitlistsOrderColumn,
  QueryWaitlistsWhereColumn,
  SortOrder,
  SqlOperator,
} from "@/gql/graphql";

export function WaitlistWithFilters() {
  return (
    <>
      <div className="py-6">
        <GenericForm
          initialValues={{
            departure_city: null!,
            delivery_city: null!,
            with_matches: "",
            with_phone_number: "",
            earliest_departure_date: null!,
            latest_departure_date: null!,
          }}
          onSubmit={() => {
            return;
          }}
        >
          <CityInput name="departure_city" label="Pickup" clearable />
          <CityInput name="delivery_city" label="Dropoff" clearable />
          <DateInput label="Earliest pickup" name="earliest_departure_date" />
          <DateInput label="Latest dropoff" name="latest_departure_date" />

          <SwitchInput label="With matches" name="with_matches" />
          <SwitchInput label="With phone number" name="with_phone_number" />

          <div className="col-span-full">
            <WaitlistTableComp />
          </div>
        </GenericForm>
      </div>
    </>
  );
}

type FormValues = {
  departure_city: string | null;
  delivery_city: string | null;
  earliest_departure_date: string | null;
  latest_departure_date: string | null;
  with_matches: string | null;
  with_phone_number: string | null;
};

function WaitlistTableComp() {
  const { values } = useForm<FormValues>();

  return (
    <WaitlistTable
      id="waitlists"
      getQueryVariables={({ search, sorting, pagination }) => {
        return {
          search,
          first: pagination.pageSize,
          page: pagination.pageIndex,
          order: search
            ? undefined
            : [
                {
                  column: QueryWaitlistsOrderColumn.CreatedAt,
                  order: SortOrder.Desc,
                },
              ],
          where: search
            ? undefined
            : {
                AND: [
                  ...(values.departure_city
                    ? [
                        {
                          column: QueryWaitlistsWhereColumn.DepartureCityId,
                          operator: SqlOperator.Eq,
                          value: values.departure_city,
                        },
                      ]
                    : []),
                  ...(values.delivery_city
                    ? [
                        {
                          column: QueryWaitlistsWhereColumn.DeliveryCityId,
                          operator: SqlOperator.Eq,
                          value: values.delivery_city,
                        },
                      ]
                    : []),
                  ...(values.earliest_departure_date
                    ? [
                        {
                          column:
                            QueryWaitlistsWhereColumn.EarliestDepartureDate,
                          operator: SqlOperator.Gte,
                          value: values.earliest_departure_date,
                        },
                      ]
                    : []),
                  ...(values.latest_departure_date
                    ? [
                        {
                          column: QueryWaitlistsWhereColumn.LatestDepartureDate,
                          operator: SqlOperator.Lte,
                          value: values.latest_departure_date,
                        },
                      ]
                    : []),
                ],
              },
          ...(values.with_matches && !search ? { hasMatches: true } : {}),
          ...(values.with_phone_number && !search
            ? { hasPhoneNumber: true }
            : {}),
        };
      }}
    />
  );
}
