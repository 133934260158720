import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { BookingCancelDialog } from "@/app/Bookings/Components/Dialogs/BookingCancelDialog";
import {
  Booking,
  BookingStatus,
  FeatureFlagType,
  ModelType,
} from "@/gql/graphql";
import { PreviewPdfDialog } from "@/lib/Components/Dialog/PreviewPdfDialog";
import {
  ArrowTopRightOnSquareIcon,
  CheckIcon,
} from "@heroicons/react/20/solid";
import {
  ActivityIcon,
  CircleDotDashedIcon,
  CrownIcon,
  MailIcon,
} from "lucide-react";
import { CheckBadgeIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { PDFIcon } from "@/assets/Icons";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { toast } from "sonner";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import {
  bookingAcceptedMutation,
  bookingPdfMutation,
  mailBookingToAdminMutation,
  mailBookingToCustomerMutation,
  mailBookingToSupplierMutation,
} from "@/app/Bookings/GraphQL/bookingMutations";
import { ActivityLogDialog } from "@/app/ActivityLogs/Components/ActivityLogDialog";
import { BookingConfirmDialog } from "@/app/Bookings/Components/Dialogs/BookingConfirmDialog";
import { BookingVipDialog } from "@/app/Bookings/Components/Dialogs/BookingVipDialog";
import { BookingPendingDialog } from "@/app/Bookings/Components/Dialogs/BookingPendingDialog";
import { useFeatureFlag } from "@/app/Meta/utils/useFeatureFlag";

export function useBookingActions(): ActionsHook<
  Pick<Booking, "id" | "status" | "permalink">
> {
  const isBookingAcceptedEnabled = useFeatureFlag(
    FeatureFlagType.BookingAcceptedState,
  );
  const { open: openCancelDialog } = useDialog(BookingCancelDialog);
  const { open: openConfirmDialog } = useDialog(BookingConfirmDialog);
  const { open: openVipDialog } = useDialog(BookingVipDialog);
  const { open: openPendingDialog } = useDialog(BookingPendingDialog);

  const { mutateAsync: getBookingUrl } = useGqlMutation(bookingPdfMutation);
  const { open: openPdf } = useDialog(PreviewPdfDialog);
  const { mutateAsync: mailToCustomer } = useGqlMutation(
    mailBookingToCustomerMutation,
  );
  const { mutateAsync: mailToSupplier } = useGqlMutation(
    mailBookingToSupplierMutation,
  );
  const { mutateAsync: mailToAdmin } = useGqlMutation(
    mailBookingToAdminMutation,
  );

  const { mutateAsync: acceptBooking } = useGqlMutation(
    bookingAcceptedMutation,
  );

  const { open: openActivityDialog } = useDialog(ActivityLogDialog);

  const { isAdmin } = useTenant();

  return (booking) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Confirm",
                Icon: CheckIcon,
                intent: "success",
                isHidden: [
                  BookingStatus.Confirmed,
                  BookingStatus.Completed,
                ].includes(booking.status),
                handleAction: () => {
                  openConfirmDialog({ bookingId: booking.id });
                },
              },
              {
                label: "Accept",
                Icon: CheckBadgeIcon,
                isHidden:
                  !isBookingAcceptedEnabled ||
                  ![
                    BookingStatus.AwaitingConfirmation,
                    BookingStatus.Vip,
                  ].includes(booking.status),
                handleAction: async () => {
                  await acceptBooking({
                    id: booking.id,
                  });

                  toast(
                    "Booking accepted! An email has been sent to the res team",
                  );
                },
                intent: "primary",
              },
              {
                label: "Pending",
                Icon: CircleDotDashedIcon,
                isHidden:
                  !isAdmin ||
                  [
                    BookingStatus.AwaitingConfirmation,
                    BookingStatus.Confirmed,
                    BookingStatus.Completed,
                    BookingStatus.Accepted,
                  ].includes(booking.status),
                handleAction: () => {
                  openPendingDialog({
                    bookingId: booking.id,
                  });
                },
                intent: "warning",
              },
              {
                label: "VIP",
                Icon: CrownIcon,
                intent: "primary",
                isHidden:
                  !isAdmin || [BookingStatus.Vip].includes(booking.status),
                handleAction: () => {
                  openVipDialog({
                    bookingId: booking.id,
                  });
                },
              },
              {
                label: "Cancel",
                Icon: XMarkIcon,
                intent: "danger",
                isHidden: [
                  BookingStatus.AdminCancelled,
                  BookingStatus.SupplierCancelled,
                  BookingStatus.CustomerCancelled,
                  BookingStatus.AwaitingPayment,
                ].includes(booking.status),
                handleAction: () => {
                  openCancelDialog({ bookingId: booking.id });
                },
              },
              {
                label: "Change cancel status",
                Icon: XMarkIcon,
                intent: "danger",
                isHiddenTopBar: true,
                isHidden: ![
                  BookingStatus.AdminCancelled,
                  BookingStatus.SupplierCancelled,
                  BookingStatus.CustomerCancelled,
                ].includes(booking.status),
                handleAction: () => {
                  openCancelDialog({ bookingId: booking.id });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Activity",
                Icon: ActivityIcon,
                isHiddenTopBar: true,
                handleAction: () => {
                  openActivityDialog({
                    modelId: booking.id,
                    modelType: ModelType.Booking,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                intent: "neutral",
                Icon: MailIcon,
                label: "Customer",
                handleAction: async () => {
                  await mailToCustomer({
                    id: booking.id,
                  });

                  toast("Email sent", {
                    description: "The email has been sent to the customer",
                  });
                },
              },
              {
                intent: "neutral",
                Icon: MailIcon,
                label: "Supplier",
                isHidden: booking.status === BookingStatus.AwaitingPayment,
                handleAction: async () => {
                  await mailToSupplier({
                    id: booking.id,
                  });

                  toast("Email sent", {
                    description: "The email has been sent to the supplier",
                  });
                },
              },
              {
                intent: "neutral",
                Icon: MailIcon,
                label: "Admin",
                isAdminAction: true,
                isHidden: booking.status === BookingStatus.AwaitingPayment,
                handleAction: async () => {
                  await mailToAdmin({
                    id: booking.id,
                  });

                  toast("Email sent", {
                    description: "The email has been sent to admin",
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Pdf",
                Icon: PDFIcon,
                intent: "neutral",
                handleAction: async () => {
                  const res = await getBookingUrl({
                    bookingId: booking.id,
                  });

                  openPdf({
                    url: res.bookingPdf,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "View",
                intent: "neutral",
                Icon: ArrowTopRightOnSquareIcon,
                handleAction: async () => {
                  window.open(booking.permalink, "_blank");
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
