import { GenericFieldArray } from "@/lib/Components/Form/FieldArray/GenericFieldArray";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { BellIcon } from "@heroicons/react/24/outline";
import {
  SupplierNotificationSettingEvent,
  SupplierNotificationSettingType,
} from "@/gql/graphql";
import { TextWithSelectInput } from "@/lib/Components/Form/Inputs/TextWithSelectInput";

type SupplierNotificationSettingFormProps = {
  event: SupplierNotificationSettingEvent;
};
export function SupplierNotificationSettingForm({
  event,
}: SupplierNotificationSettingFormProps) {
  return (
    <>
      <GenericFieldArray
        Icon={BellIcon}
        fieldArrayKey="notificationSettings.upsert"
        lineItemLabel="Notification"
        newLineItemValues={{
          email: "",
          type: SupplierNotificationSettingType.To,
          event,
        }}
        emptyState={
          <EmptyState
            Icon={BellIcon}
            header="Add notifications"
            subheader="Get notified when events in the system occur"
            iconWidth="w-20"
          />
        }
      >
        {(obj, index) => {
          return (
            <>
              <TextWithSelectInput
                label="Email"
                inputName={`notificationSettings.upsert.${index}.email`}
                className="col-span-4"
                inputProps={{
                  type: "email",
                }}
                options={[
                  { label: "To", value: SupplierNotificationSettingType.To },
                  { label: "Cc", value: SupplierNotificationSettingType.Cc },
                  { label: "Bcc", value: SupplierNotificationSettingType.Bcc },
                ]}
                selectName={`notificationSettings.upsert.${index}.type`}
              />
            </>
          );
        }}
      </GenericFieldArray>
    </>
  );
}
