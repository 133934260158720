import {
  Description,
  Fieldset,
  Label,
  Legend,
} from "@/components/catalyst/fieldset";
import { Radio, RadioField, RadioGroup } from "@/components/catalyst/radio";
import { Text } from "@/components/catalyst/text";
import { useField } from "formik";
import { HireUnitType } from "@/gql/graphql";

export function HireUnitRadioButton({
  name,
  label,
}: {
  name: string;
  label: string;
}) {
  const [{ value }, , { setValue }] = useField(name);

  return (
    <Fieldset className="col-span-full">
      <Legend>{label}</Legend>
      <Text>How do you calculate the rental period?</Text>
      <RadioGroup
        value={value}
        onChange={(newVal) => {
          setValue(newVal);
        }}
      >
        <RadioField>
          <Radio value={HireUnitType.Day} />
          <Label>Per calendar day</Label>
          <Description>
            Typical for motorhomes and campervans outside of the US, vehicles
            are sold by calendar day.
          </Description>
        </RadioField>
        <RadioField>
          <Radio value={HireUnitType.TwentyFourHours} />
          <Label>Per 24hrs</Label>
          <Description>
            Typical for car rental, pick-up and drop-off times are considered in
            the rental period.
          </Description>
        </RadioField>
        <RadioField>
          <Radio value={HireUnitType.Night} />
          <Label>Per night</Label>
          <Description>
            Typical for motorhomes and campervans inside the US, vehicles are
            sold by night.
          </Description>
        </RadioField>
      </RadioGroup>
    </Fieldset>
  );
}
