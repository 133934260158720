import { graphql } from "@/gql";

export const createInvoicePaymentMutation = graphql(`
  mutation CreateInvoicePayment($input: CreateInvoicePaymentInput!) {
    createInvoicePayment(input: $input) {
      id
    }
  }
`);

export const updateInvoicePaymentMutation = graphql(`
  mutation UpdateInvoicePayment($id: ID!, $input: UpdateInvoicePaymentInput!) {
    updateInvoicePayment(id: $id, input: $input) {
      id
    }
  }
`);

export const deleteInvoicePaymentMutation = graphql(`
  mutation DeleteInvoicePayment($id: ID!) {
    deleteInvoicePayment(id: $id) {
      id
    }
  }
`);
