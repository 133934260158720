import { useLocalStorage } from "react-use";
import { cn, localStorageKeys } from "@/lib/utils";
import { GlobalSupplierStorageObject } from "@/app/Suppliers/Components/GlobalSupplierProvider";

export function ApplicationSkeleton() {
  const [globalSupplier] = useLocalStorage<GlobalSupplierStorageObject>(
    localStorageKeys.GLOBAL_SUPPLIER,
  );

  const hasSupplier = globalSupplier?.type === "all";

  return (
    <div
      className={cn(
        "relative isolate flex h-full min-h-svh w-full bg-white max-lg:flex-col",
        {
          "lg:bg-brand": !hasSupplier,
          "lg:bg-fuchsia-950": hasSupplier,
        },
      )}
    >
      <main className="flex flex-1 flex-col pb-2 lg:min-w-0 lg:pl-64 lg:pr-2 lg:pt-2">
        <div className="flex grow items-center justify-center overflow-hidden lg:rounded-lg lg:bg-white lg:shadow-sm lg:ring-1 lg:ring-zinc-950/5">
          <svg
            className="size-6 animate-spin text-blue-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      </main>
    </div>
  );
}
