import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierAcknowledgementMutations } from "@/app/Suppliers/GraphQL/supplierAcknowledgementMutations";
import { UpdateSupplierAcknowledgementInput } from "@/gql/graphql";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { supplierAcknowledgementRecord } from "@/app/Suppliers/GraphQL/supplierAcknowledgementList";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";

export function SupplierAcknowledgementUpdateDialog({
  supplierAcknowledgementId,
}: {
  supplierAcknowledgementId: string;
}) {
  const { close } = useDialogState();
  const { mutateAsync } = useGqlMutation(
    updateSupplierAcknowledgementMutations,
  );
  const { data } = useSuspenseGqlQuery(supplierAcknowledgementRecord, {
    id: supplierAcknowledgementId,
  });

  return (
    <GenericDialog title="Update Acknowledgement">
      <GenericForm<UpdateSupplierAcknowledgementInput>
        initialValues={{
          name: data.supplierAcknowledgement.name,
          acknowledgement: data.supplierAcknowledgement.acknowledgement,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: supplierAcknowledgementId,
            input: values,
          });
          close();
        }}
      >
        <TextInput name="name" label="Name" />
        <TextAreaInput
          name="acknowledgement"
          label="Acknowledgement"
          className="col-span-full"
          textAreaProps={{
            rows: 4,
          }}
        />
        <DialogSubmitButton text={"Update Acknowledgement"} />
      </GenericForm>
    </GenericDialog>
  );
}
