import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import { TableId } from "@/app/Common/Utils/tableIds";
import { CellContext } from "@tanstack/react-table";
import {
  ContactListItem,
  contactListQuery,
} from "@/app/Contacts/GraphQL/contactListQuery";
import { ContactRecord } from "@/app/Contacts/GraphQL/contactRecordQuery";
import { Badge } from "@/lib/Components/Badges/Badge";
import { ContactListQueryVariables } from "@/gql/graphql";
import { ReactNode } from "react";
import { useContactActions } from "@/app/Contacts/Hooks/useContactActions";

type ContactTableColumnId =
  | "name"
  | "company_number"
  | "emails"
  | "phones"
  | "address";
export function ContactTable({
  id,
  queryVariables,
  rightButtons,
}: {
  id: TableId;
  queryVariables?: Partial<ContactListQueryVariables>;
  rightButtons?: ReactNode;
}) {
  const getActions = useContactActions();

  const columns: DataTableColDef<
    ContactListItem,
    unknown,
    ContactTableColumnId
  >[] = [
    {
      id: "name",
      header: "Name",
      accessorFn: (row) => row.name,
    },
    {
      id: "company_number",
      header: "Company number",
      accessorFn: (row) => row.company_number,
    },
    {
      id: "emails",
      header: "Emails",
      accessorFn: (row) => row,
      cell: EmailCell,
    },
    {
      id: "phones",
      header: "Phones",
      accessorFn: (row) => row,
      cell: PhoneCell,
    },
    {
      id: "address",
      header: "Address",
      accessorFn: (row) => row,
      cell: AddressCell,
    },
  ];

  return (
    <DataTable
      columns={columns}
      document={contactListQuery}
      rightButtons={rightButtons}
      getActions={getActions}
      getQueryVariables={({ pagination, search }) => {
        return {
          search: search,
          first: pagination.pageSize,
          page: pagination.pageIndex,
          ...queryVariables,
        };
      }}
      accessor={(data) => data.contacts}
      id={id}
    />
  );
}

function EmailCell({ row }: CellContext<ContactRecord, any>) {
  const model = row.original;

  return (
    <ul>
      {model.emails.map((email) => (
        <li key={email.id} className="space-x-2">
          <span>{email.email}</span>
          {email.is_primary ? <Badge label="primary" intent="success" /> : null}
          {email.is_billing ? <Badge label="billing" intent="primary" /> : null}
        </li>
      ))}
    </ul>
  );
}

function PhoneCell({ row }: CellContext<ContactRecord, any>) {
  const model = row.original;

  return (
    <ul>
      {model.phones.map((phone) => (
        <li key={phone.id} className="space-x-2">
          <span>{phone.phone}</span>
          {phone.is_primary ? <Badge label="primary" intent="success" /> : null}
          {phone.is_billing ? <Badge label="billing" intent="primary" /> : null}
        </li>
      ))}
    </ul>
  );
}

function AddressCell({ row }: CellContext<ContactRecord, any>) {
  const model = row.original;

  return (
    <div>
      <p>{model.billingAddress?.name ?? "--"}</p>
    </div>
  );
}
