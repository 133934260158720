import { UserWaitlistTable } from "@/app/Waitlists/Components/UserWaitlistTable";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  PolarGrid,
  PolarRadiusAxis,
  RadialBar,
  RadialBarChart,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { callLogCountQuery } from "@/app/Waitlists/GraphQL/callLogCountQuery";
import dayjs from "dayjs";
import { toDateTimeTzString } from "@/lib/Formatters/toDateTimeString";
import { max } from "lodash";
import { UserSelect } from "@/app/Waitlists/Components/UserSelect";
import { metaQuery } from "@/app/Meta/GraphQL/metaQuery";
import { Suspense, useState, useTransition } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";

const TARGET_CALLS = 40;

export function UserWaitlistScreen() {
  return (
    <div>
      <Suspense fallback={<Skeleton className="h-[364px] w-full" />}>
        <CallStatsTable />
      </Suspense>
      <UserWaitlistTable />
    </div>
  );
}

const chartConfig = {
  note_count: {
    label: "Notes",
    color: "hsl(var(--chart-1))",
  },
  phone_call_count: {
    label: "Calls",
    color: "hsl(var(--chart-2))",
  },
} satisfies ChartConfig;

function CallStatsTable() {
  const {
    data: { me },
  } = useSuspenseGqlQuery(metaQuery, {});

  const [userId, setUserId] = useState(me.id);

  const end = dayjs().endOf("day");
  const start = end.clone().subtract(7, "days").startOf("day");

  const { data } = useSuspenseGqlQuery(callLogCountQuery, {
    start: toDateTimeTzString(start),
    end: toDateTimeTzString(end),
    userId,
  });
  const [isPending, startTransition] = useTransition();

  const chartData = Array.from({ length: 7 }, (_, i) => {
    const date = start.clone().add(i + 1, "days");
    const phone_call_count = data?.callLogCount?.find(
      (log) => log.date === date.format("YYYY-MM-DD"),
    )?.phone_call_count;

    const note_count = data?.callLogCount?.find(
      (log) => log.date === date.format("YYYY-MM-DD"),
    )?.note_count;

    return {
      date: date.format("YYYY-MM-DD"),
      phone_call_count: phone_call_count ?? 0,
      note_count: note_count ?? 0,
      target: 40,
    };
  });

  return (
    <Card
      className={cn({
        "animate-pulse": isPending,
      })}
    >
      <CardHeader className="flex w-full flex-row justify-between">
        <div className="flex-grow space-y-1.5">
          <CardTitle>Call logs</CardTitle>
          <CardDescription>
            {`Last 7 days (${start.format("MMM D")} - ${end.format("MMM D")})`}
          </CardDescription>
        </div>
        <div className="w-40">
          <UserSelect
            userId={userId}
            onChange={(userId) => {
              startTransition(() => {
                setUserId(userId);
              });
            }}
          />
        </div>
      </CardHeader>

      <CardContent className="grid grid-cols-3">
        <div className="col-span-2">
          <ChartContainer config={chartConfig} className="h-60 w-full">
            <BarChart
              accessibilityLayer
              data={chartData}
              margin={{
                top: 20,
              }}
            >
              <CartesianGrid vertical={false} />
              <YAxis
                type="number"
                domain={[0, (dataMax: number) => max([50, dataMax])]}
              />
              <XAxis
                dataKey="date"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) => dayjs(value).format("ddd")}
              />
              <ReferenceLine
                y={TARGET_CALLS}
                label="Target"
                stroke="hsl(var(--chart-2))"
              />

              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent hideLabel />}
              />
              <ChartLegend content={<ChartLegendContent />} />

              <Bar
                dataKey="phone_call_count"
                fill="var(--color-phone_call_count)"
                stackId="a"
              />
              <Bar
                dataKey="note_count"
                fill="var(--color-note_count)"
                stackId="a"
                radius={[8, 8, 0, 0]}
              />
            </BarChart>
          </ChartContainer>
        </div>
        <TargetRadialGraph
          callsToday={chartData.at(-1)?.phone_call_count ?? 0}
        />
        <div className="col-span-1"></div>
      </CardContent>
    </Card>
  );
}

function TargetRadialGraph({ callsToday }: { callsToday: number }) {
  const chartConfig = {
    visitors: {
      label: "Calls",
    },
    safari: {
      label: "Calls",
      color: "hsl(var(--chart-2))",
    },
  } satisfies ChartConfig;

  const chartData = [{ visitors: callsToday, fill: "var(--color-safari)" }];

  const endAngle =
    callsToday > TARGET_CALLS ? 360 : (callsToday / TARGET_CALLS) * 360;

  return (
    <ChartContainer config={chartConfig} className="mx-auto aspect-square w-60">
      <RadialBarChart
        className="h-60"
        data={chartData}
        startAngle={0}
        endAngle={endAngle}
        innerRadius={80}
        outerRadius={110}
      >
        <PolarGrid
          gridType="circle"
          radialLines={false}
          stroke="none"
          className="first:fill-muted last:fill-background"
          polarRadius={[86, 74]}
        />
        <RadialBar
          dataKey="visitors"
          background
          cornerRadius={10}
          stackId="a"
        />

        <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
          <Label
            content={({ viewBox }) => {
              if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                return (
                  <text
                    x={viewBox.cx}
                    y={viewBox.cy}
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    <tspan
                      x={viewBox.cx}
                      y={viewBox.cy}
                      className="fill-foreground text-4xl font-bold"
                    >
                      {callsToday}
                    </tspan>
                    <tspan
                      x={viewBox.cx}
                      y={(viewBox.cy || 0) + 24}
                      className="fill-muted-foreground"
                    >
                      Calls today
                    </tspan>
                  </text>
                );
              }
            }}
          />
        </PolarRadiusAxis>
      </RadialBarChart>
    </ChartContainer>
  );
}
