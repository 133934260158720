import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { metaQuery } from "@/app/Meta/GraphQL/metaQuery";

export function AccountVerificationWarning() {
  const { data, isLoading } = useGqlQuery(metaQuery, {});

  if (isLoading || data?.meta.is_verified) {
    return null;
  }

  return (
    <div className="flex items-center justify-center gap-x-6 bg-blue-600 px-6 py-2.5 sm:px-3.5">
      <p className="text-sm leading-6 text-white">
        <span>
          <strong className="font-semibold">
            Thanks for joining {"Imoova's"} trade partner program. We will be in
            contact with you shortly to verify your account details
          </strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          Call our sales team{" "}
          <a href="tel:+61721000193" className="underline">
            +617 2100 0193
          </a>{" "}
          ext. 802
        </span>
      </p>
    </div>
  );
}
