import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type RelocationListItem = ResultOf<
  typeof relocationListQuery
>["relocations"]["data"][number];
export const relocationListQuery = graphql(`
  query RelocationList(
    $first: Int!
    $page: Int
    $search: String
    $supplier_id: ID
    $delivery_city_id: ID
    $departure_city_id: ID
    $region: Region
    $regions: [Region!]
    $where: QueryRelocationsWhereWhereConditions
    $whereVehicle: QueryRelocationsWhereVehicleWhereHasConditions
    $status: [RelocationStatus!]
    $orderBy: [QueryRelocationsOrderByRelationOrderByClause!]
    $scoutOrderBy: [QueryRelocationsScoutOrderByOrderByClause!]
    $is_linked: Boolean
  ) {
    relocations(
      first: $first
      page: $page
      search: $search
      supplier_id: $supplier_id
      delivery_city_id: $delivery_city_id
      departure_city_id: $departure_city_id
      status: $status
      orderBy: $orderBy
      scoutOrderBy: $scoutOrderBy
      where: $where
      whereVehicle: $whereVehicle
      region: $region
      regions: $regions
      is_linked: $is_linked
    ) {
      data {
        id
        reference
        is_linked
        region
        line {
          id
          reference
          expires_at
          references {
            id
            sold_at
            reference
            booking {
              id
              reference
            }
          }
        }
        supplier {
          id
          name
        }
        vehicle {
          id
          name
          code
          heroImage
          type
          seatbelts
          transmission
        }
        status
        count
        available_to_date
        available_from_date
        hire_unit_rate
        hire_unit_type
        extra_hire_unit_rate
        extra_hire_unit_supplier_net_rate
        hire_units_allowed
        extra_hire_units_allowed
        distance_allowed
        currency
        seatbelts
        distance_allowed
        measurement
        bookedBookingsCount
        linkedRelocationCount
        departureOffice {
          id
          name
          code
        }
        deliveryOffice {
          id
          name
          code
        }
        departureCity {
          id
          name
          region
          state
        }
        deliveryCity {
          id
          name
          region
          state
        }
        inclusions {
          ...RelocationInclusionFields
        }
        created_at
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);

export const relocationInclusionFragment = graphql(`
  fragment RelocationInclusionFields on RelocationInclusion {
    __typename
    id
    description
    type
    value
    is_acknowledgment_required
  }
`);
