import { graphql } from "@/gql";

export const createSupplierTripMutation = graphql(`
  mutation CreateSupplierTrip($input: CreateSupplierTripInput!) {
    createSupplierTrip(input: $input) {
      id
    }
  }
`);

export const updateSupplierTripMutation = graphql(`
  mutation UpdateSupplierTrip($id: ID!, $input: UpdateSupplierTripInput!) {
    updateSupplierTrip(id: $id, input: $input) {
      id
    }
  }
`);
