import { graphql } from "@/gql";

export const onboardingCreateOrganisationMutation = graphql(`
  mutation OnboardingCreateOrganisation(
    $input: OnboardingCreateOrganisationInput!
  ) {
    onboardingCreateOrganisation(input: $input) {
      supplier_id
      clerk_organisation_id
    }
  }
`);
