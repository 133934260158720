import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type SupplierTripListItem = ResultOf<
  typeof supplierTripListQuery
>["supplierTrips"]["data"][number];
export const supplierTripListQuery = graphql(`
  query SupplierTripList(
    $first: Int!
    $page: Int
    $where: QuerySupplierTripsWhereWhereConditions
    $orderBy: [QuerySupplierTripsOrderByOrderByClause!]
  ) {
    supplierTrips(
      page: $page
      first: $first
      where: $where
      orderBy: $orderBy
    ) {
      data {
        id
        distance_allowed
        hire_units_allowed
        minimum_hire_units
        extra_hire_units_allowed
        is_ferry_required
        inclusions {
          id
          type
          value
          description
        }
        supplier {
          id
          name
          measurement
          hire_unit_type
          currency
        }
        officeA {
          id
          name
          address {
            ...AddressFields
          }
          city {
            ...CityFields
          }
        }
        officeB {
          id
          name
          address {
            ...AddressFields
          }
          city {
            ...CityFields
          }
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
