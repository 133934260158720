import {
  GenericField,
  InputProps,
} from "@/lib/Components/Form/Fields/GenericField";
import { useState } from "react";
import {
  CountryCode,
  getCountries,
  getCountryCallingCode,
  ParseError,
  parsePhoneNumberFromString,
  parsePhoneNumberWithError,
} from "libphonenumber-js";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { useField } from "formik";
import { Region } from "@/gql/graphql";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

const regionCountryCodeMap: {
  [key in Region]: CountryCode;
} = {
  [Region.Au]: "AU",
  [Region.Eu]: "GB",
  [Region.Nz]: "NZ",
  [Region.Us]: "US",
  [Region.Ca]: "CA",
  [Region.Pi]: "FJ",
  [Region.Jp]: "JP",
  [Region.Sacu]: "ZA",
};

type PhoneInputProps = InputProps & {
  defaultRegion: Region;
};

export function PhoneInput({ defaultRegion, ...props }: PhoneInputProps) {
  const [field, meta, { setValue }] = useField<string | null>(props.name);
  const { initialValue } = meta;

  const parsedNumber = parsePhoneNumberFromString(initialValue ?? "", {
    defaultCountry: regionCountryCodeMap[defaultRegion],
  });

  const [country, setCountry] = useState<CountryCode>(
    parsedNumber?.country || regionCountryCodeMap[defaultRegion],
  );

  const showError = meta.touched && !!meta.error;

  return (
    <GenericField {...props} viewNode={<span>{field.value}</span>}>
      <div className="relative mt-1 w-full rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 flex items-center">
          <label htmlFor="country" className="sr-only">
            Country
          </label>
          <select
            name="country"
            autoComplete="country"
            className="h-full rounded-md border-transparent bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={country}
            onChange={(e) => setCountry(e.target.value as CountryCode)}
          >
            {getCountries().map((c) => (
              <option
                key={c}
                value={c}
                title={`(+${getCountryCallingCode(c)})`}
              >
                {c}
              </option>
            ))}
          </select>
        </div>
        <input
          {...field}
          value={field.value ?? ""}
          type="text"
          className={cn(
            "block w-full rounded-md border-gray-300 pl-[4.5rem] focus:outline-none sm:text-sm",
            showError
              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500"
              : "focus:border-indigo-500 focus:ring-indigo-500",
          )}
          onBlur={(e) => {
            try {
              const phoneNumber = parsePhoneNumberWithError(
                field.value ?? "",
                country,
              );

              setValue(phoneNumber.formatInternational());
              field.onBlur(e);

              return;
            } catch (error) {
              field.onBlur(e);
              return;
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              try {
                const phoneNumber = parsePhoneNumberWithError(
                  field.value ?? "",
                  country,
                );

                setValue(phoneNumber.formatInternational());
              } catch (e) {}
            }
          }}
        />
        {showError && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <InfoBox country={country} number={field.value} />
          </div>
        )}
      </div>
    </GenericField>
  );
}

export type PhoneNumberParseErrorType =
  | "INVALID_COUNTRY"
  | "NOT_A_NUMBER"
  | "TOO_SHORT"
  | "TOO_LONG";

function InfoBox({
  number,
  country,
}: {
  country: CountryCode;
  number: string | null;
}) {
  if (!number) return null;

  try {
    const phoneNumber = parsePhoneNumberWithError(number, country);

    if (phoneNumber.isValid()) {
      return (
        <Tooltip>
          <TooltipTrigger>
            <CheckCircleIcon
              className="h-5 w-5 text-green-500"
              aria-hidden="true"
            />
            <TooltipContent className="rounded-xl bg-gray-700 px-4 py-2 text-xs text-gray-50">
              <p>
                <span className="font-semibold text-gray-300">Country:</span>{" "}
                {phoneNumber.country}
              </p>
              <p>
                <span className="font-semibold text-gray-300">
                  International:
                </span>{" "}
                {phoneNumber.formatInternational()}
              </p>
              <p>
                <span className="font-semibold text-gray-300">National:</span>{" "}
                {phoneNumber.formatNational()}
              </p>
            </TooltipContent>
          </TooltipTrigger>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip>
          <TooltipTrigger>
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
            <TooltipContent className="rounded-xl bg-gray-700 px-4 py-2 text-xs text-gray-50">
              Phone number is invalid
            </TooltipContent>
          </TooltipTrigger>
        </Tooltip>
      );
    }
  } catch (error) {
    if (error instanceof ParseError) {
      // Not a phone number, non-existent country, etc.
      const message = error.message as PhoneNumberParseErrorType;

      if (message === "TOO_SHORT") {
        return (
          <Tooltip>
            <TooltipTrigger>
              <InformationCircleIcon
                className="h-5 w-5 text-yellow-500"
                aria-hidden="true"
              />
            </TooltipTrigger>
            <TooltipContent className="rounded-xl bg-gray-700 px-4 py-2 text-xs text-gray-50">
              Phone number is invalid
            </TooltipContent>
          </Tooltip>
        );
      }

      return (
        <Tooltip>
          <TooltipTrigger>
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </TooltipTrigger>
          <TooltipContent className="rounded-xl bg-gray-700 px-4 py-2 text-xs text-gray-50">
            {message}
          </TooltipContent>
        </Tooltip>
      );
    } else {
      throw error;
    }
  }
}
