import { RelocationForm } from "@/app/Relocations/Forms/RelocationForm";
import { RecordScreenFormCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenFormCard";
import { UpdateRelocationInput } from "@/gql/graphql";
import { relocationValidation } from "@/app/Relocations/Validation/relocationValidation";
import { RelocationStats } from "@/app/Relocations/Components/RelocationStats";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateRelocationMutation } from "@/app/Relocations/GraphQL/relocationMutations";
import { useRelocationRecord } from "@/app/Relocations/Hooks/useRelocationRecord";
import { getRelocationUpdateInput } from "@/app/Relocations/Utils/getRelocationUpdateInput";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { useConfirmDeleteDialog } from "@/lib/Components/Dialog/Hooks/useConfirmDeleteDialog";
import { BookingTable } from "@/app/Dashboard/Components/BookingTable";
import { useQueryClient } from "@tanstack/react-query";

export function RelocationDetailsScreen() {
  const queryClient = useQueryClient();
  const { mutateAsync } = useGqlMutation(updateRelocationMutation, {
    onSuccess: () => {
      return queryClient.invalidateQueries();
    },
  });
  const record = useRelocationRecord();
  const { openAsPromise } = useConfirmDeleteDialog();

  return (
    <div className="space-y-12">
      <RelocationStats />
      <RecordScreenFormCard<UpdateRelocationInput>
        onSubmit={async (values, formikHelpers) => {
          if (record.bookedBookingsCount) {
            try {
              await openAsPromise({
                title: "Are you sure you want to update this relocation?",
                onDelete: async () => {
                  await mutateAsync({
                    id: record.id,
                    input: values,
                  });
                },
                buttonText: "Update",
                message: `This relocation has ${record.bookedBookingsCount} bookings associated with it. Updating the relocation will affect the bookings associated with it.  Are you sure you want to update it?`,
                children: (
                  <div className="mt-3">
                    <BookingTable
                      id="dashboard-pending-bookings"
                      queryVariables={{
                        relocationId: record.id,
                      }}
                    />
                  </div>
                ),
              });
            } catch (e: any) {
              console.log("rejected", e);
              formikHelpers.resetForm();
              formikHelpers.setSubmitting(false);
            }
          } else {
            await mutateAsync({
              id: record.id,
              input: values,
            });
          }
        }}
        validationSchema={toFormikValidationSchema(relocationValidation)}
        enableReinitialize={true}
        title="Details"
        initialValues={getRelocationUpdateInput(record)}
      >
        <RelocationForm relocation={record} />
      </RecordScreenFormCard>
    </div>
  );
}
