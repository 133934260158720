import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { createMarketingEventValidation } from "@/app/Marketing/MarketingEvents/Validation/createMarketingEventValidation";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createMarketingEventMutation } from "@/app/Marketing/MarketingEvents/GraphQL/createMarketingEventMutation";
import { CreateMarketingEventInput } from "@/gql/graphql";
import { MarketingEventForm } from "@/app/Marketing/MarketingEvents/Forms/MarketingEventForm";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";

export function CreateMarketingEventDialog() {
  const { mutateAsync } = useGqlMutation(createMarketingEventMutation);
  const { close } = useDialogState();

  return (
    <GenericDialog title="Create marketing event">
      <GenericForm<CreateMarketingEventInput>
        initialValues={{
          name: "",
          days_after_trigger: 0,
          trigger: null!,
          description: "",
          mailchimp_customer_journey_id: "",
          mailchimp_customer_journey_step_id: "",
          conditions: {
            upsert: [],
            delete: [],
          },
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            input: values,
          });

          close();
        }}
        validationSchema={toFormikValidationSchema(
          createMarketingEventValidation,
        )}
      >
        <MarketingEventForm />

        <DialogSubmitButton text={"Add event"} />
      </GenericForm>
    </GenericDialog>
  );
}
