import * as React from "react";
const Svg085CalendarColour = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 801.71 774.33", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("style", null, ".calendar-color-cls-4,.calendar-color-cls-5,.calendar-color-cls-6{fill-rule:evenodd}.calendar-color-cls-2{fill:none;stroke-linecap:round;stroke:#fff;stroke-linejoin:round;stroke-width:12.98px}.calendar-color-cls-4{fill:#e49d75}.calendar-color-cls-6{fill:#e0e0e0}")), /* @__PURE__ */ React.createElement("g", { id: "Layer_1-2", "data-name": "Layer 1" }, /* @__PURE__ */ React.createElement("path", { d: "M0 .29h801.71v540.38H0Z", style: {
  fill: "#f3f3f3",
  fillRule: "evenodd"
} }), /* @__PURE__ */ React.createElement("path", { d: "M454.16 112.56h94m-94 30.73h83.7", className: "calendar-color-cls-2" }), /* @__PURE__ */ React.createElement("path", { d: "M200.09 541.84V1.47m200.09 540.37V1.47m200.09 540.37V1.47", style: {
  fill: "none",
  strokeLinecap: "round",
  stroke: "#dfdfdf",
  strokeWidth: "2.93px"
} }), /* @__PURE__ */ React.createElement("path", { d: "m350.39 742.82 1.77 20.78 18.22.44-3.38-22Zm-166.03-37.05-8.46 28.56 8.64 9 15.33-25Z", className: "calendar-color-cls-4" }), /* @__PURE__ */ React.createElement("path", { d: "m349.06 758.93-.33 15.4 47.76-.29c.33.22.09-7.55.09-7.55l-28.42-7.78Zm-173.81-29.49-9.7 13.23 20.28 28.72 19.16.13-.26-9.7-7.68-3.4-6.81-16.89Z", className: "calendar-color-cls-5" }), /* @__PURE__ */ React.createElement("path", { d: "M367.05 146.61h93.3v93.3h-93.3Z", className: "calendar-color-cls-6" }), /* @__PURE__ */ React.createElement("path", { d: "M367.05 146.61h93.3v20.84h-93.3Z", style: {
  fillRule: "evenodd",
  fill: "#a4a4a4"
} }), /* @__PURE__ */ React.createElement("path", { d: "M259.73 257.67c4.86-1.07 22.16-.61 26.79-3.09s61.78-45.3 67.53-51.13 12.86-10.89 18-17.23.17 11.23-4.32 14.69c-.52 4.7-.65 14.54-.65 14.54a8.6 8.6 0 0 0-6.79 1.71c-3.24 2.54-50.1 57.69-50.1 57.69s-55.32-16.1-50.46-17.18", className: "calendar-color-cls-4" }), /* @__PURE__ */ React.createElement("path", { d: "M217.28 422.43c-2.9 11-8.49 88 8.7 126.52-7.46 28.17-45.35 159.45-45.35 159.45l18.84 16.6 97.12-147s-20.22-160.81-79.31-155.57", style: {
  fillRule: "evenodd",
  fill: "#5fc5d1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M316.44 417.67C320.37 428.6 360.53 572 360.53 572l9.08 174.19-20.78 1.64-64.55-175.4c-7.88-8.93-57.94-128.79-60.19-144.17 9.84-10.46 88.42-21.53 92.35-10.59", style: {
  fillRule: "evenodd",
  fill: "#6bd5e1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M244.18 250.72c7.91 0 52.71 10.86 61.13 28.36 4.63 9.62 24.94 127.23 13.66 145.72s-89 17.7-104.16 3.76c-8.53-7.82-11.28-177.9 29.37-177.84", style: {
  fillRule: "evenodd",
  fill: "#ffd98e"
} }), /* @__PURE__ */ React.createElement("path", { d: "M0 .29h801.71v72.34H0Z", className: "calendar-color-cls-6" }), /* @__PURE__ */ React.createElement("path", { d: "M37.15 115h124.44", className: "calendar-color-cls-2" }), /* @__PURE__ */ React.createElement("path", { d: "M234.26 230.7h17.16V264c-6.17 4.91-17.16 0-17.16 0Z", className: "calendar-color-cls-4" }), /* @__PURE__ */ React.createElement("path", { d: "M212.31 200.6c0-20.75 14.09-37.57 31.48-37.57s31.47 16.82 31.47 37.57-14.09 37.58-31.47 37.58-31.48-16.83-31.48-37.58", className: "calendar-color-cls-4" }), /* @__PURE__ */ React.createElement("path", { d: "M240 156.85c10.08-.23 22.89 6.18 22.89 10.63 0 7.7-11.94 25.16-20.83 32.32 2.49 5.75 4.77 9.55 4.77 14.43s-7.59 24-7.59 37c0 10.2 2.28 17.14 2.28 25.6 0 23.11-15.08 37.1-36.89 37.1s-36.46-23.65-36.55-42.85c-.11-23.54 26-52.43 32.33-70.18 5.33-14.98 15.42-44.05 39.59-44.05", className: "calendar-color-cls-5" }), /* @__PURE__ */ React.createElement("path", { d: "M233.34 202.54a7.88 7.88 0 1 1 7.87 7.88 7.87 7.87 0 0 1-7.87-7.88m84.77 112.11c8.84 2.1 45.05 8.17 52 6s78.55-70.45 84.13-75.5 16.78-12.2 18.13-20.94 0-16.22-12.14-20.6c.23 5.64.45 14.44.21 13.13-2.05-6.8-2.29-14.51-6-14.42-2 6.36.82 18.35-4.1 26.38-5.65 6.14-80.69 55.38-88.57 57.87-10.28 0-36.64-9-36.64-9s-15.85 34.98-7.02 37.08", className: "calendar-color-cls-4" }), /* @__PURE__ */ React.createElement("path", { d: "M259.34 279.38c6.12-25.7 59.81-12.11 68.84-4.9 3.16 9.79-1.81 38.83-9.7 44.22-11.85 1.72-65.66-11.98-59.14-39.32", style: {
  fillRule: "evenodd",
  fill: "#ffe4ad"
} }), /* @__PURE__ */ React.createElement("path", { d: "M37.15 169.71h94m-94 93.99H88.1m-50.95 35.46h94m-94 30.73h83.7m513.96-219.25h124.44m-124.44 34.09h87m-87 36.27h116.86m-116.86 33h58.9", className: "calendar-color-cls-2" }), /* @__PURE__ */ React.createElement("path", { d: "M397 188.69h-.12l-6.26 3.31-.94-3.68 7.82-4.18h4.12v35.76H397Zm40.89-4.51v3.19l-15.58 32.57h-5l15.51-31.64v-.11h-17.49v-4Z", style: {
  fill: "#a4a4a4"
} })));
export default Svg085CalendarColour;
