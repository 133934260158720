import { GenericField, InputProps } from "../Fields/GenericField";
import { toDateTimeString } from "@/lib/Formatters/toDateTimeString";
import dayjs from "dayjs";
import { useField } from "formik";
import { cn } from "@/lib/utils";
import { ComponentPropsWithoutRef } from "react";

export type DateTimeInputProps = InputProps & {
  inputProps?: ComponentPropsWithoutRef<"input">;
};

export function DateTimeInput({ inputProps, ...props }: DateTimeInputProps) {
  const [field, meta, helpers] = useField(props.name);
  const { value } = field;
  const { setValue } = helpers;

  const showError = !!meta.error && meta.touched;

  return (
    <GenericField viewNode={<span>{value}</span>} {...props}>
      <input
        {...field}
        {...inputProps}
        value={dayjs(value).format("YYYY-MM-DDTHH:mm")}
        onChange={(event) => {
          setValue(toDateTimeString(event.target.value));
        }}
        type={"datetime-local"}
        className={cn(
          showError
            ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
            : "focus:border-indigo-500 focus:ring-indigo-500",
          "mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm",
        )}
      />
    </GenericField>
  );
}
