import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { CreateSupplierOfficeInput } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { SupplierOfficeForm } from "@/app/Offices/Forms/SupplierOfficeForm";
import { officeValidation } from "@/app/Offices/Validators/officeValidation";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createSupplierOfficeMutation } from "@/app/Offices/GraphQL/supplierOfficeMutations";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { SupplierOfficeRecord } from "@/app/Offices/GraphQL/supplierOfficeRecordQuery";

type OfficeCreateDialogProps = {
  initialValues?: Partial<CreateSupplierOfficeInput>;
};
export function OfficeCreateDialog({ initialValues }: OfficeCreateDialogProps) {
  const { supplierId } = useGlobalSupplier();
  const { mutateAsync } = useGqlMutation(createSupplierOfficeMutation);
  const { close, resolve } = useDialogState<SupplierOfficeRecord>();

  return (
    <GenericDialog title="Add office">
      <GenericForm<CreateSupplierOfficeInput>
        validationSchema={officeValidation}
        onSubmit={async (values) => {
          const res = await mutateAsync({
            input: values,
          });

          resolve?.(res.createSupplierOffice);

          close();
        }}
        initialValues={{
          name: "",
          email: "",
          phone: "",
          code: "",
          pickup_instructions: "",
          city: {
            connect: null!,
          },
          address: {
            upsert: {
              name: "",
              address_line_1: "",
              address_line_2: "",
              city: "",
              state: "",
              country: "",
              postcode: "",
              lat: null,
              lng: null,
              place_id: "",
            },
          },
          supplier: {
            connect: supplierId ?? null!,
          },
          ...initialValues,
        }}
      >
        <SupplierOfficeForm />
        <DialogSubmitButton text={"Add office"} />
      </GenericForm>
    </GenericDialog>
  );
}
