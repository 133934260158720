import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/de";
import "dayjs/locale/it";

const LONG_FORMAT = "DD MMM YY";
const SHORT_FORMAT = "DD MMM";

export function formatDate(date: undefined | string | null | Dayjs | Date) {
  const FORMAT = "DD MMM YYYY";

  if (!date) {
    return "--";
  }

  const obj = dayjs(date);

  if (obj.isSame(dayjs(), "year")) {
    return obj.format("DD MMM");
  }

  return obj.format(FORMAT);
}

export function formatLocalDate(date: string | Dayjs | Date) {
  if (date === null) {
    return null;
  }

  const obj = dayjs(date, {
    utc: true,
  }).local();

  if (obj.isSame(dayjs(), "year")) {
    return obj.format(SHORT_FORMAT);
  }

  return obj.format(LONG_FORMAT);
}
