import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export const quoteListQuery = graphql(`
  query QuoteList($page: Int!, $first: Int!) {
    quotes(page: $page, first: $first) {
      data {
        id
        uuid
        reference
        status
        amount_inc_tax
        currency
        expiry_date
        created_at
        payee {
          ...ContactRecordFields
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);

export type QuoteListItem = ResultOf<
  typeof quoteListQuery
>["quotes"]["data"][number];
