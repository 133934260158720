import { AdminDashboardStatBlocks } from "@/app/Dashboard/Components/AdminDashboardStatBlocks";
import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { BookingTable } from "@/app/Dashboard/Components/BookingTable";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { BookingStatus } from "@/gql/graphql";
import { OrganisationDashboardStatBlocks } from "@/app/Dashboard/Components/OrganisationDashboardStatBlocks";
import { DashboardTaskList } from "@/app/Dashboard/Components/DashboardTaskList";
import { Suspense } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { metaQuery } from "@/app/Meta/GraphQL/metaQuery";
import { RelocationMultiTable } from "@/app/Relocations/Components/RelocationMultiTable";
import { useMatchRoute, useNavigate, useSearch } from "@tanstack/react-router";
import { DashboardPendingPaymentRequests } from "@/app/Dashboard/Components/DashboardPendingPaymentRequests";
import { DashboardPendingChangeRequests } from "@/app/Dashboard/Components/DashboardPendingChangeRequests";

export function DashboardScreen() {
  const { isAdmin } = useTenant();
  const { hasSupplier, supplierId } = useGlobalSupplier();
  const { data } = useSuspenseGqlQuery(metaQuery, {});
  const { relocationStatus } = useSearch({
    from: "/_app/",
  });

  const navigate = useNavigate();
  const matchRoute = useMatchRoute();

  return (
    <FullPageScreen className="space-y-12 p-4">
      {isAdmin && !hasSupplier ? (
        <Suspense fallback={<Skeleton className="h-[592px] w-full" />}>
          <AdminDashboardStatBlocks />
        </Suspense>
      ) : (
        <OrganisationDashboardStatBlocks />
      )}

      {data.meta.pending_payment_request_count ? (
        <DashboardPendingPaymentRequests />
      ) : null}
      {data.meta.pending_change_request_count ? (
        <DashboardPendingChangeRequests />
      ) : null}
      {data.meta.pending_task_count ? <DashboardTaskList /> : null}

      <BookingTable
        id="dashboard-pending-bookings"
        title="Pending bookings"
        defaultEnabledStatuses={[
          BookingStatus.AwaitingConfirmation,
          BookingStatus.Accepted,
          BookingStatus.Vip,
        ]}
      />

      <RelocationMultiTable
        tableId={"dashboard-relocations"}
        isRouteMatched={(status) => {
          return !!matchRoute({
            to: "/",
            search: {
              relocationStatus: status,
            },
            includeSearch: true,
          });
        }}
        supplierId={supplierId ?? undefined}
        statuses={relocationStatus}
        onStatusChange={(newStatuses) => {
          navigate({
            to: "/",
            search: {
              relocationStatus: newStatuses,
            },
          });
        }}
      />
    </FullPageScreen>
  );
}
