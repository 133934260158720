import { ButtonMenu } from "@/lib/Components/Menu/ButtonMenu";
import { MultiActionGroupButtonType } from "@/lib/Components/Actions/ActionGroupButtons";

export function MultiActionGroupButton({
  items,
  label,
}: MultiActionGroupButtonType) {
  const filteredItems = items.filter((item) => {
    return item.actions.filter((action) => !action.isHidden).length > 0;
  });

  return <ButtonMenu label={label} actionGroups={filteredItems} />;
}
