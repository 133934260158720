import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { RelocationInclusionType } from "@/gql/graphql";
import { SelectOption } from "@/lib/Components/Form/Components/Select";

export function RelocationInclusionTypeInput(
  props: Omit<SelectInputProps<any>, "options">,
) {
  const options: {
    [key in RelocationInclusionType]: SelectOption;
  } = {
    [RelocationInclusionType.Negative]: {
      label: "Negative",
      value: RelocationInclusionType.Negative,
    },
    [RelocationInclusionType.Charge]: {
      label: "Charge",
      value: RelocationInclusionType.Charge,
    },
    [RelocationInclusionType.Ferry]: {
      value: RelocationInclusionType.Ferry,
      label: "Ferry",
    },
    [RelocationInclusionType.Fuel]: {
      value: RelocationInclusionType.Fuel,
      label: "Fuel",
    },
    [RelocationInclusionType.Other]: {
      value: RelocationInclusionType.Other,
      label: "Other",
    },
  };

  return (
    <SelectInput
      {...props}
      options={[
        {
          label: "positive",
          options: [
            options[RelocationInclusionType.Ferry],
            options[RelocationInclusionType.Fuel],
            options[RelocationInclusionType.Other],
          ],
        },
        {
          label: "negative",
          options: [
            options[RelocationInclusionType.Negative],
            options[RelocationInclusionType.Charge],
          ],
        },
      ]}
    />
  );
}
