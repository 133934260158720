import { InformationCircleIcon } from "@heroicons/react/20/solid";
import {
  Currency,
  DepositRefundedAt,
  RelocationInclusionType,
} from "@/gql/graphql";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useTranslations } from "use-intl";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { BookingRecord } from "@/app/Bookings/GraphQL/bookingRecordQuery";

export function PaymentSummarySection({ booking }: { booking: BookingRecord }) {
  const relocation = booking.relocation;
  const t = useTranslations("relocation");

  const hireUnitLabel = t(
    `hire_unit_type.${relocation.hire_unit_type}` as const,
  );

  const charges = relocation.inclusions.filter(
    (i) => i.type === RelocationInclusionType.Charge,
  );

  return (
    <div className="relative w-full rounded-md bg-gray-50 p-6">
      <ul>
        <li className="flex justify-between">
          <span className="font-semibold">Relocation {hireUnitLabel}s</span>
          <span>
            {booking.discounted_units} x{" "}
            {formatCurrency(relocation.hire_unit_rate, relocation.currency)}
          </span>
        </li>
        {booking.full_price_units > 0 ? (
          <li className="flex justify-between">
            <span className="font-semibold">Extra {hireUnitLabel}s</span>
            <span>
              {booking.full_price_units} x{" "}
              {formatCurrency(
                relocation.extra_hire_unit_rate!,
                relocation.currency,
              )}
            </span>
          </li>
        ) : null}
        {booking.pay_now_booking_fee_amount > 0 ? (
          <li className="flex justify-between">
            <span className="font-semibold">Booking fee</span>
            <span>
              {formatCurrency(
                relocation.booking_fee_amount,
                relocation.currency,
              )}
            </span>
          </li>
        ) : null}
        {booking.pay_now_holding_deposit_amount > 0 ? (
          <li className="flex justify-between">
            <span className="font-semibold">Holding deposit</span>
            <span>
              <Popover>
                <PopoverTrigger type="button">
                  <InformationCircleIcon className="mr-2 inline h-4 w-4 text-gray-400" />
                  {formatCurrency(
                    booking.pay_now_holding_deposit_amount,
                    relocation.currency,
                  )}
                </PopoverTrigger>
                <PopoverContent
                  side="bottom"
                  align="end"
                  className="text-xs text-gray-500"
                >
                  This is a deposit paid to Imoova to secure your booking. It
                  will be refunded to you when you{" "}
                  {`${
                    relocation.supplier.holding_deposit_refunded_at ===
                    DepositRefundedAt.Departure
                      ? "pickup"
                      : "drop-off"
                  }`}{" "}
                  the vehicle.
                </PopoverContent>
              </Popover>
            </span>
          </li>
        ) : null}

        {booking.relocation.inclusions
          .filter((i) => i.type === RelocationInclusionType.Charge)
          .map((charge) => {
            return (
              <li key={charge.id} className="flex justify-between capitalize">
                <div
                  className="font-semibold"
                  dangerouslySetInnerHTML={{
                    __html: charge.description ?? "--",
                  }}
                />
                <span>{formatCurrency(charge.value, relocation.currency)}</span>
              </li>
            );
          })}

        {booking.purchasableExtras.map((extra) => {
          return (
            <li key={extra.id} className="flex justify-between capitalize">
              <span className="font-semibold">
                {extra.supplierPurchasableExtra.name} x {extra.quantity}
              </span>
              <span>
                {formatCurrency(
                  extra.supplierPurchasableExtra.unit_price * extra.quantity,
                  relocation.currency,
                )}
              </span>
            </li>
          );
        })}

        <li className="mt-2 flex justify-between border-t border-gray-200 pt-2">
          <span className="font-semibold">Pay now:</span>
          <span>
            <Popover>
              <PopoverTrigger type="button">
                <InformationCircleIcon className="mr-2 inline h-4 w-4 text-gray-400" />
                {formatCurrency(
                  booking.pay_now_total_amount,
                  relocation.currency,
                )}
              </PopoverTrigger>
              <PopoverContent side="bottom" align="end">
                <ul className="w-60">
                  <TooltipLineItem
                    amount={booking.pay_now_full_price_units_deposit_amount}
                    currency={relocation.currency}
                    label={`Extra ${hireUnitLabel} deposit`}
                  />

                  <TooltipLineItem
                    amount={booking.pay_now_booking_fee_amount}
                    currency={relocation.currency}
                    label={`Booking fee`}
                  />
                  <TooltipLineItem
                    amount={booking.pay_now_holding_deposit_amount}
                    currency={relocation.currency}
                    label={`Holding deposit (refunded by ${booking.relocation.holding_deposit_refunded_by})`}
                  />
                </ul>
              </PopoverContent>
            </Popover>
          </span>
        </li>
        <li className="flex justify-between">
          <span className="font-semibold">Pay later:</span>
          <span>
            <Popover>
              <PopoverTrigger type="button">
                <InformationCircleIcon className="mr-2 inline h-4 w-4 text-gray-400" />
                {formatCurrency(
                  booking.pay_later_total_amount,
                  relocation.currency,
                )}
              </PopoverTrigger>
              <PopoverContent side="bottom" align="end">
                <ul className="w-60">
                  <TooltipLineItem
                    amount={booking.pay_later_discounted_amount}
                    currency={relocation.currency}
                    label={`Relocation ${hireUnitLabel}s`}
                  />
                  <TooltipLineItem
                    amount={booking.pay_later_full_price_amount}
                    currency={relocation.currency}
                    label={`Extra ${hireUnitLabel}s`}
                  />

                  {charges.map((charge) => {
                    return (
                      <TooltipLineItem
                        key={charge.id}
                        amount={charge.value!}
                        currency={relocation.currency}
                        label={charge.description!}
                      />
                    );
                  })}

                  {booking.purchasableExtras.map((extra) => {
                    return (
                      <TooltipLineItem
                        key={extra.id}
                        amount={
                          extra.quantity *
                          extra.supplierPurchasableExtra.unit_price
                        }
                        currency={relocation.currency}
                        label={extra.supplierPurchasableExtra.name}
                      />
                    );
                  })}

                  <TooltipLineItem
                    amount={-booking.supplier_refund_amount}
                    currency={relocation.currency}
                    label="Holding deposit"
                  />
                </ul>
              </PopoverContent>
            </Popover>
          </span>
        </li>
        <li className="mt-2 flex justify-between border-t border-gray-200 pt-2">
          <span className="font-bold">Total cost:</span>
          <span>
            {formatCurrency(booking.total_cost_amount, relocation.currency)}
          </span>
        </li>
      </ul>
    </div>
  );
}

function TooltipLineItem({
  amount,
  label,
  currency,
}: {
  label?: string;
  amount: number;
  currency: Currency;
}) {
  if (amount === 0 || Number.isNaN(amount)) return null;

  return (
    <li className="flex items-center justify-between">
      <div
        className="label text-xs text-gray-500"
        dangerouslySetInnerHTML={{
          __html: label ?? "--",
        }}
      />
      <span>{formatCurrency(amount, currency)}</span>
    </li>
  );
}
