import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { graphql } from "@/gql";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import {
  CreateSupplierPurchasableExtraInput,
  Currency,
  Supplier,
} from "@/gql/graphql";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";
import { supplierPurchasableExtraValidation } from "@/app/Suppliers/Validation/supplierPurchasableExtraValidation";
import { toFormikValidate } from "zod-formik-adapter";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";

const createPurchasableExtrasQuery = graphql(`
  mutation CreatePurchasableExtra(
    $input: CreateSupplierPurchasableExtraInput!
  ) {
    createSupplierPurchasableExtra(input: $input) {
      id
      name
    }
  }
`);

export function SupplierPurchasableExtraCreateDialog({
  supplier,
}: {
  supplier: Pick<Supplier, "currency" | "id">;
}) {
  const { close } = useDialogState();
  const { mutateAsync } = useGqlMutation(createPurchasableExtrasQuery);

  return (
    <GenericDialog title="Add purchasable extra">
      <GenericForm<CreateSupplierPurchasableExtraInput>
        validate={toFormikValidate(supplierPurchasableExtraValidation)}
        initialValues={{
          name: "",
          description: "",
          supplier: {
            connect: supplier.id,
          },
          units_purchasable: 1,
          unit_price: null!,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            input: values,
          });
          close();
        }}
      >
        <SupplierPurchasableExtraForm currency={supplier.currency} />
        <DialogSubmitButton text={"Add purchasable extra"} />
      </GenericForm>
    </GenericDialog>
  );
}

export function SupplierPurchasableExtraForm({
  currency,
}: {
  currency: Currency;
}) {
  return (
    <>
      <TextInput name="name" label="Name" />
      <NumberInput
        name="units_purchasable"
        label="Units purchasable"
        inputProps={{
          min: 0,
        }}
      />

      <CurrencyInput currency={currency} name="unit_price" label="Unit price" />
      <TextAreaInput
        name={"description"}
        label={"Description"}
        className="col-span-full"
      />
    </>
  );
}
