import { graphql } from "@/gql";

export const organisationDashboardStatsQuery = graphql(`
  query OrganisationDashboardStats(
    $start: DateTime!
    $end: DateTime!
    $supplierId: ID
  ) {
    organisationDashboardStats(
      start: $start
      end: $end
      supplierId: $supplierId
    ) {
      thisPeriod {
        expired_relocations_count
        average_minutes_to_confirmation
        active_relocations_count
        pending_bookings_count
        confirmed_bookings_count
      }
      lastPeriod {
        expired_relocations_count
        average_minutes_to_confirmation
        active_relocations_count
        pending_bookings_count
        confirmed_bookings_count
      }
    }
  }
`);
