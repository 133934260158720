import { RelocationInclusionType } from "@/gql/graphql";
import { FC } from "react";
import { DollarSignIcon, FuelIcon, ShipIcon } from "lucide-react";
import { ExclamationTriangleIcon, StarIcon } from "@heroicons/react/20/solid";

export const inclusionIconMap: {
  [key in RelocationInclusionType]: FC<any>;
} = {
  [RelocationInclusionType.Charge]: DollarSignIcon,
  [RelocationInclusionType.Fuel]: FuelIcon,
  [RelocationInclusionType.Ferry]: ShipIcon,
  [RelocationInclusionType.Negative]: ExclamationTriangleIcon,
  [RelocationInclusionType.Other]: StarIcon,
};
