import { RelocationTable } from "@/app/Relocations/Components/RelocationTable";
import { RelocationStatus } from "@/gql/graphql";
import { TableId } from "@/app/Common/Utils/tableIds";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { RelocationCreateDialog } from "@/app/Relocations/Components/RelocationCreateDialog";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { relocationStatusCountsQuery } from "@/app/Relocations/GraphQL/relocationStatusCountsQuery";
import { Button } from "@/lib/Components/Button/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import {
  Navbar,
  NavbarItem,
  NavbarSection,
} from "@/components/catalyst/navbar";
import { Badge, BadgeColor } from "@/components/catalyst/badge";
import { ReactNode } from "react";

export function RelocationMultiTable({
  supplierId,
  statuses,
  onStatusChange,
  tableId,
  isRouteMatched,
}: {
  supplierId?: string;
  statuses: RelocationStatus[] | undefined;
  onStatusChange: (newStatuses: RelocationStatus[]) => void;
  isRouteMatched: (routeStatuses: RelocationStatus[]) => boolean;
  tableId: TableId;
}) {
  return (
    <div>
      <Header
        statuses={statuses}
        supplierId={supplierId}
        onStatusChange={onStatusChange}
        isRouteMatched={isRouteMatched}
      />
      <RelocationTable
        id={tableId}
        statuses={statuses}
        onStatusChange={onStatusChange}
        queryVariables={{
          supplier_id: supplierId,
        }}
      />
    </div>
  );
}

export function Header({
  supplierId,
  onStatusChange,
  isRouteMatched,
  statuses,
}: {
  supplierId?: string;
  onStatusChange: (status: RelocationStatus[]) => void;
  isRouteMatched: (routeStatuses: RelocationStatus[]) => boolean;
  statuses: RelocationStatus[] | undefined;
}) {
  const { open } = useDialog(RelocationCreateDialog);
  const { data } = useGqlQuery(relocationStatusCountsQuery, {
    supplierId: supplierId ?? undefined,
  });

  return (
    <div className="relative border-gray-200 pb-5 sm:border-b sm:pb-0">
      <div className="md:flex md:items-center md:justify-between">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Relocations
        </h3>
        <div className="absolute right-0 top-0 flex">
          <Button
            LeadingIcon={PlusIcon}
            onClick={() => {
              open({
                initialSupplierId: supplierId,
              });
            }}
          >
            Add relocation
          </Button>
        </div>
      </div>

      <Navbar className="-mb-px">
        <NavbarSection className="max-lg:hidden">
          {filters.map(({ label, statuses, hideBadge, badgeColor }, idx) => {
            const count = statuses.reduce((acc, status) => {
              return (
                acc +
                (data?.relocationStatusCounts.find(
                  (counts) => counts.status === status,
                )?.count ?? 0)
              );
            }, 0);

            return (
              <NavbarItem
                key={idx}
                onClick={() => {
                  onStatusChange?.(statuses);
                }}
                current={isRouteMatched(statuses)}
              >
                {label}
                {count && !hideBadge ? (
                  <Badge className="py-0" color={badgeColor}>
                    {count}
                  </Badge>
                ) : null}
              </NavbarItem>
            );
          })}
        </NavbarSection>
      </Navbar>
    </div>
  );
}

type Filter = {
  label: ReactNode;
  statuses: RelocationStatus[];
  hideBadge?: boolean;
  badgeColor?: BadgeColor;
};

const filters: Filter[] = [
  {
    label: "Active",
    statuses: [RelocationStatus.Ready],
    badgeColor: "green",
  },
  {
    label: "Paused",
    statuses: [RelocationStatus.Paused],
    badgeColor: "zinc",
  },
  {
    label: "Sold out",
    statuses: [RelocationStatus.SoldOut],
    badgeColor: "yellow",
  },
  {
    label: "Expired",
    statuses: [RelocationStatus.Expired],
    badgeColor: "red",
  },

  {
    label: "Draft",
    statuses: [RelocationStatus.Draft],
  },
  {
    label: "Archived",
    statuses: [RelocationStatus.Archived],
    hideBadge: true,
  },
];
