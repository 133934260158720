export const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
] as const;

export type DayType = (typeof days)[number];

export type OpenHours = {
  [key in DayType]: string[];
};
