import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { QuoteActionFieldsFragment, QuoteStatus } from "@/gql/graphql";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SendQuoteDialog } from "@/app/Quotes/Components/Dialogs/SendQuoteDialog";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { PDFIcon } from "@/assets/Icons";
import { PreviewPdfDialog } from "@/lib/Components/Dialog/PreviewPdfDialog";
import { EnvelopeIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import {
  createQuotePdfMutation,
  issueQuoteMutation,
} from "@/app/Quotes/GraphQL/quoteMutations";
import { ThumbsDownIcon, ThumbsUpIcon } from "lucide-react";

export function useQuoteActions(): ActionsHook<QuoteActionFieldsFragment> {
  const { open } = useDialog(SendQuoteDialog);
  const { mutateAsync } = useGqlMutation(createQuotePdfMutation);
  const { mutateAsync: issueQuote } = useGqlMutation(issueQuoteMutation);
  const { open: openPdf } = useDialog(PreviewPdfDialog);

  return (quote) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Accept",
                Icon: ThumbsUpIcon,
                intent: "success",
                isHidden: quote.status !== QuoteStatus.Issued,
                handleAction: async () => {
                  alert("TODO");
                },
              },
              {
                label: "Declined",
                Icon: ThumbsDownIcon,
                intent: "danger",
                isHidden: quote.status !== QuoteStatus.Issued,
                handleAction: () => {
                  alert("TODO");
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Issue",
                Icon: PaperAirplaneIcon,
                isAdminAction: true,
                isHidden: quote.status !== QuoteStatus.Draft,
                handleAction: async () => {
                  await issueQuote({
                    id: quote.id,
                  });
                },
              },
              {
                label: "Send",
                Icon: EnvelopeIcon,
                isHidden: quote.status === QuoteStatus.Draft,
                handleAction: () => {
                  open({
                    quoteId: quote.id,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                intent: "neutral",
                label: "View",
                Icon: ArrowTopRightOnSquareIcon,
                handleAction: () => {
                  window.open(
                    new URL(
                      `/en/quotes/${quote.uuid}`,
                      import.meta.env.VITE_APP_URL,
                    ),
                    "_blank",
                  );
                },
              },
            ],
          },

          {
            actions: [
              {
                label: "Pdf",
                Icon: PDFIcon,
                intent: "neutral",
                handleAction: async () => {
                  const url = await mutateAsync({
                    id: quote.id,
                  });
                  openPdf({ url: url.createQuotePdf });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
