import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { VehicleApproximateAge } from "@/gql/graphql";
import { SelectOption } from "@/lib/Components/Form/Components/Select";

export function VehicleAgeInput(props: Omit<SelectInputProps<any>, "options">) {
  const options: {
    [key in VehicleApproximateAge]: SelectOption;
  } = {
    [VehicleApproximateAge.New]: {
      value: VehicleApproximateAge.New,
      label: "New",
    },
    [VehicleApproximateAge.LessThanThreeYears]: {
      value: VehicleApproximateAge.LessThanThreeYears,
      label: "<3 years",
    },
    [VehicleApproximateAge.LessThanFiveYears]: {
      value: VehicleApproximateAge.LessThanFiveYears,
      label: "<5 years",
    },
    [VehicleApproximateAge.LessThanTenYears]: {
      value: VehicleApproximateAge.LessThanTenYears,
      label: "<10 years",
    },
    [VehicleApproximateAge.LessThanTwentyYears]: {
      value: VehicleApproximateAge.LessThanTwentyYears,
      label: "<20 years",
    },
    [VehicleApproximateAge.MoreThanTwentyYears]: {
      value: VehicleApproximateAge.MoreThanTwentyYears,
      label: ">20 years",
    },
  };

  return (
    <SelectInput
      clearable
      options={[
        {
          label: "Approximate age",
          options: Object.values(options),
        },
      ]}
      {...props}
    />
  );
}
