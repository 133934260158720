import classNames from "classnames";
import {
  ArrowPathIcon,
  BanknotesIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";

import { formatDate } from "@/lib/Formatters/formatDate";
import { IconMenuActions } from "@/lib/Components/Actions/IconMenuActions";
import {
  InvoicePaymentFieldsFragment,
  InvoicePaymentMethod,
  InvoicePaymentType,
  InvoiceStatus,
} from "@/gql/graphql";
import { useInvoicePaymentActions } from "@/app/Invoices/Hooks/useInvoicePaymentActions";
import { useInvoiceRecord } from "@/app/Invoices/Hooks/useInvoiceRecord";

export function InvoicePaymentFeed() {
  const invoice = useInvoiceRecord();
  const getActions = useInvoicePaymentActions();

  const bgMap: {
    [key in InvoiceStatus]: string;
  } = {
    [InvoiceStatus.Voided]: "",
    [InvoiceStatus.Issued]: "bg-yellow-100",
    [InvoiceStatus.Paid]: "bg-green-50",
    [InvoiceStatus.Overdue]: "bg-red-50",
    [InvoiceStatus.Draft]: "bg-gray-50",
  };

  const stats = [
    {
      name: "Total amount due",
      stat: formatCurrency(invoice.amount_inc_tax, invoice.currency),
    },
    {
      name: "Amount paid",
      stat: formatCurrency(invoice.amount_paid, invoice.currency),
      subtitle:
        invoice.amount_refunded > 0
          ? `${formatCurrency(
              invoice.amount_refunded,
              invoice.currency,
            )} refunded`
          : undefined,
    },
    {
      name: "Amount remaining",
      stat: formatCurrency(invoice.amount_due, invoice.currency, {
        showZero: true,
      }),
      subtitle: invoice.due_date
        ? `due by ${formatDate(invoice.due_date)}`
        : undefined,
      bgColor: bgMap[invoice.status],
    },
  ];

  return (
    <>
      <div>
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
          {stats.map((item) => (
            <div
              key={item.name}
              className={classNames(
                "overflow-hidden rounded-lg bg-gray-50 px-4 py-5 shadow sm:p-6",
                item?.bgColor ?? "bg-gray-50",
              )}
            >
              <dt className="truncate text-sm font-medium text-gray-500">
                {item.name}
              </dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                {item.stat}
                {item.subtitle && (
                  <span className="ml-2 text-sm font-normal text-gray-500">
                    {item.subtitle}
                  </span>
                )}
              </dd>
            </div>
          ))}
        </dl>
      </div>
      <div className="mt-5 flow-root">
        <ul role="list" className="-mb-8">
          {invoice.invoicePayments.map((payment, index) => (
            <li key={payment.id}>
              <div className="relative pb-8">
                {index !== invoice.invoicePayments.length - 1 ? (
                  <span
                    className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div className="flex items-center justify-center">
                    <PaymentIcon payment={payment} />
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4">
                    <div>
                      <p className="space-x-2 text-sm">
                        <InvoicePaymentTag payment={payment} />
                        <span className="font-bold">{payment.reference}</span>
                        <span className="">{payment.description}</span>
                        {payment.method === InvoicePaymentMethod.Stripe && (
                          <span className="text-gray-500">(via stripe)</span>
                        )}
                      </p>
                      <p className="text-sm text-gray-500">
                        <time dateTime={payment.payment_date}>
                          {formatDate(payment.payment_date)}
                        </time>
                      </p>
                    </div>

                    <div className="flex flex-grow items-center justify-end whitespace-nowrap text-right text-sm text-gray-900">
                      {formatCurrency(payment.amount, invoice.currency)}

                      <IconMenuActions actions={getActions(payment)} />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

function PaymentIcon({ payment }: { payment: InvoicePaymentFieldsFragment }) {
  let Icon;

  if (payment.method === InvoicePaymentMethod.Stripe) {
    Icon = CurrencyDollarIcon;
  } else if (payment.type === InvoicePaymentType.Payment) {
    Icon = CurrencyDollarIcon;
  } else if (payment.type === InvoicePaymentType.Refund) {
    Icon = ArrowPathIcon;
  } else {
    Icon = BanknotesIcon;
  }

  return (
    <span
      className={classNames(
        "flex h-8 w-8 items-center justify-center rounded-full bg-white ring-8 ring-white",
      )}
    >
      <Icon className="h-5 w-5 text-gray-900" aria-hidden="true" />
    </span>
  );
}

export function InvoicePaymentTag({
  payment,
}: {
  payment: InvoicePaymentFieldsFragment;
}) {
  const colors: { [key in InvoicePaymentType]: string } = {
    [InvoicePaymentType.Payment]: "bg-green-100 text-green-800",
    [InvoicePaymentType.Credit]: "bg-yellow-100 text-yellow-800",
    [InvoicePaymentType.Refund]: "bg-pink-100 text-pink-800",
  };

  return (
    <span
      className={classNames(
        "inline-flex items-center rounded px-2 py-0.5 text-xs font-medium",
        colors[payment.type],
      )}
    >
      {payment.type}
    </span>
  );
}
