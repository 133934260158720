import { graphql } from "@/gql";

export const createBookingPaymentRequestMutation = graphql(`
  mutation CreateBookingPaymentRequest(
    $input: CreateBookingPaymentRequestInput!
  ) {
    createBookingPaymentRequest(input: $input) {
      id
    }
  }
`);

export const deleteBookingPaymentRequestMutation = graphql(`
  mutation DeleteBookingPaymentRequest($id: ID!) {
    deleteBookingPaymentRequest(id: $id) {
      id
    }
  }
`);

export const resolveBookingPaymentRequestMutation = graphql(`
  mutation ResolveBookingPaymentRequest(
    $id: ID!
    $input: ResolveBookingPaymentRequestInput!
  ) {
    resolveBookingPaymentRequest(id: $id, input: $input) {
      id
    }
  }
`);

export const sendBookingPaymentRequestMutation = graphql(`
  mutation SendBookingPaymentRequest($id: ID!) {
    sendBookingPaymentRequestToCustomer(id: $id) {
      id
    }
  }
`);
