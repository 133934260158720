import { CellContext } from "@tanstack/react-table";
import {
  QueryRelocationsOrderByColumn,
  Region,
  RelocationListQueryVariables,
  RelocationStatus,
  SortOrder,
} from "@/gql/graphql";
import {
  formatCurrency,
  formatDistance,
} from "@/lib/Formatters/formatCurrency";
import { ReactNode, useState } from "react";
import { Link } from "@tanstack/react-router";
import { cn } from "@/lib/utils";
import { useTranslations } from "use-intl";
import { RelocationStatusCell } from "@/app/Relocations/Components/RelocationStatusCell";
import { formatDate } from "@/lib/Formatters/formatDate";
import { Progress } from "@/components/ui/progress";
import { inclusionIconMap } from "@/app/Relocations/Utils/inclusionIconMap";
import dayjs from "dayjs";
import {
  RelocationListItem,
  relocationListQuery,
} from "@/app/Relocations/GraphQL/relocationListQuery";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import {
  DataTable,
  DataTableColDef,
  DataTableColumnHeader,
} from "@/lib/Components/DataTable/DataTable";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import {
  MultiSelectFilter,
  MultiSelectFilterOption,
} from "@/lib/Components/DataTable/Filters/MultiSelectFilter";
import { TimeSinceNow } from "@/lib/Components/Common/TimeSinceNow";
import { useRelocationActions } from "@/app/Relocations/Hooks/useRelocationActions";
import { TableId } from "@/app/Common/Utils/tableIds";
import { Button } from "@/components/catalyst/button";
import { useConfirmDeleteDialog } from "@/lib/Components/Dialog/Hooks/useConfirmDeleteDialog";
import { Checkbox } from "@/components/catalyst/checkbox";
import { toast } from "sonner";
import { RegionFilter } from "@/app/Common/Components/RegionFilter";
import { BadgeButton } from "@/components/catalyst/badge";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { RelocationAdditionalDestinationDialog } from "@/app/Relocations/Components/Dialogs/RelocationAdditionalDestinationDialog";
import { updateRelocationStatuses } from "@/app/Relocations/GraphQL/relocationMutations";
import { RelocationReferenceCell } from "@/app/Relocations/Components/Cells/RelocationReferenceCell";
import { VehicleIcon } from "@/app/Vehicles/Components/VehicleIcon";

type RelocationColumns =
  | "select"
  | "status"
  | "relocation"
  | "vehicle"
  | "expires_at"
  | "line"
  | "bookings"
  | "deal"
  | "actions"
  | "created_at";

const sortMap: { [key in RelocationColumns]?: QueryRelocationsOrderByColumn } =
  {
    status: QueryRelocationsOrderByColumn.Status,
    relocation: QueryRelocationsOrderByColumn.CreatedAt,
    expires_at: QueryRelocationsOrderByColumn.LatestDepartureDate,
    created_at: QueryRelocationsOrderByColumn.CreatedAt,
  };

const options: MultiSelectFilterOption<RelocationStatus>[] = [
  {
    label: "Ready",
    value: RelocationStatus.Ready,
  },
  {
    label: "Sold out",
    value: RelocationStatus.SoldOut,
  },
  {
    label: "Paused",
    value: RelocationStatus.Paused,
  },
  {
    label: "Expired",
    value: RelocationStatus.Expired,
  },
  {
    label: "Archived",
    value: RelocationStatus.Archived,
  },
  {
    label: "Draft",
    value: RelocationStatus.Draft,
  },
];

type DashboardRelocationsTableProps = {
  title?: string;
  rightButtons?: ReactNode;
  id: TableId;
  queryVariables?: Partial<RelocationListQueryVariables>;
  hiddenColumns?: RelocationColumns[];
  searchable?: boolean;

  //Un-controlled component
  defaultEnabledStatuses?: RelocationStatus[];

  //Controlled component
  statuses?: RelocationStatus[];
  onStatusChange?: (status: RelocationStatus[]) => void;
};

export function RelocationTable({
  title,
  rightButtons,
  id,
  defaultEnabledStatuses = [],
  queryVariables,
  hiddenColumns,
  statuses,
  onStatusChange,
  searchable,
}: DashboardRelocationsTableProps) {
  const { supplier } = useGlobalSupplier();
  const getActions = useRelocationActions();
  const { open } = useConfirmDeleteDialog();
  const { mutateAsync } = useGqlMutation(updateRelocationStatuses);
  const t = useTranslations("relocation");
  const [regions, setRegions] = useState<Region[]>([]);

  const columns: DataTableColDef<
    RelocationListItem,
    unknown,
    RelocationColumns
  >[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            table.getIsSomePageRowsSelected()
          }
          color="indigo"
          onChange={(value) => table.toggleAllPageRowsSelected(value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          color="indigo"
          disabled={row.original.status === RelocationStatus.Archived}
          checked={row.getIsSelected()}
          onChange={(value) => row.toggleSelected(value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "status",
      cell: RelocationStatusCell,
      accessorFn: (model) => model,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
    },
    {
      id: "relocation",
      accessorFn: (model) => model,
      enableSorting: true,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Relocation" />
      ),
      cell: ({ row }) => <RelocationCell relocation={row.original} />,
    },
    {
      id: "vehicle",
      header: "Vehicle",
      cell: ({ row }) => {
        return (
          <div>
            <VehicleIcon model={row.original.vehicle} />
            <p className="text-xs text-gray-500">{row.original.vehicle.name}</p>
            <p className="text-xs text-gray-500">
              {row.original.vehicle.transmission}
            </p>
          </div>
        );
      },
    },
    {
      id: "line",
      header: "Rego",
      cell: RelocationReferenceCell,
      accessorFn: (model) => model,
    },
    {
      id: "deal",
      header: "Deal",
      accessorFn: (model) => model,
      cell: ({ row }) => {
        const relocation = row.original;
        return (
          <div>
            <p>
              {`${relocation.hire_units_allowed} + ${
                relocation.extra_hire_units_allowed
              } ${t(`hire_unit_type.${relocation.hire_unit_type}`)}s`}
            </p>
            <p className="text-xs text-gray-500">
              {`${formatCurrency(
                relocation.hire_unit_rate,
                relocation.currency,
              )} / ${formatCurrency(
                relocation.extra_hire_unit_rate,
                relocation.currency,
              )}`}
            </p>
            <p className="text-xs text-gray-500">
              {formatDistance(
                relocation.distance_allowed,
                relocation.measurement,
              )}
            </p>
          </div>
        );
      },
    },
    {
      id: "bookings",
      header: "Bookings",
      accessorFn: (model) => model.count,
      cell: QuantityCell,
    },
    {
      id: "expires_at",
      accessorFn: (model) => model,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Expires" />
      ),
      cell: ({ row }) => {
        const relocation = row.original;

        const days = dayjs(relocation.available_to_date).diff(dayjs(), "days");

        const showExpiry =
          days >= 0 && relocation.status === RelocationStatus.Ready;

        return (
          <p
            className={cn("text-gray-500", {
              "text-bold text-red-700": days < 3 && days >= 0,
            })}
          >
            {showExpiry ? `${days} days` : "--"}
          </p>
        );
      },
    },
    {
      id: "created_at",
      accessorFn: (model) => model,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Created" />
      ),
      cell: ({ row }) => (
        <TimeSinceNow
          className="text-gray-500"
          time={row.original.created_at}
        />
      ),
    },
  ];

  const [localStatus, setStatus] = useState<RelocationStatus[] | undefined>(
    defaultEnabledStatuses,
  );

  const status = statuses ?? localStatus;

  if (status === undefined) {
    throw new Error("Status is undefined");
  }

  return (
    <DataTable
      id={id}
      searchable={searchable}
      hiddenColumns={hiddenColumns}
      getActions={getActions}
      initialSorting={[
        {
          id: "created_at",
          desc: true,
        },
      ]}
      filters={(table) => (
        <div className="space-x-2">
          <MultiSelectFilter
            label="Status"
            options={options}
            selected={status}
            onChange={(newStatus) => {
              if (onStatusChange) {
                onStatusChange(newStatus);
              } else {
                setStatus(newStatus);
              }

              table.setPagination((prev) => ({
                ...prev,
                pageIndex: 0,
              }));
            }}
          />

          <RegionFilter
            selected={regions}
            onChange={(newRegions) => {
              setRegions(newRegions);
              table.setPagination((prev) => ({
                ...prev,
                pageIndex: 0,
              }));
            }}
          />
        </div>
      )}
      title={title}
      rightButtons={(table) => {
        const selected = table.getSelectedRowModel().rows;

        return selected.length > 0 ? (
          <div className="flex h-[36px] items-center space-x-2">
            <div className="text-xs text-gray-500">
              {selected.length} selected
            </div>

            <Button
              plain
              className="text-xs text-gray-500 underline decoration-dashed"
              onClick={() => {
                table.toggleAllPageRowsSelected(false);
              }}
            >
              Deselect
            </Button>

            <Button
              outline
              onClick={() => {
                console.log(selected.map((row) => row.original.id));
                open({
                  title: "Archive relocations",
                  message:
                    "Are you sure you want to archive these relocations?",
                  buttonText: "Archive relocations",
                  onDelete: async () => {
                    await mutateAsync({
                      ids: selected.map((row) => row.original.id),
                      status: RelocationStatus.Archived,
                    });

                    table.toggleAllPageRowsSelected(false);

                    toast.success(`${selected.length} relocations archived`);
                  },
                });
              }}
            >
              Archive
            </Button>
          </div>
        ) : (
          rightButtons
        );
      }}
      columns={columns}
      document={relocationListQuery}
      accessor={(data) => data.relocations}
      getQueryVariables={({ pagination, sorting, search }) => {
        return {
          search,
          supplier_id: supplier?.id,
          first: pagination.pageSize,
          page: pagination.pageIndex,
          status: status.length ? status : undefined,
          regions: regions.length ? regions : undefined,
          is_linked: false,
          orderBy: search
            ? undefined
            : sorting.map((sort) => {
                const column = sortMap?.[sort.id as RelocationColumns];

                if (!column) {
                  throw new Error(`No column found for ${sort.id}`);
                }

                return {
                  order: sort.desc ? SortOrder.Desc : SortOrder.Asc,
                  column,
                };
              }),
          ...queryVariables,
        };
      }}
    />
  );
}

function QuantityCell({ row }: CellContext<RelocationListItem, any>) {
  const booked = row.original.bookedBookingsCount ?? 0;
  const total = row.original.count + booked;

  const percent = (booked / total) * 100;

  if (total === 0) return null;

  return (
    <div>
      <p className="text-center text-xs text-gray-500">
        {row.original.bookedBookingsCount} / {total}
      </p>
      <Progress
        value={percent}
        className="bg-yellow-300"
        indicatorClassName="bg-green-400"
      />
    </div>
  );
}

function RelocationCell({ relocation }: { relocation: RelocationListItem }) {
  const { hasSupplier } = useGlobalSupplier();
  const { open } = useDialog(RelocationAdditionalDestinationDialog);

  return (
    <div className="flex items-center space-x-2">
      <div className="w-[200px] overflow-hidden">
        <p>
          <Link
            title={relocation.id}
            className="truncate text-blue-500 underline"
            to={"/relocations/$relocationId"}
            params={{
              relocationId: relocation.id,
            }}
          >
            {relocation.departureOffice.name} to{" "}
            {relocation.deliveryOffice.name} ({relocation.id})
          </Link>
        </p>
        {!hasSupplier ? (
          <p className="text-xs font-bold">{relocation.supplier.name}</p>
        ) : null}
        <p className="text-xs text-gray-500">
          {`${formatDate(relocation.available_from_date)} - ${formatDate(
            relocation.available_to_date,
          )}`}
        </p>
        <ul className="space-y-1">
          {relocation.inclusions.map(({ id, type, value, description }) => {
            const Icon = inclusionIconMap[type];

            return (
              <li
                key={id}
                className="flex items-center text-xs text-gray-500"
                title={description ?? undefined}
              >
                <div className="mr-1 rounded-lg border border-yellow-400 p-1 shadow-md">
                  <Icon className="h-4 w-4 flex-shrink-0 text-yellow-400" />
                </div>

                {value ? (
                  <span className="mr-1">
                    {formatCurrency(value, relocation.currency)}
                  </span>
                ) : null}
                <span className="line-clamp-1 truncate whitespace-pre-line">
                  {description}
                </span>
              </li>
            );
          })}
        </ul>

        {relocation.linkedRelocationCount > 0 ? (
          <BadgeButton
            className="mt-1"
            onClick={() => {
              open({
                relocationId: relocation.id,
              });
            }}
          >
            +{relocation.linkedRelocationCount} more
          </BadgeButton>
        ) : null}
      </div>
    </div>
  );
}
