import { StripePaymentMethodListItem } from "@/app/Stripe/StripePaymentMethods/Components/StripePaymentMethodListItem";
import { StripeAddPaymentMethodButton } from "@/app/Stripe/StripePaymentMethods/Components/StripeAddPaymentMethodButton";
import { StripePaymentMethodEmptyState } from "@/app/Stripe/StripePaymentMethods/Components/StripePaymentMethodEmptyState";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import {
  ContactRecordFieldsFragment,
  StripeCustomerFieldsFragment,
} from "@/gql/graphql";

export function StripePaymentMethodCard({
  contact,
}: {
  contact: StripeCustomerFieldsFragment & ContactRecordFieldsFragment;
}) {
  const billingContact = contact;

  if (!billingContact) {
    return (
      <WarningBanner>
        This supplier does not have a billing contact. Please add one to manage
        payment methods.
      </WarningBanner>
    );
  }

  if (!billingContact.stripeCustomer?.paymentMethods?.length) {
    return <StripePaymentMethodEmptyState contact={billingContact} />;
  }

  return (
    <>
      <ul className="mb-6 space-y-2">
        {billingContact.stripeCustomer?.paymentMethods?.map((method) => {
          return (
            <StripePaymentMethodListItem
              paymentMethod={method}
              key={method.id}
            />
          );
        })}
      </ul>

      <StripeAddPaymentMethodButton contactId={billingContact.id} />
    </>
  );
}
