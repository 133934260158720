import { DismissibleDialog } from "./DismissibleDialog";

export function PreviewPdfDialog({ url }: { url: string }) {
  return (
    <DismissibleDialog>
      <div className="">
        <object
          data={url}
          type="application/pdf"
          width="100%"
          height="800px"
          title="pdf preview"
        >
          <p>
            It appears your Web browser is not configured to display PDF files.
            No worries, just{" "}
            <a href={url} className="underline underline-offset-1">
              click here
            </a>
            to download the PDF file.
          </p>
        </object>
      </div>
    </DismissibleDialog>
  );
}
