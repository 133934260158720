import * as React from "react";
const Svg105MobilePostColour = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 677.86 696.85", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("style", null, ".mobile-post-color-cls-1{fill:#f1f1f1}.mobile-post-color-cls-3{fill:#e49d75}.mobile-post-color-cls-5{fill:none;stroke-miterlimit:10;stroke-width:3px;stroke:#4fe0e0}.mobile-post-color-cls-7{fill:#ffd98e}.mobile-post-color-cls-8{fill:#fd8369}")), /* @__PURE__ */ React.createElement("path", { d: "M598.65 103.54A314 314 0 0 0 531 51.39v424.33C587.68 415 652.36 373.64 671.57 314.81c21.13-64.7-12.91-150.38-72.92-211.27M155 625.73v-489C84.65 197.32 13.74 266.72 1.8 347.83-10.08 429.69 38 523.45 108.91 594.75a458 458 0 0 0 47.47 41.75 43 43 0 0 1-1.38-10.77m50.07 42.44c47.88 25.89 96.82 36.73 137.12 22.1 13.25-4.85 25.56-12.46 37.24-22.1Z", className: "mobile-post-color-cls-1" }), /* @__PURE__ */ React.createElement("path", { d: "M531 402.82v-88.64c-60-5.24-122-8-185.51-8-65.22 0-128.91 2.9-190.49 8.43v88.2Z", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M281.78 403.18c2.8-16.66 9-68.18-21.41-68.68-3 9.07-9.11 47.92-7.19 68.68Z", className: "mobile-post-color-cls-3" }), /* @__PURE__ */ React.createElement("path", { d: "M301.27 267.5c29.92 6.41 17.3 69.36 12.24 79-9.94 7.93-45.83 3.29-58.65-11.76-1.92-7.56 14.03-74.18 46.41-67.24", style: {
  fill: "#ffc78e"
} }), /* @__PURE__ */ React.createElement("rect", { width: 376, height: 666, x: 154.99, y: 1.5, className: "mobile-post-color-cls-5", rx: 42.45 }), /* @__PURE__ */ React.createElement("path", { d: "M410.41 1.31a16 16 0 0 1 .08 1.69v10A16.5 16.5 0 0 1 394 29.5H292A16.5 16.5 0 0 1 275.49 13V3q-.02-.76.07-1.5", style: {
  fill: "#6bd5e1"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 342.99, cy: 630.42, r: 19.22, className: "mobile-post-color-cls-7" }), /* @__PURE__ */ React.createElement("circle", { cx: 198.82, cy: 443.1, r: 21.73, className: "mobile-post-color-cls-8" }), /* @__PURE__ */ React.createElement("circle", { cx: 221.59, cy: 521.77, r: 12.24, className: "mobile-post-color-cls-8" }), /* @__PURE__ */ React.createElement("path", { d: "M240.33 434.06h197.2m-188.46 87.71h197.2m-205.94-69.43h65.53", style: {
  fill: "none",
  strokeMiterlimit: 10,
  strokeWidth: 3,
  stroke: "#fd8369",
  strokeLinecap: "round"
} }), /* @__PURE__ */ React.createElement("path", { d: "M317.17 110.37H192.34s7.46-10.32 29.25-12.17 10.95-29.89 42.69-29.89 25.39 19 38.88 19 14.01 23.06 14.01 23.06m90.61 14.5h86.11s-5.15-7.12-20.18-8.39-7.55-20.62-29.44-20.62S426.75 109 417.45 109s-9.67 15.87-9.67 15.87", className: "mobile-post-color-cls-1" }), /* @__PURE__ */ React.createElement("path", { d: "M408.73 403.18c3-34.43-1.52-151.36-62.33-151.36-62.31 0-81.44 79.51-74.36 151.36Z", className: "mobile-post-color-cls-7" }), /* @__PURE__ */ React.createElement("path", { d: "M445.05 403.18c-1.8-16.56-12.46-63.35-16.22-71 0 0-43.37 8-43.37 10.88 0 2.53 10.59 46 15 60.13Z", className: "mobile-post-color-cls-3" }), /* @__PURE__ */ React.createElement("path", { d: "M385 266.33c29-9.79 50.33 50.76 50.91 61.64-4.5 11.89-37.71 26.25-56.42 19.87-5.49-5.52-25.84-70.93 5.51-81.51", style: {
  fill: "#ffe4ad"
} }), /* @__PURE__ */ React.createElement("path", { d: "M365.69 210.93c-1.59 5.45-7.61 36.9-3.3 53.47-3.06 4.09-33.49 5.11-37.12.57 2-7.27 5.33-27.93 4-35.2s36.42-18.84 36.42-18.84", style: {
  fill: "#d38d6a"
} }), /* @__PURE__ */ React.createElement("ellipse", { cx: 332.48, cy: 198, className: "mobile-post-color-cls-3", rx: 41.52, ry: 47.5 }), /* @__PURE__ */ React.createElement("path", { d: "M301.31 181.62c5.45.11 36.33-2.38 40.65-.45s5 10.1 13.62 10.9c6.46.59 11.91 12 17.05 18A54 54 0 0 0 374 198c0-26.23-18.58-47.5-41.51-47.5-16.93 0-31.48 11.59-37.94 28.21a10.6 10.6 0 0 0 6.76 2.91", className: "mobile-post-color-cls-8" }), /* @__PURE__ */ React.createElement("ellipse", { cx: 367.27, cy: 200.1, className: "mobile-post-color-cls-3", rx: 10.62, ry: 12.94 }), /* @__PURE__ */ React.createElement("path", { d: "M154.99 403.18h376v189.33h-376z", className: "mobile-post-color-cls-5" }));
export default Svg105MobilePostColour;
