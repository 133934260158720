import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { PhoneInput } from "@/lib/Components/Form/Inputs/PhoneInput";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { RegionInput } from "@/app/Common/Components/RegionInput";
import { CurrencyTypeInput } from "@/app/Common/Components/CurrencyTypeInput";
import { MeasurementInput } from "@/app/Common/Components/MeasurementInput";
import { HireUnitTypeInput } from "@/app/Common/Components/HireUnitType";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { RichTextInput } from "@/lib/Components/Form/Inputs/RichTextInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { CreateSupplierInput, Measurement, Region } from "@/gql/graphql";
import { useTranslations } from "use-intl";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";

export function SupplierDetailsForm() {
  const { values } = useForm<CreateSupplierInput>();

  const t = useTranslations("relocation");
  const hireUnitLabel = t(`hire_unit_type.${values.hire_unit_type}`);

  return (
    <>
      <TextInput name="name" label="Name" className="col-span-4" />
      <PhoneInput
        name="phone"
        label="Cust. res phone"
        className="col-span-3"
        optionalLabel
        tooltip="A contact number for the customer"
        defaultRegion={Region.Au}
      />
      <TextInput
        type="email"
        name="email"
        label="Cust. res email"
        className="col-span-3"
        optionalLabel
        tooltip="An email address for the customer"
      />

      <FormDivider />
      <RegionInput name={"region"} label={"Region"} />
      <CurrencyTypeInput name={"currency"} label={"Currency"} />
      <MeasurementInput label="Measurement (kms/mls)" name="measurement" />
      <HireUnitTypeInput name={"hire_unit_type"} label={"Default hire unit"} />
      <SwitchInput name="debit_card_accepted" label="Debit card accepted" />

      <FormDivider />
      <NumberInput
        name="default_extra_hire_units_allowed"
        label={`Default extra ${hireUnitLabel}s`}
        inputProps={{
          min: 0,
        }}
      />
      <NumberInput
        name="extra_distance_allowed_per_extra_hire_unit"
        label={`${
          values.measurement === Measurement.Metric ? "Kms" : "Mls"
        } per extra ${hireUnitLabel}`}
        tooltip="empty = unlimited"
      />
      <CurrencyInput
        currency={values.currency}
        name={"excess_distance_rate"}
        label={`Cost per excess ${
          values.measurement === Measurement.Metric ? "km" : "ml"
        }`}
      />

      <FormDivider />
      <NumberInput name="minimum_age" label="Minimum age" />
      <NumberInput name="maximum_age" label="Maximum age" />
      <SwitchInput name="has_age_surcharge" label="Age surcharge" />
      <div className="col-span-3" />

      {values.has_age_surcharge ? (
        <>
          <NumberInput
            name="minimum_no_surcharge_age"
            label="Minimum age (no surcharge)"
          />

          <NumberInput
            name="maximum_no_surcharge_age"
            label="Maximum age (no surcharge)"
          />

          <CurrencyInput
            currency={values.currency}
            name={"age_surcharge_amount"}
            label={"Surcharge amount"}
          />
        </>
      ) : null}
      <FormDivider />
      <RichTextInput
        name={"standard_inclusions"}
        label={"Standard inclusions"}
        className="col-span-full"
      />
    </>
  );
}
