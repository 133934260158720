import { TaskStatus } from "@/gql/graphql";

const status: {
  [key in TaskStatus]: string;
} = {
  [TaskStatus.Completed]: "completed",
  [TaskStatus.Overdue]: "overdue",
  [TaskStatus.Pending]: "pending",
  [TaskStatus.Snoozed]: "snoozed",
};

export const taskMessages = {
  status,
};
