import type { PaymentListSearchParams } from "@/app/Payments/Routes/paymentRoute";
import {
  PaymentListQueryVariables,
  QueryPaymentsOrderByColumn,
  SortOrder,
} from "@/gql/graphql";

export function getPaymentListQueryVariables(
  searchParams: PaymentListSearchParams,
): PaymentListQueryVariables {
  const baseVariables: PaymentListQueryVariables = {
    first: 20,
    page: 1,
    status: searchParams.paymentStatus,
    search: searchParams.paymentSearch,
  };

  if (searchParams.paymentSearch) {
    return baseVariables;
  }

  return {
    ...baseVariables,
    orderBy: [
      {
        order: SortOrder.Desc,
        column: QueryPaymentsOrderByColumn.CreatedAt,
      },
    ],
  };
}
