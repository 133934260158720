import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { RegionInput } from "@/app/Common/Components/RegionInput";
import { CurrencyTypeInput } from "@/app/Common/Components/CurrencyTypeInput";
import { HireUnitRadioButton } from "@/app/Common/Components/HireUnitRadioButton";
import { SubmitButtonInput } from "@/lib/Components/Form/Inputs/SubmitButtonInput";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";

export function SupplierMissingScreen() {
  const { mutateAsync } = useGqlMutation(createSupplierMutation);
  const { actions } = useGlobalSupplier();

  return (
    <div className="flex h-full">
      <div className="flex h-full w-[500px] flex-col overflow-y-auto">
        <div className="p-4">
          <img
            className="h-20 w-auto"
            src="/images/horizontal-logo.png"
            alt="Imoova"
          />
        </div>
        <div className="flex flex-grow justify-center p-4">
          <div className="max-w-sm pb-24">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Add your business
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Add your business to start using Imoova
            </p>
            <GenericForm
              initialValues={{
                name: "",
                region: null!,
                currency: null!,
                hire_unit_type: null!,
              }}
              onSubmit={async (values) => {
                const res = await mutateAsync({
                  input: {
                    name: values.name,
                    region: values.region,
                    currency: values.currency,
                    hire_unit_type: values.hire_unit_type,
                  },
                });

                actions.setSupplier(res.createSupplier.id);
              }}
              className="mt-6 grid grid-cols-3 lg:grid-cols-3"
            >
              <TextInput name="name" label="Business name" />
              <RegionInput label="region" name="region" />
              <CurrencyTypeInput name="currency" label="Currency" />
              <HireUnitRadioButton name="hire_unit_type" label="Units" />

              <SubmitButtonInput>Create</SubmitButtonInput>
            </GenericForm>
          </div>
        </div>
      </div>

      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1551218611-b1004ba59913?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1900&q=80"
          alt="empty road"
        />
      </div>
    </div>
  );
}
