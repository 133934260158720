import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { MarketingEventTrigger } from "@/gql/graphql";
import { SelectOption } from "@/lib/Components/Form/Components/Select";

export function MarketingEventTriggerInput(
  props: Omit<SelectInputProps, "options">,
) {
  const map: {
    [key in MarketingEventTrigger]: string;
  } = {
    [MarketingEventTrigger.BookingCreated]: "Booking created",
    [MarketingEventTrigger.BookingCompleted]: "Booking completed",
    [MarketingEventTrigger.BookingConfirmed]: "Booking confirmed",
    [MarketingEventTrigger.BookingStarted]: "Booking started",
  };

  const options: SelectOption[] = Object.entries(map).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <SelectInput
      options={[
        {
          label: "Booking",
          options: options,
        },
      ]}
      {...props}
    />
  );
}
