import { graphql } from "@/gql";

export const updateOrganisationMutation = graphql(`
  mutation UpdateOrganisation($id: ID!, $input: UpdateTenantInput!) {
    updateTenant(id: $id, input: $input) {
      id
    }
  }
`);

export const assignOrganisationToUserMutation = graphql(`
  mutation AssignSupplierToTenant($input: AssignSupplierToTenantInput!) {
    assignSupplierToTenant(input: $input)
  }
`);

export const assignSalesAgentMutation = graphql(`
  mutation AssignSalesAgent($input: CreateSalesOrganisationAttributionInput!) {
    createSalesOrganisationAttribution(input: $input) {
      id
      user {
        id
      }
    }
  }
`);

export const removeSalesAgentMutation = graphql(`
  mutation RemoveSalesAgent($orgId: ID!, $userId: ID!) {
    deleteSalesOrganisationAttribution(
      organisation_id: $orgId
      user_id: $userId
    ) {
      id
    }
  }
`);
