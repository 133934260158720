import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { InvoiceTable } from "@/app/Invoices/Components/InvoiceTable";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import {
  Navbar,
  NavbarItemLink,
  NavbarSection,
} from "@/components/catalyst/navbar";
import { Badge, BadgeColor } from "@/components/catalyst/badge";
import { InvoiceStatus } from "@/gql/graphql";
import { invoiceStatusCountQuery } from "@/app/Invoices/GraphQL/invoiceStatusCountQuery";
import { useMatch, useNavigate } from "@tanstack/react-router";
import { Button } from "@/lib/Components/Button/Button";

export function InvoiceTableScreen() {
  const { search } = useMatch({
    from: "/_app/invoices/",
  });

  const navigate = useNavigate();

  return (
    <FullPageScreen>
      <Header />
      <InvoiceTable
        id="invoices"
        statuses={search.invoiceStatus}
        buttons={
          <>
            <Button
              onClick={() => {
                navigate({
                  to: "/invoices/create",
                });
              }}
            >
              Add Invoice
            </Button>
          </>
        }
        onStatusChange={(newStatuses) => {
          navigate({
            to: "/invoices",
            search: {
              invoiceStatus: newStatuses,
            },
          });
        }}
      />
    </FullPageScreen>
  );
}
type Filter<T> = {
  label: string;
  statuses: T[];
  hideBadge?: boolean;
  isAdmin?: boolean;
  badgeColor?: BadgeColor;
};

const filters: Filter<InvoiceStatus>[] = [
  {
    label: "Due",
    statuses: [InvoiceStatus.Issued, InvoiceStatus.Overdue],
    badgeColor: "yellow",
  },
  {
    label: "Paid",
    statuses: [InvoiceStatus.Paid],
    hideBadge: true,
  },
  {
    label: "Void",
    statuses: [InvoiceStatus.Voided],
    hideBadge: true,
  },
  {
    label: "All",
    statuses: [],
    hideBadge: true,
  },
  {
    label: "Draft",
    statuses: [InvoiceStatus.Draft],
    isAdmin: true,
  },
];

function Header() {
  const { isAdmin } = useTenant();
  const { supplierId } = useGlobalSupplier();
  const { data } = useGqlQuery(invoiceStatusCountQuery, {
    supplierId: supplierId ?? undefined,
  });
  const navigate = useNavigate({
    from: "/invoices/",
  });

  return (
    <div className="relative border-b border-gray-200 sm:pb-0">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        Invoices
      </h3>
      <div className="sm:hidden">
        <label htmlFor="current-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="current-tab"
          name="current-tab"
          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
          onChange={(e) => {
            const statuses = filters.find(
              (tab) => tab.label === e.target.value,
            );

            navigate({
              search: {
                invoiceStatus: statuses?.statuses ?? [],
              },
            });
          }}
        >
          {filters.map((tab, idx) => (
            <option key={idx} value={tab.label}>
              {tab.label}
            </option>
          ))}
        </select>
      </div>
      <Navbar className="-mb-px">
        <NavbarSection className="max-lg:hidden">
          {filters.map(
            (
              {
                label,
                statuses,
                hideBadge,
                isAdmin: onlyShowForAdmin,
                badgeColor,
              },
              idx,
            ) => {
              if (onlyShowForAdmin && !isAdmin) {
                return null;
              }

              const count = statuses.reduce((acc, status) => {
                return (
                  acc +
                  (data?.invoiceStatusCount.find(
                    (counts) => counts.status === status,
                  )?.count ?? 0)
                );
              }, 0);

              return (
                <NavbarItemLink
                  key={idx}
                  to="/invoices"
                  search={{
                    invoiceStatus: statuses,
                  }}
                >
                  {label}

                  {count && !hideBadge ? (
                    <div>
                      <Badge className="py-0" color={badgeColor}>
                        {count}
                      </Badge>
                    </div>
                  ) : null}
                </NavbarItemLink>
              );
            },
          )}
        </NavbarSection>
      </Navbar>
    </div>
  );
}
