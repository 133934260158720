import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import {
  ArchiveBoxXMarkIcon,
  EnvelopeIcon,
  PaperAirplaneIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { InvoiceStatus } from "@/gql/graphql";
import { PDFIcon } from "@/assets/Icons";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { PreviewPdfDialog } from "@/lib/Components/Dialog/PreviewPdfDialog";
import { useNavigate } from "@tanstack/react-router";
import { toast } from "sonner";
import { CreditCardIcon } from "@heroicons/react/20/solid";
import { ConfirmDeleteDialog } from "@/lib/Components/Dialog/ConfirmDeleteDialog";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import {
  createInvoiceCheckoutSessionMutation,
  createInvoicePdfMutation,
  deleteInvoiceMutation,
  issueInvoiceMutation,
  resendInvoiceMutation,
  voidInvoiceMutation,
} from "@/app/Invoices/GraphQL/invoiceMutations";
import { InvoiceListItem } from "@/app/Invoices/GraphQL/invoiceListQuery";

const sendLabel: {
  [key in InvoiceStatus]: string;
} = {
  [InvoiceStatus.Draft]: "Send",
  [InvoiceStatus.Issued]: "Resend",
  [InvoiceStatus.Paid]: "Resend",
  [InvoiceStatus.Voided]: "Resend",
  [InvoiceStatus.Overdue]: "Send overdue notice",
};

export function useInvoiceActions(): ActionsHook<InvoiceListItem> {
  const { mutateAsync } = useGqlMutation(createInvoicePdfMutation);
  const { open: openPdf } = useDialog(PreviewPdfDialog);
  const { mutateAsync: issueInvoice } = useGqlMutation(issueInvoiceMutation);
  const { mutateAsync: deleteInvoice } = useGqlMutation(deleteInvoiceMutation);
  const { mutateAsync: resendInvoice } = useGqlMutation(resendInvoiceMutation);
  const { mutateAsync: createCheckout } = useGqlMutation(
    createInvoiceCheckoutSessionMutation,
  );
  const { mutateAsync: voidInvoice } = useGqlMutation(voidInvoiceMutation);
  const navigate = useNavigate();
  const { open: openDeleteDialog } = useDialog(ConfirmDeleteDialog);

  return (invoice) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Pay online",
                intent: "primary",
                isHidden: ![
                  InvoiceStatus.Issued,
                  InvoiceStatus.Overdue,
                ].includes(invoice.status),
                Icon: CreditCardIcon,
                handleAction: async () => {
                  const res = await createCheckout({
                    id: invoice.id,
                  });

                  window.open(
                    res.createInvoiceCheckoutSession.checkout_session_url,
                    "_blank",
                  );
                },
              },
            ],
          },
          {
            actions: [
              {
                label: sendLabel[invoice.status],
                intent: "neutral",
                Icon: EnvelopeIcon,
                isAdminAction: true,
                isHidden: invoice.status === InvoiceStatus.Draft,
                handleAction: async () => {
                  await resendInvoice({
                    id: invoice.id,
                  });

                  toast("Invoice sent");
                },
              },
              {
                isAdminAction: true,
                isHidden: invoice.status !== InvoiceStatus.Draft,
                label: "Issue",
                intent: "primary",
                Icon: PaperAirplaneIcon,
                handleAction: async () => {
                  await issueInvoice({
                    id: invoice.id,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Pdf",
                Icon: PDFIcon,
                intent: "neutral",
                handleAction: async () => {
                  const res = await mutateAsync({
                    id: invoice.id,
                  });
                  openPdf({
                    url: res.createInvoicePdf,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Void",
                Icon: ArchiveBoxXMarkIcon,
                intent: "highlight",
                isAdminAction: true,
                isHidden: ![
                  InvoiceStatus.Issued,
                  InvoiceStatus.Overdue,
                  InvoiceStatus.Paid,
                ].includes(invoice.status),
                isHiddenTopBar: true,
                handleAction: () => {
                  return voidInvoice({
                    id: invoice.id,
                  });
                },
              },
              {
                label: "Delete",
                Icon: TrashIcon,
                intent: "danger",
                isAdminAction: true,
                isHidden: invoice.status !== InvoiceStatus.Draft,
                handleAction: async () => {
                  openDeleteDialog({
                    buttonText: "Delete",
                    message: "Are you sure you want to delete this invoice",
                    title: "Delete invoice",
                    onDelete: async () => {
                      await deleteInvoice({
                        id: invoice.id,
                      });

                      await navigate({
                        to: "/invoices",
                      });
                    },
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
