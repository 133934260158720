import { BookingCancelledStatusInput } from "@/app/Bookings/Components/BookingCancelStatusInput";
import { BookingStatus } from "@/gql/graphql";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";

export function RelocationUnfulfilledForm() {
  return (
    <>
      <BookingCancelledStatusInput
        label="Cancellation reason"
        className="col-span-3"
        name={"cancellation_status"}
        enabledStatuses={[
          BookingStatus.CustomerCancelledNoShow,
          BookingStatus.CustomerCancelled,
          BookingStatus.SupplierCancelledNoFerry,
          BookingStatus.SupplierCancelledMechanicalFailure,
          BookingStatus.SupplierCancelled,
        ]}
      />
      <TextAreaInput
        optionalLabel
        className="col-span-full"
        name={"description"}
        label="Notes"
      />
    </>
  );
}
