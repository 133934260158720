import { Area, AreaChart, CartesianGrid, ReferenceLine, XAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { TrendingDown, TrendingUp } from "lucide-react";
import { ReactNode, Suspense, useMemo, useState, useTransition } from "react";
import { relocationViewCountListQuery } from "@/app/Relocations/GraphQL/relocationViewCountListQuery";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import dayjs from "dayjs";
import { toDateTimeTzString } from "@/lib/Formatters/toDateTimeString";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { relocationRecordQuery } from "@/app/Relocations/GraphQL/relocationRecordQuery";
import { calculatePercentChange } from "@/app/Dashboard/Utils/calculatePercentChange";
import { useRelocationRecord } from "@/app/Relocations/Hooks/useRelocationRecord";
import { RelocationRating } from "@/app/Relocations/Components/RelocationRatingCard";
import { RelocationRatingFieldsFragment } from "@/gql/graphql";
import { formatStatistic } from "@/lib/Formatters/formatStatistic";
import { formatPercent } from "@/lib/Formatters/formatPercent";
import { cn } from "@/lib/utils";

export function RelocationStats() {
  const relocation = useRelocationRecord();

  return (
    <Suspense fallback={<Skeleton className="h-[453px] w-full" />}>
      <Component relocationId={relocation.id} />
    </Suspense>
  );
}

const chartConfig = {
  relocation: {
    label: "Relocation",
    color: "hsl(var(--chart-2))",
  },
  region: {
    label: "Region Avg",
    color: "hsl(var(--chart-1))",
  },
} satisfies ChartConfig;

type TimePeriod = "day" | "week" | "month";

export function Component({ relocationId }: { relocationId: string }) {
  const { data: relocation } = useSuspenseGqlQuery(relocationRecordQuery, {
    id: relocationId,
  });

  const viewsToday = relocation.relocation.stats?.views_today ?? 0;
  const viewsYesterday = relocation.relocation.stats?.views_yesterday ?? 0;

  const { decimalPercent } = calculatePercentChange({
    current: viewsToday,
    previous: viewsYesterday,
  });

  const [duration, setDuration] = useState<TimePeriod>("day");
  const [isPending, startTransition] = useTransition();

  const durationData: Record<
    TimePeriod,
    {
      periods: number;
      unit: dayjs.ManipulateType;
    }
  > = {
    day: {
      periods: 24,
      unit: "hour",
    },
    week: {
      periods: 7,
      unit: "day",
    },
    month: {
      periods: 30,
      unit: "day",
    },
  };

  const { periods, unit } = durationData[duration];

  const end = dayjs().local().endOf(unit);
  const start = end.clone().subtract(periods, unit);

  const { data } = useSuspenseGqlQuery(relocationViewCountListQuery, {
    id: relocationId,
    start: toDateTimeTzString(start),
    end: toDateTimeTzString(end),
  });

  const chartData = useMemo(
    () =>
      Array.from({ length: periods }, (_, i) => {
        const date = start.add(i + 1, unit);

        //Find the data for the date
        const relocationData = data?.relocationViews.relocation.filter((r) => {
          const dataDate = dayjs(r.hour, {
            utc: true,
          });

          if (unit === "day") {
            return dataDate.isSame(date, "date");
          }

          return dataDate.isSame(date, "hour") && dataDate.isSame(date, "date");
        });

        //Find the data for the date
        const regionData = data?.relocationViews.region_average.filter((r) => {
          const dataDate = dayjs(r.hour, {
            utc: true,
          });

          if (unit === "day") {
            return dataDate.isSame(date, "date");
          }

          return dataDate.isSame(date, "hour") && dataDate.isSame(date, "date");
        });

        return {
          time: date.clone().local().startOf(unit).format("YYYY-MM-DD HH:mm"),
          region: regionData.reduce((acc, curr) => {
            return acc + curr.view_count;
          }, 0),
          regionData,
          relocation: relocationData.reduce((acc, curr) => {
            return acc + curr.view_count;
          }, 0),
          relocationData,
        };
      }),
    [periods, unit, relocationId],
  );

  return (
    <Card
      className={cn({
        "animate-pulse": isPending,
      })}
    >
      <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
        <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
          <CardTitle>Listing Performance</CardTitle>
          <CardDescription>
            Showing total visitors for the last{" "}
            <select
              className="border-none text-sm"
              value={duration}
              onChange={(e) => {
                startTransition(() => {
                  setDuration(e.target.value as any);
                });
              }}
            >
              <option value="day">24 hours</option>
              <option value="week">7 days</option>
              <option value="month">30 days</option>
            </select>
          </CardDescription>
        </div>
        <div className="flex">
          <RatingDialog data={relocation.relocation.stats!.rating}>
            <button className="data-[active=true]:bg-muted/50 relative z-30 flex min-w-36 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l hover:bg-gray-100 sm:border-l sm:border-t-0 sm:px-8 sm:py-6">
              <span className="text-muted-foreground text-xs">Rating</span>
              <span className="text-lg font-bold leading-none sm:text-3xl">
                {(
                  (relocation.relocation.stats!.rating.total_score /
                    relocation.relocation.stats!.rating.score_out_of) *
                  100
                ).toFixed(0)}
                %
              </span>
            </button>
          </RatingDialog>
          <div className="data-[active=true]:bg-muted/50 relative z-30 flex min-w-36 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-l sm:border-t-0 sm:px-8 sm:py-6">
            <span className="text-muted-foreground text-xs">Views</span>
            <span className="whitespace-nowrap text-lg font-bold leading-none sm:text-3xl">
              {formatStatistic(relocation.relocation.stats?.views_total)}
            </span>
          </div>
          <div className="data-[active=true]:bg-muted/50 relative z-30 flex min-w-36 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-l sm:border-t-0 sm:px-8 sm:py-6">
            <span className="text-muted-foreground whitespace-nowrap text-xs">
              Expiring in
            </span>
            <span className="whitespace-nowrap text-lg font-bold leading-none sm:text-3xl">
              {relocation.relocation.stats?.days_until_expiry
                ? `${relocation.relocation.stats?.days_until_expiry} days`
                : "--"}
            </span>
          </div>
        </div>
      </CardHeader>
      <CardContent className="px-2 sm:p-6">
        <ChartContainer config={chartConfig} className="h-[250px] w-full">
          <AreaChart
            accessibilityLayer
            data={chartData}
            margin={{
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="time"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
              tickFormatter={(value) => {
                return formatLabel(value, duration);
              }}
            />
            <ReferenceLine
              label="Listed"
              x={dayjs(relocation.relocation.created_at, {
                utc: true,
              })
                .local()
                .startOf(unit)
                .format("YYYY-MM-DD HH:mm")}
              stroke="red"
            />
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent
                  labelFormatter={(val) => {
                    return formatLabel(val, duration);
                  }}
                />
              }
            />
            <Area
              dataKey="region"
              type="basis"
              fill="var(--color-region)"
              fillOpacity={0.1}
              stroke="var(--color-region)"
              animateNewValues={false}
              stackId="a"
              strokeWidth={0}
            />
            <Area
              dataKey="relocation"
              type="basis"
              fill="var(--color-relocation)"
              fillOpacity={0}
              stroke="var(--color-relocation)"
              stackId="b"
            />
          </AreaChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="block">
        {Number.isFinite(decimalPercent) ? (
          <div className="flex w-full items-start gap-2 text-sm">
            <div className="grid gap-2">
              <div className="flex items-center gap-2 font-medium leading-none">
                Trending {decimalPercent > 0 ? "up" : "down"} by{" "}
                {formatPercent(decimalPercent)} today{" "}
                {decimalPercent > 0 ? (
                  <TrendingUp className="h-4 w-4" />
                ) : (
                  <TrendingDown className="h-4 w-4" />
                )}
              </div>
            </div>
          </div>
        ) : null}
      </CardFooter>
    </Card>
  );
}

function formatLabel(date: string, duration: string) {
  if (duration === "day") {
    return dayjs(date).format("h A");
  }

  return dayjs(date).format("MMM D");
}

function RatingDialog({
  children,
  data,
}: {
  children: ReactNode;
  data: RelocationRatingFieldsFragment;
}) {
  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-2xl">
        <DialogTitle className="flex items-center space-x-2">
          Rating
        </DialogTitle>
        <DialogDescription>
          The rating is calculated based on the performance of the listing and
          the region average.
        </DialogDescription>

        <RelocationRating data={data} />
      </DialogContent>
    </Dialog>
  );
}
