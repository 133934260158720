import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type PaidDriverRecord = ResultOf<
  typeof paidDriverRecordQuery
>["paidDriver"];
export const paidDriverRecordQuery = graphql(`
  query PaidDriverRecord($id: ID!) {
    paidDriver(id: $id) {
      id
      basedIn {
        ...CityFields
      }
      user {
        ...UserFields
      }
      bio
      created_at
      updated_at
    }
  }
`);
