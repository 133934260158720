import {
  DataTable,
  DataTableColDef,
  DataTableColumnHeader,
} from "@/lib/Components/DataTable/DataTable";
import {
  VehicleListItem,
  vehicleListQuery,
} from "@/app/Vehicles/GraphQL/vehicleListQuery";
import { ReactNode } from "react";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { useVehicleActions } from "@/app/Vehicles/Hooks/useVehicleActions";
import { ModelLinkCell } from "@/lib/Components/DataTable/Cells/ModelLinkCell";
import { TableId } from "@/app/Common/Utils/tableIds";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import {
  QueryVehiclesOrderByColumn,
  SortOrder,
  VehicleListQueryVariables,
} from "@/gql/graphql";
import { useTranslations } from "use-intl";
import { CellContext } from "@tanstack/react-table";
import { VehicleIcon } from "@/app/Vehicles/Components/VehicleIcon";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { PencilIcon } from "@heroicons/react/24/outline";
import { VehicleImageDialog } from "@/app/Vehicles/Components/VehicleImageDialog";

export type VehicleColumns =
  | "image"
  | "name"
  | "code"
  | "extra_day_rate"
  | "actions"
  | "unit";

export function VehicleTable({
  id,
  rightButtons,
  columns,
  queryVariables,
}: {
  id: TableId;
  columns?: DataTableColDef<VehicleListItem, any, any>[];
  rightButtons?: ReactNode;
  queryVariables?: Partial<VehicleListQueryVariables>;
}) {
  const getActions = useVehicleActions();
  const { supplierId } = useGlobalSupplier();
  const t = useTranslations("vehicle");
  const defaultColumns: DataTableColDef<
    VehicleListItem,
    any,
    VehicleColumns
  >[] = [
    {
      id: "image",
      header: "Image",
      cell: VehicleImageCell,
    },
    {
      id: "name",
      accessorFn: (data) => data.name,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      cell: ({ row }) => {
        return (
          <ModelLinkCell
            to={"/vehicles/$id"}
            params={{
              id: row.original.id,
            }}
          >
            {row.original.name}
          </ModelLinkCell>
        );
      },
    },
    {
      id: "code",
      accessorFn: (data) => data.code,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Code" />
      ),
    },
    {
      id: "unit",
      accessorFn: (data) => t(`hire_unit_type.${data.hire_unit_type}`),
      header: "Unit",
    },
    {
      id: "extra_day_rate",
      accessorFn: (data) =>
        formatCurrency(data.extra_hire_unit_rate, data.supplier.currency),
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Extra day rate" />
      ),
    },
  ];

  return (
    <DataTable
      rightButtons={rightButtons}
      columns={columns ? columns : defaultColumns}
      document={vehicleListQuery}
      getActions={getActions}
      getQueryVariables={({ search, sorting, pagination }) => {
        const baseParams: VehicleListQueryVariables = {
          search,
          first: pagination.pageSize,
          page: pagination.pageIndex,
          supplier_id: supplierId ?? undefined,
          archived: false,
          ...queryVariables,
        };

        if (search) {
          return baseParams;
        }

        return {
          ...baseParams,
          orderBy: sorting.map((sort) => {
            const order = sort.desc ? SortOrder.Desc : SortOrder.Asc;

            if (sort.id === "name") {
              return {
                column: QueryVehiclesOrderByColumn.Name,
                order,
              };
            }

            if (sort.id === "code") {
              return {
                column: QueryVehiclesOrderByColumn.Code,
                order,
              };
            }

            if (sort.id === "extra_day_rate") {
              return {
                column: QueryVehiclesOrderByColumn.ExtraHireUnitRate,
                order,
              };
            }

            throw new Error(`Unknown sort column: ${sort.id}`);
          }),
        };
      }}
      accessor={(data) => data.vehicles}
      id={id}
    />
  );
}

export function VehicleImageCell({ row }: CellContext<VehicleListItem, any>) {
  const vehicle = row.original;
  const { open } = useDialog(VehicleImageDialog);

  return (
    <button
      onClick={() => {
        open({
          vehicleId: vehicle.id,
        });
      }}
      className="relative"
    >
      <VehicleIcon className="size-10 rounded-sm" model={vehicle} />
      <div className="absolute inset-0 flex items-center justify-center overflow-hidden rounded-full bg-gray-700/50 opacity-0 hover:opacity-100">
        <PencilIcon className="size-4 text-white" />
      </div>
    </button>
  );
}
