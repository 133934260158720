import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierInsuranceOptionEditDialog } from "@/app/Suppliers/Components/SupplierInsuranceOptionEditDialog";
import { StarIcon } from "@heroicons/react/20/solid";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import {
  deleteSupplierInsuranceOptionMutation,
  updateSupplierInsuranceOptionMutation,
} from "@/app/Suppliers/GraphQL/supplierInsuranceOptionMutations";
import { SupplierInsuranceOptionRecordFieldsFragment } from "@/gql/graphql";
import { useConfirmDeleteDialog } from "@/lib/Components/Dialog/Hooks/useConfirmDeleteDialog";

export function useSupplierInsuranceOptionActions(): ActionsHook<SupplierInsuranceOptionRecordFieldsFragment> {
  const { open } = useDialog(SupplierInsuranceOptionEditDialog);
  const { mutateAsync } = useGqlMutation(updateSupplierInsuranceOptionMutation);
  const { open: openConfirmDelete } = useConfirmDeleteDialog();
  const { mutateAsync: deleteOption } = useGqlMutation(
    deleteSupplierInsuranceOptionMutation,
  );

  return (model) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Make primary",
                handleAction: async () => {
                  await mutateAsync({
                    id: model.id,
                    input: {
                      is_default: true,
                    },
                  });
                },
                Icon: StarIcon,
                intent: "primary",
                isHidden: model.is_default,
              },
            ],
          },
          {
            actions: [
              {
                label: "Edit",
                handleAction: () => {
                  open({ insuranceOptionId: model.id });
                },
                Icon: PencilIcon,
              },
            ],
          },
          {
            actions: [
              {
                label: "Delete",
                intent: "danger",
                handleAction: () => {
                  openConfirmDelete({
                    title: "Delete insurance option",
                    message:
                      "Are you sure you want to delete this insurance option? Relocations using this option will be changed to use the default option",
                    buttonText: "Delete",
                    onDelete: async () => {
                      await deleteOption({
                        id: model.id,
                      });
                    },
                  });
                },
                Icon: TrashIcon,
              },
            ],
          },
        ],
      },
    ];
  };
}
