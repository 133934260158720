import { MoreVertical } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

import { Separator } from "@/components/ui/separator";
import { useBookingRecord } from "@/app/Bookings/Hooks/useBookingRecord";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { VehicleIcon } from "@/app/Vehicles/Components/VehicleIcon";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { VehicleType } from "@/gql/graphql";
import { useTranslations } from "use-intl";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { VehicleEditDialog } from "@/app/Vehicles/Components/VehicleEditDialog";
import { useNavigate } from "@tanstack/react-router";
import { RelocationEditDialog } from "@/app/Relocations/Components/RelocationEditDialog";

export function BookingVehicleCard() {
  const t = useTranslations("vehicle");
  const booking = useBookingRecord();
  const { open } = useDialog(VehicleEditDialog);
  const { open: editRelocation } = useDialog(RelocationEditDialog);
  const navigate = useNavigate();
  const vehicle = booking.relocation.vehicle;
  const isSleeper = [
    VehicleType.FourWheelDriveCamper,
    VehicleType.CamperVan,
    VehicleType.MotorHome,
  ].includes(vehicle.type);

  return (
    <Card className="overflow-hidden">
      <CardHeader className="bg-muted/50 flex flex-row items-center">
        <div className="mr-2">
          <VehicleIcon model={vehicle} className="h-12 w-12" />
        </div>
        <div className="grid gap-0.5">
          <CardTitle className="group flex items-center gap-2 text-lg">
            {booking.relocation.vehicle.name}
          </CardTitle>
          {vehicle.code ? (
            <CardDescription>Code: {vehicle.code}</CardDescription>
          ) : null}
        </div>
        <div className="ml-auto flex items-center gap-1">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="outline" className="h-8 w-8">
                <MoreVertical className="h-3.5 w-3.5" />
                <span className="sr-only">More</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                onClick={() => {
                  return navigate({
                    to: `/vehicles/$id`,
                    params: {
                      id: vehicle.id,
                    },
                  });
                }}
              >
                View vehicle
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => {
                  open({
                    vehicleId: vehicle.id,
                  });
                }}
              >
                Edit vehicle
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  editRelocation({
                    id: booking.relocation.id,
                  });
                }}
              >
                Change vehicle
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </CardHeader>
      <CardContent className="p-6 text-sm">
        <div className="grid gap-3">
          <div className="font-semibold">Vehicle Details</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Brand</dt>
              <dd>{vehicle.brand}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Model</dt>
              <dd>{vehicle.model}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Category</dt>
              <dd>{t(`type.${vehicle.type}`)}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Transmission</dt>
              <dd>{t(`transmission.${vehicle.transmission}`)}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Fuel</dt>
              <dd>{t(`fuel.${vehicle.fuel}`)}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Age</dt>
              <dd>
                {vehicle.approximate_age
                  ? t(`approximate_age.${vehicle.approximate_age}`)
                  : "--"}
              </dd>
            </div>
          </dl>
        </div>
        <Separator className="my-4" />
        <div className="grid gap-3">
          <div className="font-semibold">Seating</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Seatbelts</dt>
              <dd>{vehicle.seatbelts}</dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Child seat anchors</dt>
              <dd>{vehicle.child_seat_anchor_points ?? "--"}</dd>
            </div>
            {isSleeper ? (
              <div className="flex items-center justify-between">
                <dt className="text-muted-foreground">Sleeps</dt>
                <dd>{vehicle.sleeps ?? "--"}</dd>
              </div>
            ) : null}
          </dl>
        </div>
        {isSleeper ? (
          <>
            <Separator className="my-4" />
            <div className="grid gap-3">
              <div className="font-semibold">Features</div>
              <dl className="grid grid-cols-4 gap-3">
                <div className="flex items-center">
                  <dt className="text-muted-foreground">Heating:&nbsp;</dt>
                  <dd>
                    <InclusionIcon included={vehicle.has_heating} />
                  </dd>
                </div>
                <div className="flex items-center">
                  <dt className="text-muted-foreground">Kitchen:&nbsp;</dt>
                  <dd>
                    <InclusionIcon included={vehicle.has_kitchen} />
                  </dd>
                </div>
                <div className="flex items-center">
                  <dt className="text-muted-foreground">
                    Toilet & Shower:&nbsp;
                  </dt>
                  <dd>
                    <InclusionIcon included={vehicle.has_shower_and_toilet} />
                  </dd>
                </div>
                <div className="flex items-center">
                  <dt className="text-muted-foreground">
                    Self Contained:&nbsp;
                  </dt>
                  <dd>
                    <InclusionIcon included={vehicle.is_self_contained} />
                  </dd>
                </div>
              </dl>
            </div>
          </>
        ) : null}
      </CardContent>
      <CardFooter className="bg-muted/50 flex flex-row items-center border-t px-6 py-3">
        <div className="text-muted-foreground text-xs">
          Updated{" "}
          <time dateTime="2023-11-23">
            {dayjs(booking.relocation.vehicle.updated_at).format("LL")}
          </time>
        </div>
      </CardFooter>
    </Card>
  );
}

function InclusionIcon({ included }: { included: boolean | null | undefined }) {
  if (included === null || included === undefined) {
    return "--";
  }

  return included ? (
    <CheckIcon className="h-4 w-4 text-green-500" />
  ) : (
    <XMarkIcon className="h-4 w-4 text-red-500" />
  );
}
