import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { TimelineFilterOption } from "@/gql/graphql";
import { DateRangePicker } from "@/components/ui/date-range/date-range-picker";
import { Suspense, useState, useTransition } from "react";
import { DateRange } from "react-day-picker";
import dayjs from "dayjs";
import { Skeleton } from "@/components/ui/skeleton";
import { getDatesFromFilterOption } from "@/app/Dashboard/Utils/filterOptions";
import { cn } from "@/lib/utils";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  Pie,
  PieChart,
  XAxis,
  YAxis,
} from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { toDateTimeTzString } from "@/lib/Formatters/toDateTimeString";
import colors from "tailwindcss/colors";
import {
  formatMinutes,
  formatStatistic,
} from "@/lib/Formatters/formatStatistic";
import { reportBookingsQueryGraphql } from "@/app/Reports/GraphQL/reportBookingsQuery.graphql";
import { generateBookingReportMutation } from "@/app/Reports/GraphQL/generateBookingReportMutation";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useUser } from "@clerk/clerk-react";
import { IconButton } from "@/lib/Components/Button/IconButton";
import { CheckIcon, PrinterIcon } from "@heroicons/react/24/outline";
import { toast } from "sonner";

export function ReportBookingScreen() {
  return (
    <Suspense fallback={<Skeleton className="h-80 w-full" />}>
      <BookingStats />
    </Suspense>
  );
}

function BookingStats() {
  const { user } = useUser();
  const [canSubmit, setCanSubmit] = useState(true);
  const [isPending, startTransition] = useTransition();
  const { mutateAsync } = useGqlMutation(generateBookingReportMutation);
  const { supplierId } = useGlobalSupplier();
  const { start, end } = getDatesFromFilterOption(
    TimelineFilterOption.SevenDays,
  );
  const [range, setRange] = useState<DateRange>({
    from: new Date(start),
    to: new Date(end),
  });

  const { data } = useSuspenseGqlQuery(reportBookingsQueryGraphql, {
    rangeStartDate: toDateTimeTzString(range.from)!,
    rangeEndDate: toDateTimeTzString(range.to)!,
    supplierId: supplierId,
  });

  return (
    <div
      className={cn({
        "animate-pulse": isPending,
      })}
    >
      <Card className="h-full">
        <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
          <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
            <CardTitle>Bookings</CardTitle>
            <CardDescription className="flex items-center space-x-2">
              <span>Showing total bookings for </span>
              <DateRangePicker
                className="px-1"
                showCompare={false}
                initialDateFrom={range.from}
                initialDateTo={range.to}
                align="start"
                onUpdate={(values) => {
                  setCanSubmit(true);
                  startTransition(() => {
                    setRange(values.range);
                  });
                }}
              />
              <IconButton
                Icon={!canSubmit ? CheckIcon : PrinterIcon}
                disabled={!canSubmit}
                tooltip="Export bookings"
                onClick={async () => {
                  await mutateAsync({
                    rangeStartDate: toDateTimeTzString(range.from)!,
                    rangeEndDate: toDateTimeTzString(range.to)!,
                    supplierId,
                  });

                  setCanSubmit(false);

                  toast(
                    `✅ Your report is being processed and will be sent to ${user?.primaryEmailAddress?.emailAddress}`,
                  );
                }}
              />
            </CardDescription>
          </div>

          <div className="flex">
            <div className="relative flex min-w-36 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-l sm:border-t-0 sm:px-8 sm:py-6">
              <span className="text-muted-foreground text-xs">
                Successful Bookings
              </span>
              <span className="whitespace-nowrap text-lg font-bold leading-none sm:text-3xl">
                {formatStatistic(
                  data.reportBookings.summary_stats.this_period.count_confirmed,
                )}
              </span>
            </div>
            <div className="relative flex min-w-36 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-l sm:border-t-0 sm:px-8 sm:py-6">
              <span className="text-muted-foreground text-xs">
                Confirmation time
              </span>
              <span className="whitespace-nowrap text-lg font-bold leading-none sm:text-3xl">
                {formatMinutes(
                  data.reportBookings.summary_stats.this_period
                    .average_time_to_confirmation,
                )}
              </span>
            </div>
            <div className="relative flex min-w-36 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-l sm:border-t-0 sm:px-8 sm:py-6">
              <span className="text-muted-foreground text-xs">Extra days</span>
              <span className="whitespace-nowrap text-lg font-bold leading-none sm:text-3xl">
                {formatStatistic(
                  data.reportBookings.summary_stats.this_period
                    .average_extra_days,
                )}
              </span>
            </div>
          </div>
        </CardHeader>
        <CardContent className="relative mt-6 grid grid-cols-3">
          {range.from && range.to ? (
            <>
              <BookingAreaChart
                from={range.from}
                to={range.to}
                className="col-span-2"
              />
              <BookingsPieChart
                from={range.from}
                to={range.to}
                className="col-span-1 flex items-center"
              />
            </>
          ) : null}
        </CardContent>
      </Card>
    </div>
  );
}

function BookingAreaChart({
  from,
  to,
  className,
}: {
  from: Date;
  to: Date;
  className?: string;
}) {
  const { data } = useSuspenseGqlQuery(reportBookingsQueryGraphql, {
    rangeStartDate: toDateTimeTzString(from)!,
    rangeEndDate: toDateTimeTzString(to)!,
  });

  const startDate = dayjs(from);
  const endDate = dayjs(to);
  const daysBetween = endDate.diff(startDate, "day");

  const chartData = Array.from({ length: daysBetween + 1 }, (_, index) => {
    const date = startDate.add(index, "day").format("YYYY-MM-DD");
    const dayData = data.reportBookings.daily_stats.find(
      (day) => day.date === date,
    );

    return {
      date,
      count_cancelled: dayData?.count_cancelled || 0,
      count_pending: dayData?.count_pending || 0,
      count_confirmed: dayData?.count_confirmed || 0,
      count_completed: dayData?.count_completed || 0,
      count_vip: dayData?.count_vip || 0,
    };
  });

  const chartConfig = {
    count_cancelled: {
      label: "Cancelled",
      color: colors["red"]["500"],
    },
    count_vip: {
      label: "VIP",
      color: colors["purple"]["500"],
    },
    count_pending: {
      label: "Pending",
      color: colors["yellow"]["500"],
    },
    count_confirmed: {
      label: "Confirmed",
      color: colors["green"]["500"],
    },
    count_completed: {
      label: "Completed",
      color: colors["blue"]["500"],
    },
  } satisfies ChartConfig;

  return (
    <div className={className}>
      <ChartContainer config={chartConfig}>
        <AreaChart
          accessibilityLayer
          data={chartData}
          margin={{
            left: -20,
            right: 12,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            tickLine={false}
            axisLine={false}
            includeHidden
            tickMargin={10}
            minTickGap={10}
            tickFormatter={(value) => dayjs(value).format("DD MMM")}
          />
          <YAxis axisLine={false} tickSize={10} type="number" />
          <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
          <Area
            dataKey="count_completed"
            type="basis"
            fill="var(--color-count_completed)"
            fillOpacity={0.4}
            stroke="var(--color-count_completed)"
            stackId="a"
          />
          <Area
            dataKey="count_confirmed"
            type="basis"
            fill="var(--color-count_confirmed)"
            fillOpacity={0.4}
            stroke="var(--color-count_confirmed)"
            stackId="a"
          />
          <Area
            dataKey="count_pending"
            type="basis"
            fill="var(--color-count_pending)"
            fillOpacity={0.4}
            stroke="var(--color-count_pending)"
            stackId="a"
          />
          <Area
            dataKey="count_vip"
            type="basis"
            fill="var(--color-count_vip)"
            fillOpacity={0.4}
            stroke="var(--color-count_vip)"
            stackId="a"
          />
          <Area
            dataKey="count_cancelled"
            type="basis"
            fill="var(--color-count_cancelled)"
            fillOpacity={0.4}
            stroke="var(--color-count_cancelled)"
            stackId="a"
          />
        </AreaChart>
      </ChartContainer>
    </div>
  );
}

const chartConfig = {
  count_cancelled: {
    label: "Cancelled",
    color: colors["red"]["500"],
  },
  count_vip: {
    label: "VIP",
    color: colors["purple"]["500"],
  },
  count_pending: {
    label: "Pending",
    color: colors["yellow"]["500"],
  },
  count_confirmed: {
    label: "Confirmed",
    color: colors["green"]["500"],
  },
  count_completed: {
    label: "Completed",
    color: colors["blue"]["500"],
  },
} satisfies ChartConfig;

export function BookingsPieChart({
  from,
  to,
  className,
}: {
  from: Date;
  to: Date;
  className: string;
}) {
  const { data } = useSuspenseGqlQuery(reportBookingsQueryGraphql, {
    rangeStartDate: toDateTimeTzString(from)!,
    rangeEndDate: toDateTimeTzString(to)!,
  });

  //Sum all daily stats
  const totals = data.reportBookings.daily_stats.reduce(
    (acc, curr) => {
      return {
        count_completed: acc.count_completed + curr.count_completed,
        count_confirmed: acc.count_confirmed + curr.count_confirmed,
        count_pending: acc.count_pending + curr.count_pending,
        count_vip: acc.count_vip + curr.count_vip,
        count_cancelled: acc.count_cancelled + curr.count_cancelled,
      };
    },
    {
      count_completed: 0,
      count_confirmed: 0,
      count_pending: 0,
      count_vip: 0,
      count_cancelled: 0,
    },
  );

  const chartData = Object.entries(totals).map(([key, value]) => {
    return {
      status: key,
      total: value,
      fill: `var(--color-${key})`,
    };
  });

  const total = chartData.reduce((acc, curr) => acc + curr.total, 0);

  return (
    <div className={cn(className, "h-full")}>
      <ChartContainer
        config={chartConfig}
        className={"mx-auto aspect-square min-h-[250px]"}
      >
        <PieChart>
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent hideLabel />}
          />
          <Pie
            data={chartData}
            dataKey="total"
            nameKey="status"
            innerRadius={60}
            strokeWidth={5}
          >
            <Label
              content={({ viewBox }) => {
                if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                  return (
                    <text
                      x={viewBox.cx}
                      y={viewBox.cy}
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      <tspan
                        x={viewBox.cx}
                        y={viewBox.cy}
                        className="fill-foreground text-3xl font-bold"
                      >
                        {total.toLocaleString()}
                      </tspan>
                      <tspan
                        x={viewBox.cx}
                        y={(viewBox.cy || 0) + 24}
                        className="fill-muted-foreground"
                      >
                        Bookings
                      </tspan>
                    </text>
                  );
                }
              }}
            />
          </Pie>
        </PieChart>
      </ChartContainer>
    </div>
  );
}
