import {
  BanknotesIcon,
  BuildingOfficeIcon,
  CalendarDaysIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { cn } from "@/lib/utils";
import { Link, LinkProps } from "@tanstack/react-router";
import { Badge } from "@/lib/Components/Badges/Badge";
import { ArrowLeftRightIcon, DollarSignIcon } from "lucide-react";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";

type SettingButton = {
  title: string;
  description: string;
  to: LinkProps["to"];
  icon: any;
  iconForeground: string;
  iconBackground: string;
  comingSoon?: boolean;
};

export function ReportSelectionScreen() {
  const { isAdmin } = useTenant();

  const actions: SettingButton[] = [
    {
      title: "Movement Report",
      description: "View and download movement reports",
      to: "/reports/movements",
      icon: ArrowLeftRightIcon,
      iconForeground: "text-orange-700",
      iconBackground: "bg-orange-50",
    },
    {
      title: "Bookings",
      description: "View and download booking reports",
      to: "/reports/bookings",
      icon: CalendarDaysIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    },
    {
      title: "Relocations",
      description: "View and download booking reports",
      to: "/reports/relocations",
      icon: ArrowLeftRightIcon,
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
    },
    {
      title: "Cancellations",
      description: "View cancellation reports",
      comingSoon: true,
      to: "/reports/cancellations",
      icon: UsersIcon,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
    },
    {
      title: "Invoices",
      description: "Track expenses",
      to: "/reports/invoices",
      icon: BanknotesIcon,
      iconForeground: "text-yellow-700",
      iconBackground: "bg-yellow-50",
    },
    ...(isAdmin
      ? ([
          {
            title: "Suppliers",
            description: "View and download supplier reports",
            to: "/reports/suppliers",
            icon: BuildingOfficeIcon,
            iconForeground: "text-fuchsia-700",
            iconBackground: "bg-fuchsia-50",
          },
          {
            title: "Payments",
            description: "View and download payment reports",
            to: "/reports/payments",
            icon: DollarSignIcon,
            iconForeground: "text-lime-700",
            iconBackground: "bg-lime-50",
          },
          {
            title: "Sales",
            description: "View sales report",
            to: "/reports/sales",
            icon: DollarSignIcon,
            iconForeground: "text-orange-700",
            iconBackground: "bg-orange-50",
          },
        ] satisfies SettingButton[])
      : []),
  ];

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
      {actions.map((action, actionIdx) => (
        <div
          key={action.title}
          className={cn(
            actionIdx === 0
              ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
              : "",
            actionIdx === 1 ? "sm:rounded-tr-lg" : "",
            actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
            actionIdx === actions.length - 1
              ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
              : "",
            "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500",
          )}
        >
          <div className="">
            <span
              className={cn(
                action.iconBackground,
                action.iconForeground,
                "inline-flex rounded-lg p-3 ring-4 ring-white",
              )}
            >
              <action.icon className="h-6 w-6" aria-hidden="true" />
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              <Link to={action.to} className="focus:outline-none" search={{}}>
                <span className="absolute inset-0" aria-hidden="true" />
                {action.title}
              </Link>

              {action.comingSoon ? (
                <Badge className="ml-2" label="Coming soon" intent="neutral" />
              ) : null}
            </h3>
            <p className="mt-2 text-sm text-gray-500">{action.description}</p>
          </div>
          <span
            className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
            aria-hidden="true"
          >
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        </div>
      ))}
    </div>
  );
}
