import { MoreVertical } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useBookingRecord } from "@/app/Bookings/Hooks/useBookingRecord";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { Separator } from "@/components/ui/separator";
import dayjs from "dayjs";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierInsuranceOptionEditDialog } from "@/app/Suppliers/Components/SupplierInsuranceOptionEditDialog";
import { useNavigate } from "@tanstack/react-router";
import { RelocationEditDialog } from "@/app/Relocations/Components/RelocationEditDialog";
import DOMPurify from "dompurify";

export function BookingInsuranceCard() {
  const booking = useBookingRecord();
  const { open } = useDialog(SupplierInsuranceOptionEditDialog);
  const { open: editRelocation } = useDialog(RelocationEditDialog);

  const navigate = useNavigate();
  const insuranceOption = booking.relocation.supplierInsuranceOption;

  return (
    <Card className="overflow-hidden">
      <CardHeader className="bg-muted/50 flex flex-row items-center">
        <div className="grid gap-0.5">
          <CardTitle className="group flex items-center gap-2 text-lg">
            Insurance
          </CardTitle>
          <CardDescription>
            {booking.relocation.supplierInsuranceOption?.name}
          </CardDescription>
        </div>
        <div className="ml-auto flex items-center gap-1">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="outline" className="h-8 w-8">
                <MoreVertical className="h-3.5 w-3.5" />
                <span className="sr-only">More</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                onClick={async () => {
                  await navigate({
                    to: "/settings/insurance",
                  });
                }}
              >
                View insurance
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => {
                  open({ insuranceOptionId: insuranceOption.id });
                }}
              >
                Edit insurance
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => {
                  editRelocation({
                    id: booking.relocation.id,
                  });
                }}
              >
                Change insurance
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </CardHeader>
      <CardContent className="p-6 text-sm">
        <div className="grid gap-3">
          <div className="font-semibold">Insurance Details</div>
          <dl className="grid gap-3">
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Bond</dt>
              <dd>
                {formatCurrency(
                  insuranceOption?.bond_amount,
                  booking.relocation.currency,
                )}
              </dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="text-muted-foreground">Standard liability</dt>
              <dd>
                {formatCurrency(
                  insuranceOption?.standard_liability_amount,
                  booking.relocation.currency,
                )}
              </dd>
            </div>
          </dl>
        </div>
        <Separator className="my-4" />
        <div className="grid gap-3">
          <div className="font-semibold">Reduction options</div>
          <div
            className="prose prose-sm"
            dangerouslySetInnerHTML={{
              __html: insuranceOption?.liability_reduction_options
                ? DOMPurify.sanitize(
                    insuranceOption.liability_reduction_options,
                  )
                : "--",
            }}
          ></div>
        </div>
      </CardContent>
      <CardFooter className="bg-muted/50 flex flex-row items-center border-t px-6 py-3">
        <div className="text-muted-foreground text-xs">
          Updated{" "}
          <time dateTime="2023-11-23">
            {dayjs(
              booking.relocation.supplierInsuranceOption?.updated_at,
            ).format("LL")}
          </time>
        </div>
      </CardFooter>
    </Card>
  );
}
