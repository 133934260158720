import { PaymentStatus } from "@/gql/graphql";

export const paymentMessages = {
  status: {
    [PaymentStatus.Paid]: "Paid",
    [PaymentStatus.PartiallyRefunded]: "Partially refunded",
    [PaymentStatus.Pending]: "Pending",
    [PaymentStatus.Refunded]: "Refunded",
    [PaymentStatus.Unpaid]: "Unpaid",
    [PaymentStatus.Voided]: "Voided",
  },
};
