import ManContent from "./001-drawkit-content-man-colour.svg?react";
import ManPhoneNoteSvg from "./009-app-user-colour.svg?react";
import ListAppSvg from "./013-drawkit-list-app-colour.svg?react";
import WomanSpeechBubbleSvg from "./017-drawkit-support-woman-colour.svg?react";
import ManLaptopSvg from "./019-drawkit-support-man-colour.svg?react";
import FolderManSvg from "./023-drawkit-folder-man-colour.svg?react";
import IllustrationNotebookSvg from "./027-drawkit-notebook-man-colour.svg?react";
import IllustrationFullstackSvg from "./029-drawkit-full-stack-man-colour.svg?react";
import IllustrationBinocularsSvg from "./037-drawkit-nature-man-colour.svg?react";
import ChoicesColorSvg from "./049-choices-colour.svg?react";
import HoldingPhone from "./053-holding-phone-colour.svg?react";
import IllustrationHikerSVG from "./057-hiker-man-colour.svg?react";
import DocumentsSvg from "./067-travel-tickets-colour.svg?react";
import IllustrationIdeaSvg from "./077-student-colour.svg?react";
import MobilePostSvg from "./105-mobile-post-colour.svg?react";
import CalendarSvg from "./085-calendar-colour.svg?react";
import ChartsSvg from "./033-drawkit-charts-and-graphs.svg?react";
import ShippingPackageSvg from "./069-shipping-package-colour.svg?react";

export const IllustrationIdea = IllustrationIdeaSvg;
export const IllustrationNotebook = IllustrationNotebookSvg;
export const IllustrationWomanSpeechBubble = WomanSpeechBubbleSvg;
export const IllustrationBinoculars = IllustrationBinocularsSvg;
export const IllustrationsManPhoneNote = ManPhoneNoteSvg;
export const IllustrationsDocuments = DocumentsSvg;
export const IllustrationMobilePost = MobilePostSvg;
export const IllustrationsManContent = ManContent;
export const IllustrationsHiker = IllustrationHikerSVG;
export const IllustrationFolderMan = FolderManSvg;
export const IllustrationHoldingPhone = HoldingPhone;
export const IllustrationCalendar = CalendarSvg;
export const IllustrationManLaptop = ManLaptopSvg;
export const IllustrationFullstack = IllustrationFullstackSvg;
export const IllustrationListApp = ListAppSvg;
export const IllustrationChoices = ChoicesColorSvg;
export const IllustrationCharts = ChartsSvg;
export const IllustrationShippingPackage = ShippingPackageSvg;
