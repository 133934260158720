import { DetailedHTMLProps, HTMLAttributes } from "react";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { cn } from "@/lib/utils";

export function FormDivider({
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const { isEditing } = useForm();

  return (
    <div
      {...props}
      className={cn("col-span-full mb-6 mt-3", {
        "border-b border-gray-200": isEditing,
        className,
      })}
    ></div>
  );
}
