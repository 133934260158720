import { graphql } from "@/gql";

export const supplierOfficeOpenHoursList = graphql(`
  query SupplierOfficeOpenHoursList($supplierId: ID!) {
    supplierOffices(
      first: 200
      page: 1
      supplier_id: $supplierId
      archived: false
    ) {
      data {
        id
        name
        delivery_hours
        departure_hours
      }
    }
  }
`);
