import { ReactNode } from "react";

interface RecordCreateScreenProps {
  children: ReactNode;
}

export function RecordCreateScreen({ children }: RecordCreateScreenProps) {
  return (
    <div className="scroll-container w-full overflow-y-scroll">
      <div className="mx-auto max-w-6xl p-6">{children}</div>
    </div>
  );
}
