import { RecordScreen } from "@/lib/Components/Screens/RecordScreen/RecordScreen";
import { useCityRecord } from "@/app/Cities/Cities/Hooks/useCityRecord";

import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { ArrowLeftRightIcon } from "lucide-react";

export function CityRecordScreen() {
  const city = useCityRecord();

  return (
    <RecordScreen
      title={city.name}
      tabs={[
        {
          name: "Details",
          to: "/cities/$id",
          Icon: DocumentTextIcon,
        },
        {
          name: "Trips",
          to: "/cities/$id/trips",
          Icon: ArrowLeftRightIcon,
        },
      ]}
    />
  );
}
