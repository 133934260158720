import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { formatDateTimeLocal } from "@/lib/Formatters/formatDateTime";
import dayjs from "dayjs";

export function TimeSinceNow({
  time,
  className,
}: {
  time: string;
  className?: string;
}) {
  if (!time) {
    return "--";
  }

  return (
    <Tooltip>
      <TooltipTrigger
        className={cn(
          "-ml-1 rounded-sm px-1 transition-colors hover:bg-gray-100",
          className,
        )}
      >
        <time>{formatDateTimeSinceNow(time)}</time>
      </TooltipTrigger>
      <TooltipContent className="space-y-2">
        <p>
          <span className="mr-2 font-bold">{dayjs.tz.guess()}:</span>
          <span>{formatDateTimeLocal(time)}</span>
        </p>
      </TooltipContent>
    </Tooltip>
  );
}
