import { integrationLinksList } from "@/app/Settings/Settings/GraphQL/integrationLinksQueries";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { CellContext } from "@tanstack/react-table";
import { VehicleListItem } from "@/app/Vehicles/GraphQL/vehicleListQuery";
import { ModelType } from "@/gql/graphql";
import { useIntegrationRecord } from "@/app/Settings/Settings/Hooks/useIntegrationRecord";
import { JucyCodeComboBox } from "@/app/Settings/Integrations/Jucy/Components/JucyCodeComboBox";
import { useJucyVehicleCodesList } from "@/app/Settings/Integrations/Jucy/Hooks/useJucyCodesList";

export function JucyVehicleCodeCell({
  row,
}: CellContext<VehicleListItem, any>) {
  const jucyIntegration = useIntegrationRecord();

  const integrationLinks = useGqlQuery(integrationLinksList, {
    integration_id: jucyIntegration.id,
  });

  const integrationLink = integrationLinks.data?.integrationLinks.data.find(
    (item) => {
      return (
        item.object_type === ModelType.Vehicle &&
        item.object_id === row.original.id
      );
    },
  );

  const { data, isLoading } = useJucyVehicleCodesList();

  const externalIds = data
    ? Array.from(
        new Set(
          data.map((vehicle) => {
            return {
              label: vehicle.code,
              value: vehicle.code,
            };
          }),
        ),
      )
    : [];

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <JucyCodeComboBox
      jucyIntegration={jucyIntegration}
      externalIds={externalIds}
      rowItem={row.original}
      integrationLink={integrationLink}
      type={ModelType.Vehicle}
    />
  );
}
