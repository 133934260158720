import { useState } from "react";
import {
  cityListRoute,
  cityRoute,
} from "@/app/Cities/Cities/Routes/cityRoutes";
import { SidebarList } from "@/lib/Components/SideBarList/SideBarList";
import { getCityListQueryVariables } from "@/app/Cities/Cities/Utils/getCityListQueryVariables";
import { useInfiniteGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { cityListQuery } from "@/app/Cities/Cities/GraphQL/cityListQuery";
import { keepPreviousData } from "@tanstack/react-query";

export function CityListScreen() {
  const searchParams = cityListRoute.useSearch();
  const [search, setSearch] = useState(searchParams.citySearch ?? "");
  const navigate = cityListRoute.useNavigate();
  const variables = getCityListQueryVariables({
    ...searchParams,
    citySearch: search,
  });

  const { data, isFetching, isFetchingNextPage, fetchNextPage } =
    useInfiniteGqlQuery(cityListQuery, variables, {
      placeholderData: keepPreviousData,
      initialPageParam: {
        page: 1,
      },
      getNextPageParam: (lastPage) => {
        return lastPage.cities.paginatorInfo.hasMorePages
          ? {
              page: lastPage.cities.paginatorInfo.currentPage + 1,
            }
          : undefined;
      },
    });

  const items = data?.pages.flatMap((p) => p.cities.data);
  const totalCount = data?.pages.at(0)?.cities.paginatorInfo.total ?? 0;

  return (
    <SidebarList
      isFetchingNextPage={isFetchingNextPage}
      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      itemNode={(item) => item.name}
      linkProps={(item) => ({
        to: cityRoute.to,
        params: {
          id: item.id,
        },
      })}
      onClearFilters={() => {
        setSearch("");
      }}
      items={items}
      totalCount={totalCount}
      search={search}
      onSearchChange={(newSearch) => {
        setSearch(newSearch);
        navigate({
          search: (s) => ({
            ...s,
            citySearch: newSearch,
          }),
        });
      }}
      listRoute={"/cities"}
      createRoute={"/cities"}
    />
  );
}
