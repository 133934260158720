import { z } from "zod";

export const supplierInsuranceOptionValidationZod = z.object({
  name: z.string(),
  bond_amount: z.number().nullable(),
  standard_liability_amount: z.number().nullable(),
  liability_reduction_options: z.string().nullable(),
  supplier: z
    .object({
      connect: z.string(),
    })
    .nullable(),
});
