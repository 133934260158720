import { graphql } from "@/gql";

export const cancelBookingMutation = graphql(`
  mutation CancelBooking($id: ID!, $input: CancelBookingStatusInput!) {
    cancelBooking(id: $id, input: $input) {
      id
    }
  }
`);

export const vipBookingMutation = graphql(`
  mutation VipBooking($id: ID!, $input: VipBookingStatusInput!) {
    vipBooking(id: $id, input: $input) {
      id
    }
  }
`);

export const confirmBookingMutation = graphql(`
  mutation ConfirmBooking($id: ID!, $input: ConfirmBookingInput!) {
    confirmBooking(id: $id, input: $input) {
      id
    }
  }
`);

export const pendingBookingMutation = graphql(`
  mutation PendingBooking($id: ID!, $input: PendingBookingStatusInput!) {
    pendingBooking(id: $id, input: $input) {
      id
    }
  }
`);
