import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { BookingPaymentRequestStatus } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { resolveBookingPaymentRequestMutation } from "@/app/Bookings/GraphQL/bookingPaymentRequestMutations";
import { BookingPaymentRequestListItem } from "@/app/Bookings/GraphQL/bookingPaymentRequestList";

type BookingPaymentRequestResolveDialogProps = {
  bookingPaymentRequest: BookingPaymentRequestListItem;
  type: "capture" | "cancel";
};
export function BookingPaymentRequestResolveDialog({
  bookingPaymentRequest,
  type,
}: BookingPaymentRequestResolveDialogProps) {
  const { mutateAsync } = useGqlMutation(resolveBookingPaymentRequestMutation);
  const { close } = useDialogState();
  return (
    <GenericDialog title="Payment Request">
      <GenericForm
        initialValues={{
          amountToCapture:
            type === "capture" ? bookingPaymentRequest.amount : null!,
          status:
            type === "capture"
              ? BookingPaymentRequestStatus.Paid
              : BookingPaymentRequestStatus.Cancelled,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: bookingPaymentRequest.id,
            input: values,
          });

          close();
        }}
      >
        {type === "capture" ? (
          <CurrencyInput
            label="Amount to capture"
            name="amountToCapture"
            currency={bookingPaymentRequest.currency}
          />
        ) : (
          <div className="col-span-full rounded-md bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  Attention needed
                </h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>Cancelling this payment request will cancel the payment</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <DialogSubmitButton
          text={type === "capture" ? "Capture payment" : "Cancel payment"}
        />
      </GenericForm>
    </GenericDialog>
  );
}
