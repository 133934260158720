import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { useNavigate } from "@tanstack/react-router";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { VehicleEditDialog } from "@/app/Vehicles/Components/VehicleEditDialog";
import {
  ArchiveBoxIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { VehicleListItem } from "@/app/Vehicles/GraphQL/vehicleListQuery";
import { ConfirmDeleteDialog } from "@/lib/Components/Dialog/ConfirmDeleteDialog";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import {
  archiveVehicleMutation,
  deleteVehicleMutation,
} from "@/app/Vehicles/GraphQL/vehicleMutations";

export function useVehicleActions(): ActionsHook<VehicleListItem> {
  const navigate = useNavigate();
  const { open } = useDialog(VehicleEditDialog);
  const { open: openDeleteDialog } = useDialog(ConfirmDeleteDialog);
  const { mutateAsync: deleteVehicle } = useGqlMutation(deleteVehicleMutation);
  const { mutateAsync: archiveOffice } = useGqlMutation(archiveVehicleMutation);

  return (vehicle) => {
    return [
      {
        label: "Actions",
        items: [
          // {
          //   actions: [
          //     {
          //       label: "View",
          //       Icon: LinkIcon,
          //       handleAction: async () => {
          //         await navigate({
          //           to: "/vehicles/$id",
          //           params: {
          //             id: model.id,
          //           },
          //         });
          //       },
          //     },
          //   ],
          // },
          {
            actions: [
              {
                label: "Edit",
                Icon: PencilIcon,
                intent: "neutral",
                handleAction: async () => {
                  open({
                    vehicleId: vehicle.id,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Archive",
                Icon: ArchiveBoxIcon,
                isHiddenTopBar: true,
                intent: "highlight",
                handleAction: () => {
                  openDeleteDialog({
                    message:
                      "Are you sure you want to archive this vehicle? Archiving a vehicle will remove it from the list of active vehicles.",
                    buttonText: `Archive ${vehicle.name}`,
                    title: `Archive ${vehicle.name}`,
                    onDelete: async () => {
                      await archiveOffice({ id: vehicle.id });
                    },
                  });
                },
              },
              {
                label: "Delete",
                Icon: TrashIcon,
                isHiddenTopBar: true,
                intent: "danger",
                handleAction: () => {
                  openDeleteDialog({
                    message: "Are you sure you want to delete this office?",
                    buttonText: `Delete ${vehicle.name}`,
                    title: `Delete ${vehicle.name}`,
                    onDelete: async () => {
                      await deleteVehicle({ id: vehicle.id });
                      await navigate({
                        to: "/vehicles/create",
                      });
                    },
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
