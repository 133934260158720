import { InvoiceListSearchParams } from "@/app/Invoices/Routes/invoiceRoutes";
import { QueryInvoicesOrderByColumn, SortOrder } from "@/gql/graphql";
import { InvoiceListQueryVariables } from "@/app/Invoices/GraphQL/invoiceListQuery";

export function getInvoiceListQueryVariables(
  searchParams: InvoiceListSearchParams,
  search?: string,
): InvoiceListQueryVariables {
  const baseParams = {
    first: 20,
    page: 1,
    status: searchParams.invoiceStatus,
    search,
  };

  return {
    ...baseParams,
    orderBy: search
      ? undefined
      : [
          {
            order: SortOrder.Desc,
            column: QueryInvoicesOrderByColumn.Id,
          },
        ],
  };
}
