import { RecordScreen } from "@/lib/Components/Screens/RecordScreen/RecordScreen";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import {
  ActivityIcon,
  ArrowLeftRightIcon,
  Clock,
  DollarSignIcon,
} from "lucide-react";
import { useOfficeRecord } from "@/app/Offices/Hooks/useOfficeRecord";
import { useSupplierOfficeActions } from "@/app/Offices/Hooks/useSupplierOfficeActions";
import { Badge } from "@/components/catalyst/badge";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

export function OfficeRecordScreen() {
  const office = useOfficeRecord();
  const getActions = useSupplierOfficeActions();
  const { hasSupplier } = useGlobalSupplier();

  return (
    <RecordScreen
      title={office.name}
      breadcrumbs={
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink
                to="/suppliers/$id"
                params={{
                  id: office.supplier.id,
                }}
              >
                {office.supplier.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink to="/offices">Offices</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{office.name}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      }
      badges={
        <>
          {!hasSupplier ? (
            <Badge color="zinc">{office.supplier.name}</Badge>
          ) : null}
          {office.archived_at ? <Badge color="purple">archived</Badge> : null}
        </>
      }
      actionsGroups={getActions(office)}
      tabs={[
        {
          name: "Details",
          to: "/offices/$id",
          Icon: DocumentTextIcon,
        },
        {
          name: "Open hours",
          to: "/offices/$id/open-hours",
          Icon: Clock,
        },
        {
          name: "Relocations",
          to: "/offices/$id/relocations",
          Icon: ArrowLeftRightIcon,
        },
        {
          name: "Bookings",
          to: "/offices/$id/bookings",
          Icon: DollarSignIcon,
        },
        {
          name: "Activity",
          to: "/offices/$id/activity",
          Icon: ActivityIcon,
        },
      ]}
    />
  );
}
