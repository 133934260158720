import { BookingChangeRequestStatus } from "@/gql/graphql";

export const bookingChangeRequestMessages = {
  status: {
    [BookingChangeRequestStatus.Approved]: "approved",
    [BookingChangeRequestStatus.AwaitingPayment]: "payment required",
    [BookingChangeRequestStatus.Declined]: "declined",
    [BookingChangeRequestStatus.Pending]: "pending",
  } satisfies {
    [key in BookingChangeRequestStatus]: string;
  },
};
