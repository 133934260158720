import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import {
  MatchingRelocationsTable,
  SimilarRelocationsTable,
} from "@/app/Bookings/Screens/BookingFindRelocationScreen";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import {
  BookingRecord,
  bookingRecordQuery,
} from "@/app/Bookings/GraphQL/bookingRecordQuery";
import { BookingSummaryCard } from "@/app/Bookings/Components/Cards/BookingSummaryCard";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { Button } from "@/lib/Components/Button/Button";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { BookingChangeRelocationDialog } from "@/app/Bookings/Components/BookingChangeRelocationDialog";

type BookingFindRelocationDialogProps = {
  bookingId: string;
};
export function BookingFindRelocationDialog({
  bookingId,
}: BookingFindRelocationDialogProps) {
  const { close } = useDialogState();
  const { data } = useSuspenseGqlQuery(bookingRecordQuery, {
    id: bookingId,
  });

  const booking = data.booking;

  return (
    <GenericDialog title={"Find similar relocations"} className="space-y-12">
      <BookingSummaryCard booking={booking} />

      <MatchingRelocationsTable
        booking={booking}
        onAssign={() => {
          close();
        }}
      />
      <SimilarRelocationsTable
        booking={booking}
        onAssign={() => {
          close();
        }}
      />

      <AssignRelocationDialog booking={booking} />
    </GenericDialog>
  );
}

export function AssignRelocationDialog({
  booking,
}: {
  booking: BookingRecord;
}) {
  const { open } = useDialog(BookingChangeRelocationDialog);

  return (
    <div className="text-center">
      <QuestionMarkCircledIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">
        {"Can't"} find the right relocation?
      </h2>
      <p className="mt-1 text-sm text-gray-500">
        Manually assign a different relocation to this booking by using the
        relocation ID.
      </p>
      <p className="mt-3 flex justify-center">
        <Button
          onClick={() => {
            open({
              booking: booking,
              newRelocation: null,
            });
          }}
        >
          Change relocation
        </Button>
      </p>
    </div>
  );
}
