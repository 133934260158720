import { graphql } from "@/gql";

export const createSupplierMutation = graphql(`
  mutation CreateSupplier($input: CreateSupplierInput!) {
    createSupplier(input: $input) {
      id
    }
  }
`);

export const updateSupplierMutation = graphql(`
  mutation UpdateSupplier($id: ID!, $input: UpdateSupplierInput!) {
    updateSupplier(id: $id, input: $input) {
      id
    }
  }
`);

export const deleteSupplierMutation = graphql(`
  mutation DeleteSupplier($id: ID!) {
    deleteSupplier(id: $id) {
      id
    }
  }
`);
