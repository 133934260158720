import { BookingChangeRequestStatus } from "@/gql/graphql";
import { BadgeColor } from "@/components/catalyst/badge";

export const bookingChangeRequestStatusColorMap: Record<
  BookingChangeRequestStatus,
  BadgeColor
> = {
  [BookingChangeRequestStatus.Approved]: "green",
  [BookingChangeRequestStatus.AwaitingPayment]: "blue",
  [BookingChangeRequestStatus.Declined]: "red",
  [BookingChangeRequestStatus.Pending]: "yellow",
};
