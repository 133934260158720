export function calculatePercentChange({
  current = 0,
  previous = 0,
}: {
  current?: number;
  previous?: number;
}) {
  if (current === 0) {
    return { change: 0, percent: 0, decimalPercent: 0 };
  }

  const change = current - previous;
  const decimalPercent = change / Math.abs(previous);
  const percent = decimalPercent * 100;

  return { change, percent, decimalPercent };
}
