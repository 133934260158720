import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { UpdateBookingInput } from "@/gql/graphql";
import { BookingUpdateDetailsForm } from "@/app/Bookings/Forms/BookingUpdateDetailsForm";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { bookingRecordQuery } from "@/app/Bookings/GraphQL/bookingRecordQuery";
import { updateBookingMutation } from "@/app/Bookings/GraphQL/bookingMutations";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";

export function BookingEditReservationDetailsDialog({
  bookingId,
}: {
  bookingId: string;
}) {
  const { data } = useSuspenseGqlQuery(bookingRecordQuery, { id: bookingId });
  const { mutateAsync } = useGqlMutation(updateBookingMutation);
  const { close } = useDialogState();
  const record = data.booking;

  return (
    <GenericDialog title="Edit Reservation Details">
      <GenericForm<UpdateBookingInput>
        initialValues={{
          depart_at: record.depart_at,
          deliver_at: record.deliver_at,
          supplier_reference: record.supplier_reference,
          customer_note: record.customer_note,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: values,
          });

          close();
        }}
      >
        <BookingUpdateDetailsForm />

        <DialogSubmitButton text="Update reservation details" />
      </GenericForm>
    </GenericDialog>
  );
}
