import { useQuery } from "@tanstack/react-query";
import { QueryClient } from "@/lib/ReactQuery/QueryClient";

export function useGeoPermissions() {
  return useQuery<PermissionState>({
    queryKey: ["permissions", "geolocation"],
    queryFn: async () => {
      const res = await navigator.permissions.query({ name: "geolocation" });

      res.onchange = () => {
        QueryClient.invalidateQueries({
          queryKey: ["permissions", "geolocation"],
        });
      };

      return res.state;
    },
  });
}

export function useGeoLocation() {
  const { data: permissionState } = useGeoPermissions();

  return useQuery({
    queryKey: ["geolocation"],
    queryFn: async () =>
      new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position);
          },
          (err) => {
            reject(err);
          },
        );
      }),
    enabled: permissionState === "granted",
  });
}
