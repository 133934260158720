import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { quoteRecordQuery } from "@/app/Quotes/GraphQL/quoteRecordQuery";
import { useParams } from "@tanstack/react-router";

export function useQuoteRecord() {
  const { quoteId } = useParams({
    from: "/_app/quotes/_quoteRecordLayout/$quoteId",
  });

  const { data } = useSuspenseGqlQuery(quoteRecordQuery, {
    id: quoteId,
  });

  return data.quote;
}
