import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { MarketingEventConditionField } from "@/gql/graphql";

export function MarketingEventConditionFieldSelectInput(
  props: Omit<SelectInputProps, "options">,
) {
  const options = Object.entries(MarketingEventConditionField).map(
    ([key, value]) => ({
      label: key,
      value,
    }),
  );

  return (
    <SelectInput
      options={[
        {
          label: "Fields",
          options,
        },
      ]}
      {...props}
    />
  );
}
