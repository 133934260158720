import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { IllustrationMobilePost } from "@/assets/Illustrations";
import { PhoneInput } from "@/lib/Components/Form/Inputs/PhoneInput";
import { Region } from "@/gql/graphql";
import { GenericFieldArray } from "@/lib/Components/Form/FieldArray/GenericFieldArray";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { PhoneIcon } from "lucide-react";

type ContactPhonesFormProps = {
  label?: string;
};
export function ContactPhonesForm({ label }: ContactPhonesFormProps) {
  return (
    <GenericFieldArray
      label={label}
      lineItemLabel="phone number"
      fieldArrayKey={"phones.upsert"}
      Icon={PhoneIcon}
      emptyState={
        <EmptyState
          Icon={IllustrationMobilePost}
          header="No phones"
          subheader="Add a phone number for the contact"
        />
      }
    >
      {(email, index) => (
        <>
          <PhoneInput
            defaultRegion={Region.Au}
            name={`phones.upsert.${index}.phone`}
            label={`Phone ${index + 1}`}
          />
          {/*<SwitchInput*/}
          {/*  name={`phones.upsert.${index}.is_primary`}*/}
          {/*  label="Primary phone"*/}
          {/*/>*/}
          <SwitchInput
            name={`phones.upsert.${index}.is_billing`}
            label="Billing phone"
          />
        </>
      )}
    </GenericFieldArray>
  );
}
