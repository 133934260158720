import React from "react";
import { MediaFieldsFragment } from "@/gql/graphql";
import { Image } from "@/app/Media/Components/Image";
import { cn } from "@/lib/utils";

type AvatarProps = {
  square?: boolean;
  initials?: string;
  alt?: string;
  className?: string;
} & { media?: MediaFieldsFragment | null; src?: string };

export function Avatar({
  media,
  src,
  square = false,
  initials,
  alt = "",
  className,
  ...props
}: AvatarProps & React.ComponentPropsWithoutRef<"span">) {
  return (
    <span
      data-slot="avatar"
      {...props}
      className={cn(
        className,
        // Basic layout
        "inline-grid shrink-0 align-middle [--avatar-radius:20%] [--ring-opacity:20%] *:col-start-1 *:row-start-1",
        "outline outline-1 -outline-offset-1 outline-black/[--ring-opacity] dark:outline-white/[--ring-opacity]",
        // Add the correct border radius
        square
          ? "rounded-[--avatar-radius] *:rounded-[--avatar-radius]"
          : "rounded-full *:rounded-full",
      )}
    >
      {initials && (
        <svg
          className="size-full select-none fill-current p-[5%] text-[48px] font-medium uppercase"
          viewBox="0 0 100 100"
          aria-hidden={alt ? undefined : "true"}
        >
          {alt && <title>{alt}</title>}
          <text
            x="50%"
            y="50%"
            alignmentBaseline="middle"
            dominantBaseline="middle"
            textAnchor="middle"
            dy=".125em"
          >
            {initials}
          </text>
        </svg>
      )}
      {media && (
        <Image
          className="size-full bg-white object-cover"
          media={media}
          alt={alt}
        />
      )}

      {src && <img className="size-full" src={src} alt={alt} />}
    </span>
  );
}
