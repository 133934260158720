import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { Measurement } from "@/gql/graphql";

export function MeasurementInput(
  props: Omit<SelectInputProps<any>, "options">,
) {
  const options: {
    [key in Measurement]: {
      label: string;
      value: Measurement;
    };
  } = {
    [Measurement.Imperial]: {
      label: "Imperial",
      value: Measurement.Imperial,
    },
    [Measurement.Metric]: {
      label: "Metric",
      value: Measurement.Metric,
    },
  };

  return (
    <SelectInput
      options={[
        {
          label: "Measurement",
          options: [options[Measurement.Imperial], options[Measurement.Metric]],
        },
      ]}
      {...props}
    />
  );
}
