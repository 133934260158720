import { z } from "zod";
import { createRoute } from "@tanstack/react-router";
import { VehicleActivityScreen } from "@/app/Vehicles/Screens/VehicleActivityScreen";
import { VehicleRecordScreen } from "@/app/Vehicles/Screens/VehicleRecordScreen";
import { VehicleDetailsScreen } from "@/app/Vehicles/Screens/VehicleDetailsScreen";
import { VehicleListScreen } from "@/app/Vehicles/Screens/VehicleListScreen";
import { VehicleCreateScreen } from "@/app/Vehicles/Screens/VehicleCreateScreen";
import { appLayoutRoute } from "@/routes";
import { getVehicleListQueryVariables } from "@/app/Vehicles/Utils/getVehicleListQueryVariables";
import { vehicleListQuery } from "@/app/Vehicles/GraphQL/vehicleListQuery";
import {
  fetchQuery,
  prefetchInfiniteQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { vehicleRecordQuery } from "@/app/Vehicles/GraphQL/vehicleRecordQuery";
import { VehicleImagesScreen } from "@/app/Vehicles/Screens/VehicleImagesScreen";
import { VehicleEmptyScreen } from "@/app/Vehicles/Screens/VehicleEmptyScreen";

const vehicleListSearchParams = z.object({
  vehicleSearch: z.string().optional(),
});

export type VehicleListSearchParams = z.infer<typeof vehicleListSearchParams>;
export const vehicleListRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/vehicles",
  component: VehicleListScreen,
  validateSearch: vehicleListSearchParams,
  loaderDeps: ({ search }) => search,
  loader: ({ deps, context: { auth } }) => {
    const variables = getVehicleListQueryVariables(deps);
    return prefetchInfiniteQuery(vehicleListQuery, variables, auth);
  },
});
export const vehicleRoute = createRoute({
  getParentRoute: () => vehicleListRoute,
  path: "$id",
  component: VehicleRecordScreen,
  loader: ({ params: { id }, context: { auth } }) => {
    return fetchQuery(vehicleRecordQuery, { id }, auth);
  },
});
export const vehicleEmptyRoute = createRoute({
  getParentRoute: () => vehicleListRoute,
  path: "/",
  component: VehicleEmptyScreen,
});
export const vehicleCreateRoute = createRoute({
  getParentRoute: () => vehicleListRoute,
  path: "/create",
  component: VehicleCreateScreen,
});
export const vehicleDetailRoute = createRoute({
  getParentRoute: () => vehicleRoute,
  path: "/",
  component: VehicleDetailsScreen,
});
export const vehicleImagesRoute = createRoute({
  getParentRoute: () => vehicleRoute,
  path: "/images",
  component: VehicleImagesScreen,
});
export const vehicleActivityRoute = createRoute({
  getParentRoute: () => vehicleRoute,
  path: "/activity",
  component: VehicleActivityScreen,
});
