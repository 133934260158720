import { ReactNode } from "react";
import { FormikValues } from "formik";
import { GenericForm, GenericFormProps } from "../../Form/Forms/GenericForm";
import { SubmitButtonInput } from "../../Form/Inputs/SubmitButtonInput";
import { FormationErrorBadge } from "@/lib/Components/Form/Components/FormationErrorBadge";

export type CreateCardProps<TValue extends FormikValues> =
  GenericFormProps<TValue> & {
    hideTitle?: boolean;
    title?: ReactNode;
    children?: ReactNode;
    onSubmit?: (res: TValue) => void;
  };

export function RecordCreateCard<TValue extends FormikValues>({
  title,
  children,
  hideTitle,
  ...props
}: CreateCardProps<TValue>) {
  return (
    <GenericForm {...props}>
      <>
        {!hideTitle && (
          <h1 className="col-span-full mb-6 text-lg font-medium leading-6 text-gray-900">
            {title}
            <FormationErrorBadge />
          </h1>
        )}
        {children}
        <SubmitButtonInput>Create</SubmitButtonInput>
      </>
    </GenericForm>
  );
}
