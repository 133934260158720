import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { useMatchRoute, useNavigate } from "@tanstack/react-router";
import { LinkIcon } from "lucide-react";
import {
  ArchiveBoxIcon,
  CalendarDaysIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { OfficeUpdateDialog } from "@/app/Offices/Components/OfficeUpdateDialog";
import { ConfirmDeleteDialog } from "@/lib/Components/Dialog/ConfirmDeleteDialog";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import {
  archiveSupplierOfficeMutation,
  deleteSupplierOfficeMutation,
} from "@/app/Offices/GraphQL/supplierOfficeMutations";
import { SupplierOfficeListItem } from "@/app/Offices/GraphQL/supplierOfficeListQuery";

export function useSupplierOfficeActions(): ActionsHook<SupplierOfficeListItem> {
  const { open } = useDialog(OfficeUpdateDialog);
  const { open: openDeleteDialog } = useDialog(ConfirmDeleteDialog);
  const { mutateAsync } = useGqlMutation(deleteSupplierOfficeMutation);
  const { mutateAsync: archiveOffice } = useGqlMutation(
    archiveSupplierOfficeMutation,
  );

  const navigate = useNavigate();
  const matchRoute = useMatchRoute();
  const params = matchRoute({ to: "/offices/$id" });

  return (model) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                isHiddenTopBar: true,
                isHidden: params !== false,
                label: "View",
                Icon: LinkIcon,
                handleAction: async () => {
                  await navigate({
                    to: "/offices/$id",
                    params: {
                      id: model.id,
                    },
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                isHiddenTopBar: true,
                isHidden: params !== false,
                label: "Edit",
                Icon: PencilIcon,
                handleAction: async () => {
                  open({
                    officeId: model.id,
                  });
                },
              },
              {
                isHiddenTopBar: true,
                isHidden: params !== false,
                label: "Change open hours",
                Icon: CalendarDaysIcon,
                handleAction: async () => {
                  await navigate({
                    to: "/offices/$id/open-hours",
                    params: {
                      id: model.id,
                    },
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Archive",
                Icon: ArchiveBoxIcon,
                isHiddenTopBar: true,
                intent: "highlight",
                handleAction: () => {
                  openDeleteDialog({
                    message: "Are you sure you want to archive this office?",
                    buttonText: `Archive ${model.name}`,
                    title: `Archive ${model.name}`,
                    onDelete: async () => {
                      await archiveOffice({ id: model.id });
                    },
                  });
                },
              },
              {
                label: "Delete",
                Icon: TrashIcon,
                isHiddenTopBar: true,
                intent: "danger",
                handleAction: () => {
                  openDeleteDialog({
                    message: "Are you sure you want to delete this office?",
                    buttonText: `Delete ${model.name}`,
                    title: `Delete ${model.name}`,
                    onDelete: async () => {
                      await mutateAsync({ id: model.id });
                      await navigate({
                        to: "/offices/create",
                      });
                    },
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
