import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type HmacQuery = ResultOf<typeof getHmacQuery>;

export const getHmacQuery = graphql(`
  query hmacQuery($input: HmacInputData) {
    getHmac(hmacInput: $input)
  }
`);
