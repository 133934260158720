import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { TaxType } from "@/gql/graphql";

export function TaxTypeInput(props: Omit<SelectInputProps<any>, "options">) {
  return (
    <SelectInput
      {...props}
      options={[
        {
          label: "Tax type",
          options: [
            {
              value: TaxType.Gst,
              label: "GST",
            },
            {
              value: TaxType.TaxExempt,
              label: "Tax exempt",
            },
          ],
        },
      ]}
    />
  );
}
