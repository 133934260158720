import { Button } from "@/lib/Components/Button/Button";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { useRelocationRecord } from "@/app/Relocations/Hooks/useRelocationRecord";
import { AddDestinationsDialog } from "@/app/Relocations/Components/AddDestinationsDialog";
import { GitBranchPlusIcon } from "lucide-react";
import { RelocationAdditionalDestinationsTable } from "@/app/Relocations/Components/Tables/RelocationAdditionalDestinationsTable";

export function RelocationLinesScreen() {
  const record = useRelocationRecord();
  const { open } = useDialog(AddDestinationsDialog);

  return (
    <>
      <RelocationAdditionalDestinationsTable
        title="Additional destinations"
        id="relocation-additional-destinations"
        relocationId={record.id}
        rightButtons={
          <>
            <Button
              LeadingIcon={GitBranchPlusIcon}
              onClick={() => {
                open({
                  relocationId: record.id,
                });
              }}
            >
              Add destinations
            </Button>
          </>
        }
      />
    </>
  );
}
