import { CellContext } from "@tanstack/react-table";
import {
  OrderByRelationWithColumnAggregateFunction,
  QueryRelocationsOrderByDeliveryCityColumn,
  QueryRelocationsWhereColumn,
  RelocationListQueryVariables,
  RelocationStatus,
  SortOrder,
  SqlOperator,
} from "@/gql/graphql";
import {
  formatCurrency,
  formatDistance,
} from "@/lib/Formatters/formatCurrency";
import { ReactNode } from "react";
import { cn } from "@/lib/utils";
import { useTranslations } from "use-intl";
import { RelocationStatusCell } from "@/app/Relocations/Components/RelocationStatusCell";
import { Progress } from "@/components/ui/progress";
import dayjs from "dayjs";
import {
  RelocationListItem,
  relocationListQuery,
} from "@/app/Relocations/GraphQL/relocationListQuery";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import { TimeSinceNow } from "@/lib/Components/Common/TimeSinceNow";
import { useRelocationActions } from "@/app/Relocations/Hooks/useRelocationActions";
import { TableId } from "@/app/Common/Utils/tableIds";
import { Button } from "@/components/catalyst/button";
import { useConfirmDeleteDialog } from "@/lib/Components/Dialog/Hooks/useConfirmDeleteDialog";
import { Checkbox } from "@/components/catalyst/checkbox";
import { toast } from "sonner";
import { relocationRecordQuery } from "@/app/Relocations/GraphQL/relocationRecordQuery";
import { updateRelocationStatuses } from "@/app/Relocations/GraphQL/relocationMutations";

type RelocationColumns =
  | "select"
  | "status"
  | "relocation"
  | "expires_at"
  | "line"
  | "bookings"
  | "deal"
  | "actions"
  | "created_at";

type DashboardRelocationsTableProps = {
  title?: string;
  rightButtons?: ReactNode;
  id: TableId;
  relocationId: string;
};

export function RelocationAdditionalDestinationsTable({
  title,
  rightButtons,
  id,
  relocationId,
}: DashboardRelocationsTableProps) {
  const { data } = useSuspenseGqlQuery(relocationRecordQuery, {
    id: relocationId,
  });

  const getActions = useRelocationActions();
  const { open } = useConfirmDeleteDialog();
  const { mutateAsync: updateStatus } = useGqlMutation(
    updateRelocationStatuses,
  );
  const t = useTranslations("relocation");

  const columns: DataTableColDef<
    RelocationListItem,
    unknown,
    RelocationColumns
  >[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            table.getIsSomePageRowsSelected()
          }
          color="indigo"
          onChange={(value) => table.toggleAllPageRowsSelected(value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          color="indigo"
          disabled={row.original.status === RelocationStatus.Archived}
          checked={row.getIsSelected()}
          onChange={(value) => row.toggleSelected(value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: "status",
      cell: RelocationStatusCell,
      header: "Status",
      accessorFn: (model) => model,
    },
    {
      id: "relocation",
      accessorFn: (model) => model,
      header: "Destination",
      cell: ({ row }) => {
        const relocation = row.original;
        return <div>{relocation.deliveryCity.name}</div>;
      },
    },
    {
      id: "deal",
      header: "Deal",
      accessorFn: (model) => model,
      cell: ({ row }) => {
        const relocation = row.original;
        return (
          <div>
            <p>
              {`${relocation.hire_units_allowed} + ${
                relocation.extra_hire_units_allowed
              } ${t(`hire_unit_type.${relocation.hire_unit_type}`)}s`}
            </p>
            <p className="text-xs text-gray-500">
              {`${formatCurrency(
                relocation.hire_unit_rate,
                relocation.currency,
              )} / ${formatCurrency(
                relocation.extra_hire_unit_rate,
                relocation.currency,
              )}`}
            </p>
            <p className="text-xs text-gray-500">
              {formatDistance(
                relocation.distance_allowed,
                relocation.measurement,
              )}
            </p>
          </div>
        );
      },
    },
    {
      id: "bookings",
      header: "Bookings",
      accessorFn: (model) => model.count,
      cell: QuantityCell,
    },
    {
      id: "expires_at",
      accessorFn: (model) => model,
      header: "Expires",
      cell: ({ row }) => {
        const relocation = row.original;

        const days = dayjs(relocation.available_to_date).diff(dayjs(), "days");

        const showExpiry =
          days >= 0 && relocation.status === RelocationStatus.Ready;

        return (
          <p
            className={cn("text-gray-500", {
              "text-bold text-red-700": days < 3 && days >= 0,
            })}
          >
            {showExpiry ? `${days} days` : "--"}
          </p>
        );
      },
    },
    {
      id: "created_at",
      accessorFn: (model) => model,
      header: "Created",
      cell: ({ row }) => (
        <TimeSinceNow
          className="text-gray-500"
          time={row.original.created_at}
        />
      ),
    },
  ];

  return (
    <DataTable
      id={id}
      searchable={false}
      columnToggles={false}
      getActions={getActions}
      title={title}
      rightButtons={(table) => {
        const selected = table.getSelectedRowModel().rows;

        return selected.length > 0 ? (
          <div className="flex h-[36px] items-center space-x-2">
            <div className="text-xs text-gray-500">
              {selected.length} selected
            </div>

            <Button
              plain
              className="text-xs text-gray-500 underline decoration-dashed"
              onClick={() => {
                table.toggleAllPageRowsSelected(false);
              }}
            >
              Deselect
            </Button>

            <Button
              outline
              onClick={() => {
                console.log(selected.map((row) => row.original.id));
                open({
                  title: "Archive relocations",
                  message:
                    "Are you sure you want to archive these relocations?",
                  buttonText: "Archive relocations",
                  onDelete: async () => {
                    await updateStatus({
                      ids: selected.map((row) => row.original.id),
                      status: RelocationStatus.Archived,
                    });

                    table.toggleAllPageRowsSelected(false);

                    toast.success(`${selected.length} relocations archived`);
                  },
                });
              }}
            >
              Archive
            </Button>
          </div>
        ) : (
          rightButtons
        );
      }}
      columns={columns}
      document={relocationListQuery}
      accessor={(data) => data.relocations}
      getQueryVariables={({ pagination }) => {
        return {
          first: pagination.pageSize,
          page: pagination.pageIndex,
          status: [
            RelocationStatus.Ready,
            RelocationStatus.Paused,
            RelocationStatus.Expired,
            RelocationStatus.Draft,
          ],
          where: {
            AND: [
              {
                column: QueryRelocationsWhereColumn.RelocationLineId,
                value: data.relocation.line.id,
              },
              {
                column: QueryRelocationsWhereColumn.Id,
                operator: SqlOperator.Neq,
                value: relocationId,
              },
            ],
          },
          orderBy: [
            {
              deliveryCity: {
                column: QueryRelocationsOrderByDeliveryCityColumn.Name,
                aggregate: OrderByRelationWithColumnAggregateFunction.Max,
              },
              order: SortOrder.Asc,
            },
          ],
        } satisfies RelocationListQueryVariables;
      }}
    />
  );
}

function QuantityCell({ row }: CellContext<RelocationListItem, any>) {
  const booked = row.original.bookedBookingsCount ?? 0;
  const total = row.original.count + booked;

  const percent = (booked / total) * 100;

  if (total === 0) return null;

  return (
    <div>
      <p className="text-center text-xs text-gray-500">
        {row.original.bookedBookingsCount} / {total}
      </p>
      <Progress
        value={percent}
        className="bg-yellow-300"
        indicatorClassName="bg-green-400"
      />
    </div>
  );
}
