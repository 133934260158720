import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import { PaymentsTable } from "@/app/Payments/Components/PaymentsTable";
import { PaymentStatus } from "@/gql/graphql";
import { InvoiceTable } from "@/app/Invoices/Components/InvoiceTable";
import { useContactRecord } from "@/app/Contacts/Hooks/useContactRecord";

export function ContactInvoicesScreen() {
  const record = useContactRecord();

  return (
    <div className="space-y-12 py-6">
      <RecordScreenCard title="Invoices">
        <InvoiceTable
          id="contact-invoices"
          queryVariables={{
            payeeContactId: record.id,
          }}
        />
      </RecordScreenCard>
      <RecordScreenCard title="Upcoming payments">
        <PaymentsTable
          id={"contact-upcoming-payments"}
          queryVariables={{
            status: [PaymentStatus.Unpaid],
            payeeContactId: record.id,
          }}
        />
      </RecordScreenCard>
    </div>
  );
}
