import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import {
  BaseComboboxInputProps,
  ComboboxInput,
} from "@/lib/Components/Form/Inputs/ComboboxInput";
import {
  PaymentListItem,
  paymentListQuery,
} from "@/app/Payments/GraphQL/paymentListQuery";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { Badge } from "@/components/catalyst/badge";
import { paymentStatusIntentMap } from "@/app/Payments/Utils/paymentStatusIntentMap";
import { paymentRecordQuery } from "@/app/Payments/GraphQL/paymentRecordQuery";

export function PaymentInput(
  props: BaseComboboxInputProps<
    ResultOf<typeof paymentListQuery>,
    VariablesOf<typeof paymentListQuery>,
    PaymentListItem,
    ResultOf<typeof paymentRecordQuery>,
    VariablesOf<typeof paymentRecordQuery>
  >,
) {
  return (
    <ComboboxInput
      document={paymentListQuery}
      accessor={(data) => data.payments.data}
      getQueryVariables={(search) => ({
        search,
        page: 1,
        first: 20,
      })}
      recordDocument={paymentRecordQuery}
      recordAccessor={(data) => data.payment}
      getViewNode={(item) => (item ? `Payment #${item?.id}` : "--")}
      getKey={(i) => i.id}
      getListItemNode={(i) => <PaymentItem model={i} />}
      getInputNode={(i) => i.id}
      {...props}
    />
  );
}

function PaymentItem({ model }: { model: PaymentListItem }) {
  return (
    <div>
      <p>
        <span>Payment #{model.id}</span>
      </p>
      <p className="text-gray-500">
        {formatCurrency(model.amount, model.currency)}{" "}
        {model.attributedTo?.reference ?? "--"}
      </p>
      <p>
        <Badge color={paymentStatusIntentMap[model.status]}>
          {model.status}
        </Badge>
      </p>
    </div>
  );
}
