import { ContactInput } from "@/app/Contacts/Components/ContactInput";
import { CurrencyTypeInput } from "@/app/Common/Components/CurrencyTypeInput";
import { TaxInclusionInput } from "@/app/Payments/Components/TaxInclusionInput";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";

export function QuoteDetailForm() {
  return (
    <>
      <ContactInput name="payee.connect" label="Payee" />
      <CurrencyTypeInput name="currency" label="Currency" />
      <TaxInclusionInput name="tax_inclusion_type" label="Tax" />

      <FormDivider />
      <DateInput name="quote_date" label="Issue date" />
      <DateInput name="expiry_date" label="Expiry date" />

      <FormDivider />

      <TextAreaInput
        name="additional_notations"
        label="Additional notations"
        className="col-span-full"
      />
    </>
  );
}
