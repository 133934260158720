import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { RelocationForm } from "@/app/Relocations/Forms/RelocationForm";
import { CreateRelocationInput } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { relocationValidation } from "@/app/Relocations/Validation/relocationValidation";
import { getDefaultRelocationValues } from "@/app/Relocations/Utils/getDefaultRelocationValues";
import {
  useGqlMutation,
  useNullableSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { createRelocationMutation } from "@/app/Relocations/GraphQL/relocationMutations";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";

export function RelocationCreateDialog({
  initialSupplierId,
}: {
  initialSupplierId?: string;
}) {
  const { mutateAsync } = useGqlMutation(createRelocationMutation);
  const { close } = useDialogState();
  const { supplierId } = useGlobalSupplier();
  const id = initialSupplierId ?? supplierId;
  const { data } = useNullableSuspenseGqlQuery(
    supplierRecordQuery,
    {
      id: id!,
    },
    !!id,
  );

  const initialSupplier = data?.supplier;

  return (
    <GenericDialog title="Add relocation">
      <GenericForm<CreateRelocationInput>
        validationSchema={toFormikValidationSchema(relocationValidation)}
        initialValues={getDefaultRelocationValues({
          supplier: initialSupplier,
        })}
        onSubmit={async (values) => {
          await mutateAsync({
            input: values,
          });

          close();
        }}
      >
        <RelocationForm />
        <DialogSubmitButton text={"Add relocation"} />
      </GenericForm>
    </GenericDialog>
  );
}
