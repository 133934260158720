import { graphql } from "@/gql";

export const createSupplierAcknowledgementMutations = graphql(`
  mutation createSupplierAcknowledgement(
    $input: CreateSupplierAcknowledgementInput!
  ) {
    createSupplierAcknowledgement(input: $input) {
      id
    }
  }
`);

export const updateSupplierAcknowledgementMutations = graphql(`
  mutation updateSupplierAcknowledgement(
    $id: ID!
    $input: UpdateSupplierAcknowledgementInput!
  ) {
    updateSupplierAcknowledgement(id: $id, input: $input) {
      id
    }
  }
`);

export const deleteSupplierAcknowledgementMutations = graphql(`
  mutation deleteSupplierAcknowledgement($id: ID!) {
    deleteSupplierAcknowledgement(id: $id) {
      id
    }
  }
`);
