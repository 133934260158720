import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import { ActivityLogFeed } from "@/app/ActivityLogs/Components/ActivityLogFeed";
import { ModelType } from "@/gql/graphql";
import { useRelocationRecord } from "@/app/Relocations/Hooks/useRelocationRecord";

export function RelocationActivityScreen() {
  const record = useRelocationRecord();

  return (
    <div className="py-12">
      <RecordScreenCard title={"Activity log"}>
        <ActivityLogFeed modelId={record.id} modelType={ModelType.Relocation} />
      </RecordScreenCard>
    </div>
  );
}
