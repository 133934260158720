import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type MarketingEventListItem = ResultOf<
  typeof marketingEventTableQuery
>["marketingEvents"]["data"][number];
export const marketingEventTableQuery = graphql(`
  query MarketingEventTableQuery($first: Int!, $page: Int) {
    marketingEvents(first: $first, page: $page) {
      data {
        id
        name
        description

        trigger
        days_after_trigger

        mailchimp_customer_journey_id
        mailchimp_customer_journey_step_id

        created_at
        updated_at
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
