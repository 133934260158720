import { graphql } from "@/gql";

export const paidDriverListQuery = graphql(`
  query PaidDriverList(
    $page: Int
    $first: Int!
    $search: String
    $region: [Region!]
    $orderBy: [QueryPaidDriversOrderByRelationOrderByClause!]
  ) {
    paidDrivers(
      first: $first
      page: $page
      search: $search
      orderBy: $orderBy
      region: $region
    ) {
      data {
        id
        created_at
        updated_at
        basedIn {
          id
          name
          region
          state
          created_at
        }
        user {
          id
          name
          primary_email
          primary_phone
          contact {
            profilePicture {
              ...MediaFields
            }
          }
        }
      }
      paginatorInfo {
        total
        lastPage
        currentPage
        count
        hasMorePages
        perPage
      }
    }
  }
`);
