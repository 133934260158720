import { SettingCard } from "@/app/Settings/Settings/Components/SettingFormCard";
import { SupplierTable } from "@/app/Suppliers/Components/SupplierTable";

export function SettingsSupplierSectionScreen({ id }: { id: any }) {
  return (
    <div>
      <SettingCard
        title="Brands"
        description="All of the brands associated with your organisation"
      >
        <SupplierTable id="settings-suppliers" />
      </SettingCard>
    </div>
  );
}
