import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import {
  deleteSupplierPurchasableExtraMutation,
  PurchasableExtraListResult,
} from "@/app/Suppliers/GraphQL/supplierPurchasableExtrasQuery";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierPurchasableExtraUpdateDialog } from "@/app/Suppliers/Components/SupplierPurchasableExtraUpdateDialog";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { useConfirmDeleteDialog } from "@/lib/Components/Dialog/Hooks/useConfirmDeleteDialog";

export function useSupplierPurchasableExtrasHook(): ActionsHook<PurchasableExtraListResult> {
  const { open: edit } = useDialog(SupplierPurchasableExtraUpdateDialog);
  const { open: confirmDelete } = useConfirmDeleteDialog();
  const { mutateAsync: deleteItem } = useGqlMutation(
    deleteSupplierPurchasableExtraMutation,
  );

  return (model) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Edit",
                Icon: PencilIcon,
                handleAction: () => {
                  edit({
                    supplierPurchasableExtra: model,
                    currency: model.supplier.currency,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Delete",
                Icon: TrashIcon,
                intent: "danger",
                handleAction: () => {
                  confirmDelete({
                    title: `Delete ${model.name}`,
                    message: `Are you sure you want to delete the purchasable extra "${model.name}"?`,
                    buttonText: "Delete",
                    onDelete: () => {
                      return deleteItem({
                        id: model.id,
                      });
                    },
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
