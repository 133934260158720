import { useQuery } from "@tanstack/react-query";
import { useIntegrationRecord } from "@/app/Settings/Settings/Hooks/useIntegrationRecord";
import { z } from "zod";
import { useRcmHashRecord } from "@/app/Settings/Integrations/Rcm/Hooks/useRcmHashRecord";
import { IntegrationRecord } from "@/app/Settings/Settings/GraphQL/integrationQueries";

async function getRcmData<T extends z.ZodType>(
  requestBody: string,
  hmac: string,
  apiKey: string,
  validator: T,
): Promise<z.infer<T>> {
  const formData = new URLSearchParams();
  formData.append("request", requestBody);
  formData.append("signature", hmac);

  const res = await fetch(
    new URL(
      ` https://apis.rentalcarmanager.com/booking/v3.2/${apiKey}?apiKey=${apiKey}`,
    ),
    {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        accept: "application/json, text/javascript, */*; q=0.01",
      },
      body: formData,
    },
  );

  const json = await res.json();
  try {
    validator.parse(json);
  } catch (error) {
    console.log(error);
  }
  return validator.parse(json);
}

export function useRcmVehicleCodesList() {
  const requestBody = JSON.stringify({
    method: "categorylist",
  });
  const hmac = hmacValidator.parse(useRcmHashRecord(requestBody));
  const integration = useIntegrationRecord();
  const metaData = metaDatavalidator.parse(integration.metadata);
  const apiKey = metaData["rcmApiKey"];

  return useQuery({
    queryKey: ["rcmVehicleCodes", apiKey],
    queryFn: () => {
      return getRcmData(requestBody, hmac, apiKey, vehicleValidator);
    },
  });
}

export function useRcmOfficeCodesList() {
  const requestBody = JSON.stringify({
    method: "locations",
  });
  const hmac = hmacValidator.parse(useRcmHashRecord(requestBody));
  const integration = useIntegrationRecord();
  const metaData = metaDatavalidator.parse(integration.metadata);
  const apiKey = metaData["rcmApiKey"];

  return useQuery({
    queryKey: ["rcmOfficeCodes", apiKey],
    queryFn: () => {
      return getRcmData(requestBody, hmac, apiKey, officeValidator);
    },
  });
}

export function useRcmRelocationSpecialsList(integration: IntegrationRecord) {
  const requestBody = JSON.stringify({
    method: "relocationspecials",
  });
  const hmac = hmacValidator.parse(useRcmHashRecord(requestBody));
  const metaData = metaDatavalidator.parse(integration.metadata);
  const apiKey = metaData["rcmApiKey"];

  return useQuery({
    queryKey: ["rcmRelocationSpecials", apiKey],
    queryFn: () => {
      return getRcmData(requestBody, hmac, apiKey, relocationSpecialValidator);
    },
  });
}

export function useRcmInsuranceOptionsList(integration: IntegrationRecord) {
  const requestBody = JSON.stringify({
    method: "step3",
    vehiclecategorytypeid: "",
    pickuplocationid: "",
  });
  const hmac = hmacValidator.parse(useRcmHashRecord(requestBody));
  const metaData = metaDatavalidator.parse(integration.metadata);
  const apiKey = metaData["rcmApiKey"];

  return useQuery({
    queryKey: ["rcmInsuranceOptions", apiKey],
    queryFn: () => {
      return getRcmData(requestBody, hmac, apiKey, relocationSpecialValidator);
    },
  });
}

const hmacValidator = z.string();

const metaDatavalidator = z
  .object({ rcmApiKey: z.string(), rcmSecretKey: z.string() })
  .catch(() => ({
    rcmSecretKey: "",
    rcmApiKey: "",
  }));

const vehicleValidator = z.object({
  error: z.string(),
  results: z.array(
    z.object({
      categorytype: z.string(),
      vehiclecategorytypeid: z.number(),
    }),
  ),
});

const officeValidator = z.object({
  error: z.string(),
  results: z.array(
    z.object({
      location: z.string(),
      id: z.number(),
    }),
  ),
});

const relocationSpecialValidator = z.object({
  error: z.string(),
  results: z.array(z.object({})),
});
