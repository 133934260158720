import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import isoWeek from "dayjs/plugin/isoWeek";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import dayjsBusinessDays from "dayjs-business-days";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/de";
import "dayjs/locale/it";

dayjs.extend(dayjsBusinessDays);
dayjs.extend(relativeTime);
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.extend(LocalizedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.guess();
dayjs.extend(advancedFormat);
