import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type VehicleListItem = ResultOf<
  typeof vehicleListQuery
>["vehicles"]["data"][number];

export const vehicleListQuery = graphql(`
  query VehicleList(
    $page: Int
    $first: Int!
    $supplier_id: ID
    $search: String
    $orderBy: [QueryVehiclesOrderByOrderByClause!]
    $archived: Boolean
  ) {
    vehicles(
      page: $page
      first: $first
      search: $search
      supplier_id: $supplier_id
      orderBy: $orderBy
      archived: $archived
    ) {
      data {
        ...VehicleListFields
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);

export const vehicleListFragment = graphql(`
  fragment VehicleListFields on Vehicle {
    id
    name
    code
    type
    transmission
    heroImage
    hire_unit_type
    hire_unit_rate
    extra_hire_unit_deposit_rate
    extra_hire_unit_rate
    extra_hire_unit_supplier_net_rate
    seatbelts
    fuel
    model
    brand
    supplier {
      id
      name
      currency
    }
    created_at
    updated_at
    archived_at
  }
`);
