import { BookingPaymentRequestStatus } from "@/gql/graphql";

export const bookingPaymentRequestMessages = {
  status: {
    [BookingPaymentRequestStatus.AwaitingPayment]: "payment required",
    [BookingPaymentRequestStatus.Cancelled]: "cancelled",
    [BookingPaymentRequestStatus.Paid]: "paid",
    [BookingPaymentRequestStatus.PaymentPending]: "pending",
  } satisfies {
    [key in BookingPaymentRequestStatus]: string;
  },
};
