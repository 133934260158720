import { OrganizationProfile } from "@clerk/clerk-react";

export function SettingsTeamScreen() {
  const jucyIntegrationTypes = [
    {
      type: "Vehicle",
    },
    {
      type: "Office",
    },
    {
      type: "InsuranceOption",
    },
  ];

  return (
    <div>
      <OrganizationProfile
        path="/settings/organisation"
        appearance={{
          elements: {
            rootBox: "w-full",
            navbar: "hidden",
            cardBox: "shadow rounded-md w-full",
            scrollBox: "w-full min-w-0 col-span-full",
            tagInputContainer: "rounded-md",
            input: "border-0 focus:ring-0 focus:outline-none",
          },
        }}
      />
    </div>
  );
}
