import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type BookingChangeRequestListItem = ResultOf<
  typeof bookingChangeRequestListQuery
>["bookingChangeRequests"]["data"][number];

export const bookingChangeRequestListQuery = graphql(`
  query BookingChangeRequestList(
    $first: Int!
    $page: Int
    $where: QueryBookingChangeRequestsWhereWhereConditions
  ) {
    bookingChangeRequests(first: $first, page: $page, where: $where) {
      data {
        ...BookingChangeRequestFields
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);

export const bookingChangeRequestFragment = graphql(`
  fragment BookingChangeRequestFields on BookingChangeRequest {
    id
    __typename
    request
    amount
    currency
    status
    created_at
    stripePayment {
      ...StripePaymentFields
    }
    booking {
      id
      reference
    }
  }
`);
