import { RelocationInclusionType } from "@/gql/graphql";
import { FC } from "react";
import { DollarSignIcon, FuelIcon, SailboatIcon } from "lucide-react";
import { ExclamationTriangleIcon, StarIcon } from "@heroicons/react/20/solid";

export const inclusionIconMap: {
  [key in RelocationInclusionType]: FC<{ className?: string }>;
} = {
  [RelocationInclusionType.Ferry]: SailboatIcon,
  [RelocationInclusionType.Fuel]: FuelIcon,
  [RelocationInclusionType.Negative]: ExclamationTriangleIcon,
  [RelocationInclusionType.Other]: StarIcon,
  [RelocationInclusionType.Charge]: DollarSignIcon,
};
