import * as Headless from "@headlessui/react";
import { createLink, Link as RouterLink } from "@tanstack/react-router";
import React, { forwardRef } from "react";

export const Link = createLink(
  // eslint-disable-next-line react/display-name
  forwardRef((props: any, ref: React.ForwardedRef<HTMLAnchorElement>) => {
    return (
      <Headless.DataInteractive>
        <RouterLink {...props} ref={ref} preload="intent" />
      </Headless.DataInteractive>
    );
  }),
);
