import { useMatch } from "@tanstack/react-router";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { supplierOfficeRecordQuery } from "@/app/Offices/GraphQL/supplierOfficeRecordQuery";

export function useOfficeRecord() {
  const match = useMatch({
    from: "/_app/offices/$id",
  });
  const { data } = useSuspenseGqlQuery(supplierOfficeRecordQuery, {
    id: match.params.id,
  });

  return data.supplierOffice;
}
