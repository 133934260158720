import { QuoteStatus } from "@/gql/graphql";

export const quoteMessages = {
  status: {
    [QuoteStatus.Accepted]: "Accepted",
    [QuoteStatus.Rejected]: "Rejected",
    [QuoteStatus.Draft]: "Draft",
    [QuoteStatus.Invoiced]: "Invoiced",
    [QuoteStatus.Issued]: "Issued",
    [QuoteStatus.Expired]: "Expired",
  } satisfies {
    [sting in QuoteStatus]: string;
  },
};
