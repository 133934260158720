import { graphql } from "@/gql";

export const createIntegrationMutation = graphql(`
  mutation createIntegration($input: CreateIntegrationInput!) {
    createIntegration(input: $input) {
      id
    }
  }
`);

export const updateIntegrationMutation = graphql(`
  mutation updateIntegration($id: ID!, $input: UpdateIntegrationInput!) {
    updateIntegration(id: $id, input: $input) {
      id
    }
  }
`);

export const deleteIntegrationrMutation = graphql(`
  mutation deleteIntegration($id: ID!) {
    deleteIntegration(id: $id) {
      id
    }
  }
`);
