import { PaidDriverListSearchParams } from "@/app/PaidDrivers/PaidDrivers/Routes/paidDriverRoutes";
import {
  PaidDriverListQueryVariables,
  QueryPaidDriversOrderByColumn,
  SortOrder,
} from "@/gql/graphql";

export function getPaidDriverQueryVariables(
  searchParams: PaidDriverListSearchParams,
): PaidDriverListQueryVariables {
  const baseVariables: PaidDriverListQueryVariables = {
    first: 20,
    search: searchParams.paidDriverSearch || undefined,
    page: 1,
  };

  if (searchParams.paidDriverSearch) {
    return baseVariables;
  }

  return {
    ...baseVariables,
    orderBy: [
      {
        order: SortOrder.Desc,
        column: QueryPaidDriversOrderByColumn.CreatedAt,
      },
    ],
  };
}
