import { graphql } from "@/gql";

export const tripByCities = graphql(`
  query TripByCities($cityAId: ID!, $cityBId: ID!) {
    tripByCities(cityAId: $cityAId, cityBId: $cityBId) {
      id
      __typename
      duration
      distance
      cityB {
        id
        name
      }
      cityA {
        id
        name
      }
    }
  }
`);
