import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { SupplierAcknowledgementListItem } from "@/app/Suppliers/GraphQL/supplierAcknowledgementList";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierAcknowledgementUpdateDialog } from "@/app/Suppliers/Components/Dialogs/SupplierAcknowledgementUpdateDialog";
import { useConfirmDeleteDialog } from "@/lib/Components/Dialog/Hooks/useConfirmDeleteDialog";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { deleteSupplierAcknowledgementMutations } from "@/app/Suppliers/GraphQL/supplierAcknowledgementMutations";

export function useSupplierAcknowledgementActions(): ActionsHook<SupplierAcknowledgementListItem> {
  const { open } = useDialog(SupplierAcknowledgementUpdateDialog);
  const { open: deleteAcknowledgement } = useConfirmDeleteDialog();
  const { mutateAsync } = useGqlMutation(
    deleteSupplierAcknowledgementMutations,
  );

  return (item) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Edit",
                Icon: PencilIcon,
                handleAction: () => {
                  open({
                    supplierAcknowledgementId: item.id,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Delete",
                Icon: TrashIcon,
                intent: "danger",
                handleAction: () => {
                  deleteAcknowledgement({
                    message:
                      "Are you sure you want to delete this acknowledgement?",
                    buttonText: "Delete",
                    title: "Delete Acknowledgement",
                    onDelete: () => {
                      return mutateAsync({
                        id: item.id,
                      });
                    },
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
