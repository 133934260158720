import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import * as yup from "yup";
import {
  CreateInvoicePaymentInput,
  InvoicePaymentFieldsFragment,
  InvoicePaymentMethod,
  InvoicePaymentType,
} from "@/gql/graphql";
import dayjs from "dayjs";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import {
  createInvoicePaymentMutation,
  updateInvoicePaymentMutation,
} from "@/app/Invoices/GraphQL/invoicePaymentMutations";
import { InvoiceRecord } from "@/app/Invoices/GraphQL/invoiceRecordQuery";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";

type InvoicePaymentDialogProps = {
  invoice: InvoiceRecord;
  type?: InvoicePaymentType;
  invoicePayment?: InvoicePaymentFieldsFragment;
};

export function InvoicePaymentDialog({
  invoice,
  invoicePayment,
  type = InvoicePaymentType.Payment,
}: InvoicePaymentDialogProps) {
  const { close } = useDialogState();

  const { mutateAsync } = useGqlMutation(createInvoicePaymentMutation);

  const { mutateAsync: updateInvoicePayment } = useGqlMutation(
    updateInvoicePaymentMutation,
  );

  const validation = yup.object({
    amount: yup
      .number()
      .required("amount is required")
      .typeError("amount is required")
      .min(1),
    description: yup.string().nullable(),
    payment_date: yup.string().required(),
  });

  const labels: {
    [index in InvoicePaymentType]: {
      label: string;
      paymentDateLabel: string;
    };
  } = {
    [InvoicePaymentType.Payment]: {
      label: invoicePayment ? "Edit Payment" : "Add Payment",
      paymentDateLabel: "Paid on",
    },
    [InvoicePaymentType.Credit]: {
      label: invoicePayment ? "Edit Credit" : "Issue Credit",
      paymentDateLabel: "Credited on",
    },
    [InvoicePaymentType.Refund]: {
      label: invoicePayment ? "Edit Refund" : "Issue Refund",
      paymentDateLabel: "Refunded on",
    },
  };

  return (
    <GenericDialog
      title={labels[type].label}
      subtitle="Add payments, credits or refunds to an invoice"
    >
      <GenericForm<CreateInvoicePaymentInput>
        validationSchema={validation}
        initialValues={{
          amount: invoicePayment?.amount ?? 0,
          description: invoicePayment?.description ?? "",
          payment_date:
            invoicePayment?.payment_date ?? dayjs().format("YYYY-MM-DD"),
          invoice: { connect: invoice.id },
          type: invoicePayment?.type ?? type,
          method: invoicePayment?.method ?? InvoicePaymentMethod.DirectDeposit,
        }}
        onSubmit={async (values) => {
          if (!invoicePayment) {
            await mutateAsync({
              input: values,
            });
          } else {
            await updateInvoicePayment({
              id: invoicePayment.id,
              input: values,
            });
          }

          close();
        }}
        className="grid grid-cols-12 gap-6"
      >
        <CurrencyInput
          name="amount"
          label="Amount"
          currency={invoice.currency}
        />
        <DateInput
          name="payment_date"
          label={labels[type].paymentDateLabel}
          max={dayjs().format("YYYY-MM-DD")}
        />
        <TextInput
          name="description"
          label="Reference"
          className="col-span-4"
          optionalLabel
        />
        <RemainingAmount invoice={invoice} />
        <DialogSubmitButton text={labels[type].label} />
      </GenericForm>
    </GenericDialog>
  );
}

function RemainingAmount({ invoice }: { invoice: InvoiceRecord }) {
  return (
    <>
      <div className="col-span-2 text-right">
        <dt className="text-sm font-bold text-gray-900">Amount due</dt>
        <dd className="mt-3 text-sm text-gray-900">
          {formatCurrency(invoice.amount_due, invoice.currency, {
            showZero: true,
            allowNegative: true,
          })}
        </dd>
      </div>
    </>
  );
}
