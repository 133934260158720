import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { invoiceRecordQuery } from "@/app/Invoices/GraphQL/invoiceRecordQuery";
import { useParams } from "@tanstack/react-router";

export function useInvoiceRecord() {
  const { id } = useParams({
    from: "/_app/invoices/_invoiceRecordLayout/$id",
  });

  const { data } = useSuspenseGqlQuery(invoiceRecordQuery, {
    id,
  });

  return data.invoice;
}
