import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { DepositRefundedAt } from "@/gql/graphql";

export function DepositRefundedAtInput(
  props: Omit<SelectInputProps<any>, "options">,
) {
  const options: {
    [key in DepositRefundedAt]: {
      label: string;
      value: DepositRefundedAt;
    };
  } = {
    [DepositRefundedAt.Delivery]: {
      label: "Drop-off",
      value: DepositRefundedAt.Delivery,
    },
    [DepositRefundedAt.Departure]: {
      label: "Pickup",
      value: DepositRefundedAt.Departure,
    },
  };

  return (
    <SelectInput
      options={[
        {
          label: "Refunded at",
          options: Object.values(options),
        },
      ]}
      {...props}
    />
  );
}
