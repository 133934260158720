import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { CityForm } from "@/app/Cities/Cities/Forms/CityForm";
import { CreateCityInput } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createCityMutation } from "@/app/Cities/Cities/GraphQL/cityMutations";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { cityValidation } from "@/app/Cities/Cities/Validation/cityValidation";

export type CityCreateDialogProps = {
  initialValues?: Partial<CreateCityInput>;
};
export function CityCreateDialog({ initialValues }: CityCreateDialogProps) {
  const { close, resolve } = useDialogState();
  const { mutateAsync } = useGqlMutation(createCityMutation);

  return (
    <GenericDialog title="Add city">
      <GenericForm<CreateCityInput>
        onSubmit={async (values) => {
          const res = await mutateAsync({
            input: values,
          });

          resolve?.(res.createCity);

          close();
        }}
        validationSchema={toFormikValidationSchema(cityValidation)}
        initialValues={{
          name: "",
          region: null!,
          state: null!,
          ...initialValues,
        }}
      >
        <CityForm />
        <DialogSubmitButton text="Add City" />
      </GenericForm>
    </GenericDialog>
  );
}
