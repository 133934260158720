import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";

import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { RelocationHighlightForm } from "@/app/Tools/RelocationHighlights/Components/RelocationHighlightCreateDialog";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateRelocationHighlightMutation } from "@/app/Tools/RelocationHighlights/GraphQL/relocationHighlightMutations";
import { CreateRelocationHighlightInput } from "@/gql/graphql";
import { RelocationHighlightListItem } from "@/app/Tools/RelocationHighlights/GraphQL/relocationHighlightListQuery";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { relocationHighlightValidation } from "@/app/Tools/RelocationHighlights/validation/relocationHighlightValidation";

export function RelocationHighlightUpdateDialog({
  relocationHighlight,
}: {
  relocationHighlight: RelocationHighlightListItem;
}) {
  const { close } = useDialogState();
  const { mutateAsync } = useGqlMutation(updateRelocationHighlightMutation);

  return (
    <GenericDialog title={"Update highlight"}>
      <GenericForm<CreateRelocationHighlightInput>
        validationSchema={toFormikValidationSchema(
          relocationHighlightValidation,
        )}
        initialValues={{
          region: relocationHighlight.region,
          primary_text: relocationHighlight.primary_text,
          secondary_text: relocationHighlight.secondary_text,
          order: relocationHighlight.order,
          departureCity: {
            connect: relocationHighlight.deliveryCity?.id ?? null!,
          },
          deliveryCity: {
            connect: relocationHighlight.deliveryCity?.id ?? null!,
          },
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: relocationHighlight.id,
            input: values,
          });
          close();
        }}
      >
        <RelocationHighlightForm />

        <DialogSubmitButton text={"Update highlight"} />
      </GenericForm>
    </GenericDialog>
  );
}
