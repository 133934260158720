import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { DepositRefundedAtInput } from "@/app/Common/Components/DepositRefundedAtInput";
import { HoldingDepositRefundedByInput } from "@/app/Common/Components/HoldingDepositRefundedByInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { ContactType, ModelType, UpdateSupplierInput } from "@/gql/graphql";
import { ContactInput } from "@/app/Contacts/Components/ContactInput";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { TaxTypeInput } from "@/app/Payments/Components/TaxTypeInput";
import { CurrencyTypeInput } from "@/app/Common/Components/CurrencyTypeInput";
import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";

export function SupplierBillingForm({
  supplier,
}: {
  supplier: SupplierRecord;
}) {
  const { values } = useForm<UpdateSupplierInput>();
  const currency = supplier.currency;

  return (
    <>
      {supplier.managedBy === null ? (
        <div className="col-span-full">
          <WarningBanner>
            <p className="text-sm">
              The supplier is not linked to any organisation
            </p>
          </WarningBanner>
        </div>
      ) : null}
      <FormDivider />
      <CurrencyInput
        label="Booking fee"
        name="booking_fee_amount"
        currency={currency}
      />

      <FormDivider />

      <CurrencyTypeInput name={"billing_currency"} label={"Billing Currency"} />
      <CurrencyInput
        currency={values.billing_currency!}
        name={"charge_per_move_amount"}
        label={"Charge per move"}
      />

      <ContactInput
        className="col-span-3"
        name="billingEntity.connect"
        label="Billing entity"
        isEditable={!!supplier.managedBy}
        getQueryVariables={(search) => ({
          search,
          page: 1,
          first: 20,
          managedById: supplier.managedBy?.id,
          type: [ContactType.Entity, ContactType.Organisation],
        })}
        initialFixtureValues={
          supplier.managedBy
            ? {
                type: ContactType.Entity,
                name: "",
                object: {
                  connect: {
                    type: ModelType.Contact,
                    id: supplier.managedBy?.contact.id,
                  },
                },
              }
            : undefined
        }
      />

      {values.charge_per_move_amount ? (
        <SwitchInput
          name="confirm_delivery_before_billing"
          label="Confirm delivery"
          tooltip="Require confirmation of delivery before billing"
        />
      ) : (
        <div className="col-span-1" />
      )}

      <SwitchInput
        name="is_tax_included"
        label="Tax included in amount"
        tooltip="Is tax included in the amounts shown on the supplier's invoices?"
      />

      <TaxTypeInput name="tax_type" label="Tax type" />

      <FormDivider />

      <SwitchInput
        name="is_holding_deposit_required"
        label="Require holding deposit"
      />

      {values.is_holding_deposit_required ? (
        <>
          <CurrencyInput
            currency={currency}
            name={"holding_deposit_amount"}
            label={"Holding deposit"}
          />

          <DepositRefundedAtInput
            name="holding_deposit_refunded_at"
            label="Refunded at"
          />

          <HoldingDepositRefundedByInput
            name="holding_deposit_refunded_by"
            label="Refunded by"
          />
        </>
      ) : null}
    </>
  );
}
