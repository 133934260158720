import { graphql } from "@/gql";

export const googleMapsAddressLookupQuery = graphql(`
  query GoogleMapsAddressLookup($input: GoogleMapsAutocompleteInput!) {
    googleMapsAutocomplete(input: $input) {
      description
      place_id
      structured_formatting {
        main_text
        secondary_text
      }
    }
  }
`);

export const googleMapsPlaceDetailsQuery = graphql(`
  query GoogleMapsPlace($placeId: ID!) {
    googleMapsPlace(placeId: $placeId) {
      place_id
      address_components {
        long_name
        short_name
        types
      }
      formatted_address
      name
      geometry {
        location {
          lat
          lng
        }
      }
    }
  }
`);
