import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { getHmacQuery } from "@/app/Settings/Integrations/Rcm/GraphQL/getHmac";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";

export function useRcmHashRecord(requestBody: string) {
  const { supplier } = useGlobalSupplier();
  const { data } = useSuspenseGqlQuery(getHmacQuery, {
    input: {
      requestBody: requestBody,
      supplierId: supplier?.id,
    },
  });
  return data.getHmac;
}
