import {
  ActivityLogEvent,
  ActivityLogListQueryVariables,
  ModelType,
  QueryActivityLogsOrderByColumn,
  QueryActivityLogsWhereColumn,
  SortOrder,
} from "@/gql/graphql";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { Field } from "formik";
import { ModelEventItem } from "@/app/ActivityLogs/Components/ModelEventItem";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { ActivityIcon } from "lucide-react";
import { useRef } from "react";
import { FormikProps } from "formik/dist/types";
import * as Yup from "yup";
import { cn } from "@/lib/utils";
import { useGqlMutation, useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { activityLogListQuery } from "@/app/ActivityLogs/GraphQL/activityLogListQuery";
import { createActivityLogMutation } from "@/app/ActivityLogs/GraphQL/activityLogMutations";
import { useQueryClient } from "@tanstack/react-query";

const schema = Yup.object({
  comment: Yup.string().required(),
});

export type ActivityLogFeedProps = {
  modelId: string;
  modelType: ModelType;
  variables?: Partial<ActivityLogListQueryVariables>;
  onCommentLogged?: () => void;
  className?: string;
};
export function ActivityLogFeed({
  modelId,
  modelType,
  variables,
  onCommentLogged,
  className,
}: ActivityLogFeedProps) {
  const queryClient = useQueryClient();
  const { mutateAsync } = useGqlMutation(createActivityLogMutation, {
    onSuccess: () => {
      return queryClient.invalidateQueries({
        type: "active",
        queryKey: ["ActivityLogList"],
      });
    },
  });
  const ref = useRef<FormikProps<any>>(null);
  const containerRef = useRef<HTMLFormElement>(null);

  const { data, isLoading } = useGqlQuery(activityLogListQuery, {
    page: 1,
    first: 200,
    where: {
      AND: [
        {
          column: QueryActivityLogsWhereColumn.SubjectId,
          value: modelId,
        },
        {
          column: QueryActivityLogsWhereColumn.SubjectType,
          value: modelType,
        },
      ],
    },
    orderBy: [
      {
        order: SortOrder.Desc,
        column: QueryActivityLogsOrderByColumn.CreatedAt,
      },
      {
        order: SortOrder.Desc,
        column: QueryActivityLogsOrderByColumn.Id,
      },
    ],
    ...variables,
  });

  const logs = data?.activityLogs?.data ?? [];

  if (isLoading || !logs) {
    return <Spinner />;
  }

  return (
    <div className={className}>
      <ul role="list" className="flex flex-col-reverse gap-y-6">
        {logs.length === 0 ? (
          <EmptyState Icon={ActivityIcon} header="No logs found" />
        ) : null}
        {logs.map((activityItem, idx) => (
          <li key={activityItem.id} className="relative w-full text-xs">
            <div
              className={cn(
                idx === 0 ? "h-6" : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center",
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
            <ModelEventItem log={activityItem} />
          </li>
        ))}
      </ul>

      <div className="mt-6 flex">
        <GenericForm
          innerRef={ref as any}
          validationSchema={schema}
          initialValues={{
            comment: "",
            event: ActivityLogEvent.Note,
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.metaKey) {
              ref.current?.handleSubmit();
            }

            if (e.key === "k" && e.metaKey) {
              ref.current?.setFieldValue("event", ActivityLogEvent.PhoneCall);
              ref.current?.handleSubmit();
            }
          }}
          containerRef={containerRef}
          onSubmit={async (values, { resetForm }) => {
            await mutateAsync({
              input: {
                model_id: modelId,
                model_type: modelType,
                description: values.comment,
                event: values.event,
              },
            });

            resetForm();
            onCommentLogged?.();
          }}
          className="relative block flex-auto"
        >
          <div className="overflow-hidden rounded-lg bg-white pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <TextArea />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-end space-x-2 py-2 pl-3 pr-2">
            <SubmitButton />
            <LogCall />
          </div>
        </GenericForm>
      </div>
    </div>
  );
}

function TextArea() {
  return (
    <Field
      as="textarea"
      rows={2}
      name="comment"
      className={cn(
        "block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
      )}
      placeholder="Add your comment..."
    />
  );
}

function SubmitButton() {
  const { isSubmitting } = useForm();

  return (
    <button
      disabled={isSubmitting}
      type="submit"
      className="relative flex items-center overflow-hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50"
    >
      {isSubmitting ? (
        <div className="absolute inset-0 flex items-center justify-center bg-white">
          <Spinner className="h-4 w-4" />
        </div>
      ) : null}

      <span className="mr-2 space-x-1 text-xs">
        <span className="rounded-md bg-gray-100 p-1">⌘</span>
        <span>+</span>
        <span className="rounded-md bg-gray-100 p-1">↵</span>
      </span>
      <span>Comment</span>
    </button>
  );
}

function LogCall() {
  const { isSubmitting, submitForm, setFieldValue } = useForm();

  return (
    <button
      disabled={isSubmitting}
      onClick={() => {
        setFieldValue("event", ActivityLogEvent.PhoneCall);
        submitForm();
      }}
      type="button"
      className="relative flex items-center overflow-hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50"
    >
      {isSubmitting ? (
        <div className="absolute inset-0 flex items-center justify-center bg-white">
          <Spinner className="h-4 w-4" />
        </div>
      ) : null}

      <span className="mr-2 space-x-1 text-xs">
        <span className="rounded-md bg-gray-100 p-1">⌘</span>
        <span>+</span>
        <span className="rounded-md bg-gray-100 p-1">K</span>
      </span>
      <span>Log call</span>
    </button>
  );
}
