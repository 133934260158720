import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type SupplierOfficeRecord = ResultOf<
  typeof supplierOfficeRecordQuery
>["supplierOffice"];
export const supplierOfficeRecordQuery = graphql(`
  query SupplierOfficeRecord($id: ID!) {
    supplierOffice(id: $id) {
      id
      name
      code
      email
      booking_email
      cc_booking_to_departure_office

      phone
      pickup_instructions
      created_at
      updated_at
      archived_at
      delivery_hours
      departure_hours
      closed_dates
      address {
        ...AddressFields
      }
      city {
        ...CityFields
      }
      supplier {
        ...SupplierFields
      }
    }
  }
`);

export const supplierOfficeFieldsFragment = graphql(`
  fragment SupplierOfficeFields on SupplierOffice {
    id
    name
    code
    email
    booking_email
    cc_booking_to_departure_office

    phone
    pickup_instructions
    created_at
    updated_at
    delivery_hours
    departure_hours
    closed_dates
    address {
      ...AddressFields
    }
    city {
      ...CityFields
    }
    supplier {
      ...SupplierFields
    }
  }
`);
