import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import { TableId } from "@/app/Common/Utils/tableIds";
import {
  CityListItem,
  cityListQuery,
} from "@/app/Cities/Cities/GraphQL/cityListQuery";
import { useTranslations } from "use-intl";
import { ModelLinkCell } from "@/lib/Components/DataTable/Cells/ModelLinkCell";
import { Button } from "@/lib/Components/Button/Button";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { CityCreateDialog } from "@/app/Cities/Cities/Components/CityCreateDialog";
import { PlusIcon } from "@heroicons/react/24/outline";

type CityTableColumnId = "city" | "state" | "region";

export function CityTable({ id }: { id: TableId }) {
  const t = useTranslations("city");
  const { open } = useDialog(CityCreateDialog);

  const columns: DataTableColDef<CityListItem, any, CityTableColumnId>[] = [
    {
      id: "city",
      header: "City",
      accessorFn: (data) => data.name,
      cell: ({ row }) => (
        <ModelLinkCell
          to="/cities/$id"
          params={{
            id: row.original.id,
          }}
        >
          {row.original.name}
        </ModelLinkCell>
      ),
    },
    {
      id: "state",
      header: "State",
      accessorFn: (data) => t(`state.${data.state!}`),
    },
    {
      id: "region",
      header: "Region",
      accessorFn: (data) => t(`region.${data.region}`),
    },
  ];

  return (
    <DataTable
      columns={columns}
      document={cityListQuery}
      rightButtons={
        <Button
          LeadingIcon={PlusIcon}
          onClick={() => {
            open({});
          }}
        >
          Add city
        </Button>
      }
      getQueryVariables={({ search, sorting, pagination }) => {
        return {
          search,
          page: pagination.pageIndex,
          first: pagination.pageSize,
        };
      }}
      accessor={(data) => data.cities}
      id={id}
    />
  );
}
