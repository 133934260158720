import { CellContext } from "@tanstack/react-table";
import { UserProfilePicture } from "@/app/Users/Components/UserProfilePicture";
import {
  usersWithWaitlistQuery,
  UsersWithWaitlistsListItem,
} from "@/app/Waitlists/GraphQL/waitlistListQuery";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { UserWaitlistsDialog } from "@/app/Waitlists/Components/UserWaitlistsDialog";
import { TimeSinceNowCell } from "@/lib/Components/DataTable/TimeSinceNowCell";
import { DeEmphasisedCell } from "@/lib/Components/DataTable/DeEmphasisedCell";
import { SelectFilter } from "@/lib/Components/DataTable/Filters/SelectFilter";
import { useState } from "react";
import { MultiSelectFilter } from "@/lib/Components/DataTable/Filters/MultiSelectFilter";
import {
  Region,
  SortOrder,
  UsersWithWaitlistsListQueryVariables,
} from "@/gql/graphql";
import { useTranslations } from "use-intl";
import { Button } from "@/components/catalyst/button";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

type UserWaitlistColumns =
  | "customer"
  | "email"
  | "phone"
  | "waitlists"
  | "last_call"
  | "actions"
  | "created_at";

type LastCallFilter = "24" | "48" | "72" | "168" | null;

export function UserWaitlistTable() {
  const { open } = useDialog(UserWaitlistsDialog);
  const [hasPhone, setHasPhone] = useState<
    null | "has-phone" | "missing-phone"
  >("has-phone");
  const t = useTranslations("city");
  const [lastCall, setLastCall] = useState<LastCallFilter>("168");
  const [regions, setRegion] = useState<Region[]>([]);

  const options = Object.values(Region).map((r) => ({
    label: t(`region.${r}`),
    value: r,
  }));

  const columns: DataTableColDef<
    UsersWithWaitlistsListItem,
    any,
    UserWaitlistColumns
  >[] = [
    {
      id: "customer",
      header: "Customer",
      cell: CustomerCell,
    },
    {
      id: "email",
      header: "Email",
      cell: ({ row }) => {
        if (!row.original.primary_email)
          return <p className="text-gray-500">{"--"}</p>;

        return (
          <a
            className="text-blue-500 underline"
            href={`mailto:${row.original.primary_email}`}
          >
            {row.original.primary_email}
          </a>
        );
      },
    },
    {
      id: "phone",
      header: "Phone",
      cell: ({ row }) => {
        if (!row.original.primary_phone)
          return <p className="text-gray-500">{"--"}</p>;

        return (
          <a
            className="text-blue-500 underline"
            href={`tel:${row.original.primary_phone}`}
          >
            {row.original.primary_phone}
          </a>
        );
      },
    },
    {
      id: "waitlists",
      header: "Waitlists",
      accessorFn: (row) => row.waitlist_count,
      cell: DeEmphasisedCell,
    },
    {
      id: "last_call",
      header: "Last call",
      accessorFn: (row) => row.latestCallActivity?.created_at,
      cell: TimeSinceNowCell,
    },
    {
      id: "actions",
      header: () => null,
      size: 20,
      cell: ({ row }) => {
        return (
          <Button
            outline
            onClick={() => {
              open({
                userId: row.original.id,
              });
            }}
          >
            <MagnifyingGlassIcon />
            View
          </Button>
        );
      },
    },
  ];

  return (
    <DataTable
      id="user-waitlists"
      document={usersWithWaitlistQuery}
      accessor={(data) => data.allUsers}
      columns={columns}
      filters={(table) => (
        <div className="space-x-2">
          <SelectFilter
            label="Phone"
            selected={hasPhone}
            onChange={(newVal) => {
              table.setPagination((prev) => ({
                ...prev,
                pageIndex: 0,
              }));
              setHasPhone(newVal);
            }}
            options={[
              {
                value: "has-phone",
                label: "✅ Has phone",
              },
              {
                value: "missing-phone",
                label: "❌ Missing phone",
              },
            ]}
          />

          <SelectFilter
            label="Last called"
            selected={lastCall}
            onChange={(newVal) => {
              table.setPagination((prev) => ({
                ...prev,
                pageIndex: 0,
              }));
              setLastCall(newVal);
            }}
            options={[
              {
                value: "24",
                label: ">24hrs",
              },
              {
                value: "48",
                label: ">48hrs",
              },
              {
                value: "72",
                label: ">72hrs",
              },
              {
                value: "168",
                label: ">7 days",
              },
            ]}
          />

          <MultiSelectFilter
            label="Region"
            options={options}
            selected={regions}
            onChange={(newVal) => {
              table.setPagination((prev) => ({
                ...prev,
                pageIndex: 0,
              }));
              setRegion(newVal);
            }}
          />
        </div>
      )}
      getQueryVariables={({ pagination, search }) => {
        if (search) {
          return {
            page: pagination.pageIndex,
            first: pagination.pageSize,
            search: search,
          };
        }

        const hasPhoneObj = {
          "has-phone": true,
          "missing-phone": false,
        };

        return {
          page: pagination.pageIndex,
          first: pagination.pageSize,
          hasPhone: hasPhone !== null ? hasPhoneObj[hasPhone] : undefined,
          hasWaitlists: true,
          waitlistRegions: regions.length ? regions : undefined,
          hrsSinceLastCall: lastCall ? parseInt(lastCall) : undefined,
          orderByWaitlistNotification: SortOrder.Desc,
        } satisfies UsersWithWaitlistsListQueryVariables;
      }}
    />
  );
}

export function CustomerCell({
  row,
}: CellContext<UsersWithWaitlistsListItem, any>) {
  const user = row.original;

  return (
    <div className="flex items-center space-x-3">
      <UserProfilePicture model={user} />
      <div>
        <p>{user.name?.trim() || "--"}</p>
      </div>
    </div>
  );
}
