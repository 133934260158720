import { graphql } from "@/gql";

export const invoiceStatusCountQuery = graphql(`
  query InvoiceStatusCountQuery($supplierId: ID) {
    invoiceStatusCount(supplier_id: $supplierId) {
      status
      count
    }
  }
`);
