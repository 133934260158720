import { ReactNode, useState } from "react";
import { toast } from "sonner";
import { CopyCheckIcon, CopyIcon } from "lucide-react";

export function CopyToClipboard({
  text,
  children,
}: {
  text: string | null;
  children?: ReactNode;
}) {
  const [copied, setCopied] = useState(false);

  if (!text) return <div>--</div>;

  return (
    <button
      className="group/copy relative flex items-center space-x-2 whitespace-nowrap rounded-md text-left ring-gray-500 transition-colors hover:bg-gray-100"
      onClick={() => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        toast.success("Success", {
          description: `'${text}' to clipboard`,
        });

        setTimeout(() => {
          setCopied(false);
        }, 2000);
      }}
    >
      <span>{children ? children : text}</span>
      <span className="absolute -right-4 opacity-0 transition duration-300 group-hover/copy:opacity-100">
        {copied ? (
          <CopyCheckIcon className="h-3 w-3" />
        ) : (
          <CopyIcon className="h-3 w-3" />
        )}
      </span>
    </button>
  );
}
