import { SupplierInput } from "@/app/Suppliers/Components/SupplierInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import {
  CreateSupplierTripInput,
  Currency,
  HireUnitType,
  Measurement,
} from "@/gql/graphql";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { GenericFieldArray } from "@/lib/Components/Form/FieldArray/GenericFieldArray";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { RichTextInput } from "@/lib/Components/Form/Inputs/RichTextInput";
import { Directions } from "@/app/SupplierTrips/Components/SupplierTripMap";
import { useTranslations } from "use-intl";
import { floor } from "lodash";
import { LightbulbIcon } from "lucide-react";
import { RelocationInclusionTypeInput } from "@/app/Relocations/Components/RelocationInclusionTypeInput";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { SupplierOfficeInput } from "@/app/Offices/Components/SupplierOfficeInput";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { supplierRecordQuery } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { supplierOfficeRecordQuery } from "@/app/Offices/GraphQL/supplierOfficeRecordQuery";
import { DistanceAllowedInput } from "@/lib/Components/Form/Inputs/DistanceAllowedInput";
import { tripByCities } from "@/app/Cities/Trips/GraphQL/tripByCities";
import { Skeleton } from "@/components/ui/skeleton";
import { Suspense } from "react";

export function SupplierTripForm() {
  const { values, touched, initialValues, setFieldValue } =
    useForm<CreateSupplierTripInput>();

  const t = useTranslations("relocation");

  const { data } = useGqlQuery(
    supplierRecordQuery,
    {
      id: values.supplier?.connect!,
    },
    {
      enabled: !!values.supplier?.connect,
    },
  );
  const supplier = data?.supplier;
  const hireUnitLabel = t(
    `hire_unit_type.${supplier?.hire_unit_type ?? HireUnitType.Day}`,
  );

  const { data: officeARes } = useGqlQuery(
    supplierOfficeRecordQuery,
    {
      id: values.officeA?.connect!,
    },
    {
      enabled: !!values.officeA?.connect,
    },
  );
  const origin = officeARes?.supplierOffice;

  const { data: officeBRes } = useGqlQuery(
    supplierOfficeRecordQuery,
    {
      id: values.officeB.connect!,
    },
    {
      enabled: !!values.officeB?.connect,
    },
  );
  const destination = officeBRes?.supplierOffice;

  return (
    <>
      {!initialValues.supplier?.connect ? (
        <>
          <SupplierInput name="supplier.connect" label="Supplier" />
          <FormDivider />
        </>
      ) : null}

      {supplier ? (
        <>
          {!initialValues.officeA?.connect &&
          !initialValues.officeB?.connect ? (
            <>
              <SupplierOfficeInput
                name="officeA.connect"
                label="Office A"
                getQueryVariables={(search) => ({
                  first: 20,
                  page: 1,
                  search,
                  archived: false,
                  supplier_id: supplier.id,
                })}
              />
              <SupplierOfficeInput
                name="officeB.connect"
                label="Office B"
                getQueryVariables={(search) => ({
                  first: 20,
                  page: 1,
                  search,
                  archived: false,
                  supplier_id: supplier.id,
                })}
              />
            </>
          ) : null}

          {origin && destination && supplier ? (
            <>
              <div className="col-span-full pb-6">
                <Suspense fallback={<Skeleton className="h-[260px] w-full" />}>
                  <Directions
                    measurement={supplier.measurement}
                    deliveryOfficeId={destination.id}
                    departureOfficeId={origin.id}
                  />
                </Suspense>
              </div>
              <div className="col-span-full pb-6">
                <TripRecommendationBanner
                  officeAId={origin.id}
                  officeBId={destination.id}
                  measurement={supplier.measurement}
                  hireUnitType={supplier.hire_unit_type}
                />
              </div>
            </>
          ) : null}

          <NumberInput
            name="hire_units_allowed"
            label={`${hireUnitLabel}s allowed`}
            inputProps={{
              min: 0,
            }}
            onChange={(value) => {
              if (value === null) return;

              if (!touched.minimum_hire_units) {
                setFieldValue("minimum_hire_units", floor(value / 2) + 1);
              }
            }}
          />

          <NumberInput
            name="extra_hire_units_allowed"
            label={`Extra ${hireUnitLabel}s allowed`}
          />

          <NumberInput
            name="minimum_hire_units"
            label={`Minimum ${hireUnitLabel}s allowed`}
          />
          <FormDivider />
          <DistanceAllowedInput
            name="distance_allowed"
            label={`${
              supplier?.measurement === Measurement.Metric ? "Kms" : "Mls"
            } allowed`}
            measurement={supplier?.measurement ?? Measurement.Metric}
          />

          <SwitchInput
            name="is_ferry_required"
            label="Is ferry required"
            tooltip="Do you want the customer to specify which ferry date they would prefer"
          />

          <GenericFieldArray
            label="Standard inclusions"
            fieldArrayKey="inclusions.upsert"
            newLineItemValues={{
              type: null,
              description: null,
              value: null,
            }}
          >
            {(item, index) => {
              return (
                <>
                  <RelocationInclusionTypeInput
                    name={`inclusions.upsert.${index}.type`}
                    label="Type"
                  />
                  <CurrencyInput
                    currency={supplier?.currency ?? Currency.Usd}
                    name={`inclusions.upsert.${index}.value`}
                    label="Monetary value"
                    optionalLabel
                  />
                  <RichTextInput
                    name={`inclusions.upsert.${index}.description`}
                    label="Description"
                    className="col-span-full"
                  />
                </>
              );
            }}
          </GenericFieldArray>
        </>
      ) : null}
    </>
  );
}

export function TripRecommendationBanner({
  officeAId,
  officeBId,
  measurement,
  hireUnitType,
}: {
  officeAId: string;
  officeBId: string;
  measurement: Measurement;
  hireUnitType: HireUnitType;
}) {
  const t = useTranslations("relocation");

  const { data: departureData } = useGqlQuery(supplierOfficeRecordQuery, {
    id: officeAId,
  });

  const { data: deliveryData } = useGqlQuery(supplierOfficeRecordQuery, {
    id: officeBId,
  });

  const { data } = useGqlQuery(
    tripByCities,
    {
      cityAId: departureData?.supplierOffice.city.id!,
      cityBId: deliveryData?.supplierOffice.city.id!,
    },
    {
      enabled: !!departureData && !!deliveryData,
    },
  );

  if (!data || !departureData || !deliveryData) return null;

  const { distance, duration } = data.tripByCities;

  const hireUnitLabel = t(`hire_unit_type.${hireUnitType}`) as string;

  const measurementLabel = measurement === Measurement.Metric ? "kms" : "mls";

  const hireUnits = duration - (hireUnitType === HireUnitType.Day ? 0 : 1);

  //Convert distance to miles if measurement is imperial
  const distanceUnits =
    measurement === Measurement.Metric
      ? distance
      : (distance * 0.621371).toFixed(0);

  const origin =
    data.tripByCities.cityA.id === departureData!.supplierOffice.city.id
      ? data.tripByCities.cityA
      : data.tripByCities.cityB;

  const destination =
    data.tripByCities.cityA.id === departureData!.supplierOffice.city.id
      ? data.tripByCities.cityB
      : data.tripByCities.cityA;

  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <LightbulbIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">Hint</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
              For this trip, we recommend{" "}
              <strong>
                {hireUnits} x {hireUnitLabel.toLowerCase()}s
              </strong>{" "}
              and{" "}
              <strong>
                {distanceUnits} {measurementLabel}
              </strong>{" "}
              for {origin.name} {destination.name}.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
