import {
  HireUnitType,
  RelocationInclusionType,
  RelocationStatus,
} from "@/gql/graphql";

export const relocationMessages = {
  hire_unit_type: {
    [HireUnitType.Day]: "day",
    [HireUnitType.Night]: "night",
    [HireUnitType.TwentyFourHours]: "24hr",
  },
  status: {
    [RelocationStatus.Archived]: "archived",
    [RelocationStatus.Draft]: "draft",
    [RelocationStatus.Expired]: "expired",
    [RelocationStatus.Paused]: "paused",
    [RelocationStatus.Ready]: "active",
    [RelocationStatus.SoldOut]: "sold",
  },
  inclusions: {
    type: {
      [RelocationInclusionType.Fuel]: "Fuel",
      [RelocationInclusionType.Ferry]: "Ferry",
      [RelocationInclusionType.Charge]: "Additional Charge",
      [RelocationInclusionType.Other]: "Other benefit",
      [RelocationInclusionType.Negative]: "Negative",
    } satisfies {
      [key in RelocationInclusionType]: string;
    },
    typeDescription: {
      [RelocationInclusionType.Fuel]:
        "Add information about the fuel inclusion",
      [RelocationInclusionType.Ferry]:
        "Add information about the ferry inclusion",
      [RelocationInclusionType.Charge]:
        "Add information about the additional charges",
      [RelocationInclusionType.Other]:
        "Add information about the other benefits",
      [RelocationInclusionType.Negative]:
        "Add information about what negatives come with this relocation (e.g. damage to the vehicle, cleaning required etc)",
    } satisfies {
      [key in RelocationInclusionType]: string;
    },
  },
};
