import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

export function VehicleIcon({
  model,
  className,
}: {
  model?: {
    name: string;
    heroImage: string;
  };
  className?: string;
}) {
  const initials = model?.name
    .match(/(\b\S)?/g)!
    .join("")!
    .match(/(^\S|\S$)?/g)!
    .join("")
    .toUpperCase();

  return (
    <Avatar className={className}>
      <AvatarImage src={model?.heroImage} className="object-contain" />
      <AvatarFallback>{initials}</AvatarFallback>
    </Avatar>
  );
}
