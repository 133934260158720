import { z } from "zod";
import { BookingStatus } from "@/gql/graphql";

export const relocationFulfillmentValidation = z.object({
  description: z.string().max(255).nullish(),
  was_relocation_completed: z.boolean(),
  cancellation_status: z
    .nativeEnum(BookingStatus)
    .nullish()
    .refine((v) => {
      return v;
    }),
});
