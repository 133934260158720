import {
  BaseComboboxInputProps,
  ComboboxInput,
} from "@/lib/Components/Form/Inputs/ComboboxInput";
import {
  OrganisationListItem,
  organisationListQueryGraphql,
} from "@/app/Organisations/GraphQL/organisationListQuery.graphql";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import { organisationRecordQueryGraphql } from "@/app/Organisations/GraphQL/organisationRecordQuery.graphql";

export function OrganisationInput(
  props: BaseComboboxInputProps<
    ResultOf<typeof organisationListQueryGraphql>,
    VariablesOf<typeof organisationListQueryGraphql>,
    OrganisationListItem,
    ResultOf<typeof organisationRecordQueryGraphql>,
    VariablesOf<typeof organisationRecordQueryGraphql>
  >,
) {
  return (
    <ComboboxInput
      document={organisationListQueryGraphql}
      accessor={(data) => data.organisations.data}
      getQueryVariables={(search) => ({
        first: 50,
        page: 1,
        search,
      })}
      recordDocument={organisationRecordQueryGraphql}
      recordAccessor={(data) => data.organisation}
      getViewNode={(i) => i?.name ?? "--"}
      getKey={(item) => item.id}
      getListItemNode={(item) => item.name}
      getInputNode={(item) => item.name}
      {...props}
    />
  );
}
