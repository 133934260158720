import { graphql } from "@/gql";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";

export const allUserListQuery = graphql(`
  query AllUserList(
    $first: Int!
    $page: Int
    $search: String
    $orderBy: [QueryAllUsersOrderByRelationOrderByClause!]
  ) {
    allUsers(first: $first, page: $page, search: $search, orderBy: $orderBy) {
      data {
        id
        name
        primary_phone
        primary_email
        contact {
          profilePicture {
            ...MediaFields
          }
        }
        created_at
        updated_at
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);

export type AllUserListVariables = VariablesOf<typeof allUserListQuery>;
export type AllUserListItem = ResultOf<
  typeof allUserListQuery
>["allUsers"]["data"][number];
