import { createRoute, Outlet } from "@tanstack/react-router";
import { appLayoutRoute } from "@/routes";
import { QuoteTableScreen } from "@/app/Quotes/Screens/QuoteTableScreen";
import { QuoteRecordScreen } from "@/app/Quotes/Screens/QuoteRecordScreen";
import { QuoteDetailScreen } from "@/app/Quotes/Screens/QuoteDetailScreen";
import { QuoteActivityScreen } from "@/app/Quotes/Screens/QuoteActivityScreen";
import { fetchQuery } from "@/lib/GraphQLCodegen/fetcher";
import { quoteRecordQuery } from "@/app/Quotes/GraphQL/quoteRecordQuery";

export const quotesRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/quotes",
  component: Outlet,
});

export const quoteTableRoute = createRoute({
  getParentRoute: () => quotesRoute,
  path: "/",
  component: QuoteTableScreen,
});

export const quoteRecordLayoutRoute = createRoute({
  getParentRoute: () => quotesRoute,
  id: "_quoteRecordLayout",
  component: Outlet,
});

export const quoteRecordRoute = createRoute({
  getParentRoute: () => quoteRecordLayoutRoute,
  path: "$quoteId",
  component: QuoteRecordScreen,
  loader: ({ params: { quoteId }, context: { auth } }) => {
    return fetchQuery(quoteRecordQuery, { id: quoteId }, auth);
  },
});

export const quoteDetailRoute = createRoute({
  getParentRoute: () => quoteRecordRoute,
  path: "/",
  component: QuoteDetailScreen,
});

export const quoteActivityRoute = createRoute({
  getParentRoute: () => quoteRecordRoute,
  path: "/activity",
  component: QuoteActivityScreen,
});
