import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { quoteRecordQuery } from "@/app/Quotes/GraphQL/quoteRecordQuery";

export function SendQuoteDialog({ quoteId }: { quoteId: string }) {
  const { data } = useSuspenseGqlQuery(quoteRecordQuery, {
    id: quoteId,
  });

  return <GenericDialog title="Send Quote">quote..</GenericDialog>;
}
