import { Button } from "@/lib/Components/Button/Button";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { forwardRef, ReactNode } from "react";
import { Intent } from "@/lib/Components/Common/Intent";

type DialogSubmitButton = {
  text: ReactNode;
  intent?: Intent;
};

export const DialogSubmitButton = forwardRef<
  HTMLButtonElement,
  DialogSubmitButton
>(({ text, intent = "primary" }, ref) => {
  const { close, reject } = useDialogState();
  const { isSubmitting, submitCount, isValid, errorCount } = useForm();

  const showWarning = submitCount > 1 && !isValid;

  return (
    <div className="col-span-full mt-9 flex justify-end space-x-3">
      <Button
        type="text"
        onClick={() => {
          reject?.(false);
          close();
        }}
      >
        Cancel
      </Button>
      <Button
        ref={ref}
        LeadingIcon={showWarning ? ExclamationTriangleIcon : undefined}
        htmlButtonType="submit"
        isLoading={isSubmitting}
        intent={showWarning ? "danger" : intent}
        showCommandShortcut
      >
        {showWarning ? `${errorCount} error(s)` : text}
      </Button>
    </div>
  );
});

DialogSubmitButton.displayName = "DialogSubmitButton";
