import { RelocationRecord } from "@/app/Relocations/GraphQL/relocationRecordQuery";
import { UpdateRelocationInput } from "@/gql/graphql";

export function getRelocationUpdateInput(
  relocation: RelocationRecord,
): UpdateRelocationInput {
  return {
    supplier: {
      connect: relocation.supplier.id,
    },
    supplierInsuranceOption: {
      connect: relocation.supplierInsuranceOption?.id ?? null!,
    },
    vehicle: {
      connect: relocation.vehicle.id,
    },
    departureOffice: {
      connect: relocation.departureOffice.id,
    },
    deliveryOffice: {
      connect: relocation.deliveryOffice.id,
    },
    count: relocation.count,
    available_to_date: relocation.available_to_date,
    available_from_date: relocation.available_from_date,
    fixed_latest_departure_date: relocation.fixed_latest_departure_date,

    distance_allowed: relocation.distance_allowed,
    extra_distance_allowed_per_extra_hire_unit:
      relocation.extra_distance_allowed_per_extra_hire_unit,
    excess_distance_rate: relocation.excess_distance_rate,
    hire_unit_type: relocation.hire_unit_type,
    hire_units_allowed: relocation.hire_units_allowed,
    extra_hire_unit_deposit_rate: relocation.extra_hire_unit_deposit_rate,
    extra_hire_unit_rate: relocation.extra_hire_unit_rate,
    extra_hire_unit_supplier_net_rate:
      relocation.extra_hire_unit_supplier_net_rate,
    extra_hire_units_allowed: relocation.extra_hire_units_allowed,

    hire_unit_rate: relocation.hire_unit_rate,
    minimum_hire_units: relocation.minimum_hire_units,
    booking_fee_amount: relocation.booking_fee_amount,
    holding_deposit_amount: relocation.holding_deposit_amount,
    holding_deposit_refunded_at: relocation.holding_deposit_refunded_at,
    holding_deposit_refunded_by: relocation.holding_deposit_refunded_by,
    charge_per_move_amount: relocation.charge_per_move_amount,

    is_ferry_required: relocation.is_ferry_required,

    currency: relocation.currency,
    region: relocation.region,
    measurement: relocation.measurement,

    inclusions: {
      upsert: relocation.inclusions.map((inclusion) => {
        return {
          id: inclusion.id,
          type: inclusion.type,
          description: inclusion.description,
          value: inclusion.value,
        };
      }),
      delete: [],
    },

    line: {
      upsert: {
        id: relocation.line.id,
        reference: relocation.line.reference ?? null,
      },
    },
  };
}
