import {
  QuerySupplierOfficesOrderByColumn,
  SortOrder,
  SupplierOfficeListQueryVariables,
} from "@/gql/graphql";
import { OfficeListSearchParams } from "@/app/Offices/Routes/officeRoutes";
import { getGlobalSupplierId } from "@/app/Suppliers/Utils/getGlobalSupplierId";

export function getOfficeListQueryVariables(
  searchParams: OfficeListSearchParams,
): SupplierOfficeListQueryVariables {
  const supplierId = getGlobalSupplierId();

  const baseParams: SupplierOfficeListQueryVariables = {
    first: 20,
    page: 1,
    search: searchParams.officeSearch || undefined,
    supplier_id: supplierId ?? undefined,
  };

  if (searchParams.officeSearch) {
    return baseParams;
  }
  return {
    ...baseParams,
    orderBy: [
      {
        order: SortOrder.Asc,
        column: QuerySupplierOfficesOrderByColumn.Name,
      },
    ],
  };
}
