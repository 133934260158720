import { graphql } from "@/gql";

export const relocationStatusCountsQuery = graphql(`
  query RelocationStatusCountsQuery($supplierId: ID) {
    relocationStatusCounts(supplierId: $supplierId) {
      status
      count
    }
  }
`);
