import {
  MediaCollection,
  ModelType,
  Region,
  UpdateSupplierInput,
} from "@/gql/graphql";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { PhoneInput } from "@/lib/Components/Form/Inputs/PhoneInput";
import {
  SettingCard,
  SettingFormCard,
} from "@/app/Settings/Settings/Components/SettingFormCard";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { supplierValidator } from "@/app/Suppliers/Validation/supplierValidator";
import { createMediaMutation } from "@/app/Media/GraphQL/mediaMutations";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { Avatar } from "@/components/catalyst/avatar";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { FileUploader } from "@/lib/FileUpload/FileUploader";
import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { useState } from "react";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { RegionInput } from "@/app/Common/Components/RegionInput";
import { CurrencyTypeInput } from "@/app/Common/Components/CurrencyTypeInput";
import { HireUnitTypeInput } from "@/app/Common/Components/HireUnitType";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { MeasurementInput } from "@/app/Common/Components/MeasurementInput";
import { NoSupplierEmptyState } from "@/app/Settings/Settings/Components/NoSupplierEmptyState";
import { SupplierAcknowledgmentTable } from "@/app/Suppliers/Components/Tables/SupplierAcknowledgmentTable";

export function SettingsGeneralScreen() {
  const { supplier } = useGlobalSupplier();
  const { mutateAsync } = useGqlMutation(updateSupplierMutation);

  if (!supplier) {
    return <NoSupplierEmptyState />;
  }

  return (
    <div className="space-y-12">
      <SettingFormCard
        title="Profile"
        description="This information will be displayed on booking confirmations so be careful what you share."
        initialValues={{
          name: supplier.name,
        }}
        onSubmit={async (values) => {
          return mutateAsync({
            id: supplier.id,
            input: values,
          });
        }}
        validationSchema={toFormikValidationSchema(
          supplierValidator.pick({ name: true }),
        )}
      >
        <TextInput name="name" label="Name" className="col-span-4" />

        <Logo supplier={supplier} />
      </SettingFormCard>

      <SettingFormCard
        title="Contact Information"
        description="This information will be displayed publicly so be careful what you share."
        initialValues={{
          email: supplier.email,
          phone: supplier.phone,
        }}
        onSubmit={async (values) => {
          return mutateAsync({
            id: supplier.id,
            input: values,
          });
        }}
      >
        <TextInput
          type="email"
          name="email"
          label="Email"
          className="col-span-4"
          optionalLabel
          tooltip="An email address for the customer"
        />
        <PhoneInput
          name="phone"
          label="Phone"
          className="col-span-4"
          optionalLabel
          tooltip="A contact number for the customer"
          defaultRegion={Region.Au}
        />
      </SettingFormCard>

      <SettingFormCard<UpdateSupplierInput>
        title="Defaults"
        description="These settings will be used as defaults for your account."
        initialValues={{
          region: supplier.region,
          currency: supplier.currency,
          measurement: supplier.measurement,
          hire_unit_type: supplier.hire_unit_type,
        }}
        onSubmit={(values) => {
          return mutateAsync({
            id: supplier.id,
            input: values,
          });
        }}
      >
        <RegionInput
          name={"region"}
          label={"Region"}
          className="col-span-4 row-start-1"
        />
        <CurrencyTypeInput
          name={"currency"}
          label={"Currency"}
          className="col-span-4"
        />
        <MeasurementInput
          name={"measurement"}
          label={"Measurement"}
          className="col-span-4"
        />

        <HireUnitTypeInput
          className="col-span-4 row-start-2"
          name={"hire_unit_type"}
          label={"Hire unit"}
        />

        <div className="col-span-full rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                Are your vehicles sold by the day, night or 24hrs?
              </p>
              <p className="mt-3 text-sm md:ml-6 md:mt-0">
                <a
                  href="https://www.imoova.com/en/blog/rental-days"
                  className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                  rel={"noreferrer"}
                  target={"_blank"}
                >
                  Read more
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </SettingFormCard>

      <SettingCard
        title="Acknoledgements"
        description="What do you want your customers to accept before making a booking?"
      >
        <SupplierAcknowledgmentTable supplierId={supplier.id} />
      </SettingCard>
    </div>
  );
}

function Logo({ supplier }: { supplier: SupplierRecord }) {
  const { mutateAsync: createMedia } = useGqlMutation(createMediaMutation);
  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  return (
    <div className="col-span-full">
      <label
        htmlFor="photo"
        className="block text-sm font-medium leading-6 text-gray-700"
      >
        Logo
      </label>
      <div className="mt-2 flex items-center gap-x-3">
        {supplier.logo ? (
          <Avatar media={supplier.logo} className="h-12 w-12" />
        ) : (
          <UserCircleIcon
            className="h-12 w-12 text-gray-300"
            aria-hidden="true"
          />
        )}
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>
            <button
              type="button"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Change
            </button>
          </DialogTrigger>
          <DialogContent>
            <DialogTitle>Upload a logo</DialogTitle>

            <div className="relative w-full py-1">
              <FileUploader
                onDrop={() => {
                  setIsUploading(true);
                }}
                onUpload={async (uploads) => {
                  if (uploads.length === 0 || uploads.length > 1) {
                    throw new Error("Uploads must be exactly 1");
                  }
                  const upload = uploads[0];

                  await createMedia({
                    input: {
                      file_name: upload.file.name,
                      key: upload.temporary_url,
                      collectionName: MediaCollection.Logo,
                      objectType: ModelType.Supplier,
                      objectId: supplier.id,
                    },
                  });

                  setOpen(false);

                  setIsUploading(false);
                }}
              />

              {isUploading ? (
                <div className="absolute inset-0 flex items-center justify-center bg-white/30 backdrop-blur-sm">
                  <Spinner />
                </div>
              ) : null}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
