import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";
import { TripListItem } from "@/app/Cities/Trips/GraphQL/tripList.graphql";
import { updateTrip } from "@/app/Cities/Trips/GraphQL/tripMutations.graphql";
import { UpdateTripInput } from "@/gql/graphql";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";

export function TripEditDialog({ trip }: { trip: TripListItem }) {
  const { close } = useDialogState();
  const { mutateAsync } = useGqlMutation(updateTrip);

  return (
    <GenericDialog title="Update trip">
      <GenericForm<UpdateTripInput>
        initialValues={{
          distance_override: trip.distance,
          duration_override: trip.duration,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: trip.id,
            input: values,
          });

          close();
        }}
      >
        <NumberInput name="distance_override" label="Kms" />
        <NumberInput name="duration_override" label="Number of days" />
        <DialogSubmitButton text="Update trip" />
      </GenericForm>
    </GenericDialog>
  );
}
