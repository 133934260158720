import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type InvoiceRecord = ResultOf<typeof invoiceRecordQuery>["invoice"];
export const invoiceRecordQuery = graphql(`
  query InvoiceRecord($id: ID!) {
    invoice(id: $id) {
      id
      currency
      reference
      purchase_order_number
      is_tax_included
      status
      amount_refunded
      amount_paid
      amount_credited
      amount_due
      amount_inc_tax
      amount_exc_tax
      amount_tax
      created_at
      updated_at
      due_date
      invoice_date
      payee {
        ...ContactRecordFields
        ...StripeCustomerFields
        billingEmail {
          id
          email
        }
        billingPhone {
          id
          phone
        }
      }
      managedBy {
        id
        name
        contact {
          id
          name
        }
      }
      invoicePayments {
        ...InvoicePaymentFields
      }
      lineItems {
        id
        tax_type
        unit_amount
        amount
        quantity
        description
        fulfills {
          id
          __typename
          type
          attributed_to_id
          attributed_to_type
        }
      }
    }
  }
`);

export const invoicePaymentFieldsFragment = graphql(`
  fragment InvoiceLineItemFields on PayableLineItem {
    id
    tax_type
    unit_amount
    amount
    quantity
    description
    fulfills {
      id
      __typename
      type
      attributed_to_id
      attributed_to_type
    }
  }

  fragment InvoicePaymentFields on InvoicePayment {
    id
    type
    method
    reference
    description
    amount
    payment_date
    stripePayment {
      id
      currency
      receipt_url
      payment_intent_id
      status
      created_at
      updated_at
    }
    created_at
    updated_at
  }
`);
