import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { ContactInput } from "@/app/Contacts/Components/ContactInput";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";
import {
  ContactType,
  ModelType,
  PaymentFieldsFragment,
  PaymentStatus,
  UpdatePaymentInput,
} from "@/gql/graphql";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { CurrencyTypeInput } from "@/app/Common/Components/CurrencyTypeInput";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { OrganisationRecord } from "@/app/Organisations/GraphQL/organisationRecordQuery.graphql";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";

type PaymentFormProps = {
  payment?: PaymentFieldsFragment;
  manager?: OrganisationRecord;
};
export function PaymentForm({ payment, manager }: PaymentFormProps) {
  const { values } = useForm<UpdatePaymentInput>();

  const canEditValues = !payment || payment.status === PaymentStatus.Unpaid;

  return (
    <>
      <ContactInput
        name="payee.connect"
        label="Payee"
        initialFixtureValues={
          manager
            ? {
                name: "",
                type: ContactType.Entity,
                object: {
                  connect: {
                    type: ModelType.Contact,
                    id: manager.contact.id,
                  },
                },
              }
            : undefined
        }
        getQueryVariables={(search) => ({
          managedById: manager?.id,
          type: [ContactType.Organisation, ContactType.Entity],
          search,
          first: 20,
          page: 1,
        })}
      />
      <FormDivider />
      <CurrencyInput
        label="Amount"
        name="amount"
        isEditable={canEditValues}
        currency={values.currency!}
      />
      <CurrencyTypeInput
        label="Currency"
        name="currency"
        isEditable={canEditValues}
      />
      <SwitchInput
        name={"is_tax_included"}
        label={"Tax inclusive"}
        isEditable={canEditValues}
      />
      <DateInput name="due_date" label="Due date" />

      <TextAreaInput
        name={"description"}
        label={"Description"}
        className="col-span-full"
      />
    </>
  );
}
