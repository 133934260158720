import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { VehicleForm } from "@/app/Vehicles/Forms/VehicleForm";
import { UpdateVehicleInput } from "@/gql/graphql";
import { mapVehicleModelToInput } from "@/app/Vehicles/Utils/mapVehicleModelToInput";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { updateVehicleMutation } from "@/app/Vehicles/GraphQL/vehicleMutations";
import { vehicleRecordQuery } from "@/app/Vehicles/GraphQL/vehicleRecordQuery";

type VehicleEditDialogProps = {
  vehicleId: string;
};
export function VehicleEditDialog({ vehicleId }: VehicleEditDialogProps) {
  const { mutateAsync } = useGqlMutation(updateVehicleMutation);
  const { close } = useDialogState();
  const { data } = useSuspenseGqlQuery(vehicleRecordQuery, {
    id: vehicleId,
  });

  const vehicle = data.vehicle;

  return (
    <GenericDialog title={"Edit vehicle"}>
      <GenericForm<UpdateVehicleInput>
        initialValues={mapVehicleModelToInput(vehicle)}
        onSubmit={async (values) => {
          await mutateAsync({
            id: vehicle.id,
            input: values,
          });

          close();
        }}
      >
        <VehicleForm />
        <DialogSubmitButton text="Update vehicle" />
      </GenericForm>
    </GenericDialog>
  );
}
