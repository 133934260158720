import {
  Fuel,
  HireUnitType,
  Transmission,
  VehicleApproximateAge,
  VehicleType,
} from "@/gql/graphql";

export const vehicleMessages = {
  type: {
    [VehicleType.BoxTruck]: "Box truck",
    [VehicleType.CamperVan]: "Campervan",
    [VehicleType.Car]: "Car",
    [VehicleType.FourWheelDrive]: "4WD",
    [VehicleType.FourWheelDriveCamper]: "4WD camper",
    [VehicleType.LuxuryCar]: "Luxury car",
    [VehicleType.MiniVan]: "Mini-van",
    [VehicleType.MotorHome]: "Motorhome",
    [VehicleType.Suv]: "SUV",
    [VehicleType.Ute]: "Ute",
    [VehicleType.Van]: "Van",
    [VehicleType.Caravan]: "Caravan",
  } satisfies {
    [key in VehicleType]: string;
  },
  transmission: {
    [Transmission.Automatic]: "Automatic",
    [Transmission.Manual]: "Manual",
  } satisfies {
    [key in Transmission]: string;
  },
  fuel: {
    [Fuel.Diesel]: "Diesel",
    [Fuel.Electric]: "Electric",
    [Fuel.Gasoline]: "Gasoline",
    [Fuel.Hybrid]: "Hybrid",
  } satisfies {
    [key in Fuel]: string;
  },
  approximate_age: {
    [VehicleApproximateAge.LessThanThreeYears]: "<3 years",
    [VehicleApproximateAge.LessThanFiveYears]: "<5 years",
    [VehicleApproximateAge.LessThanTenYears]: "<10 years",
    [VehicleApproximateAge.LessThanTwentyYears]: "<20 years",
    [VehicleApproximateAge.MoreThanTwentyYears]: ">20 years",
    [VehicleApproximateAge.New]: "New",
  } satisfies {
    [key in VehicleApproximateAge]: string;
  },
  hire_unit_type: {
    [HireUnitType.Day]: "Day",
    [HireUnitType.Night]: "Night",
    [HireUnitType.TwentyFourHours]: "24 hours",
  } satisfies {
    [key in HireUnitType]: string;
  },
};
