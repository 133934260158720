import { BookingPaymentRequestStatus } from "@/gql/graphql";
import { BadgeColor } from "@/components/catalyst/badge";

export const bookingPaymentRequestStatusColorMap: Record<
  BookingPaymentRequestStatus,
  BadgeColor
> = {
  [BookingPaymentRequestStatus.AwaitingPayment]: "blue",
  [BookingPaymentRequestStatus.Cancelled]: "red",
  [BookingPaymentRequestStatus.Paid]: "green",
  [BookingPaymentRequestStatus.PaymentPending]: "yellow",
};
