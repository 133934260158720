import { GenericField } from "@/lib/Components/Form/Fields/GenericField";
import { MultiSelect, MultiSelectOption } from "@/components/ui/multi-select";
import { useField } from "formik";

export function MultiSelectInput({
  label,
  name,
  options,
  className,
}: {
  name: string;
  label: string;
  className: string;
  options: MultiSelectOption[];
}) {
  const [field, meta, { setValue }] = useField<string[]>(name);

  return (
    <GenericField
      name={name}
      label={label}
      viewNode={"TODO"}
      className={className}
    >
      <MultiSelect
        defaultValue={field.value}
        options={options}
        onValueChange={(newVal) => {
          setValue(newVal);
        }}
      />
    </GenericField>
  );
}
