import { CreditCard } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { useBookingRecord } from "@/app/Bookings/Hooks/useBookingRecord";
import { useTranslations } from "use-intl";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { StripePaymentFieldsFragment } from "@/gql/graphql";

export function BookingPaymentCard() {
  const booking = useBookingRecord();

  const firstPayment = booking.customerPayments
    .filter((payment) => payment.fulfilledBy?.__typename === "StripePayment")
    .at(0)?.fulfilledBy as StripePaymentFieldsFragment | null | undefined;
  const t = useTranslations("booking.cards.orderCard");

  return (
    <Card className="overflow-hidden">
      <CardContent className="p-6 text-sm">
        <div className="grid gap-3">
          <div className="font-semibold">Order Details</div>
          <ul className="grid gap-3">
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">
                {t("relocationDays", {
                  type: booking.relocation.hire_unit_type,
                  units: booking.discounted_units,
                })}
              </span>
              <span>
                {formatCurrency(
                  booking.pay_later_discounted_amount,
                  booking.currency,
                )}
              </span>
            </li>
            {booking.full_price_units > 0 ? (
              <li className="flex items-center justify-between">
                <span className="text-muted-foreground">
                  {t("extraDays", {
                    type: booking.relocation.hire_unit_type,
                    units: booking.full_price_units,
                  })}
                </span>
                <span>
                  {formatCurrency(
                    booking.pay_later_full_price_amount,
                    booking.currency,
                  )}
                </span>
              </li>
            ) : null}
          </ul>
          <Separator className="my-2" />
          {booking.pay_now_holding_deposit_amount > 0 ? (
            <>
              <ul>
                <li className="flex items-center justify-between">
                  <span className="text-muted-foreground">Holding deposit</span>
                  <span>
                    {formatCurrency(
                      booking.pay_now_holding_deposit_amount,
                      booking.currency,
                    )}
                  </span>
                </li>
              </ul>
              <Separator className="my-2" />
            </>
          ) : null}
          <ul className="grid gap-3">
            <li className="flex items-center justify-between">
              <span className="text-muted-foreground">{t("subtotal")}</span>
              <span>
                {formatCurrency(
                  booking.pay_later_cost_amount,
                  booking.currency,
                )}
              </span>
            </li>
            {booking.supplier_refund_amount ? (
              <li className="flex items-center justify-between">
                <span className="text-muted-foreground">
                  {t("depositRefund", {
                    deposit_refund_at:
                      booking.relocation.holding_deposit_refunded_at,
                  })}
                </span>
                <span>
                  (
                  {formatCurrency(
                    booking.supplier_refund_amount,
                    booking.currency,
                  )}
                  )
                </span>
              </li>
            ) : null}
            <li className="flex items-center justify-between font-semibold">
              <span className="text-muted-foreground">{t("total")}</span>
              <span>
                {formatCurrency(
                  booking.pay_later_total_amount,
                  booking.currency,
                )}
              </span>
            </li>
          </ul>

          {booking.imoova_refund_amount > 0 ? (
            <>
              <Separator className="my-2" />
              <ul>
                <li className="flex items-center justify-between">
                  <span className="text-muted-foreground">
                    Deposit refund (after hire via Imoova)
                  </span>
                  <span>
                    (
                    {formatCurrency(
                      booking.imoova_refund_amount,
                      booking.currency,
                    )}
                    )
                  </span>
                </li>
              </ul>
            </>
          ) : null}
        </div>
        {firstPayment ? (
          <>
            <Separator className="my-4" />
            <div className="grid gap-3">
              <div className="font-semibold">Payment Information</div>
              <dl className="grid gap-3">
                <div className="flex items-center justify-between">
                  <dt className="text-muted-foreground flex items-center gap-1 capitalize">
                    <CreditCard className="h-4 w-4" />
                    {firstPayment.brand}
                  </dt>
                  <dd>**** **** **** {firstPayment.last4}</dd>
                </div>
              </dl>
            </div>
          </>
        ) : null}
      </CardContent>
    </Card>
  );
}
