import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";
import { SupplierNotificationSettingEvent } from "@/gql/graphql";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierNotificationSettingDialog } from "@/app/Suppliers/Components/SupplierNotificationSettingDialog";
import { TextButton } from "@/lib/Components/Button/TextButton";
import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";

export function SupplierMissingBookingNotificationBanner({
  supplier,
}: {
  supplier: SupplierRecord;
}) {
  //Count the number of notification settings for the pending booking event
  const count =
    supplier.notificationSettings?.filter(
      (setting) =>
        setting.event === SupplierNotificationSettingEvent.BookingPending,
    ).length ?? 0;

  const { open } = useDialog(SupplierNotificationSettingDialog);

  if (count > 0) return null;

  return (
    <WarningBanner className="my-6">
      This supplier has no pending booking notifications.{" "}
      <TextButton
        className="underline"
        onClick={() => {
          open({
            supplier,
            event: SupplierNotificationSettingEvent.BookingPending,
          });
        }}
      >
        Add notification email
      </TextButton>
    </WarningBanner>
  );
}
