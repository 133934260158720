import { SettingCard } from "@/app/Settings/Settings/Components/SettingFormCard";
import { CityTable } from "@/app/Cities/Cities/Components/CityTable";

export function SettingsCitiesScreen() {
  return (
    <>
      <SettingCard title="Cities" description="">
        <CityTable id="settings-cities" />
      </SettingCard>
    </>
  );
}
