import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { PendingBookingStatusInput } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { pendingBookingMutation } from "@/app/Bookings/GraphQL/cancelBookingMutation";
import { bookingRecordQuery } from "@/app/Bookings/GraphQL/bookingRecordQuery";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { toFormikValidate } from "zod-formik-adapter";
import { pendingBookingValidation } from "@/app/Bookings/Validation/bookingChangeStatusSchema";

type BookingConfirmDialogProps = {
  bookingId: string;
};
export function BookingPendingDialog({ bookingId }: BookingConfirmDialogProps) {
  const { mutateAsync } = useGqlMutation(pendingBookingMutation);
  const { close } = useDialogState();

  const { data } = useSuspenseGqlQuery(bookingRecordQuery, {
    id: bookingId,
  });

  const booking = data.booking;

  return (
    <GenericDialog title="Pending booking">
      <GenericForm<PendingBookingStatusInput>
        validate={toFormikValidate(pendingBookingValidation)}
        initialValues={{}}
        onSubmit={async (values) => {
          await mutateAsync({
            id: booking.id,
            input: values,
          });

          close();
        }}
      >
        <DialogSubmitButton text="Make pending" />
      </GenericForm>
    </GenericDialog>
  );
}
