import { graphql } from "@/gql";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";

export type SupplierListItem = ResultOf<
  typeof supplierListQuery
>["suppliers"]["data"][number];
export type SupplierListQueryVariables = VariablesOf<typeof supplierListQuery>;
export const supplierListQuery = graphql(`
  query SupplierList(
    $first: Int!
    $page: Int
    $search: String
    $region: Region
    $orderBy: [QuerySuppliersOrderByOrderByClause!]
  ) {
    suppliers(
      first: $first
      page: $page
      search: $search
      orderBy: $orderBy
      region: $region
    ) {
      data {
        id
        name
        email
        booking_fee_amount
        holding_deposit_amount
        holding_deposit_refunded_by
        holding_deposit_refunded_at
        charge_per_move_amount
        is_holding_deposit_required
        default_extra_hire_units_allowed

        import_template
        currency
        region
        measurement

        extra_distance_allowed_per_extra_hire_unit
        excess_distance_rate

        standard_inclusions

        hire_unit_type
        email_on_line_sold
        relocation_lines_valid_until
        billing_currency

        defaultInsuranceOption {
          ...SupplierInsuranceOptionRecordFields
        }

        created_at

        ...SupplierIconFields
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
