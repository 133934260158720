import { z } from "zod";
import { Region } from "@/gql/graphql";

export const relocationHighlightValidation = z
  .object({
    region: z.nativeEnum(Region).nullish(),
    primary_text: z.string().min(1).max(50),
    secondary_text: z.string().max(50).nullish(),
    order: z.number().int().min(0).max(100).nullish(),
    departureCity: z.object({
      connect: z.string().nullish(),
    }),
    deliveryCity: z.object({
      connect: z.string().nullish(),
    }),
  })
  .superRefine((data, ctx) => {
    if ((data.primary_text + data.secondary_text).length > 38) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: 38,
        type: "string",
        inclusive: true,
        message:
          "Primary text and secondary text combined must be less than 38 characters",
        path: ["primary_text"],
      });

      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: 38,
        type: "string",
        inclusive: true,
        message:
          "Primary text and secondary text combined must be less than 38 characters",
        path: ["secondary_text"],
      });
    }
  });
