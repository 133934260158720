import { VehicleListSearchParams } from "@/app/Vehicles/Routes/vehicleRoutes";
import { QueryVehiclesOrderByColumn, SortOrder } from "@/gql/graphql";
import { VariablesOf } from "@graphql-typed-document-node/core";
import { vehicleListQuery } from "@/app/Vehicles/GraphQL/vehicleListQuery";
import { getGlobalSupplierId } from "@/app/Suppliers/Utils/getGlobalSupplierId";

export function getVehicleListQueryVariables(
  searchParams: VehicleListSearchParams,
): VariablesOf<typeof vehicleListQuery> {
  const supplierId = getGlobalSupplierId();

  const baseParams: VariablesOf<typeof vehicleListQuery> = {
    page: 1,
    first: 20,
    search: searchParams.vehicleSearch || undefined,
    supplier_id: supplierId ?? undefined,
  };

  if (searchParams.vehicleSearch) {
    return baseParams;
  }

  return {
    ...baseParams,
    orderBy: [
      {
        order: SortOrder.Desc,
        column: QueryVehiclesOrderByColumn.Id,
      },
    ],
  };
}
