import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { RelocationForm } from "@/app/Relocations/Forms/RelocationForm";
import { UpdateRelocationInput } from "@/gql/graphql";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { relocationRecordQuery } from "@/app/Relocations/GraphQL/relocationRecordQuery";
import { updateRelocationMutation } from "@/app/Relocations/GraphQL/relocationMutations";
import { getRelocationUpdateInput } from "@/app/Relocations/Utils/getRelocationUpdateInput";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { relocationValidation } from "@/app/Relocations/Validation/relocationValidation";
import { BookingTable } from "@/app/Dashboard/Components/BookingTable";
import { useConfirmDeleteDialog } from "@/lib/Components/Dialog/Hooks/useConfirmDeleteDialog";

export function RelocationEditDialog({ id }: { id: string }) {
  const { data } = useSuspenseGqlQuery(relocationRecordQuery, {
    id,
  });
  const { relocation } = data;

  const { mutateAsync } = useGqlMutation(updateRelocationMutation);
  const { close } = useDialogState();
  const { openAsPromise } = useConfirmDeleteDialog();

  return (
    <GenericDialog title="Edit relocation">
      <GenericForm<UpdateRelocationInput>
        validationSchema={toFormikValidationSchema(relocationValidation)}
        onSubmit={async (values, formikHelpers) => {
          if (relocation.bookedBookingsCount) {
            try {
              await openAsPromise({
                title: "Are you sure you want to update this relocation?",
                onDelete: async () => {
                  await mutateAsync({
                    id: relocation.id,
                    input: values,
                  });
                },
                buttonText: "Update",
                message: `This relocation has ${relocation.bookedBookingsCount} bookings associated with it. Updating the relocation will affect the bookings associated with it.  Are you sure you want to update it?`,
                children: (
                  <div className="mt-3">
                    <BookingTable
                      id="dashboard-pending-bookings"
                      queryVariables={{
                        relocationId: relocation.id,
                      }}
                    />
                  </div>
                ),
              });
            } catch (e: any) {
              console.error(e);
              formikHelpers.resetForm();
              formikHelpers.setSubmitting(false);
            }
          } else {
            await mutateAsync({
              id: relocation.id,
              input: values,
            });
          }

          close();
        }}
        initialValues={getRelocationUpdateInput(relocation)}
      >
        <RelocationForm relocation={relocation} />
        <DialogSubmitButton text={"Save"} />
      </GenericForm>
    </GenericDialog>
  );
}
