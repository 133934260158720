import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { BookingPaymentRequestStatus, ModelType } from "@/gql/graphql";
import { ActivityIcon, MailIcon } from "lucide-react";
import {
  ArrowTopRightOnSquareIcon,
  CheckIcon,
} from "@heroicons/react/20/solid";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { BookingPaymentRequestResolveDialog } from "@/app/Bookings/Components/BookingPaymentRequestResolveDialog";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import {
  deleteBookingPaymentRequestMutation,
  sendBookingPaymentRequestMutation,
} from "@/app/Bookings/GraphQL/bookingPaymentRequestMutations";
import { BookingPaymentRequestListItem } from "@/app/Bookings/GraphQL/bookingPaymentRequestList";
import { ActivityLogDialog } from "@/app/ActivityLogs/Components/ActivityLogDialog";

export function useBookingPaymentRequestActions(): ActionsHook<BookingPaymentRequestListItem> {
  const { mutateAsync } = useGqlMutation(deleteBookingPaymentRequestMutation);
  const { open } = useDialog(BookingPaymentRequestResolveDialog);
  const { mutateAsync: sendEmail } = useGqlMutation(
    sendBookingPaymentRequestMutation,
  );
  const { open: openActivityLog } = useDialog(ActivityLogDialog);

  return (model) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                Icon: CheckIcon,
                label: "Approve",
                handleAction: () => {
                  open({
                    bookingPaymentRequest: model,
                    type: "capture",
                  });
                },
                intent: "success",
                isHidden:
                  model.status !== BookingPaymentRequestStatus.PaymentPending,
              },
              {
                Icon: XMarkIcon,
                label: "Decline",
                handleAction: () => {
                  open({
                    bookingPaymentRequest: model,
                    type: "cancel",
                  });
                },
                intent: "danger",
                isHidden:
                  model.status !== BookingPaymentRequestStatus.PaymentPending,
              },
            ],
          },

          {
            actions: [
              {
                Icon: ArrowTopRightOnSquareIcon,
                label: "View",
                handleAction: () => {
                  window.open(
                    `${model.booking.permalink}/payment-requests/${model.uuid}`,
                    "_blank",
                  );
                },
                intent: "neutral",
                isHidden:
                  model.status !== BookingPaymentRequestStatus.AwaitingPayment,
              },
            ],
          },
          {
            actions: [
              {
                Icon: MailIcon,
                label: "Send to customer",
                handleAction: async () => {
                  await sendEmail({
                    id: model.id,
                  });
                },
                intent: "neutral",
                isHidden:
                  model.status !== BookingPaymentRequestStatus.AwaitingPayment,
              },
            ],
          },
          {
            actions: [
              {
                Icon: ActivityIcon,
                label: "Activity",
                handleAction: () => {
                  openActivityLog({
                    modelId: model.booking.id,
                    modelType: ModelType.Booking,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                intent: "danger",
                isHidden:
                  model.status !== BookingPaymentRequestStatus.AwaitingPayment,
                handleAction: async () => {
                  await mutateAsync({
                    id: model.id,
                  });
                },
                label: "Delete",
                Icon: TrashIcon,
              },
            ],
          },
        ],
      },
    ];
  };
}
