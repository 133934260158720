import { graphql } from "@/gql";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";

export type BookingPaymentRequestListQueryVariables = VariablesOf<
  typeof bookingPaymentRequestList
>;
export type BookingPaymentRequestListItem = ResultOf<
  typeof bookingPaymentRequestList
>["bookingPaymentRequests"]["data"][number];
export const bookingPaymentRequestList = graphql(`
  query BookingPaymentRequestList(
    $page: Int
    $first: Int!
    $where: QueryBookingPaymentRequestsWhereWhereConditions
  ) {
    bookingPaymentRequests(page: $page, first: $first, where: $where) {
      data {
        id
        uuid
        currency
        description
        amount
        created_at
        status
        stripePayment {
          ...StripePaymentFields
        }
        booking {
          id
          reference
          permalink
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
