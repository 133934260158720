import { RecordCreateScreen } from "@/lib/Components/Screens/RecordScreen/RecordCreateScreen";
import { RecordCreateCard } from "@/lib/Components/SideBarList/Cards/RecordCreateCard";
import {
  CreateSupplierInput,
  Currency,
  DepositRefundedAt,
  HireUnitType,
  HoldingDepositRefundedBy,
  Measurement,
  Region,
} from "@/gql/graphql";
import { useNavigate } from "@tanstack/react-router";
import * as Yup from "yup";
import { SupplierDetailsForm } from "@/app/Suppliers/Forms/SupplierDetailsForm";
import { supplierRecordRoute } from "@/app/Suppliers/Routes/supplierRoutes";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";

const validation = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().email().nullable(),
  phone: Yup.string().nullable(),
  region: Yup.string().required(),
  currency: Yup.string().required(),
  measurement: Yup.string().required(),
  hire_unit_type: Yup.string().required(),
  booking_fee_amount: Yup.number().required(),
  age_surcharge_amount: Yup.number().required(),
  maximum_no_surcharge_age: Yup.number().required(),
  minimum_no_surcharge_age: Yup.number().required(),
  minimum_age: Yup.number().required(),
  maximum_age: Yup.number().required(),
  extra_distance_allowed_per_extra_hire_unit: Yup.number().nullable(),
  default_extra_hire_units_allowed: Yup.number().required(),
  excess_distance_rate: Yup.number().nullable(),
  excess_distance_note: Yup.string().nullable(),
  holding_deposit_amount: Yup.number().required(),
  debit_card_accepted: Yup.boolean(),
});

export function SupplierCreateScreen() {
  const navigate = useNavigate();
  const { mutateAsync } = useGqlMutation(createSupplierMutation);

  return (
    <RecordCreateScreen>
      <RecordCreateCard<CreateSupplierInput>
        title={"Create supplier"}
        validationSchema={validation}
        initialValues={{
          managed_by_id: null!,
          name: "",
          email: "",
          phone: "",
          region: Region.Au,
          currency: Currency.Aud,
          measurement: Measurement.Metric,
          hire_unit_type: HireUnitType.Day,
          booking_fee_amount: 35 * 100,

          debit_card_accepted: false,
          charge_per_move_amount: 75 * 100,

          has_age_surcharge: false,
          age_surcharge_amount: 0,
          maximum_no_surcharge_age: 70,
          minimum_no_surcharge_age: 25,
          minimum_age: 18,
          maximum_age: 90,

          extra_distance_allowed_per_extra_hire_unit: null!,
          excess_distance_rate: null!,
          standard_inclusions: null,
          default_extra_hire_units_allowed: 0,

          holding_deposit_amount: 100 * 100,
          holding_deposit_refunded_at: DepositRefundedAt.Departure,
          holding_deposit_refunded_by: HoldingDepositRefundedBy.Supplier,
          is_holding_deposit_required: false,

          terms_and_conditions: "",

          email_on_line_sold: false,
          relocation_lines_valid_until: null,
        }}
        onSubmit={async (values) => {
          const res = await mutateAsync({
            input: values,
          });

          await navigate({
            to: supplierRecordRoute.to,
            params: {
              id: res.createSupplier.id,
            },
          });
        }}
      >
        <SupplierDetailsForm />
      </RecordCreateCard>
    </RecordCreateScreen>
  );
}
