import { FC, useContext, useEffect, useId } from "react";
import { DialogContext } from "../Providers/DialogContext";

export function useDialog<TProps, TData = unknown>(
  dialog: FC<TProps>,
): {
  open: (props: TProps) => void;
  openAsPromise: (props: TProps) => Promise<TData>;
} {
  const id = useId();
  const { createDialog, removeDialog } = useContext(DialogContext);

  const open = (props: TProps | undefined) => {
    createDialog(id, {
      Component: dialog,
      isOpen: true,
      props,
    });
  };

  const openAsPromise = async (props: TProps) => {
    return new Promise<TData>((resolve, reject) => {
      createDialog(id, {
        Component: dialog,
        props,
        resolve,
        reject,
        isOpen: true,
      });
    });
  };

  useEffect(() => {
    return () => {
      removeDialog(id);
    };
  }, []);

  return { open, openAsPromise };
}
