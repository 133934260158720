import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { Contact, ContactType } from "@/gql/graphql";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { BillingCycleDateInput } from "@/app/Contacts/Components/BillingCycleDateInput";

export function ContactDetailsForm({
  contact,
}: {
  contact?: Pick<Contact, "type">;
}) {
  return (
    <>
      <TextInput
        name={"name"}
        label={"Name"}
        isEditable={
          contact
            ? ![
                ContactType.User,
                ContactType.Organisation,
                ContactType.System,
              ].includes(contact.type)
            : true
        }
      />

      <FormDivider />
      <TextInput
        name={"legal_name"}
        label={"Legal name"}
        tooltip="Used on formal documents"
      />
      <TextInput
        name={"company_number"}
        label={"Business number"}
        optionalLabel
      />
      <TextInput
        name={"purchase_order_number"}
        label={"Default PO #"}
        optionalLabel
      />

      <BillingCycleDateInput
        label="Invoice cycle date"
        name="billing_cycle_day_of_month"
      />
    </>
  );
}
