import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type SupplierAcknowledgementListItem = ResultOf<
  typeof supplierAcknowledgementList
>["supplierAcknowledgements"]["data"][number];
export const supplierAcknowledgementList = graphql(`
  query supplierAcknowledgementList(
    $supplierId: ID!
    $page: Int!
    $first: Int!
  ) {
    supplierAcknowledgements(
      first: $first
      page: $page
      supplier_id: $supplierId
    ) {
      data {
        id
        name
        acknowledgement
        created_at
        updated_at
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);

export const supplierAcknowledgementRecord = graphql(`
  query supplierAcknowledgementRecord($id: ID!) {
    supplierAcknowledgement(id: $id) {
      id
      name
      acknowledgement
      created_at
      updated_at
    }
  }
`);
