import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { HoldingDepositRefundedBy } from "@/gql/graphql";

export function HoldingDepositRefundedByInput(
  props: Omit<SelectInputProps<any>, "options">,
) {
  const options: {
    [key in HoldingDepositRefundedBy]: {
      label: string;
      value: HoldingDepositRefundedBy;
    };
  } = {
    [HoldingDepositRefundedBy.Imoova]: {
      label: "Imoova",
      value: HoldingDepositRefundedBy.Imoova,
    },
    [HoldingDepositRefundedBy.Supplier]: {
      label: "Supplier",
      value: HoldingDepositRefundedBy.Supplier,
    },
  };

  return (
    <SelectInput
      options={[
        {
          label: "Refunded by",
          options: Object.values(options),
        },
      ]}
      {...props}
    />
  );
}
