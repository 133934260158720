import { useForm } from "../Hooks/useForm";
import { Badge } from "@/components/catalyst/badge";

export function FormationErrorBadge() {
  const { errorCount, isValid, submitCount, isEditing } = useForm();
  const isError = !(isValid || !isEditing);

  if (!isError) {
    return null;
  }

  if (errorCount === 0 || submitCount === 0) {
    return null;
  }

  return (
    <Badge color="red">
      {`${errorCount} ${errorCount === 1 ? "Error" : "Errors"}`}
    </Badge>
  );
}
