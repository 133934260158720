import {
  OptionGroup,
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";
import { memo } from "react";
import { groupBy } from "@/lib/Utils/groupBy";

function TimezoneInputComponent(props: Omit<SelectInputProps, "options">) {
  return <SelectInput {...props} options={options} />;
}

export const TimezoneInput = memo(TimezoneInputComponent);

const timezones = {
  "America/Anchorage": "(GMT-09:00) Alaska Time",
  "Pacific/Gambier": "(GMT-09:00) Gambier",
  "America/Los_Angeles": "(GMT-08:00) Pacific Time",
  "America/Tijuana": "(GMT-08:00) Pacific Time - Tijuana",
  "America/Vancouver": "(GMT-08:00) Pacific Time - Vancouver",
  "America/Whitehorse": "(GMT-08:00) Pacific Time - Whitehorse",
  "Pacific/Pitcairn": "(GMT-08:00) Pitcairn",
  "America/Denver": "(GMT-07:00) Mountain Time",
  "America/Belize": "(GMT-06:00) Belize",
  "America/Chicago": "(GMT-06:00) Central Time",
  "America/Costa_Rica": "(GMT-06:00) Costa Rica",
  "America/El_Salvador": "(GMT-06:00) El Salvador",
  "America/Guatemala": "(GMT-06:00) Guatemala",
  "America/Managua": "(GMT-06:00) Managua",
  "America/Mexico_City": "(GMT-06:00) Central Time - Mexico City",
  "America/Regina": "(GMT-06:00) Central Time - Regina",
  "America/Tegucigalpa": "(GMT-06:00) Central Time - Tegucigalpa",
  "America/Winnipeg": "(GMT-06:00) Central Time - Winnipeg",
  "Pacific/Galapagos": "(GMT-06:00) Galapagos",
  "America/Bogota": "(GMT-05:00) Bogota",
  "America/Cancun": "(GMT-05:00) America Cancun",
  "America/Cayman": "(GMT-05:00) Cayman",
  "America/Guayaquil": "(GMT-05:00) Guayaquil",
  "America/Havana": "(GMT-05:00) Havana",
  "America/Iqaluit": "(GMT-05:00) Eastern Time - Iqaluit",
  "America/Jamaica": "(GMT-05:00) Jamaica",
  "America/Lima": "(GMT-05:00) Lima",
  "America/Nassau": "(GMT-05:00) Nassau",
  "America/New_York": "(GMT-05:00) Eastern Time",
  "America/Panama": "(GMT-05:00) Panama",
  "America/Port-au-Prince": "(GMT-05:00) Port-au-Prince",
  "America/Rio_Branco": "(GMT-05:00) Rio Branco",
  "America/Toronto": "(GMT-05:00) Eastern Time - Toronto",
  "Pacific/Easter": "(GMT-05:00) Easter Island",
  "America/Caracas": "(GMT-04:00) Caracas",
  "America/Asuncion": "(GMT-03:00) Asuncion",
  "America/Barbados": "(GMT-04:00) Barbados",
  "America/Boa_Vista": "(GMT-04:00) Boa Vista",
  "America/Campo_Grande": "(GMT-03:00) Campo Grande",
  "America/Cuiaba": "(GMT-03:00) Cuiaba",
  "America/Curacao": "(GMT-04:00) Curacao",
  "America/Grand_Turk": "(GMT-04:00) Grand Turk",
  "America/Guyana": "(GMT-04:00) Guyana",
  "America/Halifax": "(GMT-04:00) Atlantic Time - Halifax",
  "America/La_Paz": "(GMT-04:00) La Paz",
  "America/Manaus": "(GMT-04:00) Manaus",
  "America/Martinique": "(GMT-04:00) Martinique",
  "America/Port_of_Spain": "(GMT-04:00) Port of Spain",
  "America/Porto_Velho": "(GMT-04:00) Porto Velho",
  "America/Puerto_Rico": "(GMT-04:00) Puerto Rico",
  "America/Santo_Domingo": "(GMT-04:00) Santo Domingo",
  "America/Thule": "(GMT-04:00) Thule",
  "Atlantic/Bermuda": "(GMT-04:00) Bermuda",
  "America/St_Johns": "(GMT-03:30) Newfoundland Time - St. Johns",
  "America/Araguaina": "(GMT-03:00) Araguaina",
  "America/Argentina/Buenos_Aires": "(GMT-03:00) Buenos Aires",
  "America/Bahia": "(GMT-03:00) Salvador",
  "America/Belem": "(GMT-03:00) Belem",
  "America/Cayenne": "(GMT-03:00) Cayenne",
  "America/Fortaleza": "(GMT-03:00) Fortaleza",
  "America/Godthab": "(GMT-03:00) Godthab",
  "America/Maceio": "(GMT-03:00) Maceio",
  "America/Miquelon": "(GMT-03:00) Miquelon",
  "America/Montevideo": "(GMT-03:00) Montevideo",
  "America/Paramaribo": "(GMT-03:00) Paramaribo",
  "America/Recife": "(GMT-03:00) Recife",
  "America/Santiago": "(GMT-03:00) Santiago",
  "America/Sao_Paulo": "(GMT-03:00) Sao Paulo",
  "Antarctica/Palmer": "(GMT-03:00) Palmer",
  "Antarctica/Rothera": "(GMT-03:00) Rothera",
  "Atlantic/Stanley": "(GMT-03:00) Stanley",
  "America/Noronha": "(GMT-02:00) Noronha",
  "America/Scoresbysund": "(GMT-01:00) Scoresbysund",
  "Etc/GMT": "(GMT+00:00) GMT (no daylight saving)",
  "Europe/Dublin": "(GMT+00:00) Dublin",
  "Europe/Lisbon": "(GMT+00:00) Lisbon",
  "Europe/London": "(GMT+00:00) London",
  "Europe/Amsterdam": "(GMT+01:00) Amsterdam",
  "Europe/Andorra": "(GMT+01:00) Andorra",
  "Europe/Belgrade": "(GMT+01:00) Central European Time - Belgrade",
  "Europe/Berlin": "(GMT+01:00) Berlin",
  "Europe/Brussels": "(GMT+01:00) Brussels",
  "Europe/Budapest": "(GMT+01:00) Budapest",
  "Europe/Copenhagen": "(GMT+01:00) Copenhagen",
  "Europe/Gibraltar": "(GMT+01:00) Gibraltar",
  "Europe/Luxembourg": "(GMT+01:00) Luxembourg",
  "Europe/Madrid": "(GMT+01:00) Madrid",
  "Europe/Malta": "(GMT+01:00) Malta",
  "Europe/Monaco": "(GMT+01:00) Monaco",
  "Europe/Oslo": "(GMT+01:00) Oslo",
  "Europe/Paris": "(GMT+01:00) Paris",
  "Europe/Prague": "(GMT+01:00) Central European Time - Prague",
  "Europe/Rome": "(GMT+01:00) Rome",
  "Europe/Stockholm": "(GMT+01:00) Stockholm",
  "Europe/Tirane": "(GMT+01:00) Tirane",
  "Europe/Vienna": "(GMT+01:00) Vienna",
  "Europe/Warsaw": "(GMT+01:00) Warsaw",
  "Europe/Zurich": "(GMT+01:00) Zurich",
  "Africa/Cairo": "(GMT+02:00) Cairo",
  "Africa/Johannesburg": "(GMT+02:00) Johannesburg",
  "Africa/Maputo": "(GMT+02:00) Maputo",
  "Africa/Tripoli": "(GMT+02:00) Tripoli",
  "Europe/Athens": "(GMT+02:00) Athens",
  "Europe/Bucharest": "(GMT+02:00) Bucharest",
  "Europe/Chisinau": "(GMT+02:00) Chisinau",
  "Europe/Helsinki": "(GMT+02:00) Helsinki",
  "Europe/Istanbul": "(GMT+03:00) Istanbul",
  "Europe/Kaliningrad": "(GMT+02:00) Moscow-01 - Kaliningrad",
  "Europe/Kyiv": "(GMT+02:00) Kyiv",
  "Europe/Riga": "(GMT+02:00) Riga",
  "Europe/Sofia": "(GMT+02:00) Sofia",
  "Europe/Tallinn": "(GMT+02:00) Tallinn",
  "Europe/Vilnius": "(GMT+02:00) Vilnius",
  "Europe/Minsk": "(GMT+03:00) Minsk",
  "Europe/Moscow": "(GMT+03:00) Moscow+00 - Moscow",
  "Europe/Samara": "(GMT+04:00) Moscow+01 - Samara",
  "Australia/Perth": "(GMT+08:00) Western Time - Perth",
  "Pacific/Palau": "(GMT+09:00) Palau",
  "Australia/Adelaide": "(GMT+10:30) Central Time - Adelaide",
  "Australia/Darwin": "(GMT+09:30) Central Time - Darwin",
  "Australia/Brisbane": "(GMT+10:00) Eastern Time - Brisbane",
  "Australia/Hobart": "(GMT+11:00) Eastern Time - Hobart",
  "Australia/Sydney": "(GMT+11:00) Eastern Time - Melbourne, Sydney",
  "Pacific/Auckland": "(GMT+13:00) Auckland",
};

const grouped = groupBy(
  Object.entries(timezones).map(([key, value]) => {
    return {
      value: key,
      label: value,
    };
  }),
  (item) => item.value.split("/")[0],
);

const options: OptionGroup<string>[] = Object.entries(grouped).map(
  ([key, value]) => {
    return {
      label: key,
      options: value,
    };
  },
);
