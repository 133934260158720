import { GenericMenu } from "@/lib/Components/Menu/GenericMenu";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { ReactNode } from "react";
import { ActionItemGroup } from "@/lib/Components/Actions/ActionGroupButtons";

interface ButtonMenuProps {
  label: ReactNode;
  actionGroups: ActionItemGroup[];
}

export function ButtonMenu({ label, actionGroups }: ButtonMenuProps) {
  return (
    <GenericMenu
      button={
        <div className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          {label}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </div>
      }
      actionGroups={actionGroups}
    />
  );
}
