import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type UsersWithWaitlistsListItem = ResultOf<
  typeof usersWithWaitlistQuery
>["allUsers"]["data"][number];

export const usersWithWaitlistQuery = graphql(`
  query UsersWithWaitlistsList(
    $page: Int
    $first: Int!
    $hasPhone: Boolean
    $hasWaitlists: Boolean
    $hrsSinceLastCall: Int
    $search: String
    $waitlistRegions: [Region!]
    $orderBy: [QueryAllUsersOrderByRelationOrderByClause!]
    $orderByWaitlistNotification: SortOrder
  ) {
    allUsers(
      page: $page
      first: $first
      search: $search
      hasPhone: $hasPhone
      hasWaitlists: $hasWaitlists
      hrsSinceLastCall: $hrsSinceLastCall
      waitlistRegions: $waitlistRegions
      orderBy: $orderBy
      orderByWaitlistNotification: $orderByWaitlistNotification
    ) {
      data {
        ...UserFields
        primary_email
        primary_phone
        created_at
        waitlist_count
        latestCallActivity {
          id
          created_at
          properties
          event
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);

export type WaitlistListItem = ResultOf<
  typeof waitlistListQuery
>["waitlists"]["data"][number];

export const waitlistListQuery = graphql(`
  query WaitlistList(
    $first: Int!
    $page: Int
    $relocationId: ID
    $search: String
    $order: [QueryWaitlistsOrderOrderByClause!]
    $where: QueryWaitlistsWhereWhereConditions
    $hasMatches: Boolean
    $hasPhoneNumber: Boolean
  ) {
    waitlists(
      first: $first
      page: $page
      relocation_id: $relocationId
      search: $search
      order: $order
      where: $where
      hasMatches: $hasMatches
      hasPhoneNumber: $hasPhoneNumber
    ) {
      data {
        id
        earliest_departure_date
        latest_departure_date
        created_at
        relocations_count
        relocationLink
        last_sent_at
        next_send_after

        trip {
          id
          city_a_id
          city_b_id
        }
        image {
          ...MediaFields
        }
        deliveryCity {
          id
          name
        }
        departureCity {
          id
          name
        }
        user {
          id
          name
          primary_email
          primary_phone
          latestCallActivity {
            created_at
          }
          contact {
            profilePicture {
              ...MediaFields
            }
          }
        }

        activities {
          id
          event
          description
          causer {
            id
            name
          }
          properties
          created_at
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
