import {
  ContactListQueryVariables,
  QueryContactsOrderByColumn,
  SortOrder,
} from "@/gql/graphql";
import { ContactsSearchParams } from "@/app/Contacts/Routes/contactRoute";

export function getContactListQueryVariables(
  searchParams: ContactsSearchParams,
): ContactListQueryVariables {
  const baseParams: ContactListQueryVariables = {
    first: 20,
    page: 1,
    search: searchParams.contactSearch || undefined,
    type: searchParams.contactType,
  };

  if (searchParams.contactSearch) {
    return baseParams;
  }

  return {
    ...baseParams,
    orderBy: [
      {
        order: SortOrder.Asc,
        column: QueryContactsOrderByColumn.Name,
      },
    ],
  };
}
