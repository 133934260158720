import { SettingFormCard } from "@/app/Settings/Settings/Components/SettingFormCard";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useGqlMutation, useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";
import { ClosedDatesForm } from "@/app/Suppliers/Components/ClosedDatesForm";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { IllustrationCalendar } from "@/assets/Illustrations";
import {
  fromOpenDaysSchema,
  toOpenDaysSchema,
} from "@/app/Suppliers/Utils/mapClosedDays";
import { sortOpenHours } from "@/app/Offices/Utils/sortOpenHours";
import { OpenHoursInput } from "@/app/Offices/Components/OpenHoursInput";
import { Link, redirect } from "@tanstack/react-router";
import { supplierOfficeOpenHoursList } from "@/app/Offices/GraphQL/supplierOfficeOpenHoursList";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";

export function SettingOpenHoursScreen() {
  const { supplier } = useGlobalSupplier();
  const { mutateAsync } = useGqlMutation(updateSupplierMutation);

  if (!supplier) {
    throw redirect({
      to: "/settings",
    });
  }

  const { data } = useGqlQuery(supplierOfficeOpenHoursList, {
    supplierId: supplier.id,
  });

  const customPickupHours = data?.supplierOffices.data.filter((office) => {
    return office.departure_hours !== null;
  });

  const customDropoffHours = data?.supplierOffices.data.filter((office) => {
    return office.delivery_hours !== null;
  });

  return (
    <div className="space-y-12">
      <SettingFormCard
        title="Departure hours"
        description="Specify the hours when the offices are open for pickup"
        onSubmit={(values) => {
          return mutateAsync({
            id: supplier.id,
            input: values,
          });
        }}
        initialValues={{
          departure_hours: sortOpenHours(supplier.departure_hours),
        }}
      >
        {customPickupHours?.length ? (
          <WarningBanner className="col-span-full mb-3">
            <p>{customPickupHours.length} offices have custom pickup hours</p>
            <ul className="mt-3 list-inside list-disc">
              {customPickupHours?.map((office) => {
                return (
                  <li key={office.id}>
                    <Link
                      className="underline"
                      to="/offices/$id/open-hours"
                      params={{
                        id: office.id,
                      }}
                    >
                      {office.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </WarningBanner>
        ) : null}

        <OpenHoursInput prefix="departure_hours" className="col-span-full" />
      </SettingFormCard>

      <SettingFormCard
        title="Delivery hours"
        description="Specify the hours when the offices are open for drop-off"
        onSubmit={(values) => {
          return mutateAsync({
            id: supplier.id,
            input: values,
          });
        }}
        initialValues={{
          delivery_hours: sortOpenHours(supplier.delivery_hours),
        }}
      >
        {customDropoffHours?.length ? (
          <WarningBanner className="col-span-full mb-3">
            <p>{customDropoffHours.length} offices have custom pickup hours</p>
            <ul className="mt-3 list-inside list-disc">
              {customDropoffHours?.map((office) => {
                return (
                  <li key={office.id}>
                    <Link
                      className="underline"
                      to="/offices/$id/open-hours"
                      params={{
                        id: office.id,
                      }}
                    >
                      {office.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </WarningBanner>
        ) : null}

        <OpenHoursInput prefix="delivery_hours" className="col-span-full" />
      </SettingFormCard>

      <SettingFormCard
        title="Closed dates"
        description="Specify the dates when all offices are closed"
        initialValues={{
          closed_dates: fromOpenDaysSchema(supplier.closed_dates),
        }}
        onSubmit={async (values) => {
          const foo = toOpenDaysSchema(values.closed_dates);
          await mutateAsync({
            id: supplier.id,
            input: {
              closed_dates: foo,
            },
          });
        }}
      >
        <ClosedDatesForm
          emptyState={
            <EmptyState
              Icon={IllustrationCalendar}
              header="No closed dates"
              subheader="Add date(s) you want to close all offices on"
            />
          }
        />
      </SettingFormCard>
    </div>
  );
}
