import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { Outlet } from "@tanstack/react-router";

export function ReportScreen() {
  return (
    <FullPageScreen className="space-y-12">
      <h1 className="text-2xl font-bold">Reporting</h1>
      <Outlet />
      <div className="h-40" />
    </FullPageScreen>
  );
}
