import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { CancelBookingStatusInput } from "@/gql/graphql";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { RichTextInput } from "@/lib/Components/Form/Inputs/RichTextInput";
import { BookingCancelledStatusInput } from "@/app/Bookings/Components/BookingCancelStatusInput";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";
import { cancelBookingValidation } from "@/app/Bookings/Validation/bookingChangeStatusSchema";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { cancelBookingMutation } from "@/app/Bookings/GraphQL/cancelBookingMutation";
import { bookingRecordQuery } from "@/app/Bookings/GraphQL/bookingRecordQuery";
import { toFormikValidate } from "zod-formik-adapter";

type BookingCancelDialogProps = {
  bookingId: string;
};
export function BookingCancelDialog({ bookingId }: BookingCancelDialogProps) {
  const { mutateAsync } = useGqlMutation(cancelBookingMutation);
  const { close } = useDialogState();

  const { data } = useSuspenseGqlQuery(bookingRecordQuery, {
    id: bookingId,
  });

  const booking = data.booking;

  return (
    <GenericDialog title="Cancel booking">
      <GenericForm<CancelBookingStatusInput>
        validate={toFormikValidate(cancelBookingValidation)}
        initialValues={{
          customer_note: booking.customer_note,
          release_relocation: false,
          reason: "",
          status: null!,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: booking.id,
            input: values,
          });

          close();
        }}
      >
        <BookingCancelledStatusInput
          name={"status"}
          label={"Status"}
          initialOpen={true}
        />

        <SwitchInput
          name="release_relocation"
          label="Relist relocation"
          tooltip="Should the relocation be relisted?"
        />

        <TextAreaInput
          name={"reason"}
          label={"Internal note"}
          className="col-span-full"
        />

        <RichTextInput
          tooltip="This will appear on the customers booking pdf"
          name="customer_note"
          label="Customer note"
          className="col-span-full"
          optionalLabel
        />

        <DialogSubmitButton text="Cancel booking" />
      </GenericForm>
    </GenericDialog>
  );
}
