import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { RegionInput } from "@/app/Common/Components/RegionInput";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { CityInput } from "@/app/Cities/Cities/Components/CityInput";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { CreateRelocationHighlightInput } from "@/gql/graphql";
import { createRelocationHighlightMutation } from "@/app/Tools/RelocationHighlights/GraphQL/relocationHighlightMutations";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { relocationHighlightValidation } from "@/app/Tools/RelocationHighlights/validation/relocationHighlightValidation";

export function RelocationHighlightCreateDialog() {
  const { close } = useDialogState();
  const { mutateAsync } = useGqlMutation(createRelocationHighlightMutation);

  return (
    <GenericDialog title={"Add highlight"}>
      <GenericForm<CreateRelocationHighlightInput>
        validationSchema={toFormikValidationSchema(
          relocationHighlightValidation,
        )}
        initialValues={{
          region: null,
          primary_text: "",
          secondary_text: "",
          order: null!,
          departureCity: {
            connect: null!,
          },
          deliveryCity: {
            connect: null!,
          },
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            input: values,
          });
          close();
        }}
      >
        <RelocationHighlightForm />

        <DialogSubmitButton text={"Add highlight"} />
      </GenericForm>
    </GenericDialog>
  );
}

export function RelocationHighlightForm() {
  const { values } = useForm<CreateRelocationHighlightInput>();

  return (
    <>
      <TextInput
        name={"primary_text"}
        label={"Primary text"}
        className="col-span-4"
      />
      <TextInput
        name={"secondary_text"}
        label={"Secondary text"}
        className="col-span-4"
        optionalLabel
      />
      <NumberInput name="order" label="Order" optionalLabel />

      <FormDivider />
      <RegionInput name={"region"} label={"Region"} />
      <CityInput
        name="departureCity.connect"
        label="Departure city"
        getQueryVariables={(search) => ({
          search,
          first: 10,
          page: 1,
          region: values.region ?? undefined,
        })}
      />
      <CityInput
        name="deliveryCity.connect"
        label="Delivery city"
        getQueryVariables={(search) => ({
          search,
          first: 10,
          page: 1,
          region: values.region ?? undefined,
        })}
      />
    </>
  );
}
