import { graphql } from "@/gql";

export const createPaymentMutation = graphql(`
  mutation CreatePayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      id
    }
  }
`);

export const updatePaymentMutation = graphql(`
  mutation UpdatePayment($id: ID!, $input: UpdatePaymentInput!) {
    updatePayment(id: $id, input: $input) {
      id
    }
  }
`);

export const markPaymentAsPaidMutation = graphql(`
  mutation MarkPaymentPaid($id: ID!, $input: MarkPaymentPaidInput!) {
    markPaymentAsPaid(id: $id, input: $input) {
      id
    }
  }
`);

export const deletePaymentMutation = graphql(`
  mutation DeletePayment($id: ID!) {
    deletePayment(id: $id) {
      id
    }
  }
`);
