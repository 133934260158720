import { createRoute } from "@tanstack/react-router";
import { UserDetailsScreen } from "@/app/Users/Screens/UserDetailsScreen";
import { UserListScreen } from "@/app/Users/Screens/UserListScreen";
import { UserRecordScreen } from "@/app/Users/Screens/UserRecordScreen";
import { appLayoutRoute } from "@/routes";
import { z } from "zod";
import { getUserListQueryVariables } from "@/app/Users/Utils/getUserListQueryVariables";
import { prefetchInfiniteQuery } from "@/lib/GraphQLCodegen/fetcher";
import { allUserListQuery } from "@/app/Users/GraphQL/allUserListQuery";

const userListSearchParams = z.object({
  userSearch: z.string().optional(),
});

export type UserListSearchParams = z.infer<typeof userListSearchParams>;

export const userListRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  validateSearch: userListSearchParams,
  loaderDeps: ({ search }) => search,
  loader: ({ deps, context: { auth } }) => {
    const variables = getUserListQueryVariables(deps);
    return prefetchInfiniteQuery(allUserListQuery, variables, auth);
  },
  path: "/users",
  component: UserListScreen,
});
export const userRoute = createRoute({
  getParentRoute: () => userListRoute,
  path: "$id",
  component: UserRecordScreen,
});
export const userDetailRoute = createRoute({
  getParentRoute: () => userRoute,
  path: "/",
  component: UserDetailsScreen,
});
