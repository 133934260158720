import { z } from "zod";
import { Region, State } from "@/gql/graphql";

export const cityValidation = z.object({
  name: z.string().min(2, "Name must be at least 2 characters long"),
  region: z
    .nativeEnum(Region)
    .refine((r) => r, { message: "Region is required" }),

  state: z.nativeEnum(State).refine((s) => s, { message: "State is required" }),
});
