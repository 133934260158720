import { HireUnitType, RelocationInclusionType } from "@/gql/graphql";
import { z } from "zod";

const connectBelongsTo = z.object({
  connect: z.string().min(1),
});

export const relocationValidation = z
  .object({
    available_from_date: z.string(),
    available_to_date: z.string(),
    departureOffice: connectBelongsTo,
    deliveryOffice: connectBelongsTo,
    supplier: connectBelongsTo,
    vehicle: connectBelongsTo,
    supplierInsuranceOption: connectBelongsTo,
    count: z.number().min(0),
    hire_unit_rate: z.number().min(1),
    hire_unit_type: z.nativeEnum(HireUnitType),
    hire_units_allowed: z.number().min(1),
    minimum_hire_units: z.number().min(0),
    extra_hire_units_allowed: z.number().min(0),
    extra_hire_unit_rate: z.number().nullable(),
    booking_fee_amount: z.number().min(0),
    holding_deposit_amount: z.number().min(0),
    distance_allowed: z.number().min(0).nullable(),
    inclusions: z.object({
      upsert: z.array(
        z.object({
          type: z.nativeEnum(RelocationInclusionType),
          description: z.string().nullable(),
          value: z.number().nullable(),
        }),
      ),
      delete: z.array(z.string()),
    }),
  })
  .refine((schema) => {
    if (!schema.minimum_hire_units || !schema.hire_units_allowed) {
      return true;
    }

    return schema.minimum_hire_units > schema.hire_units_allowed;
  });
