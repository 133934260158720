import { SVGProps } from "react";

const JucyLogoSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 224 225"
    preserveAspectRatio="xMidYMid meet"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    {...props}
  >
    <path
      fill="#88be22"
      d="M-.5.5c74.674-.664 149.007.003 223 2H.5v221h-1V.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#80bc05"
      d="M222.5 2.5v221H.5V2.5h222Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#fcfdf9"
      d="M58.5 90.5c4.599-.3 9.099.033 13.5 1 1.149 11.149.482 22.149-2 33-1.167 3.833-3.667 6.333-7.5 7.5-6.75.877-13.416.544-20-1-1.317-4.175-1.15-8.342.5-12.5a3.944 3.944 0 0 1 2-1c3.314 1.807 6.814 2.307 10.5 1.5 1.759-1.008 2.926-2.508 3.5-4.5.5-8.012.333-16.012-.5-24Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#fdfefb"
      d="M77.5 90.5h15c-.166 8.34 0 16.673.5 25 1.056 4.714 2.723 5.047 5 1 .5-8.66.666-17.327.5-26h15c.166 8.673 0 17.34-.5 26-4.218 15.916-13.718 20.416-28.5 13.5-4.5-4.166-7-9.333-7.5-15.5a99.61 99.61 0 0 1 .5-24Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#fbfdf8"
      d="M130.5 89.5c9.007-1.166 15.34 2.334 19 10.5a3.943 3.943 0 0 1-1 2l-8.5 3.5c-3.397-3.859-6.73-3.859-10 0-1.163 5.262-.329 10.096 2.5 14.5 3.284.981 5.951.147 8-2.5 3.696.741 7.029 2.074 10 4-5.593 12.299-14.26 15.132-26 8.5-10.959-12.959-10.625-25.626 1-38a38.715 38.715 0 0 0 5-2.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#fdfdfb"
      d="M150.5 90.5a43.123 43.123 0 0 1 13 1c1.32 3.614 2.487 7.28 3.5 11 1.168-3.848 2.335-7.682 3.5-11.5 4.587-.8 9.087-.632 13.5.5a282.538 282.538 0 0 1-9 25 128.507 128.507 0 0 0-.5 16h-14a112.97 112.97 0 0 0-.5-15 144.522 144.522 0 0 1-9.5-27Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#8cb83b"
      d="M-.5.5v-1h224v225H-.5v-1h223V2.5A6217.322 6217.322 0 0 0-.5.5Z"
      style={{
        opacity: 1,
      }}
    />
  </svg>
);
export default JucyLogoSvg;
