import { graphql } from "@/gql";

export const supplierTripByOfficesQuery = graphql(`
  query SupplierTripQuery($officeAId: ID!, $officeBId: ID!) {
    supplierTripByOffices(officeAId: $officeAId, officeBId: $officeBId) {
      id
      __typename
      distance_allowed
      hire_units_allowed
      extra_hire_units_allowed
      minimum_hire_units
      is_ferry_required

      supplier {
        id
        measurement
        hire_unit_type
        currency
      }

      inclusions {
        id
        value
        description
        type
      }

      officeA {
        id
        address {
          id
          lat
          lng
        }
        city {
          id
          name
        }
      }

      officeB {
        id
        address {
          id
          lat
          lng
        }
        city {
          id
          name
        }
      }
    }
  }
`);
