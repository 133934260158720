import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { OrganisationListItem } from "@/app/Organisations/GraphQL/organisationListQuery.graphql";
import { StarIcon } from "@heroicons/react/20/solid";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import {
  removeSalesAgentMutation,
  updateOrganisationMutation,
} from "@/app/Organisations/GraphQL/organisationMutations.graphql";
import { useOrganizationList } from "@clerk/clerk-react";
import { useQueryClient } from "@tanstack/react-query";
import { AssignOrganisationSalesAgentDialog } from "@/app/Organisations/Components/AssignOrganisationSalesAgentDialog";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { DollarSignIcon } from "lucide-react";
import { FlagIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { FeatureFlagDialog } from "@/app/Meta/Components/FeatureFlagDialog";

export function useOrganisationActions(): ActionsHook<OrganisationListItem> {
  const { mutateAsync } = useGqlMutation(updateOrganisationMutation);
  const { open } = useDialog(AssignOrganisationSalesAgentDialog);
  const { mutateAsync: removeSalesAgent } = useGqlMutation(
    removeSalesAgentMutation,
  );
  const { open: openFeatureFlagDialog } = useDialog(FeatureFlagDialog);

  const { setActive } = useOrganizationList();
  const queryClient = useQueryClient();

  return (data) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Verify",
                Icon: StarIcon,
                isHidden: data.is_verified,
                handleAction: async () => {
                  await mutateAsync({
                    id: data.id,
                    input: {
                      is_verified: true,
                    },
                  });
                },
                intent: "primary",
              },
            ],
          },
          {
            actions: [
              {
                label: "Edit feature flags",
                Icon: FlagIcon,
                intent: "neutral",
                handleAction: async () => {
                  openFeatureFlagDialog({ organisationId: data.id });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Assign Sales Agent",
                Icon: DollarSignIcon,
                handleAction: async () => {
                  open({ organisationId: data.id });
                },
              },
              {
                label: "Remove Sales Agent",
                Icon: XMarkIcon,
                isHidden: !data.salesAgent,
                handleAction: async () => {
                  if (!data.salesAgent) return;
                  return removeSalesAgent({
                    orgId: data.id,
                    userId: data.salesAgent.id,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Make current",
                handleAction: async () => {
                  await setActive?.({
                    organization: data.clerk_org_id,
                  });

                  await queryClient.invalidateQueries();
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
