import {
  BookingChangeRequestListQueryVariables,
  BookingChangeRequestStatus,
  QueryBookingChangeRequestsWhereColumn,
  SqlOperator,
} from "@/gql/graphql";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import { BadgeCell } from "@/lib/Components/DataTable/Cells/BadgeCell";
import {
  BookingChangeRequestListItem,
  bookingChangeRequestListQuery,
} from "@/app/Bookings/GraphQL/bookingChangeRequestListQuery";
import { useBookingRecord } from "@/app/Bookings/Hooks/useBookingRecord";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import { useBookingChangeRequestActions } from "@/app/Bookings/Hooks/useBookingChangeRequestActions";

export function BookingChangeRequestScreen() {
  const record = useBookingRecord();
  const getActions = useBookingChangeRequestActions();
  const columns: DataTableColDef<BookingChangeRequestListItem, any, any>[] = [
    {
      id: "id",
      header: "id",
      accessorFn: (row) => row.id,
    },
    {
      id: "status",
      header: "status",
      cell: ({ row }) => (
        <BadgeCell
          badges={[
            {
              label: row.original.status,
              intent: "neutral",
            },
          ]}
        />
      ),
    },
    {
      id: "paid",
      header: "paid",
      accessorFn: (row) => formatCurrency(row.amount, row.currency),
    },
    {
      id: "request",
      header: "request",
      accessorFn: (row) => row.request,
    },
    {
      id: "created_at",
      header: "created",
      accessorFn: (row) => formatDateTimeSinceNow(row.created_at),
    },
  ];

  return (
    <RecordScreenCard title="Change requests">
      <DataTable
        id="booking-change-requests"
        document={bookingChangeRequestListQuery}
        accessor={(data) => data.bookingChangeRequests}
        columns={columns}
        searchable={false}
        getActions={getActions}
        getQueryVariables={({ pagination }) => {
          return {
            page: pagination.pageIndex,
            first: pagination.pageSize,
            where: {
              AND: [
                {
                  column: QueryBookingChangeRequestsWhereColumn.BookingId,
                  value: record.id,
                },
                {
                  column: QueryBookingChangeRequestsWhereColumn.Status,
                  operator: SqlOperator.Neq,
                  value: BookingChangeRequestStatus.AwaitingPayment,
                },
              ],
            },
          } as BookingChangeRequestListQueryVariables;
        }}
      />
    </RecordScreenCard>
  );
}
