import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { ContactEditDialog } from "@/app/Contacts/Components/ContactEditDialog";
import { PencilIcon } from "@heroicons/react/24/outline";
import { ContactRecord } from "@/app/Contacts/GraphQL/contactRecordQuery";

export function useContactActions(): ActionsHook<ContactRecord> {
  const { open } = useDialog(ContactEditDialog);

  return (model) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Edit",
                Icon: PencilIcon,
                handleAction: () => {
                  open({
                    contactId: model.id,
                  });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
