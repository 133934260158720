import { StripePaymentMethod } from "@/gql/graphql";
import { CreditCardIcon } from "@heroicons/react/24/outline";
import { Badge } from "@/components/catalyst/badge";
import { IconMenuActions } from "@/lib/Components/Actions/IconMenuActions";
import { useStripePaymentMethodActions } from "@/app/Stripe/StripePaymentMethods/Hooks/useStripePaymentMethodActions";

export function StripePaymentMethodListItem({
  paymentMethod,
}: {
  paymentMethod: Pick<
    StripePaymentMethod,
    | "id"
    | "card_last_four"
    | "card_brand"
    | "card_exp_month"
    | "card_exp_year"
    | "type"
    | "is_primary"
  >;
}) {
  const getActions = useStripePaymentMethodActions();

  if (paymentMethod.type !== "card") {
    throw new Error("Unsupported payment method type " + paymentMethod.type);
  }

  return (
    <li className="flex justify-between space-x-2 rounded-md border border-gray-100 p-2">
      <div className="flex items-center space-x-2">
        <CreditCardIcon className="h-6 w-6 text-gray-700" />
        <div className="text-xs">
          <p className="space-x-1">
            <span className="text-sm">{paymentMethod.card_brand}</span>
            <span className="">••••</span>
            <span>{paymentMethod.card_last_four}</span>
          </p>
          <p className="text-xs text-gray-500">
            Expires {paymentMethod.card_exp_month} /{" "}
            {paymentMethod.card_exp_year}
          </p>
        </div>
        <div>
          {paymentMethod.is_primary ? (
            <Badge color="blue">primary</Badge>
          ) : null}
        </div>
      </div>
      <div>
        <IconMenuActions actions={getActions(paymentMethod)} />
      </div>
    </li>
  );
}
