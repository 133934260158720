import { z } from "zod";

export const supplierPurchasableExtraValidation = z.object({
  name: z.string().min(1),
  units_purchasable: z.number().int().positive(),
  unit_price: z.number().int().positive(),
  description: z.string().nullish(),
  supplier: z.object({
    connect: z.string().min(1).nullish(),
  }),
});
