import {
  CreateInvoiceInput,
  CreateQuoteInput,
  Currency,
  TaxInclusionType,
  TaxType,
  UpsertPayableLineItemInput,
} from "@/gql/graphql";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { GenericFieldArray } from "@/lib/Components/Form/FieldArray/GenericFieldArray";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { TaxTypeInput } from "@/app/Payments/Components/TaxTypeInput";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";

export function QuoteLineItemsForm() {
  const { values } = useForm<CreateQuoteInput>();

  return (
    <GenericFieldArray<UpsertPayableLineItemInput>
      label="Line items"
      fieldArrayKey="lineItems.upsert"
      newLineItemValues={{
        quantity: 1,
        unit_amount: null!,
        tax_type: TaxType.Gst,
        description: "",
      }}
      footer={<TaxFooter isTaxIncluded={false} currency={values.currency} />}
    >
      {(object, index) => {
        return (
          <>
            <NumberInput
              isEditable={!object.fulfills}
              name={`lineItems.upsert.${index}.quantity`}
              label="Quantity"
              inputProps={{
                pattern: "^d+(?:.d{1,2})?$",
              }}
            />
            <CurrencyInput
              name={`lineItems.upsert.${index}.unit_amount`}
              label="Unit price"
              currency={values.currency}
            />
            {values.tax_inclusion_type !== TaxInclusionType.NoTax ? (
              <TaxTypeInput
                label="Tax"
                name={`lineItems.upsert.${index}.tax_type`}
              />
            ) : null}
            <TextAreaInput
              className="col-span-full"
              name={`lineItems.upsert.${index}.description`}
              label="Description"
            />
          </>
        );
      }}
    </GenericFieldArray>
  );
}

type TaxFooterProps = {
  currency: Currency;
  isTaxIncluded: boolean;
};
function TaxFooter({ currency, isTaxIncluded }: TaxFooterProps) {
  const { values } = useForm<CreateInvoiceInput>();

  const lineItemAmounts = values.lineItems?.upsert?.map((item) => {
    return getTaxAmounts(
      item.unit_amount * item.quantity,
      item.tax_type,
      isTaxIncluded,
    );
  });

  const subtotal = lineItemAmounts?.reduce((acc, curr) => {
    return acc + curr.amountExcTax;
  }, 0);

  const taxAmount = lineItemAmounts?.reduce((acc, curr) => {
    return acc + curr.taxAmount;
  }, 0);

  const total = lineItemAmounts?.reduce((acc, curr) => {
    return acc + curr.amountIncTax;
  }, 0);

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <tfoot>
        <tr>
          <th
            scope="row"
            className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
          >
            Subtotal
          </th>

          <td className="w-36 pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
            {formatCurrency(subtotal, currency)}
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
          >
            GST
          </th>
          <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
            {formatCurrency(taxAmount, currency)}
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
          >
            Total
          </th>

          <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
            {formatCurrency(total, currency)}
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

function getTaxAmounts(
  amount: number,
  taxType: TaxType,
  isTaxIncluded: boolean,
) {
  const taxAmount = calculateTax(amount, taxType, isTaxIncluded);

  const amountIncTax = isTaxIncluded ? amount : amount + taxAmount;
  const amountExcTax = isTaxIncluded ? amount - taxAmount : amount;

  return {
    amountIncTax,
    amountExcTax,
    taxAmount,
  };
}

function calculateTax(
  amount: number,
  taxType: TaxType,
  isTaxIncluded: boolean,
) {
  const rate = TaxType.Gst === taxType ? 0.1 : 0;
  if (isTaxIncluded) {
    return amount - amount / (1 + rate);
  }

  return amount * rate;
}
