import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import { TableId } from "@/app/Common/Utils/tableIds";
import {
  bookingPaymentRequestList,
  BookingPaymentRequestListItem,
} from "@/app/Bookings/GraphQL/bookingPaymentRequestList";
import { useBookingPaymentRequestActions } from "@/app/Bookings/Hooks/useBookingPaymentRequestActions";
import { Badge } from "@/components/catalyst/badge";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { bookingPaymentRequestStatusColorMap } from "@/app/Bookings/Utils/bookingPaymentRequestStatusColorMap";
import { VariablesOf } from "@graphql-typed-document-node/core";
import { ModelLinkCell } from "@/lib/Components/DataTable/Cells/ModelLinkCell";
import { TimeSinceNowCell } from "@/lib/Components/DataTable/TimeSinceNowCell";
import { ReactNode } from "react";
import { useTranslations } from "use-intl";

type BookingPaymentRequestColumnId =
  | "id"
  | "status"
  | "amount"
  | "created_at"
  | "description"
  | "booking";

export function BookingPaymentRequestTable({
  id,
  queryVariables,
  rightButtons,
}: {
  id: TableId;
  queryVariables?: Partial<VariablesOf<typeof bookingPaymentRequestList>>;
  rightButtons?: ReactNode;
}) {
  const t = useTranslations("bookingPaymentRequest");
  const getActions = useBookingPaymentRequestActions();
  const columns: DataTableColDef<
    BookingPaymentRequestListItem,
    any,
    BookingPaymentRequestColumnId
  >[] = [
    {
      id: "booking",
      header: "Booking",
      cell: ({ row }) => (
        <ModelLinkCell
          to="/bookings/$bookingId"
          params={{
            bookingId: row.original.booking.id,
          }}
        >
          {row.original.booking.reference}
        </ModelLinkCell>
      ),
    },
    {
      id: "status",
      header: "Status",
      cell: ({ row }) => (
        <Badge
          color={bookingPaymentRequestStatusColorMap[row.original.status]}
          className="capitalize"
        >
          {t(`status.${row.original.status}`)}
        </Badge>
      ),
    },
    {
      id: "amount",
      header: "Amount",
      accessorFn: (row) => formatCurrency(row.amount, row.currency),
    },
    {
      id: "description",
      header: "Request",
      cell: ({ row }) => (
        <div
          className="text-gray-500"
          dangerouslySetInnerHTML={{
            __html: row.original.description ?? "--",
          }}
        />
      ),
    },
    {
      id: "created_at",
      header: "Created",
      accessorFn: (row) => row.created_at,
      cell: TimeSinceNowCell,
    },
  ];

  return (
    <DataTable
      title="Payment requests"
      rightButtons={rightButtons}
      columns={columns}
      document={bookingPaymentRequestList}
      searchable={false}
      columnToggles={false}
      getQueryVariables={({ pagination }) => {
        return {
          page: pagination.pageIndex,
          first: pagination.pageSize,
          ...queryVariables,
        };
      }}
      accessor={(data) => data.bookingPaymentRequests}
      id={id}
      getActions={getActions}
    />
  );
}
