import { useParams } from "@tanstack/react-router";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { userRecordQuery } from "@/app/Users/GraphQL/userRecordQuery";

export function useUserRecord() {
  const { id } = useParams({
    from: "/_app/users/$id",
  });

  const { data } = useSuspenseGqlQuery(userRecordQuery, {
    id,
  });

  return data.user;
}
