import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { assignSalesAgentMutation } from "@/app/Organisations/GraphQL/organisationMutations.graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { CreateSalesOrganisationAttributionInput } from "@/gql/graphql";
import { AllUserInput } from "@/app/Users/Components/AllUserInput";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";

export function AssignOrganisationSalesAgentDialog({
  organisationId,
}: {
  organisationId: string;
}) {
  const { mutateAsync: assignSalesAgent } = useGqlMutation(
    assignSalesAgentMutation,
  );
  const { close } = useDialogState();

  return (
    <GenericDialog title="Assign Sales Agent">
      <GenericForm<CreateSalesOrganisationAttributionInput>
        initialValues={{
          organisation: {
            connect: organisationId,
          },
          user: {
            connect: null!,
          },
        }}
        onSubmit={async (values) => {
          await assignSalesAgent({
            input: values,
          });

          close();
        }}
      >
        <AllUserInput label="User" name="user.connect" />
        <DialogSubmitButton text="Assign" />
      </GenericForm>
    </GenericDialog>
  );
}
