import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { ReactNode } from "react";

export function InfoBanner({ children }: { children: ReactNode }) {
  return (
    <div className="rounded-md bg-blue-50 p-4">
      <div className="flex">
        <div className="shrink-0">
          <InformationCircleIcon
            aria-hidden="true"
            className="size-5 text-blue-400"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <div className="text-sm text-blue-700">{children}</div>
        </div>
      </div>
    </div>
  );
}
