import "./styles.css";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import { $generateNodesFromDOM } from "@lexical/html";
import {
  $createParagraphNode,
  $getRoot,
  COMMAND_PRIORITY_HIGH,
  KEY_ENTER_COMMAND,
  KEY_TAB_COMMAND,
  LexicalEditor,
  TextNode,
} from "lexical";
import { CodeNode } from "@lexical/code";
import { ReactNode, RefObject, useEffect } from "react";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import ExampleTheme from "./themes/ExampleTheme";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "@/lib/Components/Lexical/plugins/ToolbarPlugin/ToolbarPlugin";
import { mergeRegister } from "@lexical/utils";

interface RichTextEditorProps {
  className?: string;
  children?: ReactNode;
  initialHtml?: string;
  name?: string;
  editorRef?: RefObject<LexicalEditor>;
  debug?: boolean;
}
export function RichTextEditor({
  children,
  className,
  initialHtml = "",
  name = "text",
}: RichTextEditorProps) {
  const editorConfig = {
    namespace: name,
    theme: ExampleTheme,

    editorState: (editor: LexicalEditor) => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(
        initialHtml || '<p class=\\"editor-paragraph\\"></p>',
        "text/html",
      );
      const nodes = $generateNodesFromDOM(editor, dom);
      const root = $getRoot();

      const cleanedNodes = nodes.map((node) => {
        if (node instanceof TextNode) {
          const paragraphNode = $createParagraphNode();
          paragraphNode.append(node);
          return paragraphNode;
        }
        return node;
      });

      root.append(...cleanedNodes);
    },
    // Handling of errors during update
    onError(error: any) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode, // required for list intending as the transformers have a dependency on this node
      AutoLinkNode,
      LinkNode,
    ],
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className={className}>
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className="editor-input prose max-w-full"
                tabIndex={0}
              />
            }
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <RemapKeyPlugin />
          {children}
        </div>
      </div>
    </LexicalComposer>
  );
}

function Placeholder() {
  return <div className="editor-placeholder" />;
}

function RemapKeyPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand<KeyboardEvent>(
        KEY_TAB_COMMAND,
        () => true,
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand<KeyboardEvent>(
        KEY_ENTER_COMMAND,
        (event) => {
          return !!(event !== null && event?.metaKey);
        },
        COMMAND_PRIORITY_HIGH,
      ),
    );
  }, [editor]);

  return null;
}
