import {
  SettingCard,
  SettingFormCard,
} from "@/app/Settings/Settings/Components/SettingFormCard";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateSupplierMutation } from "@/app/Suppliers/GraphQL/supplierMutations";
import { UpdateSupplierInput } from "@/gql/graphql";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";
import { SwitchInput } from "@/lib/Components/Form/Inputs/SwitchInput";
import { CurrencyInput } from "@/lib/Components/Form/Inputs/CurrencyInput";
import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { SupplierInsuranceOptionTable } from "@/app/Suppliers/Components/SupplierInsuranceOptionTable";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierInsuranceOptionCreateDialog } from "@/app/Suppliers/Components/SupplierInsuranceOptionCreateDialog";
import { redirect } from "@tanstack/react-router";
import { TermsAndConditionsSettingsCard } from "@/app/Settings/Settings/Components/TermsAndConditionsSettingsCard";
import { Button } from "@/lib/Components/Button/Button";
import { PlusIcon } from "@heroicons/react/24/outline";

export function SettingsInsuranceScreen() {
  const { supplier } = useGlobalSupplier();
  const { mutateAsync } = useGqlMutation(updateSupplierMutation);
  const { open } = useDialog(SupplierInsuranceOptionCreateDialog);

  if (!supplier) {
    throw redirect({
      to: "/settings",
    });
  }

  return (
    <div className="space-y-12">
      <TermsAndConditionsSettingsCard />

      <SettingFormCard<UpdateSupplierInput>
        title="Bond"
        description="Add details about the bond, whether they can pay with a debit card and any other conditions."
        onSubmit={(values) => {
          return mutateAsync({
            id: supplier.id,
            input: values,
          });
        }}
        initialValues={{
          debit_card_accepted: supplier.debit_card_accepted,
        }}
      >
        <SwitchInput name="debit_card_accepted" label="Debit card accepted" />
      </SettingFormCard>

      <SettingCard
        title="Insurance options"
        description="Let your customers know what options they have with insurance, their bond amount and what their liability is."
      >
        <SupplierInsuranceOptionTable
          rightButtons={
            <Button
              LeadingIcon={PlusIcon}
              onClick={() => {
                open({
                  initialValues: {
                    supplier: {
                      connect: supplier.id,
                    },
                  },
                });
              }}
            >
              Add option
            </Button>
          }
          id="settings-insurance"
          supplier={supplier}
        />
      </SettingCard>

      <SettingFormCard<UpdateSupplierInput>
        title="Age requirements"
        description="Specify the age requirements for renting a vehicle"
        onSubmit={(values) => {
          return mutateAsync({
            id: supplier.id,
            input: values,
          });
        }}
        initialValues={{
          minimum_age: supplier.minimum_age,
          maximum_age: supplier.maximum_age,
          has_age_surcharge: supplier.has_age_surcharge,
          minimum_no_surcharge_age: supplier.minimum_no_surcharge_age,
          maximum_no_surcharge_age: supplier.maximum_no_surcharge_age,
          age_surcharge_amount: supplier.age_surcharge_amount,
        }}
      >
        <AgeSurchargeForm supplier={supplier} />
      </SettingFormCard>
    </div>
  );
}

function AgeSurchargeForm({ supplier }: { supplier: SupplierRecord }) {
  const { values } = useForm<UpdateSupplierInput>();

  return (
    <>
      <NumberInput name="minimum_age" label="Minimum age" />
      <NumberInput name="maximum_age" label="Maximum age" />
      <SwitchInput name="has_age_surcharge" label="Age surcharge" />
      <div className="col-span-3" />

      {values.has_age_surcharge ? (
        <>
          <NumberInput
            name="minimum_no_surcharge_age"
            label="Minimum age (no surcharge)"
          />

          <NumberInput
            name="maximum_no_surcharge_age"
            label="Maximum age (no surcharge)"
          />

          <CurrencyInput
            currency={supplier.currency}
            name={"age_surcharge_amount"}
            label={"Surcharge amount"}
          />
        </>
      ) : null}
    </>
  );
}
