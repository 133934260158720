import {
  SettingCard,
  SettingFormCard,
} from "@/app/Settings/Settings/Components/SettingFormCard";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateContactMutation } from "@/app/Contacts/GraphQL/contactMutations";
import { PhoneInput } from "@/lib/Components/Form/Inputs/PhoneInput";
import { PaymentStatus, UpdateContactInput } from "@/gql/graphql";
import { CheckIcon } from "@heroicons/react/20/solid";
import { cn } from "@/lib/utils";
import { SquareArrowOutUpRightIcon } from "lucide-react";
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
} from "@/components/catalyst/description-list";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { StripePaymentMethodCard } from "@/app/Stripe/StripePaymentMethods/Components/StripePaymentMethodCard";
import { PaymentsTable } from "@/app/Payments/Components/PaymentsTable";
import { BillingCycleDateInput } from "@/app/Contacts/Components/BillingCycleDateInput";

const pricing = {
  tiers: [
    {
      name: "Starter",
      id: "tier-startup",
      href: "#",
      price: { monthly: "$100", annually: "$288" },
      description:
        "For business who need to move vehicles occasionally and with flexibility.",
      features: [
        "Unlimited movements",
        "Access to our customers",
        "24-hour support response time",
      ],
      mostPopular: false,
    },
    {
      name: "Enterprise",
      id: "tier-enterprise",
      href: "#",
      price: { monthly: "$120", annually: "$576" },
      description:
        "For businesses who need vehicles moved with urgency and increased reliability.",
      features: [
        "Unlimited movements",
        "Access to our customers",
        "3-hour support response time",
        "Dedicated account manager",
        "Custom integrations",
        "Optional holding deposit",
      ],
      mostPopular: true,
    },
  ],
};

export function SettingsBillingScreen() {
  const { supplier } = useGlobalSupplier();
  const { mutateAsync } = useGqlMutation(updateContactMutation);
  if (!supplier) {
    return null;
  }

  const billingContact = supplier.billingEntity;

  return (
    <div className="space-y-12">
      <SettingCard title="Billing" description="Your current plan & costs">
        <DescriptionList>
          {supplier.charge_per_move_amount > 0 ? (
            <>
              <DescriptionTerm>Cost per move</DescriptionTerm>
              <DescriptionDetails>
                {formatCurrency(
                  supplier.charge_per_move_amount,
                  supplier.billing_currency,
                )}
              </DescriptionDetails>

              <DescriptionTerm>Holding deposit</DescriptionTerm>
              <DescriptionDetails>
                {supplier.is_holding_deposit_required
                  ? formatCurrency(
                      supplier.holding_deposit_amount,
                      supplier.billing_currency,
                    )
                  : "NO"}
              </DescriptionDetails>
            </>
          ) : (
            <>
              <DescriptionTerm>Cost per move</DescriptionTerm>
              <DescriptionDetails>
                {formatCurrency(
                  supplier.holding_deposit_amount,
                  supplier.billing_currency,
                )}
              </DescriptionDetails>
            </>
          )}
        </DescriptionList>
      </SettingCard>

      {billingContact ? (
        <>
          <SettingFormCard<UpdateContactInput>
            title="Billing contact"
            description="Add the contact details for your billing entity, these details will be used on your invoices"
            initialValues={{
              legal_name: billingContact.legal_name,
              company_number: billingContact.company_number,
              purchase_order_number: billingContact.purchase_order_number,
              billing_cycle_day_of_month:
                billingContact.billing_cycle_day_of_month,
              emails: {
                upsert: billingContact.billingEmail
                  ? [
                      {
                        email: billingContact.billingEmail.email,
                        id: billingContact.billingEmail.id,
                      },
                    ]
                  : [
                      {
                        email: null!,
                        is_billing: true,
                        is_invoice_reminder: true,
                      },
                    ],
              },
              phones: {
                upsert: billingContact.billingPhone
                  ? [
                      {
                        id: billingContact.billingPhone.id,
                        phone: billingContact.billingPhone.phone,
                      },
                    ]
                  : [
                      {
                        phone: null!,
                        is_billing: true,
                      },
                    ],
              },
            }}
            onSubmit={(values) => {
              //Check if an email is present
              if (!values.emails?.upsert?.at(0)?.email) {
                delete values.emails;
              }

              //Check if a phone is present
              if (!values.phones?.upsert?.at(0)?.phone) {
                delete values.phones;
              }

              return mutateAsync({
                id: billingContact.id,
                input: values,
              });
            }}
          >
            <TextInput
              name={"legal_name"}
              label={"Legal name"}
              tooltip="Used on formal documents"
              className="col-span-4"
            />
            <TextInput
              name="emails.upsert.0.email"
              label="Billing email"
              className="col-span-4"
            />
            <PhoneInput
              name="phones.upsert.0.phone"
              label="Billing phone"
              defaultRegion={supplier.region}
              className="col-span-4"
            />
            <TextInput
              name={"company_number"}
              label={"Business registration number"}
              tooltip="Used on formal documents"
              className="col-span-4 row-start-2"
            />
            <TextInput
              name={"purchase_order_number"}
              label={"Default PO #"}
              optionalLabel
              className="col-span-4 row-start-2"
            />
            <BillingCycleDateInput
              label="Invoice cycle date"
              name="billing_cycle_day_of_month"
              className="col-span-4 row-start-2"
            />
          </SettingFormCard>

          <SettingCard
            title="Payment methods"
            description="Add a payment method to automatically pay your invoices when they are issued."
          >
            <StripePaymentMethodCard contact={billingContact} />
          </SettingCard>
        </>
      ) : (
        <SettingCard
          title="Billing contact"
          description="Add your contact details"
        >
          <p>There is no billing contact for this supplier</p>
        </SettingCard>
      )}

      <SettingCard
        title="Upcoming payments"
        description="Payments upcoming from completed relocations. These will be automatically added to your next invoice."
      >
        <PaymentsTable
          id={"settings-upcoming-payments"}
          queryVariables={{
            payeeContactId: billingContact?.id,
            status: [PaymentStatus.Unpaid],
          }}
        />
      </SettingCard>

      <SettingCard
        title="Available plans"
        description="See what plans are currently on offer"
      >
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-2">
          {pricing.tiers.map((tier) => (
            <div
              key={tier.id}
              className={cn(
                tier.mostPopular
                  ? "ring-2 ring-indigo-600"
                  : "ring-1 ring-gray-200",
                "rounded-3xl p-8",
              )}
            >
              <div className="flex items-center justify-between">
                <h2
                  id={tier.id}
                  className={cn(
                    tier.mostPopular ? "text-indigo-600" : "text-gray-900",
                    "text-lg font-semibold leading-8",
                  )}
                >
                  {tier.name}
                </h2>
                {tier.mostPopular ? (
                  <div>
                    <p className="rounded-full bg-indigo-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                      Most popular
                    </p>
                  </div>
                ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">
                  {tier.price["monthly"]}
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-600">
                  /move
                </span>
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={cn(
                  tier.mostPopular
                    ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                    : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                  "mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                )}
              >
                Chat with us{" "}
                <SquareArrowOutUpRightIcon className="ml-2 inline h-4 w-4" />
              </a>
              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </SettingCard>
    </div>
  );
}
