import { Button } from "@/lib/Components/Button/Button";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { billingCreateOffSessionPaymentMethodCheckoutUrlMutation } from "@/app/Stripe/StripePaymentMethods/GraphQL/billingCreateOffSessionPaymentMethodCheckoutUrlMutation";
import { CreditCardIcon } from "@heroicons/react/24/outline";

export function StripeAddPaymentMethodButton({
  contactId,
}: {
  contactId: string;
}) {
  const { mutateAsync } = useGqlMutation(
    billingCreateOffSessionPaymentMethodCheckoutUrlMutation,
  );

  const returnUrl = new URL(
    "/settings/billing",
    import.meta.env.VITE_ADMIN_URL,
  ).toString();

  return (
    <Button
      LeadingIcon={CreditCardIcon}
      onClick={async () => {
        const res = await mutateAsync({
          input: {
            contact_id: contactId,
            cancel_url: returnUrl,
            success_url: returnUrl,
          },
        });

        window.location.href =
          res.billingCreateOffSessionPaymentMethodCheckoutUrl;
      }}
    >
      Add payment method
    </Button>
  );
}
