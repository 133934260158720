import { graphql } from "@/gql";

export const relocationDeliveryOpenHoursQuery = graphql(`
  query RelocationOpenHours(
    $relocationId: ID!
    $departureDate: String!
    $departureTime: String!
  ) {
    relocationDeliveryOpenHours(
      id: $relocationId
      departureDate: $departureDate
      departureTime: $departureTime
    ) {
      date
      ranges {
        end
        start
      }
    }
  }
`);
