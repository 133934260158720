import { ArmchairIcon, GaugeIcon } from "lucide-react";
import { ReactNode } from "react";
import { StarFilledIcon, StopwatchIcon } from "@radix-ui/react-icons";
import { formatDate } from "@/lib/Formatters/formatDate";
import { RelocationInclusion } from "@/gql/graphql";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { cn } from "@/lib/utils";
import { RelocationListItem } from "@/app/Relocations/GraphQL/relocationListQuery";

export function RelocationSummaryCard({
  relocation,
}: {
  relocation: RelocationListItem;
}) {
  return (
    <div className="flex flex-row space-x-3 rounded-md bg-gray-100 p-3">
      <div className="w-40">
        <img src={relocation.vehicle.heroImage} alt={relocation.vehicle.name} />
        <p className="mt-2 text-xs text-gray-500">{relocation.vehicle.name}</p>
      </div>
      <div>
        <h2 className="text-2xl font-bold">{`${relocation.departureOffice.name} to ${relocation.deliveryOffice.name} (${relocation.id})`}</h2>
        <ul className="flex flex-row space-x-3">
          <IconAndText Icon={ArmchairIcon} text={relocation.seatbelts} />
          <IconAndText
            Icon={GaugeIcon}
            text={relocation.distance_allowed ?? "Unlimited"}
          />
          <IconAndText
            Icon={StopwatchIcon}
            text={relocation.hire_units_allowed}
          />
        </ul>
        <p className="mt-1 text-xs text-gray-500">
          {formatDate(relocation.available_from_date)} -{" "}
          {formatDate(relocation.available_to_date)}
        </p>
        <ul>
          {relocation.inclusions.map((inclusion) => (
            <Inclusion
              key={inclusion.id}
              inclusion={inclusion}
              relocation={relocation}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

export function IconAndText({
  Icon,
  text,
  highlight = false,
}: {
  Icon: any;
  text: ReactNode;
  highlight?: boolean;
}) {
  return (
    <li
      className={cn({
        "bg-yellow-200": highlight,
      })}
    >
      <Icon className="mr-2 inline h-4 w-4" />
      <span>{text}</span>
    </li>
  );
}

function Inclusion({
  inclusion,
  relocation,
}: {
  inclusion: Pick<RelocationInclusion, "value" | "description">;
  relocation: RelocationListItem;
}) {
  return (
    <li className="flex flex-row items-center space-x-3">
      <StarFilledIcon className="h-4 w-4 text-yellow-500" />
      {inclusion.value ? (
        <div>{formatCurrency(inclusion.value, relocation.currency)}</div>
      ) : null}
      <div
        dangerouslySetInnerHTML={{
          __html: inclusion.description ?? "--",
        }}
      />
    </li>
  );
}
