import { RecordScreen } from "@/lib/Components/Screens/RecordScreen/RecordScreen";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { usePaidDriverRecord } from "@/app/PaidDrivers/PaidDrivers/Hooks/usePaidDriverRecord";

export function PaidDriverRecordScreen() {
  const paidDriver = usePaidDriverRecord();

  return (
    <RecordScreen
      title={paidDriver.user.name ?? "--"}
      tabs={[
        {
          name: "Details",
          Icon: DocumentTextIcon,
          to: "/paid-drivers/$id",
        },
      ]}
    />
  );
}
