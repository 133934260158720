import { UpdateVehicleInput } from "@/gql/graphql";
import { VehicleRecord } from "@/app/Vehicles/GraphQL/vehicleRecordQuery";

export function mapVehicleModelToInput(
  model: VehicleRecord,
): UpdateVehicleInput {
  return {
    name: model.name,
    code: model.code,
    description: model.description,
    supplier: {
      connect: model.supplier.id,
    },
    model: model.model,
    brand: model.brand,
    seatbelts: model.seatbelts,
    type: model.type,
    fuel: model.fuel,
    transmission: model.transmission,
    extra_hire_unit_rate: model.extra_hire_unit_rate,
    extra_hire_unit_deposit_rate: model.extra_hire_unit_deposit_rate,
    extra_hire_unit_supplier_net_rate: model.extra_hire_unit_supplier_net_rate,
    hire_unit_rate: model.hire_unit_rate,
    hire_unit_type: model.hire_unit_type,

    has_heating: model.has_heating,
    approximate_age: model.approximate_age,
    child_seat_anchor_points: model.child_seat_anchor_points,
    has_kitchen: model.has_kitchen,
    has_shower_and_toilet: model.has_shower_and_toilet,
    is_pet_friendly: model.is_pet_friendly,
    is_self_contained: model.is_self_contained,
    pet_cleaning_fee: model.pet_cleaning_fee,
    sleeps: model.sleeps,
  };
}
