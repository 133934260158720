import { graphql } from "@/gql";

export const reportBookingsQueryGraphql = graphql(`
  query ReportBookings(
    $rangeStartDate: String!
    $rangeEndDate: String!
    $supplierId: ID
    $vehicleId: ID
    $departureOfficeId: ID
    $deliveryOfficeId: ID
  ) {
    reportBookings(
      range_start_date: $rangeStartDate
      range_end_date: $rangeEndDate
      supplier_id: $supplierId
      vehicle_id: $vehicleId
      departure_office_id: $departureOfficeId
      delivery_office_id: $deliveryOfficeId
    ) {
      daily_stats {
        date
        count_confirmed
        count_cancelled
        count_pending
        count_vip
        count_completed
        count_total
      }
      summary_stats {
        this_period {
          count_confirmed
          count_cancelled
          count_pending
          average_extra_days
          average_time_to_confirmation
        }
        previous_period {
          count_confirmed
          count_cancelled
          count_pending
          average_extra_days
          average_time_to_confirmation
        }
      }
    }
  }
`);
