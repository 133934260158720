import { z } from "zod";

export const quoteLineItemValidation = z.object({
  lineItems: z.object({
    upsert: z.array(
      z.object({
        id: z.string().optional(),
        description: z.string().min(3),
      }),
    ),
    delete: z.array(z.string()),
  }),
});
