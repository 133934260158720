import { graphql } from "@/gql";

export const reportRelocationsQueryGraphql = graphql(`
  query ReportRelocations(
    $rangeStartDate: String!
    $rangeEndDate: String!
    $supplierId: ID
    $vehicleId: ID
    $departureOfficeId: ID
    $deliveryOfficeId: ID
  ) {
    reportRelocations(
      range_start_date: $rangeStartDate
      range_end_date: $rangeEndDate
      supplier_id: $supplierId
      vehicle_id: $vehicleId
      departure_office_id: $departureOfficeId
      delivery_office_id: $deliveryOfficeId
    ) {
      daily_stats {
        date
        cancelled_bookings_count
        successful_bookings_count
        counts {
          active_count
          paused_count
          sold_out_count
          archived_count
          draft_count
        }
      }
      summary_stats {
        this_period {
          average_daily_views
          booked_percent
          counts {
            active_count
            paused_count
            sold_out_count
            archived_count
            draft_count
          }
        }
        previous_period {
          average_daily_views
          booked_percent
          counts {
            active_count
            paused_count
            sold_out_count
            archived_count
            draft_count
          }
        }
      }
    }
  }
`);
