import { integrationLinksList } from "@/app/Settings/Settings/GraphQL/integrationLinksQueries";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { CellContext } from "@tanstack/react-table";
import { useIntegrationRecord } from "@/app/Settings/Settings/Hooks/useIntegrationRecord";
import { JucyCodeComboBox } from "@/app/Settings/Integrations/Jucy/Components/JucyCodeComboBox";
import { ModelType } from "@/gql/graphql";
import { SupplierInsuranceOptionListItem } from "@/app/Suppliers/GraphQL/supplierInsuranceOptionListQuery";

export function JucyInsuranceOptionCodeCell({
  row,
}: CellContext<SupplierInsuranceOptionListItem, any>) {
  const jucyIntegration = useIntegrationRecord();

  const integrationLinks = useGqlQuery(integrationLinksList, {
    integration_id: jucyIntegration.id,
  });

  const integrationLink = integrationLinks.data?.integrationLinks.data.find(
    (item) => {
      return (
        item.object_type === ModelType.SupplierInsuranceOption &&
        item.object_id === row.original.id
      );
    },
  );

  return (
    <JucyCodeComboBox
      jucyIntegration={jucyIntegration}
      externalIds={[
        {
          label: "Risk Taker - Condo- $5000 Excess/Bond",
          value: "RTCM",
        },
        {
          label: "Stress Free - Condo",
          value: "STCM",
        },
        {
          label: "Stress Free Plus - Condo",
          value: "SPCM",
        },
      ]}
      rowItem={row.original}
      integrationLink={integrationLink}
      type={ModelType.SupplierInsuranceOption}
    />
  );
}
