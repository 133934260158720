import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { ContactInput } from "@/app/Contacts/Components/ContactInput";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";
import { CurrencyTypeInput } from "@/app/Common/Components/CurrencyTypeInput";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { TaxInclusionInput } from "@/app/Payments/Components/TaxInclusionInput";
import { QuoteLineItemsForm } from "@/app/Quotes/Components/QuoteLineItemsForm";
import {
  CreateQuoteInput,
  Currency,
  TaxInclusionType,
  TaxType,
} from "@/gql/graphql";
import dayjs from "dayjs";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createQuoteMutation } from "@/app/Quotes/GraphQL/quoteMutations";

export function CreateQuoteDialog() {
  const { close } = useDialogState();
  const { mutateAsync } = useGqlMutation(createQuoteMutation);

  return (
    <GenericDialog title="Create Quote">
      <GenericForm<CreateQuoteInput>
        initialValues={{
          currency: Currency.Aud,
          quote_date: dayjs().format("YYYY-MM-DD"),
          expiry_date: dayjs().add(7, "days").format("YYYY-MM-DD"),
          tax_inclusion_type: TaxInclusionType.Exclusive,
          additional_notations: "",
          payee: {
            connect: null,
          },
          lineItems: {
            upsert: [
              {
                description: "",
                quantity: 1,
                tax_type: TaxType.Gst,
                unit_amount: null!,
              },
            ],
          },
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            input: values,
          });

          close();
        }}
      >
        <ContactInput name="payee.connect" label="Payee" />
        <CurrencyTypeInput name="currency" label="Currency" />
        <TaxInclusionInput name="tax_inclusion_type" label="Tax" />

        <FormDivider />
        <DateInput name="quote_date" label="Issue date" />
        <DateInput name="expiry_date" label="Expiry date" />

        <QuoteLineItemsForm />

        <TextAreaInput
          name="additional_notations"
          label="Additional notations"
          className="col-span-full pt-6"
        />

        <DialogSubmitButton text={"Create Quote"} />
      </GenericForm>
    </GenericDialog>
  );
}
