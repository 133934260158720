import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { ActivityLogFeed } from "@/app/ActivityLogs/Components/ActivityLogFeed";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { ModelType } from "@/gql/graphql";

export function UserActivityDialog({ userId }: { userId: string }) {
  const { close } = useDialogState();

  return (
    <GenericDialog title={"User Activity"}>
      <ActivityLogFeed
        modelId={userId}
        modelType={ModelType.User}
        onCommentLogged={close}
      />
    </GenericDialog>
  );
}
