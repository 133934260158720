import { ReactNode } from "react";
import { Button } from "../../Button/Button";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";

export type SubmitButtonInputProps = {
  isLoading?: boolean;
  children: ReactNode;
};

export function SubmitButtonInput({
  isLoading,
  children,
}: SubmitButtonInputProps) {
  const { errorCount, isSubmitting, submitCount, isValid } = useForm();
  const showWarning = submitCount > 1 && !isValid;

  return (
    <div className="col-span-full mt-4">
      <Button
        LeadingIcon={showWarning ? ExclamationTriangleIcon : undefined}
        htmlButtonType="submit"
        width="full"
        isLoading={isLoading || isSubmitting}
        intent={showWarning ? "danger" : "primary"}
      >
        {showWarning ? `${errorCount} error(s)` : children}
      </Button>
    </div>
  );
}
