import { graphql } from "@/gql";

export const createSignedMediaUploadUrl = graphql(`
  mutation CreateSignedStorageUrl($input: SignedStorageUrlInput!) {
    createSignedStorageUrl(input: $input) {
      key
      bucket
      headers
      url
      uuid
    }
  }
`);

export const createMediaMutation = graphql(`
  mutation CreateMedia($input: CreateMediaInput!) {
    createMedia(input: $input) {
      id
    }
  }
`);

export const deleteMediaMutation = graphql(`
  mutation DeleteMedia($id: ID!) {
    deleteMedia(id: $id) {
      id
    }
  }
`);

export const mediaSetNewOrderMutation = graphql(`
  mutation MediaSetNewOrder($ids: [ID!]!) {
    mediaSetNewOrder(ids: $ids)
  }
`);
