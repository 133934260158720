import { useMatch } from "@tanstack/react-router";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { paidDriverRecordQuery } from "@/app/PaidDrivers/PaidDrivers/GraphQL/paidDriverRecordQuery";

export function usePaidDriverRecord() {
  const match = useMatch({
    from: "/_app/paid-drivers/$id",
  });

  const { data } = useSuspenseGqlQuery(paidDriverRecordQuery, {
    id: match.params.id,
  });

  return data.paidDriver;
}
