import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { ActivityLogListItem } from "@/app/ActivityLogs/GraphQL/activityLogListQuery";

export function NotationActivityLogItem({
  log,
  title,
}: {
  log: ActivityLogListItem;
  title: string;
}) {
  return (
    <div className="isolate rounded-md bg-white p-3 ring-1 ring-inset ring-gray-200">
      <div className="flex justify-between gap-x-4">
        <div className="py-0.5 text-xs leading-5 text-gray-500">
          <span className="font-medium text-gray-900">
            {log.causer?.name ?? "Guest"}
          </span>{" "}
          {title}
        </div>

        <div className="flex-none py-0.5 text-xs leading-5 text-gray-500">
          <time dateTime={log.created_at}>
            {formatDateTimeSinceNow(log.created_at)}
          </time>
        </div>
      </div>
      <p className="text-sm leading-6 text-gray-500">{log.description}</p>
    </div>
  );
}
