import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { ConfirmBookingInput } from "@/gql/graphql";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { RichTextInput } from "@/lib/Components/Form/Inputs/RichTextInput";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { vipBookingMutation } from "@/app/Bookings/GraphQL/cancelBookingMutation";
import { bookingRecordQuery } from "@/app/Bookings/GraphQL/bookingRecordQuery";
import { toFormikValidate } from "zod-formik-adapter";
import { vipBookingValidation } from "@/app/Bookings/Validation/bookingChangeStatusSchema";

type BookingConfirmDialogProps = {
  bookingId: string;
};
export function BookingVipDialog({ bookingId }: BookingConfirmDialogProps) {
  const { mutateAsync } = useGqlMutation(vipBookingMutation);
  const { close } = useDialogState();

  const { data } = useSuspenseGqlQuery(bookingRecordQuery, {
    id: bookingId,
  });

  const booking = data.booking;

  return (
    <GenericDialog title="VIP booking">
      <GenericForm<ConfirmBookingInput>
        validate={toFormikValidate(vipBookingValidation)}
        initialValues={{
          customer_note: booking.customer_note,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: booking.id,
            input: values,
          });

          close();
        }}
      >
        <RichTextInput
          tooltip="This will appear on the customers booking pdf"
          name="customer_note"
          label="Customer note"
          className="col-span-full"
          optionalLabel
        />

        <DialogSubmitButton text="Confirm" />
      </GenericForm>
    </GenericDialog>
  );
}
