import {
  SelectInput,
  SelectInputProps,
} from "@/lib/Components/Form/Inputs/SelectInput";

export function BillingCycleDateInput(
  props: Omit<SelectInputProps<any>, "options" | "getViewNode">,
) {
  return (
    <SelectInput
      {...props}
      tooltip="The day of the month that you would like to be billed on, you will be billed for completed relocations in the previous month."
      options={[
        {
          label: "Day of the month",
          options: Array.from({ length: 31 }, (_, i) => ({
            label: `${i + 1}${nthNumber(i + 1)}`,
            value: (i + 1) as unknown as string,
          })),
        },
      ]}
    />
  );
}

const nthNumber = (number: number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
