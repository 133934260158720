import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { TaskListItem } from "@/app/Tasks/GraphQL/taskListQuery";
import { TaskType } from "@/gql/graphql";
import { RelocationCompletedTaskDialog } from "@/app/Tasks/Components/RelocationCompletedTaskDialog";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";

export function useTaskActions(): ActionsHook<TaskListItem> {
  const { open } = useDialog(RelocationCompletedTaskDialog);

  return (task) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                isHidden: task.type !== TaskType.ConfirmRelocationWasCompleted,
                label: "Resolve",
                handleAction: () => {
                  open({ task });
                },
              },
            ],
          },
        ],
      },
    ];
  };
}
