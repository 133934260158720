import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { NumberInput } from "@/lib/Components/Form/Inputs/NumberInput";
import { PhoneInput } from "@/lib/Components/Form/Inputs/PhoneInput";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import {
  useGqlMutation,
  useSuspenseGqlQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { updateBookingMutation } from "@/app/Bookings/GraphQL/bookingMutations";
import { bookingRecordQuery } from "../../GraphQL/bookingRecordQuery";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";

export function BookingEditCustomerDetailsDialog({
  bookingId,
}: {
  bookingId: string;
}) {
  const { data } = useSuspenseGqlQuery(bookingRecordQuery, { id: bookingId });
  const { mutateAsync } = useGqlMutation(updateBookingMutation);
  const { isAdmin } = useTenant();
  const { close } = useDialogState();
  const record = data.booking;
  const showFerryFields = !!record.relocation.is_ferry_required;

  return (
    <GenericDialog title="Edit Customer Details">
      <GenericForm
        initialValues={{
          name: record.name,
          email: record.email,
          phone: record.phone,
          date_of_birth: record.date_of_birth,
          number_of_travellers: record.number_of_travellers,
          address: record.address,
          preferred_ferry_date: record.preferred_ferry_date,
          referrer: record.referrer,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: record.id,
            input: values,
          });

          close();
        }}
      >
        <TextInput name={"name"} label={"Name"} className="col-span-4" />
        <NumberInput
          className="col-span-4"
          name={"number_of_travellers"}
          label={"Number of travellers"}
        />

        <TextInput name={"email"} label={"Email"} className="col-span-4" />
        <PhoneInput
          name={"phone"}
          label={"Phone"}
          className="col-span-4"
          defaultRegion={record.relocation.region}
        />
        <DateInput
          name={"date_of_birth"}
          label={"Date of Birth"}
          calendarProps={{
            captionLayout: "dropdown",
            fromYear: 1900,
            toYear: new Date().getFullYear() - 18,
          }}
          className="col-span-4"
        />

        {showFerryFields ? (
          <DateInput name="preferred_ferry_date" label="Preferred ferry date" />
        ) : null}

        {record.relocation.supplier.collect_address_on_bookings ? (
          <TextAreaInput
            name={"address"}
            label={"Address"}
            className="col-span-full"
          />
        ) : null}

        <DialogSubmitButton text={"Update customer details"} />
      </GenericForm>
    </GenericDialog>
  );
}
