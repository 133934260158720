import { z } from "zod";
import {
  MarketingEventConditionField,
  MarketingEventConditionOperator,
  MarketingEventTrigger,
} from "@/gql/graphql";

export const createMarketingEventValidation = z.object({
  name: z.string().min(2),
  description: z.string().nullish(),
  trigger: z.nativeEnum(MarketingEventTrigger),
  days_after_trigger: z.number().int(),
  mailchimp_customer_journey_id: z.string().min(1),
  mailchimp_customer_journey_step_id: z.string().min(1),
  conditions: z.object({
    upsert: z.array(
      z.object({
        id: z.string().nullish(),
        field: z.nativeEnum(MarketingEventConditionField),
        operator: z.nativeEnum(MarketingEventConditionOperator),
        value: z.any(),
      }),
    ),
    delete: z.array(z.string()).nullish(),
  }),
});
