import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { supplierListQuery } from "@/app/Suppliers/GraphQL/supplierListQuery";
import { Link } from "@tanstack/react-router";
import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { Heading } from "@/components/catalyst/heading";
import { SupplierIcon } from "@/app/Suppliers/Components/SupplierIcon";
import { QuerySuppliersOrderByColumn, SortOrder } from "@/gql/graphql";

export function ImportSelectSupplierScreen() {
  const { data } = useGqlQuery(supplierListQuery, {
    page: 1,
    first: 200,
    orderBy: [
      {
        order: SortOrder.Asc,
        column: QuerySuppliersOrderByColumn.Name,
      },
    ],
  });

  return (
    <FullPageScreen className="space-y-12">
      <Heading>Select Supplier</Heading>

      <ul className="space-y-3">
        {data?.suppliers.data.map((supplier) => (
          <li key={supplier.id}>
            <Link
              to="/import/$supplierId"
              params={{
                supplierId: supplier.id,
              }}
              className="flex items-center space-x-3 rounded-md p-3 transition-colors hover:bg-gray-100"
            >
              <SupplierIcon model={supplier} className="size-10" />
              <span className="flex-grow">{supplier.name}</span>
              <span>&rarr;</span>
            </Link>
          </li>
        ))}
      </ul>
    </FullPageScreen>
  );
}
