import dayjs from "dayjs";

export type ClosedDaysSchema = {
  date: string;
  repeating: boolean;
};
export function fromOpenDaysSchema(
  raw: Record<string, string[]>,
): ClosedDaysSchema[] {
  return Object.entries(raw ?? {})
    .map(([key, value]) => {
      //Find if the key is day of the month, a day of the year or an individual date
      const parts = key.split("-");

      if (value.length > 0) {
        throw new Error("A value with open times was found");
      }

      if (parts.length === 3) {
        //Day of the month
        return {
          date: key,
          repeating: false,
        };
      }

      if (parts.length === 2) {
        //Day of the year
        const [month, day] = parts;

        return {
          date: dayjs()
            .set("month", parseInt(month) - 1)
            .set("date", parseInt(day))
            .format("YYYY-MM-DD"),
          repeating: true,
        };
      }

      console.error("Unhandled single date " + key);

      return null;
    })
    .filter((entry) => entry !== null) as ClosedDaysSchema[];
}

export function toOpenDaysSchema(
  entries: ClosedDaysSchema[],
): Record<string, string[]> {
  const result: Record<string, string[]> = {};

  entries.forEach((entry) => {
    const date = dayjs(entry.date);

    if (entry.repeating) {
      const key = `${date.format("MM")}-${date.format("DD")}`;

      result[key] = [];
    } else {
      result[entry.date] = [];
    }
  });

  return result;
}
