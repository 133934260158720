import { createRoute, Outlet } from "@tanstack/react-router";
import { PaidDriverRecordScreen } from "@/app/PaidDrivers/PaidDrivers/Screens/PaidDriverRecordScreen";
import { PaidDriverListScreen } from "@/app/PaidDrivers/PaidDrivers/Screens/PaidDriverListScreen";
import { PaidDriverDetailScreen } from "@/app/PaidDrivers/PaidDrivers/Screens/PaidDriverDetailScreen";
import { appLayoutRoute } from "@/routes";
import { z } from "zod";
import { prefetchInfiniteQuery } from "@/lib/GraphQLCodegen/fetcher";
import { getPaidDriverQueryVariables } from "@/app/PaidDrivers/PaidDrivers/Utils/getPaidDriverQueryVariables";
import { paidDriverListQuery } from "@/app/PaidDrivers/PaidDrivers/GraphQL/paidDriverListQuery";
import { ResultOf } from "@graphql-typed-document-node/core";

const paidDriverSearchParams = z.object({
  paidDriverSearch: z.string().optional(),
});
export type PaidDriverListSearchParams = z.infer<typeof paidDriverSearchParams>;
export type PaidDriverListItem = ResultOf<
  typeof paidDriverListQuery
>["paidDrivers"]["data"][number];

export const paidDriversListRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  validateSearch: paidDriverSearchParams,
  loaderDeps: ({ search }) => search,
  loader: ({ deps, context: { auth } }) => {
    return prefetchInfiniteQuery(
      paidDriverListQuery,
      getPaidDriverQueryVariables(deps),
      auth,
    );
  },
  path: "/paid-drivers",
  component: Outlet,
});

export const paidDriverTableRoute = createRoute({
  getParentRoute: () => paidDriversListRoute,
  path: "/",
  component: PaidDriverListScreen,
});

export const paidDriverRoute = createRoute({
  getParentRoute: () => paidDriversListRoute,
  path: "$id",
  component: PaidDriverRecordScreen,
});

export const paidDriverDetailRoute = createRoute({
  getParentRoute: () => paidDriverRoute,
  path: "/",
  component: PaidDriverDetailScreen,
});
