import { Switch } from "@headlessui/react";
import {
  GenericField,
  InputProps,
} from "@/lib/Components/Form/Fields/GenericField";
import { useField } from "formik";

type SwitchInputProps = InputProps;
export function SwitchInput({ ...props }: SwitchInputProps) {
  const [field, , helpers] = useField(props.name);
  const { value } = field;
  const enabled = !!value;

  return (
    <GenericField {...props} viewNode={<span>{value ? "YES" : "NO"}</span>}>
      <Switch
        checked={enabled}
        onChange={(val) => {
          helpers.setValue(val);
        }}
        className={`${
          enabled ? "bg-blue-600" : "bg-gray-200"
        } relative mt-1 inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span
          className={`${
            enabled ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
    </GenericField>
  );
}
