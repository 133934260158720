import { createRoute, Outlet, redirect } from "@tanstack/react-router";
import { appLayoutRoute } from "@/routes";
import { SettingsScreen } from "@/app/Settings/Settings/Screens/SettingsScreen";
import { SettingMarketingScreen } from "@/app/Settings/Settings/Screens/SettingMarketingScreen";
import { SettingsGeneralScreen } from "@/app/Settings/Settings/Screens/SettingsGeneralScreen";
import { SettingsTeamScreen } from "@/app/Settings/Settings/Screens/SettingsTeamScreen";
import { SettingOpenHoursScreen } from "@/app/Settings/Settings/Screens/SettingOpenHoursScreen";
import { SettingsInsuranceScreen } from "@/app/Settings/Settings/Screens/SettingsInsuranceScreen";
import { SettingsExtrasScreen } from "@/app/Settings/Settings/Screens/SettingsExtrasScreen";
import { SettingsNotificationScreen } from "@/app/Settings/Settings/Screens/SettingsNotificationScreen";
import { SettingsBillingScreen } from "@/app/Settings/Settings/Screens/SettingsBillingScreen";
import { SettingsSupplierSectionScreen } from "@/app/Settings/Settings/Screens/SettingsSupplierSectionScreen";
import { SettingsVehicleScreen } from "@/app/Settings/Settings/Screens/SettingsVehicleScreen";
import { SettingsOfficeScreen } from "@/app/Settings/Settings/Screens/SettingsOfficeScreen";
import { SettingsReportingScreen } from "@/app/Settings/Settings/Screens/SettingsReportingScreen";
import { SettingsCitiesScreen } from "@/app/Settings/Settings/Screens/SettingsCitiesScreen";
import { SettingsOrganisationsScreen } from "@/app/Settings/Settings/Screens/SettingsOrganisationsScreen";
import { SettingsIntegrationScreen } from "@/app/Settings/Settings/Screens/SettingsIntegrationScreen";
import { getGlobalSupplierId } from "@/app/Suppliers/Utils/getGlobalSupplierId";
import { SettingsCurrencyScreen } from "@/app/Settings/Settings/Screens/SettingsCurrencyScreen";
import { SettingsIntegrationsScreen } from "@/app/Settings/Settings/Screens/SettingsIntegrationsScreen";
import { integrationRecordQuery } from "@/app/Settings/Settings/GraphQL/integrationQueries";
import { fetchQuery } from "@/lib/GraphQLCodegen/fetcher";

export const settingsRoute = createRoute({
  path: "/settings",
  getParentRoute: () => appLayoutRoute,
  component: SettingsScreen,
});

export const settingsGeneralRoute = createRoute({
  path: "/",
  beforeLoad: () => {
    const supplierId = getGlobalSupplierId();
    if (!supplierId) {
      throw redirect({
        to: settingsSuppliersRoute.to,
      });
    }
  },
  getParentRoute: () => settingsRoute,
  component: SettingsGeneralScreen,
});

export const settingsVehicleRoute = createRoute({
  path: "/vehicles",
  getParentRoute: () => settingsRoute,
  component: SettingsVehicleScreen,
});

export const settingsOfficeRoute = createRoute({
  path: "/offices",
  getParentRoute: () => settingsRoute,
  component: SettingsOfficeScreen,
});

export const settingsReportRoute = createRoute({
  path: "/reports",
  getParentRoute: () => settingsRoute,
  component: SettingsReportingScreen,
});

export const settingsMarketingRoute = createRoute({
  path: "/marketing",
  getParentRoute: () => settingsRoute,
  component: SettingMarketingScreen,
});

export const settingsAvailabilityRoute = createRoute({
  path: "/availability",
  beforeLoad: ({ context: {} }) => {
    const supplierId = getGlobalSupplierId();
    if (!supplierId) {
      throw redirect({
        to: settingsSuppliersRoute.to,
      });
    }
  },
  getParentRoute: () => settingsRoute,
  component: SettingOpenHoursScreen,
});

export const settingsInsuranceRoute = createRoute({
  path: "/insurance",
  beforeLoad: ({ context: {} }) => {
    const supplierId = getGlobalSupplierId();
    if (!supplierId) {
      throw redirect({
        to: settingsSuppliersRoute.to,
      });
    }
  },
  getParentRoute: () => settingsRoute,
  component: SettingsInsuranceScreen,
});

export const settingsExtrasRoute = createRoute({
  path: "/extras",
  beforeLoad: ({ context: {} }) => {
    const supplierId = getGlobalSupplierId();
    if (!supplierId) {
      throw redirect({
        to: settingsSuppliersRoute.to,
      });
    }
  },
  getParentRoute: () => settingsRoute,
  component: SettingsExtrasScreen,
});

export const settingNotificationRoute = createRoute({
  path: "/notifications",
  beforeLoad: ({ context: {} }) => {
    const supplierId = getGlobalSupplierId();
    if (!supplierId) {
      throw redirect({
        to: settingsSuppliersRoute.to,
      });
    }
  },
  component: SettingsNotificationScreen,
  getParentRoute: () => settingsRoute,
});

export const settingBillingRoute = createRoute({
  path: "/billing",
  beforeLoad: ({ context: {} }) => {
    const supplierId = getGlobalSupplierId();
    if (!supplierId) {
      throw redirect({
        to: settingsSuppliersRoute.to,
      });
    }
  },
  component: SettingsBillingScreen,
  getParentRoute: () => settingsRoute,
});

export const settingIntegrationsRoute = createRoute({
  path: "/integrations",
  component: Outlet,
  getParentRoute: () => settingsRoute,
});

export const settingIntegrationsListRoute = createRoute({
  path: "/",
  component: SettingsIntegrationsScreen,
  getParentRoute: () => settingIntegrationsRoute,
});

export const settingsIntegrationRotue = createRoute({
  path: "$integrationId",
  component: SettingsIntegrationScreen,
  loader: async ({ params, context }) => {
    const res = await fetchQuery(
      integrationRecordQuery,
      { id: params.integrationId },
      context.auth,
    );

    const supplierId = getGlobalSupplierId();

    if (res.integration.supplier.id !== supplierId) {
      throw redirect({
        to: "/settings/integrations",
      });
    }

    return res;
  },
  getParentRoute: () => settingIntegrationsRoute,
});

export const settingsOrganisationRoute = createRoute({
  path: "/organisation",
  component: SettingsTeamScreen,
  getParentRoute: () => settingsRoute,
});

export const settingsOrganisationMembersRoute = createRoute({
  path: "/organization-members",
  component: SettingsTeamScreen,
  getParentRoute: () => settingsOrganisationRoute,
});

export const settingsCitiesRoute = createRoute({
  path: "/cities",
  component: SettingsCitiesScreen,
  getParentRoute: () => settingsRoute,
});

export const settingsSuppliersRoute = createRoute({
  path: "/suppliers",
  component: SettingsSupplierSectionScreen,
  getParentRoute: () => settingsRoute,
});

export const settingsOrganisationsRoute = createRoute({
  path: "/organisations",
  component: SettingsOrganisationsScreen,
  getParentRoute: () => settingsRoute,
});

export const settingsDefaultRatesRoute = createRoute({
  path: "/default-rates",
  component: SettingsCurrencyScreen,
  getParentRoute: () => settingsRoute,
});
