import { SupplierTable } from "@/app/Suppliers/Components/SupplierTable";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { RegionInput } from "@/app/Common/Components/RegionInput";
import { Button } from "@/lib/Components/Button/Button";
import { FileSpreadsheetIcon } from "lucide-react";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createSupplierReportMutationGraphql } from "@/app/Reports/GraphQL/createSupplierReportMutation.graphql";
import { useDownload } from "@/lib/Utils/useDownload";
import { Region } from "@/gql/graphql";
import { InProgressBanner } from "@/app/Reports/Screens/ReportInvoiceScreen";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";

type ReportSupplierValues = {
  region: Region | null;
};

export function ReportSupplierScreen() {
  const { mutateAsync } = useGqlMutation(createSupplierReportMutationGraphql);
  const download = useDownload();

  return (
    <>
      <InProgressBanner />
      <GenericForm<ReportSupplierValues>
        onSubmit={async (values) => {
          const res = await mutateAsync({
            region: values.region,
          });
          download(res.createReportSupplierDownloadUrl);
        }}
        initialValues={{
          region: null,
        }}
      >
        <FormInner />
      </GenericForm>
    </>
  );
}

function FormInner() {
  const { isSubmitting, values } = useForm();

  return (
    <>
      <RegionInput name={"region"} label="Region" clearable />

      <div className="col-span-9 flex justify-end">
        <Button
          LeadingIcon={FileSpreadsheetIcon}
          htmlButtonType={"submit"}
          isLoading={isSubmitting}
        >
          Export
        </Button>
      </div>

      <div className="col-span-full">
        <SupplierTable
          id={"report-suppliers"}
          queryVariables={{
            region: values?.region ?? undefined,
          }}
        />
      </div>
    </>
  );
}
