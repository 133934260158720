import { RecordCreateScreen } from "@/lib/Components/Screens/RecordScreen/RecordCreateScreen";
import { RecordCreateCard } from "@/lib/Components/SideBarList/Cards/RecordCreateCard";
import { CreateInvoiceInput, Currency, TaxType } from "@/gql/graphql";
import { useState } from "react";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { IllustrationsDocuments } from "@/assets/Illustrations";
import { useNavigate } from "@tanstack/react-router";
import { InvoiceLineItemsForm } from "@/app/Invoices/Forms/InvoiceLineItemsForm";
import { InvoiceDetailsForm } from "@/app/Invoices/Forms/InvoiceDetailsForm";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createInvoiceMutation } from "@/app/Invoices/GraphQL/invoiceMutations";
import { ContactListItem } from "@/app/Contacts/GraphQL/contactListQuery";

export function InvoiceCreateScreen() {
  const [currency, setCurrency] = useState<Currency | null>(null);
  const [contact, setContact] = useState<ContactListItem | null>(null);
  const { mutateAsync } = useGqlMutation(createInvoiceMutation);
  const navigate = useNavigate();

  return (
    <RecordCreateScreen>
      <RecordCreateCard<CreateInvoiceInput>
        title="Create an invoice"
        onSubmit={async (values) => {
          const invoice = await mutateAsync({
            input: values,
          });

          await navigate({
            to: "/invoices/$id",
            params: {
              id: invoice.createInvoice.id,
            },
          });
        }}
        initialValues={{
          payee: null!,
          currency: null!,
          is_tax_included: false,
          purchase_order_number: "",
          lineItems: {
            upsert: [
              {
                quantity: 1,
                unit_amount: null!,
                tax_type: TaxType.TaxExempt,
                description: "",
              },
            ],
          },
        }}
      >
        <InvoiceDetailsForm setCurrency={setCurrency} setPayee={setContact} />

        <div className="col-span-full">
          {contact && currency ? (
            <InvoiceLineItemsForm currency={currency} payee={contact} />
          ) : (
            <EmptyState
              Icon={IllustrationsDocuments}
              header="Add line items"
              subheader="Select a payee and currency you wish to add line items for"
            />
          )}
        </div>
      </RecordCreateCard>
    </RecordCreateScreen>
  );
}
