import { Link, LinkProps } from "@tanstack/react-router";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";

export function ModelLinkCell({
  className,
  children,
  ...props
}: { className?: string; children: ReactNode } & LinkProps) {
  return (
    <span>
      <Link {...props} className={cn("text-blue-500 underline", className)}>
        {children}
      </Link>
    </span>
  );
}
