import { HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";

interface FullPageScreenProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  hero?: ReactNode;
}

export function FullPageScreen({
  children,
  hero,
  className,
  ...props
}: FullPageScreenProps) {
  return (
    <div className="h-full lg:overflow-y-scroll">
      {hero}
      <div
        className={classNames("mx-auto max-w-7xl px-6 lg:py-12", className)}
        {...props}
      >
        {children}
      </div>
    </div>
  );
}
