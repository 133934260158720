import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useRelocationRecord } from "@/app/Relocations/Hooks/useRelocationRecord";
import { RelocationStatus } from "@/gql/graphql";

export function RelocationDraftWarning() {
  const relocation = useRelocationRecord();

  if (relocation.status !== RelocationStatus.Draft) {
    return null;
  }

  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            Attention needed
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
              This relocation is a <strong>draft</strong> and is not publicly
              visible on our marketplace. Your account needs to be verified by
              our team.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
