import { PaymentStatus, StripePaymentStatus } from "@/gql/graphql";
import { BadgeColor } from "@/components/catalyst/badge";

export const paymentStatusColorMap: {
  [key in PaymentStatus]: BadgeColor;
} = {
  [PaymentStatus.Paid]: "green",
  [PaymentStatus.PartiallyRefunded]: "green",
  [PaymentStatus.Pending]: "yellow",
  [PaymentStatus.Refunded]: "red",
  [PaymentStatus.Unpaid]: "zinc",
  [PaymentStatus.Voided]: "purple",
};

export const stripeMapStatusColorMap: {
  [key in StripePaymentStatus]: string;
} = {
  [StripePaymentStatus.Canceled]: "bg-red-50 text-red-700 ring-red-600/20",
  [StripePaymentStatus.Processing]: "bg-red-50 text-red-700 ring-red-600/20",
  [StripePaymentStatus.RequiresAction]:
    "bg-red-50 text-red-700 ring-red-600/20",
  [StripePaymentStatus.RequiresConfirmation]:
    "bg-red-50 text-red-700 ring-red-600/20",
  [StripePaymentStatus.RequiresPaymentMethod]:
    "bg-red-50 text-red-700 ring-red-600/20",
  [StripePaymentStatus.RequiresCapture]:
    "bg-yellow-50 text-yellow-700 ring-yellow-600/20",
  [StripePaymentStatus.Succeeded]:
    "bg-green-50 text-green-700 ring-green-600/20",
};
