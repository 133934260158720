import { CellContext } from "@tanstack/react-table";
import { cn } from "@/lib/utils";
import { Spinner } from "@/lib/Components/Layout/Loaders/Spinner";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateRelocationStatuses } from "@/app/Relocations/GraphQL/relocationMutations";
import { relocationStatusMap } from "@/app/Dashboard/Utils/relocationStatusMap";
import { RelocationListItem } from "@/app/Relocations/GraphQL/relocationListQuery";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { QueryClient } from "@/lib/ReactQuery/QueryClient";
import { RelocationStatus } from "@/gql/graphql";

export function RelocationStatusCell({
  cell,
}: CellContext<RelocationListItem, any>) {
  const { mutateAsync, isPending } = useGqlMutation(updateRelocationStatuses, {
    onSuccess: () => {
      return QueryClient.invalidateQueries();
    },
  });

  const relocation = cell.row.original;
  const { Icon, label, color, transitions } =
    relocationStatusMap[relocation.status];

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            disabled={relocation.status === RelocationStatus.Archived}
          >
            {isPending ? (
              <Spinner className="h-4 w-4" />
            ) : (
              <Icon className={cn(`h-4 w-4`, color)} />
            )}
            <div className="ml-2">{label}</div>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          {transitions.map((status) => {
            const { Icon, label, color } = relocationStatusMap[status];
            return (
              <DropdownMenuItem
                key={status}
                onClick={async () => {
                  await mutateAsync({
                    ids: [relocation.id],
                    status,
                  });
                }}
              >
                <div className="flex items-center space-x-2">
                  <Icon className={cn(`h-4 w-4`, color)} />
                  <div>{label}</div>
                </div>
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
