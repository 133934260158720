import { Spinner } from "../../Layout/Loaders/Spinner";
import { DialogContext, DialogStateContext } from "./DialogContext";
import { FC, ReactNode, Suspense, useCallback, useState } from "react";

export type DialogContextProps = {
  dialogs: Record<string, Dialog>;
  createDialog: (uuid: string, data: DialogInput) => void;
  removeDialog: (uuid: string) => void;
  depth: number;
};

export type Dialog<TParams = any, TRecord = any> = {
  Component: FC<TParams>;
  props: TParams;
  isOpen: boolean;
  close: () => void;
  uuid: string;
  resolve?: (value: TRecord) => void;
  reject?: (value: any) => void;
};

export type DialogInput = Omit<Dialog, "close" | "uuid">;

export type DialogState<TRecord> = Omit<
  Dialog<never, TRecord>,
  "props" | "Component"
> & {
  depth: number;
  remove: () => void;
};

export function DialogProvider({
  depth = 0,
  children,
}: {
  depth?: number;
  children: ReactNode;
}) {
  const [dialogs, setDialogs] = useState<Record<string, Dialog>>({});

  const closeDialog = useCallback((dialogId: string) => {
    setDialogs((oldDialogs) => {
      //If the dialog has unmounted, don't try to close it
      if (!oldDialogs[dialogId]) {
        return oldDialogs;
      }

      oldDialogs[dialogId].isOpen = false;

      return { ...oldDialogs };
    });
  }, []);

  const value = {
    depth,
    dialogs,
    createDialog: (dialogId: string, data: DialogInput) => {
      setDialogs((oldDialogs) => {
        oldDialogs[dialogId] = {
          uuid: dialogId,
          close: () => closeDialog(dialogId),
          ...data,
        };

        return { ...oldDialogs };
      });
    },
    removeDialog: (dialogId: string) => {
      setDialogs((oldDialogs) => {
        const copy = { ...oldDialogs };
        delete copy[dialogId];
        return copy;
      });
    },
  };

  return (
    <DialogContext value={value}>
      {children}
      {Object.entries(dialogs).map(([uuid, { Component, props, ...rest }]) => {
        return (
          <DialogStateContext
            value={{ ...rest, depth, remove: () => value.removeDialog(uuid) }}
            key={uuid}
          >
            <Suspense
              fallback={
                <div className="fixed inset-0 z-10 flex flex-col items-center justify-center space-y-4 bg-black bg-opacity-25 opacity-100">
                  <Spinner />
                </div>
              }
            >
              <Component {...props} />
            </Suspense>
          </DialogStateContext>
        );
      })}
    </DialogContext>
  );
}
