import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type VehicleRecord = ResultOf<typeof vehicleRecordQuery>["vehicle"];
export const vehicleRecordQuery = graphql(`
  query VehicleRecord($id: ID!) {
    vehicle(id: $id) {
      ...VehicleListFields

      id
      name
      code
      type
      transmission
      heroImage
      hire_unit_type
      hire_unit_rate
      extra_hire_unit_deposit_rate
      extra_hire_unit_rate
      extra_hire_unit_supplier_net_rate
      seatbelts
      fuel
      supplier {
        id
        name
        currency
      }
      created_at
      updated_at
      archived_at

      id
      name
      description
      transmission
      type
      brand
      model
      fuel
      heroImage
      seatbelts
      code
      heroImage

      extra_hire_unit_rate
      extra_hire_unit_deposit_rate
      extra_hire_unit_supplier_net_rate

      approximate_age

      child_seat_anchor_points

      sleeps
      has_heating
      has_kitchen
      has_shower_and_toilet
      is_self_contained

      is_pet_friendly
      pet_cleaning_fee

      hire_unit_type
      hire_unit_rate

      created_at
      updated_at

      supplier {
        ...SupplierFields
      }

      images {
        ...MediaFields
      }
    }
  }
`);

export const vehicleRecordFragment = graphql(`
  fragment VehicleFields on Vehicle {
    id
    name
    code
    type
    transmission
    heroImage
    hire_unit_type
    hire_unit_rate
    extra_hire_unit_deposit_rate
    extra_hire_unit_rate
    extra_hire_unit_supplier_net_rate
    seatbelts
    fuel
    supplier {
      id
      name
      currency
    }
    created_at
    updated_at

    id
    name
    description
    transmission
    type
    brand
    model
    fuel
    heroImage
    seatbelts
    code
    heroImage

    extra_hire_unit_rate
    extra_hire_unit_deposit_rate
    extra_hire_unit_supplier_net_rate

    approximate_age

    child_seat_anchor_points

    sleeps
    has_heating
    has_kitchen
    has_shower_and_toilet
    is_self_contained

    is_pet_friendly
    pet_cleaning_fee

    hire_unit_type
    hire_unit_rate

    created_at

    supplier {
      ...SupplierFields
    }

    images {
      ...MediaFields
    }
  }
`);
