import { Form, FormikValues } from "formik";
import { ReactNode, Ref } from "react";
import { Formation, FormationProps } from "../Providers/Formation";
import { GenericErrorBoundary } from "@/lib/Components/Layout/ErrorBoundary/ErrorBoundary";
import { cn } from "@/lib/utils";

export type GenericFormProps<T> = FormationProps<T> & {
  children: ReactNode;
  className?: string;
  containerRef?: Ref<HTMLFormElement>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLFormElement>) => void;
};

export function GenericForm<T extends FormikValues>({
  className,
  children,
  containerRef,
  onKeyDown,
  ...props
}: GenericFormProps<T>) {
  return (
    <GenericErrorBoundary>
      <Formation<T> {...props}>
        {({ handleSubmit }) => {
          return (
            <Form
              className={cn(
                "grid grid-cols-3 gap-x-6 lg:grid-cols-12",
                className,
              )}
              ref={containerRef}
              onKeyDown={(e) => {
                if (onKeyDown) {
                  onKeyDown(e);
                } else {
                  if (!(e.key === "Enter" && e.metaKey)) return;
                  //Submit the form
                  handleSubmit();
                }
              }}
            >
              {children}
            </Form>
          );
        }}
      </Formation>
    </GenericErrorBoundary>
  );
}
