import { RelocationStatus } from "@/gql/graphql";
import { BadgeColor } from "@/components/catalyst/badge";

export const relocationStatusColorMap: {
  [key in RelocationStatus]: BadgeColor;
} = {
  [RelocationStatus.Archived]: "purple",
  [RelocationStatus.Draft]: "zinc",
  [RelocationStatus.Ready]: "green",
  [RelocationStatus.SoldOut]: "yellow",
  [RelocationStatus.Paused]: "red",
  [RelocationStatus.Expired]: "red",
};
