import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import {
  BookingStatus,
  Task,
  UpdateRelocationCompletedTaskInput,
} from "@/gql/graphql";
import { useGqlMutation, useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { bookingRecordQuery } from "@/app/Bookings/GraphQL/bookingRecordQuery";
import { Skeleton } from "@/components/ui/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ArrowPathIcon, CheckIcon } from "@heroicons/react/20/solid";
import { Link } from "@tanstack/react-router";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { IllustrationsDocuments } from "@/assets/Illustrations";
import { RelocationUnfulfilledForm } from "@/app/Tasks/RelocationUnfulfilledForm";
import { BookingCard } from "@/app/Bookings/Components/BookingCard";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { formatCurrency } from "@/lib/Formatters/formatCurrency";
import { ResultOf } from "@graphql-typed-document-node/core";
import { Intent } from "@/lib/Components/Common/Intent";
import { FC } from "react";
import { Badge } from "@/lib/Components/Badges/Badge";
import { CircleDollarSignIcon } from "lucide-react";
import { Strong, Text } from "@/components/catalyst/text";
import { updateRelocationCompletedTask } from "@/app/Tasks/GraphQL/taskMutations";
import { toFormikValidate } from "zod-formik-adapter";
import { relocationFulfillmentValidation } from "@/app/Tasks/Validation/relocationFulfillmentValidation";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";

type Booking = ResultOf<typeof bookingRecordQuery>["booking"];

export function RelocationCompletedTaskDialog({
  task,
  tab,
}: {
  task: Pick<Task, "id" | "related_to_id">;
  tab?: "completed" | "not_completed";
}) {
  const { close } = useDialogState();
  const { data } = useGqlQuery(bookingRecordQuery, {
    id: task.related_to_id,
  });
  const { mutateAsync } = useGqlMutation(updateRelocationCompletedTask);

  return (
    <GenericDialog title="Complete relocation" className="space-y-6">
      {data ? (
        <>
          <div className="rounded-md bg-gray-50 p-4">
            <BookingCard booking={data.booking} />
          </div>
          <Tabs defaultValue={tab ?? "undefined"} className="w-full">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="completed">
                <CheckIcon className="mr-2 h-4 w-4 text-green-500" />
                Completed
              </TabsTrigger>
              <TabsTrigger value="not_completed">
                <XMarkIcon className="mr-2 h-4 w-4 text-red-500" />
                Not Completed
              </TabsTrigger>
            </TabsList>

            <TabsContent value="undefined">
              <EmptyState
                header="Was this relocation completed?"
                subheader="Let us know if a relocation was completed or not. This will help us make sure you are billed correctly and that the customer receives their deposit back."
                Icon={IllustrationsDocuments}
              />
            </TabsContent>
            <TabsContent value="completed">
              <FulfilledTransactionTable
                booking={data.booking}
                completed={true}
              />
              <Text className="mx-auto max-w-2xl p-6 text-center">
                By completing this booking, you are confirming that the
                relocation was <Strong>completed</Strong>. You will be billed
                for this booking in the next billing cycle and the customer will
                have their deposit refunded.
              </Text>
              <GenericForm<UpdateRelocationCompletedTaskInput>
                initialValues={{
                  was_relocation_completed: true,
                }}
                onSubmit={async (values) => {
                  await mutateAsync({
                    id: task.id,
                    input: values,
                  });
                  close();
                }}
              >
                <DialogSubmitButton text={"Complete booking"} />
              </GenericForm>
            </TabsContent>
            <TabsContent value="not_completed">
              <FulfilledTransactionTable
                booking={data.booking}
                completed={false}
              />
              <Text className="mx-auto max-w-2xl p-6 text-center">
                By cancelling this booking, you are confirming that the
                relocation was <Strong>not completed</Strong>. You will not be
                billed for this booking and the customer will forfeit their
                deposit
              </Text>
              <GenericForm<UpdateRelocationCompletedTaskInput>
                validate={toFormikValidate(relocationFulfillmentValidation)}
                initialValues={{
                  was_relocation_completed: false,
                  cancellation_status: BookingStatus.CustomerCancelledNoShow,
                  description: "",
                }}
                onSubmit={async (values) => {
                  await mutateAsync({
                    id: task.id,
                    input: values,
                  });
                  close();
                }}
              >
                <RelocationUnfulfilledForm />
                <DialogSubmitButton text={"Cancel booking"} />
              </GenericForm>
            </TabsContent>
          </Tabs>
        </>
      ) : (
        <BookingSkeleton />
      )}
    </GenericDialog>
  );
}

function BookingSkeleton() {
  return (
    <div className="space-y-2">
      <Skeleton className="h-4 w-[250px]" />
      <Skeleton className="h-4 w-[200px]" />
    </div>
  );
}

function FulfilledTransactionTable({
  completed,
  booking,
}: {
  completed: boolean;
  booking: Booking;
}) {
  const firstPayment = booking.customerPayments[0];

  const relocation = booking.relocation;
  const supplier = relocation.supplier;
  const contact = supplier.billingEntity!;

  const fulfilled: Transaction[] = [];

  if (completed) {
    fulfilled.push({
      amount: formatCurrency(
        relocation.charge_per_move_amount,
        booking.currency,
      ),
      status: "Added to your invoice",
      intent: "neutral",
      client: contact?.name ?? "--",
      description: "Relocation charge",
      icon: CircleDollarSignIcon,
    });
  }

  if (booking.imoova_refund_amount > 0) {
    fulfilled.push({
      paymentId: firstPayment?.id,
      amount: formatCurrency(booking.imoova_refund_amount, booking.currency),
      status: completed ? "Refund to customer" : "Withheld by imoova",
      intent: completed ? "success" : "danger",
      client: booking.name,
      description: "Deposit refund",
      icon: ArrowPathIcon,
    });
  }

  return <Table transactions={fulfilled} />;
}

type Transaction = {
  amount: string;
  status: string;
  intent: Intent;
  client: string;
  description: string;
  icon: FC<any>;
  paymentId?: string;
};

function Table({ transactions }: { transactions: Transaction[] }) {
  return (
    <div>
      <div className="mt-6 overflow-hidden border-t border-gray-100">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <table className="w-full text-left">
              <thead className="sr-only">
                <tr>
                  <th>Amount</th>
                  <th className="hidden sm:table-cell">Client</th>
                  <th>More details</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction, idx) => (
                  <tr key={idx}>
                    <td className="relative py-5 pr-6">
                      <div className="flex gap-x-6">
                        <transaction.icon
                          className="hidden h-6 w-5 flex-none text-gray-400 sm:block"
                          aria-hidden="true"
                        />
                        <div className="flex-auto">
                          <div className="flex items-start gap-x-3">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              {transaction.amount}
                            </div>
                            <Badge
                              label={transaction.status}
                              intent={transaction.intent}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                      <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                    </td>
                    <td className="hidden py-5 pr-6 sm:table-cell">
                      <div className="text-sm leading-6 text-gray-900">
                        {transaction.client}
                      </div>
                      <div className="mt-1 text-xs leading-5 text-gray-500">
                        {transaction.description}
                      </div>
                    </td>
                    <td className="py-5 text-right">
                      {transaction.paymentId ? (
                        <>
                          <div className="flex justify-end">
                            <Link
                              to={"/payments/$id"}
                              params={{
                                id: transaction.paymentId,
                              }}
                              search={{}}
                              className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500"
                            >
                              View
                              <span className="hidden sm:inline">
                                {" "}
                                transaction
                              </span>
                              <span className="sr-only">
                                , payment #{transaction.paymentId},{" "}
                                {transaction.client}
                              </span>
                            </Link>
                          </div>
                          <div className="mt-1 text-xs leading-5 text-gray-500">
                            Payment{" "}
                            <span className="text-gray-900">
                              #{transaction.paymentId}
                            </span>
                          </div>
                        </>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
