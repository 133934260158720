import { RecordScreenFormCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenFormCard";
import { CityForm } from "@/app/Cities/Cities/Forms/CityForm";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { toFormikValidate } from "zod-formik-adapter";
import { cityValidation } from "@/app/Cities/Cities/Validation/cityValidation";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateCityMutation } from "@/app/Cities/Cities/GraphQL/cityMutations";
import { useCityRecord } from "@/app/Cities/Cities/Hooks/useCityRecord";

export function CityDetailScreen() {
  const record = useCityRecord();
  const { mutateAsync } = useGqlMutation(updateCityMutation);
  const { isAdmin } = useTenant();

  return (
    <>
      <RecordScreenFormCard
        isEditable={isAdmin}
        onSubmit={async (values) => {
          return mutateAsync({
            id: record.id,
            input: values,
          });
        }}
        title="City"
        validate={toFormikValidate(cityValidation)}
        initialValues={{
          name: record.name,
          region: record.region,
          state: record.state!,
        }}
      >
        <CityForm />
      </RecordScreenFormCard>
    </>
  );
}
