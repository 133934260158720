import { createRoute, Outlet } from "@tanstack/react-router";
import { RelocationRecordScreen } from "@/app/Relocations/Screens/RelocationRecordScreen";
import { RelocationStatus } from "@/gql/graphql";
import { RelocationDetailsScreen } from "@/app/Relocations/Screens/RelocationDetailsScreen";
import { RelocationWaitlistScreen } from "@/app/Relocations/Screens/RelocationWaitlistScreen";
import { RelocationActivityScreen } from "@/app/Relocations/Screens/RelocationActivityScreen";
import { RelocationListScreen } from "@/app/Relocations/Screens/RelocationListScreen";
import { z } from "zod";
import { RelocationLinesScreen } from "@/app/Relocations/Screens/RelocationLinesScreen";
import { RelocationCreateScreen } from "@/app/Relocations/Screens/RelocationCreateScreen";
import { appLayoutRoute } from "@/routes";
import {
  fetchQuery,
  prefetchInfiniteQuery,
} from "@/lib/GraphQLCodegen/fetcher";
import { relocationRecordQuery } from "@/app/Relocations/GraphQL/relocationRecordQuery";
import { RelocationBookingScreen } from "@/app/Relocations/Screens/RelocationBookingScreen";
import { RelocationTableScreen } from "@/app/Relocations/Screens/RelocationTableScreen";
import { getRelocationListQueryVariables } from "@/app/Relocations/Utils/getRelocationListQueryVariables";
import { relocationListQuery } from "@/app/Relocations/GraphQL/relocationListQuery";

const relocationsSearchParams = z.object({
  relocationStatus: z
    .array(z.nativeEnum(RelocationStatus))
    .optional()
    .catch(undefined),
  relocationSearch: z.string().optional(),
});

export type RelocationListSearchParams = z.infer<
  typeof relocationsSearchParams
>;

export const relocationsRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/relocations",
  component: Outlet,
  validateSearch: relocationsSearchParams,
});

export const relocationTableRoute = createRoute({
  path: "/",
  getParentRoute: () => relocationsRoute,
  component: RelocationTableScreen,
});

export const relocationRecordLayout = createRoute({
  getParentRoute: () => relocationsRoute,
  id: "_relocationRecordLayout",
  component: RelocationListScreen,
  loaderDeps: ({ search }) => search,
  loader: ({ deps, context: { auth } }) => {
    const variables = getRelocationListQueryVariables(deps);
    return prefetchInfiniteQuery(relocationListQuery, variables, auth);
  },
});

export const relocationCreateRoute = createRoute({
  getParentRoute: () => relocationRecordLayout,
  path: "/create",
  component: RelocationCreateScreen,
});

export const relocationRoute = createRoute({
  getParentRoute: () => relocationRecordLayout,
  path: "$relocationId",
  component: RelocationRecordScreen,
  loader: ({ params: { relocationId }, context: { auth } }) => {
    return fetchQuery(relocationRecordQuery, { id: relocationId }, auth);
  },
});

export const relocationDetailsRoute = createRoute({
  getParentRoute: () => relocationRoute,
  path: "/",
  component: RelocationDetailsScreen,
});

export const relocationLinesRoute = createRoute({
  getParentRoute: () => relocationRoute,
  path: "/lines",
  component: RelocationLinesScreen,
});

export const relocationWaitlistRoute = createRoute({
  getParentRoute: () => relocationRoute,
  path: "/waitlists",
  component: RelocationWaitlistScreen,
});

export const relocationActivityRoute = createRoute({
  getParentRoute: () => relocationRoute,
  path: "/activity",
  component: RelocationActivityScreen,
});

export const relocationBookingsRoute = createRoute({
  getParentRoute: () => relocationRoute,
  path: "/bookings",
  component: RelocationBookingScreen,
});
