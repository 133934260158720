import {
  SidebarList,
  SidebarListItemProps,
} from "@/lib/Components/SideBarList/SideBarList";
import { useState } from "react";
import { useInfiniteGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import {
  SupplierOfficeListItem,
  supplierOfficeListQuery,
} from "@/app/Offices/GraphQL/supplierOfficeListQuery";
import { keepPreviousData } from "@tanstack/react-query";
import { getOfficeListQueryVariables } from "@/app/Offices/Utils/getOfficeListQueryVariables";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { useTranslations } from "use-intl";
import { Badge } from "@/components/catalyst/badge";
import { useSearch } from "@tanstack/react-router";

export function OfficeListScreen() {
  const searchParams = useSearch({
    from: "/_app/offices",
  });
  const [search, setSearch] = useState(searchParams.officeSearch ?? "");

  const { data, fetchNextPage, isFetchingNextPage, isFetching } =
    useInfiniteGqlQuery(
      supplierOfficeListQuery,
      getOfficeListQueryVariables({ ...searchParams, officeSearch: search }),
      {
        placeholderData: keepPreviousData,
        initialPageParam: {
          page: 1,
        },
        getNextPageParam: (lastPage) => {
          return lastPage.supplierOffices.paginatorInfo.hasMorePages
            ? {
                page: lastPage.supplierOffices.paginatorInfo.currentPage + 1,
              }
            : undefined;
        },
      },
    );

  const items = data?.pages.flatMap((page) => page.supplierOffices.data) ?? [];
  const totalCount = data?.pages[0].supplierOffices.paginatorInfo.count ?? 0;

  return (
    <SidebarList<SupplierOfficeListItem>
      items={items}
      totalCount={totalCount}
      linkProps={(item) => ({
        to: "/offices/$id",
        params: {
          id: item.id,
        },
      })}
      search={search}
      onSearchChange={(newSearch) => {
        setSearch(newSearch);
      }}
      onClearFilters={() => {
        setSearch("");
      }}
      listRoute="/offices"
      createRoute="/offices/create"
      isFetchingNextPage={isFetchingNextPage}
      isFetching={isFetching}
      fetchNextPage={fetchNextPage}
      itemNode={(item) => <OfficeItem model={item} />}
    />
  );
}

function OfficeItem({ model }: SidebarListItemProps<SupplierOfficeListItem>) {
  const t = useTranslations("city");

  return (
    <div className="relative">
      <p className="truncate text-sm text-gray-900">{model.name}</p>
      <p className="truncate text-xs text-gray-500">
        {t(`state.${model.city.state!}`)}
      </p>
      <p>
        <time dateTime={model.created_at} className="text-xs text-gray-500">
          {formatDateTimeSinceNow(model.created_at)}
        </time>
      </p>

      {model.archived_at ? (
        <div className="absolute right-0 top-0">
          <Badge color="purple">archived</Badge>
        </div>
      ) : null}
    </div>
  );
}
