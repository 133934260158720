import { BookingStatus } from "@/gql/graphql";

export function getBookingCancelledStatuses() {
  return [
    BookingStatus.CustomerCancelledNoShow,
    BookingStatus.CustomerCancelledConfirmationTimeliness,
    BookingStatus.CustomerCancelledRebooked,
    BookingStatus.CustomerCancelled,
    BookingStatus.SupplierCancelledNoFerry,
    BookingStatus.SupplierCancelledMechanicalFailure,
    BookingStatus.SupplierCancelledNotAvailable,
    BookingStatus.SupplierCancelled,
    BookingStatus.AdminCancelled,
  ];
}
