import { RecordScreen } from "@/lib/Components/Screens/RecordScreen/RecordScreen";
import {
  AlarmClock,
  ArrowLeftRightIcon,
  CircleDollarSignIcon,
} from "lucide-react";
import {
  DocumentTextIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { Badge } from "@/components/catalyst/badge";
import { useTranslations } from "use-intl";
import { bookingStatusColorMap } from "@/app/Bookings/Utils/bookingStatusColorMap";
import { useBookingActions } from "@/app/Bookings/Hooks/useBookingActions";
import { useBookingRecord } from "@/app/Bookings/Hooks/useBookingRecord";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

export function BookingRecordScreen() {
  const { isAdmin } = useTenant();
  const booking = useBookingRecord();
  const actions = useBookingActions()(booking);

  const t = useTranslations("booking");

  return (
    <RecordScreen
      title={booking.reference}
      breadcrumbs={
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink to="/bookings">Bookings</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{booking.reference}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      }
      actionsGroups={actions}
      badges={
        <>
          <Badge
            color={bookingStatusColorMap[booking.status]}
            className="capitalize"
          >
            {t(`status.${booking.status}`)}
          </Badge>

          {booking.relocationLineReference?.reference ? (
            <Badge color="zinc">
              Line/Rego: {booking.relocationLineReference.reference}
            </Badge>
          ) : null}

          {booking.supplier_reference ? (
            <Badge color="zinc">
              {booking.relocation.supplier.name} reference:{" "}
              {booking.supplier_reference}
            </Badge>
          ) : (
            <Badge color="zinc">{booking.relocation.supplier.name}</Badge>
          )}
        </>
      }
      tabs={[
        {
          name: "Details",
          to: "/bookings/$bookingId",
          Icon: DocumentTextIcon,
        },
        {
          name: "Relocation",
          to: "/bookings/$bookingId/relocation",
          Icon: ArrowLeftRightIcon,
        },
        {
          name: "Tasks",
          to: "/bookings/$bookingId/tasks",
          Icon: AlarmClock,
          notificationCount: booking.taskCount,
        },

        {
          name: "Find relocation",
          to: "/bookings/$bookingId/find-relocation",
          Icon: MagnifyingGlassIcon,
          isHidden: !isAdmin,
        },
        {
          name: "Payments",
          to: "/bookings/$bookingId/payments",
          Icon: CircleDollarSignIcon,
          isHidden: !isAdmin,
        },
      ]}
    />
  );
}
