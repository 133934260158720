import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type PaymentRecord = ResultOf<typeof paymentRecordQuery>["payment"];
export const paymentRecordQuery = graphql(`
  query PaymentRecord($id: ID!) {
    payment(id: $id) {
      ...PaymentFields
      attributedTo {
        __typename
        ... on Booking {
          id
          reference
        }
      }
    }
  }
`);

export const paymentFieldsFragment = graphql(`
  fragment PaymentFields on Payment {
    id
    type
    status
    currency
    description
    due_date
    amount
    amount_refunded
    amount_tax
    amount_fee
    amount_paid
    amount_inc_tax
    is_tax_included
    tax_type
    payee {
      ...ContactRecordFields
    }

    created_at
    updated_at

    fulfilledBy {
      __typename
      ... on StripePayment {
        ...StripePaymentFields
      }

      ... on PayableLineItem {
        id
        object_id
        object_type
      }
    }
  }

  fragment StripePaymentFields on StripePayment {
    currency
    id
    status
    last4
    exp_year
    exp_month
    amount
    amount_captured
    amount_fee
    amount_refunded
    brand
    payment_intent_id
    created_at
    attributed_to_id
    attributed_to_type
  }
`);
