import { graphql } from "@/gql";

export const reportSalesQueryGraphql = graphql(`
  query SalesReport($start: DateTimeTz!, $end: DateTimeTz!) {
    salesReport(start: $start, end: $end) {
      user {
        ...UserFields
      }
      organisations {
        organisation {
          ...OrganisationFields
        }
        bookingsByDate {
          count
          date
        }
      }
    }
  }
`);
