import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export const purchasableExtrasQuery = graphql(`
  query PurchasableExtras($supplierId: ID, $page: Int, $first: Int!) {
    supplierPurchasableExtras(
      supplier_id: $supplierId
      page: $page
      first: $first
    ) {
      data {
        id
        name
        description
        units_purchasable
        unit_price
        supplier {
          currency
        }
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);

export type PurchasableExtraListResult = ResultOf<
  typeof purchasableExtrasQuery
>["supplierPurchasableExtras"]["data"][number];

export const updateSupplierPurchasableExtraMutation = graphql(`
  mutation UpdateSupplierPurchasableExtra(
    $id: ID!
    $input: UpdateSupplierPurchasableExtraInput!
  ) {
    updateSupplierPurchasableExtra(id: $id, input: $input) {
      id
      name
      description
      units_purchasable
      unit_price
    }
  }
`);

export const deleteSupplierPurchasableExtraMutation = graphql(`
  mutation DeleteSupplierPurchasableExtra($id: ID!) {
    deleteSupplierPurchasableExtra(id: $id) {
      id
    }
  }
`);
