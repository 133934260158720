import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type BookingRecord = ResultOf<typeof bookingRecordQuery>["booking"];
export const bookingRecordQuery = graphql(`
  query BookingRecordQuery($id: ID!) {
    booking(id: $id) {
      id
      created_at
      updated_at

      taskCount

      user {
        ...UserFields
      }

      createdBy {
        ...UserFields
      }

      imoova_refund_amount

      reference
      email
      phone
      name
      number_of_travellers
      address
      supplier_reference
      customer_note
      date_of_birth
      status
      permalink
      depart_at
      deliver_at
      referrer

      relocation {
        ...RelocationFields
      }
      original_relocation_id

      status

      currency
      pay_now_cost_amount
      pay_now_total_amount
      pay_now_total_amount
      pay_now_booking_fee_amount
      pay_now_full_price_units_deposit_amount
      pay_now_holding_deposit_amount

      pay_later_discounted_amount
      pay_later_total_amount
      pay_later_full_price_amount
      pay_later_cost_amount
      pay_later_additional_charges_amount

      supplier_refund_amount

      distance_allowed
      full_price_units
      discounted_units

      total_cost_amount
      total_refund_amount

      customerPayments {
        ...PaymentFields
      }

      supplierPayments {
        ...PaymentFields
      }

      purchasableExtras {
        id
        quantity
        supplierPurchasableExtra {
          id
          name
          description
          unit_price
          units_purchasable
        }
      }

      changeRequests {
        id
        request
        status
        amount
        currency
      }

      relocationLineReference {
        id
        reference
        created_at
      }

      supplierPaymentSummary {
        holding_deposit_amount
        additional_charges_amount
        relocation_discount_amount
        relocation_discount_rate
        discount_and_refund_amount
        rental_deposit_amount
        subtotal
        total
        rental_amount
      }

      preferred_ferry_date
      ferry_note

      potentialDuplicates {
        id
        reference
        deliver_at
        depart_at
        relocation {
          departureCity {
            name
          }
          deliveryCity {
            name
          }
        }
      }
    }
  }
`);
