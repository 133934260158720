import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type CityRecord = ResultOf<typeof cityRecordQuery>["city"];
export const cityRecordQuery = graphql(`
  query CityRecord($id: ID!) {
    city(id: $id) {
      ...CityFields
    }
  }
`);

export const cityFieldsFragment = graphql(`
  fragment CityFields on City {
    id
    name
    region
    state
    created_at
  }
`);
