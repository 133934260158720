import { graphql } from "@/gql";

export const createContactMutation = graphql(`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      ...ContactRecordFields
    }
  }
`);

export const updateContactMutation = graphql(`
  mutation UpdateContact($id: ID!, $input: UpdateContactInput!) {
    updateContact(id: $id, input: $input) {
      id
      name
    }
  }
`);

export const createEntityMutation = graphql(`
  mutation CreateEntity($contactId: ID!, $input: CreateEntityInput!) {
    createEntity(contactId: $contactId, input: $input) {
      id
    }
  }
`);
