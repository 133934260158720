import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { FC, ReactNode } from "react";
import { Link, LinkProps } from "@tanstack/react-router";

type EmptyRecordScreenProps = {
  title: ReactNode;
  flair: ReactNode;
  description: ReactNode;
  items: EmptyRecordScreenItem[];
};

type EmptyRecordScreenItem = {
  name: string;
  toOptions: LinkProps;
  description: string;
  Icon: FC<any>;
};

export function EmptyRecordScreen({
  title,
  flair,
  description,
  items,
}: EmptyRecordScreenProps) {
  return (
    <div className="mx-auto h-full w-full max-w-7xl overflow-y-auto px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <p className="text-base font-semibold leading-8 text-indigo-600">
          {flair}
        </p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {title}
        </h1>
        <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
          {description}
        </p>
      </div>
      <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
        <h2 className="sr-only">Popular pages</h2>
        <ul
          role="list"
          className="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5"
        >
          {items.map((link, linkIdx) => (
            <li
              key={linkIdx}
              className="relative flex gap-x-6 rounded-md px-2 py-6 hover:bg-gray-50"
            >
              <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
                <link.Icon
                  className="h-6 w-6 text-indigo-600"
                  aria-hidden="true"
                />
              </div>
              <div className="flex-auto">
                <h3 className="text-sm font-semibold leading-6 text-gray-900">
                  <Link {...link.toOptions}>
                    <span className="absolute inset-0" aria-hidden="true" />
                    {link.name}
                  </Link>
                </h3>
                <p className="mt-2 text-sm leading-6 text-gray-600">
                  {link.description}
                </p>
              </div>
              <div className="flex-none self-center">
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </li>
          ))}
        </ul>
        <div className="mt-10 flex justify-center">
          <Link
            to="/"
            params={{}}
            search={{}}
            className="text-sm font-semibold leading-6 text-indigo-600"
          >
            <span aria-hidden="true">&larr; </span>
            Back to dashboard
          </Link>
        </div>
      </div>
    </div>
  );
}
