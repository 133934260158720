import { forwardRef, RefObject, useEffect, useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { $generateHtmlFromNodes } from "@lexical/html";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { $getRoot, LexicalEditor } from "lexical";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { GenericField, InputProps } from "../Fields/GenericField";
import { RichTextEditor } from "../../Lexical/RichTextEditor";
import { LexicalOnFocusPlugin } from "@/lib/Components/Lexical/plugins/OnFocusPlugin/LexicalOnFocusPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useField } from "formik";
import DOMPurify from "dompurify";
import { cn } from "@/lib/utils";

export type RichInputProps = InputProps & {
  initialValue?: string;
  editorRef?: RefObject<LexicalEditor>;
  debug?: boolean;
};

export function RichTextInput({
  initialValue,
  editorRef,
  debug,
  ...props
}: RichInputProps) {
  const [isFocussed, setIsFocussed] = useState(false);
  const [hasTyped, setHasTyped] = useState(false);
  const [field, meta, helpers] = useField(props.name);
  const { name } = field;
  const showError = !!meta.error && meta.touched;
  const { setValue, setTouched } = helpers;

  return (
    <GenericField
      {...props}
      viewNode={
        <div
          className="prose whitespace-normal"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(field.value),
          }}
        />
      }
    >
      <>
        <RichTextEditor
          debug={debug}
          editorRef={editorRef}
          className={cn(
            "mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 pb-2 text-sm shadow-sm transition-shadow duration-100 focus-within:border-indigo-500 focus-within:ring-1 focus-visible:outline-none",
            {
              "border-indigo-500 focus-within:border-indigo-500 focus-within:ring-indigo-500":
                isFocussed,
              "border-red-300 focus-within:border-red-500 focus-within:ring-red-500":
                showError,
            },
          )}
          initialHtml={initialValue ?? field.value}
          name={name}
        >
          <OnChangePlugin
            ignoreHistoryMergeTagChange={false}
            onChange={(editorState, editor) => {
              editor.update(() => {
                const root = $getRoot();

                if (root.getTextContentSize() > 0) {
                  const htmlString = $generateHtmlFromNodes(editor, null);

                  const dom = new DOMParser().parseFromString(
                    htmlString,
                    "text/html",
                  );

                  setValue(dom.body.innerHTML);
                  setHasTyped(true);
                } else {
                  setValue(null);
                }
              });
            }}
          />
          <LexicalOnFocusPlugin
            setHasFocus={(hasFocus) => {
              setIsFocussed(hasFocus);
            }}
            onBlur={() => {
              if (hasTyped) {
                setTouched(true);
              }
            }}
          />
          <ClearEditorPlugin />
          <EditorCapturePlugin ref={editorRef} />
        </RichTextEditor>
        {showError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </>
    </GenericField>
  );
}

export const EditorCapturePlugin = forwardRef(function EditorCapture(
  props: any,
  ref: any,
) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (!ref) return;

    ref.current = editor;
    return () => {
      ref.current = null;
    };
  }, [editor, ref]);

  return null;
});
