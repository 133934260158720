import dayjs from "dayjs";

export function formatDateTimeSinceNow(date: Date | null | undefined | string) {
  if (typeof date === "undefined" || date === null) {
    return "--";
  }

  return dayjs().to(dayjs.utc(date), false);
}

export function formatDateTimeFromNow(date: Date | null | undefined | string) {
  if (typeof date === "undefined" || date === null) {
    return "--";
  }

  return dayjs.utc(date).from(dayjs(), false);
}
