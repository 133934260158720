import { ActivityLogFeed } from "@/app/ActivityLogs/Components/ActivityLogFeed";
import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import { useSupplierRecord } from "@/app/Suppliers/Hooks/useSupplierRecord";
import { ModelType } from "@/gql/graphql";

export function SupplierActivityScreen() {
  const record = useSupplierRecord();

  return (
    <div className="py-6">
      <RecordScreenCard title="Activity">
        <ActivityLogFeed modelId={record.id} modelType={ModelType.Supplier} />
      </RecordScreenCard>
    </div>
  );
}
