import { Fragment } from "react";
import { Menu, MenuButton, MenuItems, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import {
  Action,
  ActionMenuItem,
} from "@/lib/Components/Actions/ActionGroupButtons";
import classNames from "classnames";

interface SplitButtonProps {
  primaryAction: Action;
  secondaryActions: Action[];
}

export function SplitActionButton({
  primaryAction,
  secondaryActions,
}: SplitButtonProps) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="inline-flex divide-x rounded-md bg-indigo-500 shadow-sm">
        <div className="relative z-0 inline-flex divide-x rounded-md shadow-sm">
          <button
            className="relative inline-flex items-center rounded-l-md border border-transparent py-2 pl-3 pr-4 text-white shadow-sm active:bg-indigo-600"
            type="button"
            onClick={() => {
              primaryAction.handleAction();
            }}
          >
            {primaryAction.Icon && <primaryAction.Icon className="h-5 w-5" />}
            <p className="ml-2.5 text-sm font-medium">{primaryAction.label}</p>
          </button>
          {secondaryActions.length > 0 && (
            <MenuButton
              className={classNames(
                "relative inline-flex items-center rounded-l-none rounded-r-md p-2 text-sm font-medium text-white focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 active:bg-indigo-600",
              )}
            >
              <ChevronDownIcon
                className="h-5 w-5 text-white"
                aria-hidden="true"
              />
            </MenuButton>
          )}
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {secondaryActions?.map((action, itemIndex) => (
            <ActionMenuItem {...action} key={itemIndex} />
          ))}
        </MenuItems>
      </Transition>
    </Menu>
  );
}
