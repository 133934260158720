import { RecordCreateCard } from "@/lib/Components/SideBarList/Cards/RecordCreateCard";
import { CreateVehicleInput } from "@/gql/graphql";
import { RecordCreateScreen } from "@/lib/Components/Screens/RecordScreen/RecordCreateScreen";
import { useNavigate } from "@tanstack/react-router";
import { VehicleForm } from "@/app/Vehicles/Forms/VehicleForm";
import { createVehicleSchema } from "@/app/Vehicles/Validators/createVehicleSchema";

import { vehicleRoute } from "@/app/Vehicles/Routes/vehicleRoutes";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createVehicleMutation } from "@/app/Vehicles/GraphQL/vehicleMutations";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { useGetInitialVehicleValues } from "@/app/Vehicles/Utils/useGetInitialVehicleValues";

export function VehicleCreateScreen() {
  const navigate = useNavigate();
  const { mutateAsync } = useGqlMutation(createVehicleMutation);
  const { supplier } = useGlobalSupplier();
  const initialInput = useGetInitialVehicleValues(supplier);

  return (
    <RecordCreateScreen>
      <RecordCreateCard<CreateVehicleInput>
        title="Create vehicle"
        validationSchema={createVehicleSchema}
        initialValues={initialInput}
        onSubmit={async (values) => {
          const res = await mutateAsync({
            input: values,
          });

          await navigate({
            to: vehicleRoute.to,
            params: {
              id: res.createVehicle.id,
            },
          });
        }}
      >
        <VehicleForm />
      </RecordCreateCard>
    </RecordCreateScreen>
  );
}
