import { PaymentStatus } from "@/gql/graphql";
import { BadgeColor } from "@/components/catalyst/badge";

export const paymentStatusIntentMap: {
  [key in PaymentStatus]: BadgeColor;
} = {
  [PaymentStatus.Paid]: "green",
  [PaymentStatus.PartiallyRefunded]: "blue",
  [PaymentStatus.Pending]: "yellow",
  [PaymentStatus.Refunded]: "red",
  [PaymentStatus.Voided]: "purple",
  [PaymentStatus.Unpaid]: "zinc",
};
