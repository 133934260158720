import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { TextAreaInput } from "@/lib/Components/Form/Inputs/TextAreaInput";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createSupplierAcknowledgementMutations } from "@/app/Suppliers/GraphQL/supplierAcknowledgementMutations";
import { CreateSupplierAcknowledgementInput } from "@/gql/graphql";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";

export function SupplierAcknowledgementCreateDialog({
  supplierId,
}: {
  supplierId: string;
}) {
  const { close } = useDialogState();
  const { mutateAsync } = useGqlMutation(
    createSupplierAcknowledgementMutations,
  );

  return (
    <GenericDialog title="Add Acknowledgement">
      <GenericForm<CreateSupplierAcknowledgementInput>
        initialValues={{
          supplier_id: supplierId,
          name: "",
          acknowledgement: "",
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            input: values,
          });
          close();
        }}
      >
        <TextInput name="name" label="Name" />
        <TextAreaInput
          name="acknowledgement"
          label="Acknowledgement"
          className="col-span-full"
        />
        <DialogSubmitButton text="Add Acknowledgement" />
      </GenericForm>
    </GenericDialog>
  );
}
