import { ContactType } from "@/gql/graphql";
import { BadgeColor } from "@/components/catalyst/badge";

export const contactTypeColorMap: { [key in ContactType]: BadgeColor } = {
  [ContactType.Entity]: "purple",
  [ContactType.Organisation]: "blue",
  [ContactType.System]: "zinc",
  [ContactType.User]: "green",
  [ContactType.Guest]: "teal",
};
