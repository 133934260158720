import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type OrganisationListItem = ResultOf<
  typeof organisationListQueryGraphql
>["organisations"]["data"][number];
export const organisationListQueryGraphql = graphql(`
  query OrganisationList(
    $page: Int
    $first: Int!
    $search: String
    $orderBy: [QueryOrganisationsOrderByOrderByClause!]
  ) {
    organisations(
      first: $first
      page: $page
      search: $search
      orderBy: $orderBy
    ) {
      data {
        ...OrganisationFields
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
