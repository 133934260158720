import * as Yup from "yup";

export const contactValidation = Yup.object({
  name: Yup.string().required(),
  company_number: Yup.string().nullable(),
  emails: Yup.object({
    upsert: Yup.array().of(
      Yup.object({
        email: Yup.string().email().required(),
      }),
    ),
  }),
});
