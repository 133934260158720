import { TimelineFilterOption } from "@/gql/graphql";
import { useState } from "react";
import {
  filterOptions,
  getDatesFromFilterOption,
} from "@/app/Dashboard/Utils/filterOptions";
import { formatDateTimeLocal } from "@/lib/Formatters/formatDateTime";
import { DashboardCard } from "@/app/Dashboard/Components/DashboardCard";
import { ArrowLeftRightIcon } from "lucide-react";
import { calculatePercentChange } from "@/app/Dashboard/Utils/calculatePercentChange";
import { useGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { organisationDashboardStatsQuery } from "@/app/Dashboard/GraphQL/organisationDashboardStatsQuery";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";

export function OrganisationDashboardStatBlocks() {
  const [filter, setFilter] = useState(TimelineFilterOption.SevenDays);
  const { start, end } = getDatesFromFilterOption(filter);
  const { supplierId } = useGlobalSupplier();
  const { data, isLoading } = useGqlQuery(organisationDashboardStatsQuery, {
    start,
    end,
    supplierId,
  });

  const averageTimeToConfirmation =
    data?.organisationDashboardStats.thisPeriod
      ?.average_minutes_to_confirmation ?? 0;
  const averageTimeToConfirmationHours = (
    averageTimeToConfirmation / 60
  ).toFixed(1);
  const averageTimeToConfirmationLastPeriod =
    data?.organisationDashboardStats.lastPeriod
      ?.average_minutes_to_confirmation ?? 0;
  const averageTimeToConfirmationLastPeriodHours = (
    averageTimeToConfirmationLastPeriod / 60
  ).toFixed(1);

  const bookingsThisPeriod =
    data?.organisationDashboardStats.thisPeriod.confirmed_bookings_count ?? 0;
  const bookingsLastPeriod =
    data?.organisationDashboardStats.lastPeriod?.confirmed_bookings_count ?? 0;
  const pendingBookingsThisPeriod =
    data?.organisationDashboardStats.thisPeriod?.pending_bookings_count ?? 0;

  return (
    <div className="">
      <h2 className="text-lg font-medium leading-6 text-gray-900">
        Overview
        <select
          className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value as TimelineFilterOption);
          }}
        >
          {Object.entries(filterOptions).map(([option, label]) => (
            <option key={option} value={option}>
              {label}
            </option>
          ))}
        </select>
        <span className="ml-2 text-xs text-gray-500">
          {formatDateTimeLocal(start)} - {formatDateTimeLocal(end)}
        </span>
      </h2>

      <div className="mt-2 grid grid-cols-3 gap-6">
        <DashboardCard
          label="Bookings"
          primaryFigure={bookingsThisPeriod}
          supplementaryFigure={`${pendingBookingsThisPeriod} pending`}
          percentChange={
            calculatePercentChange({
              current: bookingsThisPeriod,
              previous: bookingsLastPeriod,
            }).percent
          }
          tooltip={
            <div>
              <p>Current period: {bookingsThisPeriod}</p>
              <p>Previous period: {bookingsLastPeriod}</p>
            </div>
          }
          isLoading={isLoading}
        />
        <DashboardCard
          label="Confirmation time"
          isLoading={isLoading}
          primaryFigure={`${averageTimeToConfirmationHours} hours`}
          tooltip={
            <div>
              <p>Current period: {averageTimeToConfirmationHours} hours</p>
              <p>
                Previous period: {averageTimeToConfirmationLastPeriodHours}{" "}
                hours
              </p>
            </div>
          }
          percentChange={
            calculatePercentChange({
              current: averageTimeToConfirmation,
              previous: averageTimeToConfirmationLastPeriod,
            }).percent
          }
          isNegativeTrendGood={true}
        />
        <DashboardCard
          isLoading={isLoading}
          label="Relocations"
          primaryFigure={`${data?.organisationDashboardStats.thisPeriod.active_relocations_count}`}
          supplementaryFigure={
            data?.organisationDashboardStats.thisPeriod
              .expired_relocations_count
              ? `${data?.organisationDashboardStats.thisPeriod.expired_relocations_count} expired`
              : undefined
          }
          Icon={ArrowLeftRightIcon}
        />
      </div>
    </div>
  );
}
