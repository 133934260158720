import { graphql } from "@/gql";
import { SupplierIconFieldsFragment } from "@/gql/graphql";
import { getInitials } from "@/lib/Utils/getInitials";
import { Avatar } from "@/components/catalyst/avatar";
import { cn } from "@/lib/utils";

graphql(`
  fragment SupplierIconFields on Supplier {
    id
    name
    logo {
      ...MediaFields
    }
  }
`);

export function SupplierIcon({
  model,
  className,
}: {
  model: SupplierIconFieldsFragment;
  className?: string;
}) {
  return (
    <Avatar
      className={cn("bg-purple-500 text-white", className)}
      initials={getInitials(model.name)}
      media={model.logo}
    />
  );
}
