import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import dayjs from "dayjs";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { generateStatementOfAccounts } from "@/app/Contacts/GraphQL/contactGenerateStatementOfAccounts";
import { useContactRecord } from "@/app/Contacts/Hooks/useContactRecord";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { PreviewPdfDialog } from "@/lib/Components/Dialog/PreviewPdfDialog";
import { SubmitButtonInput } from "@/lib/Components/Form/Inputs/SubmitButtonInput";

export function ContactStatementScreen() {
  const { mutateAsync } = useGqlMutation(generateStatementOfAccounts);
  const { open: openPdf } = useDialog(PreviewPdfDialog);
  const contact = useContactRecord();

  return (
    <div className="py-12">
      <Card>
        <CardHeader>
          <CardTitle>Statement of Accounts</CardTitle>
          <CardDescription>
            Generate a statement of account for this contact
          </CardDescription>
        </CardHeader>
        <CardContent>
          <GenericForm
            initialValues={{
              start: dayjs().startOf("year").format("YYYY-MM-DD"),
              end: dayjs().format("YYYY-MM-DD"),
            }}
            onSubmit={async (values) => {
              const res = await mutateAsync({
                input: {
                  contactId: contact.id,
                  end: values.end,
                  start: values.start,
                },
              });

              openPdf({
                url: res.generateStatementOfAccounts,
              });
            }}
            shouldToggleEditOnSubmit={false}
          >
            <DateInput name="start" label="Start date" />
            <DateInput name="end" label="End date" />
            <div className="col-span-full">
              <SubmitButtonInput>Generate Statement</SubmitButtonInput>
            </div>
          </GenericForm>
        </CardContent>
      </Card>
    </div>
  );
}
