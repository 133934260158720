import { useDownload } from "@/lib/Utils/useDownload";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createInvoiceReportMutationGraphql } from "@/app/Reports/GraphQL/createInvoiceReportMutation.graphql";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import dayjs from "dayjs";
import { Button } from "@/lib/Components/Button/Button";
import {
  ConstructionIcon,
  FileSpreadsheetIcon,
  SquareArrowOutUpRightIcon,
} from "lucide-react";
import { InvoiceTable } from "@/app/Invoices/Components/InvoiceTable";
import {
  ConnectBelongsTo,
  InvoiceStatus,
  QueryInvoicesWhereColumn,
  SqlOperator,
} from "@/gql/graphql";
import { OrganisationInput } from "@/app/Organisations/Components/OrganisationInput";
import { InvoiceStatusInput } from "@/app/Invoices/Components/InvoiceStatusInput";
import { useForm } from "@/lib/Components/Form/Hooks/useForm";
import { DateInput } from "@/lib/Components/Form/Inputs/DateInput";
import { cn } from "@/lib/utils";

type InvoiceReportFormProps = {
  rangeStartDate: string;
  rangeEndDate: string;
  status: InvoiceStatus | null;
  organisation: ConnectBelongsTo | null;
};

export function ReportInvoiceScreen() {
  const { mutateAsync } = useGqlMutation(createInvoiceReportMutationGraphql);
  const download = useDownload();

  return (
    <>
      <InProgressBanner />
      <GenericForm<InvoiceReportFormProps>
        initialValues={{
          rangeStartDate: dayjs()
            .local()
            .subtract(1, "years")
            .format("YYYY-MM-DD"),
          rangeEndDate: dayjs().local().format("YYYY-MM-DD"),
          status: null!,
          organisation: null!,
        }}
        onSubmit={async (values) => {
          const res = await mutateAsync({
            rangeEndDate: values.rangeEndDate,
            rangeStartDate: values.rangeStartDate,
            status: values.status,
            organisationId: values.organisation?.connect,
          });
          download(res.createReportInvoiceDownloadUrl);
        }}
      >
        <Form />
      </GenericForm>
    </>
  );
}

function Form() {
  const { values, isSubmitting } = useForm<InvoiceReportFormProps>();

  return (
    <>
      <DateInput
        name={"rangeStartDate"}
        label="From"
        max={dayjs().format("YYYY-MM-DD")}
      />
      <DateInput
        name={"rangeEndDate"}
        label="To"
        min={values.rangeStartDate}
        max={dayjs().format("YYYY-MM-DD")}
      />

      <InvoiceStatusInput name={"status"} label={"Status"} clearable />

      <div className="col-span-3 flex justify-end">
        <Button
          LeadingIcon={FileSpreadsheetIcon}
          htmlButtonType={"submit"}
          isLoading={isSubmitting}
        >
          Export
        </Button>
      </div>

      <OrganisationInput name="organisation" label="Organisation" clearable />

      <div className="col-span-full">
        <InvoiceTable
          id="reporting-invoices"
          queryVariables={{
            where: {
              AND: [
                {
                  operator: SqlOperator.Between,
                  column: QueryInvoicesWhereColumn.CreatedAt,
                  value: [values.rangeStartDate, values.rangeEndDate],
                },
                ...(values.status
                  ? [
                      {
                        operator: SqlOperator.Eq,
                        column: QueryInvoicesWhereColumn.Status,
                        value: values.status,
                      },
                    ]
                  : []),
                ...(values.organisation?.connect
                  ? [
                      {
                        operator: SqlOperator.Eq,
                        column: QueryInvoicesWhereColumn.ManagedById,
                        value: values.organisation?.connect,
                      },
                    ]
                  : []),
              ],
            },
          }}
        />
      </div>
    </>
  );
}

export function InProgressBanner({ className }: { className?: string }) {
  return (
    <div className={cn("rounded-md bg-purple-50 p-4", className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ConstructionIcon
            className="h-5 w-5 text-purple-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-purple-800">
            Under construction
          </h3>
          <div className="mt-2 text-sm text-purple-700">
            <p>
              This component is being worked on. Is there something you want
              included, fixed or changed?{" "}
              <a
                href="mailto:carl@imoova.com"
                rel="noreferrer"
                target="_blank"
                className="underline"
              >
                email me
              </a>
              <SquareArrowOutUpRightIcon className="ml-2 inline h-3 w-3" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
