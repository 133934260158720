import { GraphQLClient } from "graphql-request";

const endpoint = import.meta.env.VITE_API_URL;

export const graphQLClient = new GraphQLClient(
  new URL("/graphql", endpoint).toString(),
  {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  },
);
