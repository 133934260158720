import { NumericFormat } from "react-number-format";
import { GenericField, InputProps } from "../Fields/GenericField";
import {
  changeCurrencyToCents,
  changeCurrencyToDollars,
  formatCurrency,
  getCurrencySymbol,
} from "@/lib/Formatters/formatCurrency";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Currency } from "@/gql/graphql";
import { useField } from "formik";
import { cn } from "@/lib/utils";

type CurrencyInputProps = InputProps & {
  currency: Currency;
  onChange?: (val: number | null) => void;
};

export function CurrencyInput({
  currency,
  onChange,
  ...props
}: CurrencyInputProps) {
  const [field, { error, touched }, helpers] = useField(props.name);
  const { name, value } = field;
  const { setValue } = helpers;
  const showError = !!error && touched;

  return (
    <GenericField
      {...props}
      viewNode={<span>{formatCurrency(value, currency)}</span>}
    >
      <>
        <NumericFormat
          name={name}
          onBlur={field.onBlur}
          className={cn(
            "block w-full rounded-md border-gray-300 shadow-sm transition-shadow duration-100 focus:outline-none sm:text-sm",
            {
              "border-red-300 text-red-900 focus:border-red-500 focus:ring-red-500":
                showError,
              "focus:border-indigo-500 focus:ring-indigo-500": !showError,
            },
          )}
          placeholder={formatCurrency(0, currency, {
            showZero: true,
          })}
          prefix={getCurrencySymbol(currency)}
          value={
            field.value === null
              ? ""
              : changeCurrencyToDollars(field.value, currency)
          }
          onValueChange={async (values) => {
            const newVal =
              values.floatValue === undefined
                ? null
                : changeCurrencyToCents(values.floatValue, currency);

            await setValue(newVal); //Round the value to avoid floating point errors
            onChange?.(newVal);
          }}
        />
        {showError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </>
    </GenericField>
  );
}
