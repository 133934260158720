import { graphql } from "@/gql";

export const createIntegrationLink = graphql(`
  mutation createIntegrationLink($input: CreateIntegrationLinkInput!) {
    createIntegrationLink(input: $input) {
      id
    }
  }
`);

export const upsertIntegrationLink = graphql(`
  mutation upsertIntegrationLink($id: ID, $input: CreateIntegrationLinkInput!) {
    upsertIntegrationLink(id: $id, input: $input) {
      id
    }
  }
`);

export const updateIntegrationLink = graphql(`
  mutation updateIntegrationLink(
    $id: ID!
    $input: UpdateIntegrationLinkInput!
  ) {
    updateIntegrationLink(id: $id, input: $input) {
      id
    }
  }
`);
