import { FC, ReactNode } from "react";
import { Link, ToOptions } from "@tanstack/react-router";

export function PersonnelCard({
  primaryItem,
  items,
  linkLabel,
  toOptions,
  badge,
}: {
  primaryItem: {
    label: ReactNode;
    value: ReactNode;
  };
  items: {
    label: ReactNode;
    Icon: FC<any>;
  }[];
  toOptions: ToOptions;
  linkLabel: string;
  badge: ReactNode;
}) {
  return (
    <div className="">
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className="flex-auto pl-6 pt-6">
            <dt className="text-sm font-semibold leading-6 text-gray-900">
              {primaryItem.label}
            </dt>
            <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
              {primaryItem.value}
            </dd>
          </div>
          <div className="flex-none self-end px-6 pt-4">{badge}</div>

          {items.map((item, idx) => {
            return (
              <div
                className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6"
                key={idx}
              >
                <dt className="flex-none">
                  <span className="sr-only">Client</span>
                  <item.Icon
                    className="h-6 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </dt>
                <dd className="text-sm font-medium leading-6 text-gray-900">
                  {item.label}
                </dd>
              </div>
            );
          })}
        </dl>
        <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
          <Link
            {...toOptions}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {linkLabel} <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
