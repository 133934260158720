import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { FileUploader } from "@/lib/FileUpload/FileUploader";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { createMediaMutation } from "@/app/Media/GraphQL/mediaMutations";
import { MediaCollection, ModelType } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";

export function UploadMediaDialog({
  objectId,
  objectType,
  collection,
}: {
  objectId: string;
  objectType: ModelType;
  collection: MediaCollection;
}) {
  const { mutateAsync: createMedia } = useGqlMutation(createMediaMutation);
  const { close } = useDialogState();

  return (
    <GenericDialog title={"Upload Media"}>
      <FileUploader
        onUpload={async (uploads) => {
          const fileToUpload = uploads[0];

          await createMedia({
            input: {
              file_name: fileToUpload.file.name,
              objectId,
              objectType,
              collectionName: collection,
              key: fileToUpload.temporary_url,
            },
          });

          close();
        }}
      />
    </GenericDialog>
  );
}
