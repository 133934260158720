import { cn } from "@/lib/utils";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import { FC } from "react";
import { ActivityLogListItem } from "@/app/ActivityLogs/GraphQL/activityLogListQuery";
import { TimeSinceNow } from "@/lib/Components/Common/TimeSinceNow";

export function DefaultActivityLogItem({
  log,
  Icon,
  iconColor = "text-gray-400",
}: {
  log: ActivityLogListItem;
  Icon?: FC<any>;
  iconColor?: string;
}) {
  return (
    <div className="isolate flex bg-white">
      <div className="mr-4 flex h-6 w-6 flex-none items-center justify-center overflow-hidden">
        {Icon ? (
          <Icon className={cn("size-5", iconColor)} aria-hidden="true" />
        ) : (
          <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
        )}
      </div>

      <div className="mr-1 flex-shrink-0 font-medium text-gray-900">
        {log.causer?.name ?? "Guest"}
      </div>

      <div className="flex min-w-0 flex-grow">
        {log.properties?.old || log.properties.attributes ? (
          <Dialog>
            <DialogTrigger asChild>
              <div
                role="button"
                className="w-full flex-grow truncate whitespace-nowrap text-left text-xs text-gray-500 hover:overflow-auto"
              >
                {log.description}
              </div>
            </DialogTrigger>
            <DialogContent className="max-w-[1400px] p-0">
              <ReactDiffViewer
                oldValue={JSON.stringify(log.properties?.old, null, 2)}
                newValue={JSON.stringify(log.properties?.attributes, null, 2)}
                compareMethod={DiffMethod.WORDS}
                leftTitle="Old"
                rightTitle="New"
              />
            </DialogContent>
          </Dialog>
        ) : (
          <div
            className="w-full flex-grow truncate whitespace-nowrap text-left text-xs text-gray-500"
            title={log.description}
          >
            {log.description}
          </div>
        )}

        <div className="flex-shrink-0">
          <TimeSinceNow className="text-gray-500" time={log.created_at} />
        </div>
      </div>
    </div>
  );
}
