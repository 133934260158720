import {
  ModelType,
  PaymentType,
  QueryBookingPaymentRequestsWhereColumn,
  QueryPaymentsWhereColumn,
} from "@/gql/graphql";
import { Button } from "@/lib/Components/Button/Button";
import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { BookingPaymentRequestCreateDialog } from "@/app/Bookings/Components/BookingPaymentRequestCreateDialog";
import { PaymentItem } from "@/app/Bookings/Components/PaymentItem";
import { EmptyState } from "@/lib/Components/EmptyState/EmptyState";
import { IllustrationNotebook } from "@/assets/Illustrations";
import { PaymentsTable } from "@/app/Payments/Components/PaymentsTable";
import { BookingPaymentCreateDialog } from "@/app/Payments/Components/BookingPaymentCreateDialog";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useBookingRecord } from "@/app/Bookings/Hooks/useBookingRecord";
import { BookingPaymentRequestTable } from "@/app/Bookings/Components/BookingPaymentRequestTable";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SupplierPaymentSummary } from "@/app/Bookings/Components/SupplierPaymentSummary";
import { PaymentSummarySection } from "@/app/Bookings/Components/PaymentSummarySection";

export function BookingPaymentsScreen() {
  const { open } = useDialog(BookingPaymentRequestCreateDialog);
  const { open: openPaymentDialog } = useDialog(BookingPaymentCreateDialog);
  const record = useBookingRecord();

  return (
    <div className="space-y-12 py-12">
      <RecordScreenCard
        title="Customer payments"
        subtitle="Payments taken via stripe"
      >
        {record.customerPayments.length > 0 ? (
          <div className="grid w-full grid-cols-3 gap-6">
            {record.customerPayments.map((payment) => {
              return <PaymentItem payment={payment} key={payment.id} />;
            })}
          </div>
        ) : (
          <EmptyState
            Icon={IllustrationNotebook}
            header="Payments"
            subheader="There are no customer payments for this booking"
          />
        )}
      </RecordScreenCard>

      <PaymentBlock />

      <RecordScreenCard>
        <BookingPaymentRequestTable
          id={"booking-payment-requests"}
          rightButtons={
            <Button
              LeadingIcon={PlusIcon}
              onClick={() => {
                open({
                  booking: record,
                });
              }}
            >
              Request payment
            </Button>
          }
          queryVariables={{
            where: {
              column: QueryBookingPaymentRequestsWhereColumn.BookingId,
              value: record.id,
            },
          }}
        />
      </RecordScreenCard>

      <RecordScreenCard
        title="Supplier payments"
        buttons={
          <>
            <Button
              LeadingIcon={PlusIcon}
              onClick={() => {
                openPaymentDialog({
                  booking: record,
                });
              }}
            >
              Add payment
            </Button>
          </>
        }
      >
        <PaymentsTable
          id={"booking-supplier-payments"}
          queryVariables={{
            where: {
              AND: [
                {
                  column: QueryPaymentsWhereColumn.AttributedToType,
                  value: ModelType.Booking,
                },
                {
                  column: QueryPaymentsWhereColumn.AttributedToId,
                  value: record.id,
                },
                {
                  column: QueryPaymentsWhereColumn.Type,
                  value: PaymentType.SupplierPayment,
                },
              ],
            },
          }}
        />
      </RecordScreenCard>
    </div>
  );
}

function PaymentBlock() {
  const record = useBookingRecord();

  return (
    <RecordScreenCard title="Payment Summary">
      <Tabs defaultValue="supplier" className="w-full">
        <TabsList>
          <TabsTrigger value="supplier">Supplier summary</TabsTrigger>
          <TabsTrigger value="payment">Payment summary</TabsTrigger>
        </TabsList>

        <TabsContent value="supplier">
          <SupplierPaymentSummary booking={record} />
        </TabsContent>

        <TabsContent value="payment">
          <PaymentSummarySection booking={record} />
        </TabsContent>
      </Tabs>
    </RecordScreenCard>
  );
}
