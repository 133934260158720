import { graphql } from "@/gql";
import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";

export type InvoiceListItem = ResultOf<
  typeof invoiceListQuery
>["invoices"]["data"][number];
export type InvoiceListQueryVariables = VariablesOf<typeof invoiceListQuery>;
export const invoiceListQuery = graphql(`
  query InvoiceListQuery(
    $search: String
    $page: Int
    $first: Int!
    $status: [InvoiceStatus!]
    $where: QueryInvoicesWhereWhereConditions
    $managedById: ID
    $payeeContactId: ID
    $orderBy: [QueryInvoicesOrderByOrderByClause!]
  ) {
    invoices(
      first: $first
      page: $page
      search: $search
      where: $where
      payee_contact_id: $payeeContactId
      managed_by_id: $managedById
      orderBy: $orderBy
      status: $status
    ) {
      data {
        id
        reference
        amount_inc_tax
        due_date
        invoice_date
        status
        currency
        managedBy {
          id
          name
        }
        payee {
          id
          name
        }
        created_at
        updated_at
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
