import { QRCodeTool } from "@/app/Tools/Components/QRCodeTool";
import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { MarketingEventTable } from "@/app/Marketing/MarketingEvents/Components/MarketingEventTable";
import { RelocationHighlightTable } from "@/app/Tools/RelocationHighlights/Components/RelocationHighlightTable";

export function ToolsScreen() {
  return (
    <FullPageScreen className="space-y-12">
      <QRCodeTool />
      <MarketingEventTable />
      <RelocationHighlightTable />
    </FullPageScreen>
  );
}
