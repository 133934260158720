import { GenericField, InputProps } from "../Fields/GenericField";
import { useField } from "formik";
import { ComponentPropsWithoutRef } from "react";
import { cn } from "@/lib/utils";

export type TextInputProps = InputProps & {
  inputProps?: ComponentPropsWithoutRef<"input">;
  onChange?: (val: number | null) => void;
};

export function NumberInput({
  inputProps,
  onChange,
  ...props
}: TextInputProps) {
  const [field, meta, { setValue }] = useField(props.name);
  const { value: fieldVal } = field;
  const showError = !!meta.error && meta.touched;

  let value: string | null | number;
  if (fieldVal === undefined || fieldVal === null || isNaN(fieldVal)) {
    value = "";
  } else {
    value = fieldVal.toString() || "0";
  }
  return (
    <GenericField viewNode={<span>{value}</span>} {...props}>
      <>
        <input
          {...field}
          {...inputProps}
          onWheel={(e) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            e.target.blur();
          }}
          id={field.name}
          type="number"
          value={value?.toString() ?? ""}
          onChange={async (event) => {
            let fieldValue: string | number | null | undefined;

            if (event.target.value === "") {
              fieldValue = null;
            } else {
              fieldValue = parseFloat(event.target.value);
            }

            await setValue(fieldValue);
            onChange?.(fieldValue);
          }}
          className={cn(
            showError
              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : "focus:border-indigo-500 focus:ring-indigo-500",
            "block w-full rounded-md border-gray-300 text-sm shadow-sm transition-shadow duration-100",
          )}
        />
      </>
    </GenericField>
  );
}
