import { SupplierRecord } from "@/app/Suppliers/GraphQL/supplierRecordQuery";
import { Currency, HireUnitType } from "@/gql/graphql";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { metaQuery } from "@/app/Meta/GraphQL/metaQuery";

export function useGetInitialVehicleValues(
  supplier: SupplierRecord | undefined,
) {
  const currency = supplier?.currency ?? Currency.Aud;
  const { data } = useSuspenseGqlQuery(metaQuery, {});
  const rates = data?.meta.default_rates.find(
    (rate) => rate.currency === currency,
  );

  if (!rates) {
    throw new Error(`Currency ${currency} not found`);
  }

  return {
    name: "",
    description: "",
    brand: "",
    model: "",
    code: "",
    seatbelts: null!,
    supplier: {
      connect: supplier?.id ?? null!,
    },
    type: null!,
    fuel: null!,
    transmission: null!,
    approximate_age: null,
    extra_hire_unit_rate: rates.extra_hire_unit_deposit_rate * 5,
    extra_hire_unit_deposit_rate: rates.extra_hire_unit_deposit_rate,
    extra_hire_unit_supplier_net_rate: rates.extra_hire_unit_deposit_rate * 4,
    hire_unit_rate: 100,
    hire_unit_type: supplier?.hire_unit_type ?? HireUnitType.Day,
    is_pet_friendly: null!,
    child_seat_anchor_points: null!,
    has_heating: null,
    has_kitchen: null,
    has_shower_and_toilet: null,
    is_self_contained: null,
    pet_cleaning_fee: null,
    sleeps: null,
  };
}
