import { DataTableColDef } from "@/lib/Components/DataTable/DataTable";
import {
  SettingCard,
  SettingFormCard,
} from "@/app/Settings/Settings/Components/SettingFormCard";
import { useGlobalSupplier } from "@/app/Suppliers/Utils/useGlobalSupplier";
import { SupplierInsuranceOptionListItem } from "@/app/Suppliers/GraphQL/supplierInsuranceOptionListQuery";
import { JucyInsuranceOptionCodeCell } from "@/app/Settings/Integrations/Jucy/Components/JucyInsuranceOptionCodeCell";
import { IntegrationRecord } from "@/app/Settings/Settings/GraphQL/integrationQueries";
import { SupplierInsuranceOptionTable } from "@/app/Suppliers/Components/SupplierInsuranceOptionTable";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateIntegrationMutation } from "@/app/Settings/Settings/GraphQL/integrationMutations";
import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { JucyVehicleTable } from "@/app/Settings/Integrations/Jucy/Components/JucyVehicleTable";
import { JucyOfficeTable } from "@/app/Settings/Integrations/Jucy/Components/JucyOfficeTable";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";

export function JucyIntegrationScreen({
  integration,
}: {
  integration: IntegrationRecord;
}) {
  const { supplier } = useGlobalSupplier();

  const supplierInsuranceOptionCols: DataTableColDef<
    SupplierInsuranceOptionListItem,
    unknown,
    any
  >[] = [
    {
      id: "jucy",
      header: "Jucy Code",
      cell: JucyInsuranceOptionCodeCell,
    },
  ];

  const { mutateAsync } = useGqlMutation(updateIntegrationMutation);
  const metadata = integration?.metadata;

  return (
    <div className="space-y-6">
      <SettingFormCard
        title="Metadata"
        description=""
        initialValues={{
          apiKey: metadata?.jucyApiKey ?? "",
          apiUrl: metadata?.apiUrl ?? "",
        }}
        onSubmit={async (values) => {
          return mutateAsync({
            id: integration?.id,
            input: {
              metadata: {
                ...metadata,
                jucyApiKey: values.apiKey,
                apiUrl: values.apiUrl,
              },
            },
          });
        }}
      >
        <TextInput name="apiKey" label="Account ID" className="col-span-8" />
        <TextInput
          name="apiUrl"
          label="API url"
          inputProps={{
            placeholder: "https://lanier.test.jucy.cloud",
          }}
          className="col-span-8"
        />
      </SettingFormCard>

      {metadata?.jucyApiKey && metadata?.apiUrl ? (
        <>
          <SettingCard title="Vehicles" description="">
            <JucyVehicleTable />
          </SettingCard>
          <SettingCard title="Offices" description="">
            <JucyOfficeTable />
          </SettingCard>
          <SettingCard title="Insurance Options" description="">
            <WarningBanner className="mb-6">
              As the insurance options are dynamic (based on trip, vehicle, and
              driver), the insurance option will be a best guess based on the
              data available. Please review the insurance options before
              confirming.
            </WarningBanner>
            <SupplierInsuranceOptionTable
              id="settings-integrations-jucy-insurance"
              supplier={supplier}
              columns={supplierInsuranceOptionCols}
            />
          </SettingCard>
        </>
      ) : (
        <WarningBanner>
          Please enter the API key and API url to continue
        </WarningBanner>
      )}
    </div>
  );
}
