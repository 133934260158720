import {
  QueryRelocationsWhereColumn,
  QueryRelocationsWhereVehicleColumn,
  RelocationStatus,
  SqlOperator,
} from "@/gql/graphql";
import { RecordScreenCard } from "@/lib/Components/Screens/RecordScreen/Cards/RecordScreenCard";
import dayjs from "dayjs";
import { BookingRecord } from "@/app/Bookings/GraphQL/bookingRecordQuery";
import { RelocationListItem } from "@/app/Relocations/GraphQL/relocationListQuery";
import { useBookingRecord } from "@/app/Bookings/Hooks/useBookingRecord";
import { BookingSummaryCard } from "@/app/Bookings/Components/Cards/BookingSummaryCard";
import { BookingMatchingRelocationsTable } from "../Components/BookingMatchingRelocationsTable";
import { AssignRelocationDialog } from "@/app/Bookings/Components/BookingFindRelocationDialog";

export function BookingFindRelocationScreen() {
  const booking = useBookingRecord();

  return (
    <div className="space-y-6 py-6">
      <BookingSummaryCard booking={booking} />
      <RecordScreenCard>
        <MatchingRelocationsTable booking={booking} />
      </RecordScreenCard>
      <RecordScreenCard>
        <SimilarRelocationsTable booking={booking} />
      </RecordScreenCard>
      <AssignRelocationDialog booking={booking} />
    </div>
  );
}

export function MatchingRelocationsTable({
  booking,
  onAssign,
}: {
  booking: BookingRecord;
  onAssign?: (relocation: RelocationListItem) => void;
}) {
  return (
    <BookingMatchingRelocationsTable
      booking={booking}
      onAssign={onAssign}
      title="Matching relocations"
      queryVariables={{
        status: [RelocationStatus.Ready],
        delivery_city_id: booking.relocation!.deliveryCity.id,
        departure_city_id: booking.relocation!.departureCity.id,
        where: {
          AND: [
            {
              column: QueryRelocationsWhereColumn.AvailableFromDate,
              value: dayjs(booking.depart_at).format("YYYY-MM-DD"),
              operator: SqlOperator.Lte,
            },
            {
              column: QueryRelocationsWhereColumn.LatestDepartureDate,
              value: dayjs(booking.depart_at).format("YYYY-MM-DD"),
              operator: SqlOperator.Gte,
            },
            {
              column: QueryRelocationsWhereColumn.AvailableToDate,
              value: dayjs(booking.deliver_at).format("YYYY-MM-DD"),
              operator: SqlOperator.Gte,
            },
          ],
        },
        whereVehicle: {
          AND: [
            {
              column: QueryRelocationsWhereVehicleColumn.Type,
              value: booking.relocation!.vehicle.type,
              operator: SqlOperator.Eq,
            },
            {
              column: QueryRelocationsWhereVehicleColumn.Seatbelts,
              value: booking.number_of_travellers,
              operator: SqlOperator.Gte,
            },
          ],
        },
      }}
    />
  );
}

export function SimilarRelocationsTable({
  booking,
  onAssign,
}: {
  booking: BookingRecord;
  onAssign?: (relocation: RelocationListItem) => void;
}) {
  return (
    <BookingMatchingRelocationsTable
      onAssign={onAssign}
      booking={booking}
      title="Similar relocations"
      queryVariables={{
        status: [RelocationStatus.Ready],
        where: {
          AND: [
            {
              column: QueryRelocationsWhereColumn.AvailableFromDate,
              value: dayjs(booking.depart_at)
                .add(2, "day")
                .format("YYYY-MM-DD"),
              operator: SqlOperator.Lte,
            },
            {
              column: QueryRelocationsWhereColumn.LatestDepartureDate,
              value: dayjs(booking.depart_at)
                .subtract(2, "day")
                .format("YYYY-MM-DD"),
              operator: SqlOperator.Gte,
            },
            {
              column: QueryRelocationsWhereColumn.AvailableToDate,
              value: dayjs(booking.deliver_at)
                .subtract(2, "day")
                .format("YYYY-MM-DD"),
              operator: SqlOperator.Gte,
            },
            {
              column: QueryRelocationsWhereColumn.DepartureCityId,
              operator: SqlOperator.In,
              value: [
                booking.relocation?.departureCity.id,
                booking.relocation?.deliveryCity.id,
              ],
            },
            {
              column: QueryRelocationsWhereColumn.DeliveryCityId,
              operator: SqlOperator.In,
              value: [
                booking.relocation?.departureCity.id,
                booking.relocation?.deliveryCity.id,
              ],
            },
          ],
        },
        whereVehicle: {
          AND: [
            {
              column: QueryRelocationsWhereVehicleColumn.Seatbelts,
              value: booking.number_of_travellers,
              operator: SqlOperator.Gte,
            },
          ],
        },
      }}
    />
  );
}
