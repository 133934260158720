export function formatPercent(value: number | undefined | null): string {
  if (value === null || value === undefined) {
    return "--";
  }

  return Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 1,
  }).format(value);
}
