import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { UpdateWaitlistInput } from "@/gql/graphql";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { WaitlistForm } from "@/app/Waitlists/Forms/WaitlistForm";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { updateWaitlistMutation } from "@/app/Waitlists/GraphQL/waitlistMutations";
import { WaitlistListItem } from "@/app/Waitlists/GraphQL/waitlistListQuery";

export function WaitlistEditDialog({
  waitlist,
}: {
  waitlist: WaitlistListItem;
}) {
  const { close } = useDialogState();
  const { mutateAsync } = useGqlMutation(updateWaitlistMutation);
  return (
    <GenericDialog title={"Edit Waitlist"}>
      <GenericForm<UpdateWaitlistInput>
        initialValues={{
          departureCity: {
            connect: waitlist.departureCity.id,
          },
          deliveryCity: {
            connect: waitlist.deliveryCity.id,
          },
          earliest_departure_date: waitlist.earliest_departure_date,
          latest_departure_date: waitlist.latest_departure_date,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            id: waitlist.id,
            input: values,
          });
          close();
        }}
      >
        <WaitlistForm />
        <DialogSubmitButton text={"Update"} />
      </GenericForm>
    </GenericDialog>
  );
}
