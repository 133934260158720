import dayjs from "dayjs";

export const DB_DATE_FORMAT = "YYYY-MM-DD";

export function now() {
  return dayjs();
}

export function today() {
  return dayjs().format(DB_DATE_FORMAT);
}
