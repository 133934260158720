import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type RelocationHighlightListItem = ResultOf<
  typeof relocationHighlightListQuery
>["relocationHighlights"]["data"][number];

export const relocationHighlightListQuery = graphql(`
  query RelocationHighlightList(
    $page: Int
    $first: Int!
    $where: QueryRelocationHighlightsWhereWhereConditions
    $order: [QueryRelocationHighlightsOrderOrderByClause!]
  ) {
    relocationHighlights(
      first: $first
      page: $page
      where: $where
      order: $order
    ) {
      data {
        id
        region
        primary_text
        secondary_text
        order
        departureCity {
          id
          name
        }
        deliveryCity {
          id
          name
        }
        created_at
        updated_at
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);
