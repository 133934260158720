import { FullPageScreen } from "@/lib/Components/Screens/FullPageScreen";
import { SupplierTable } from "@/app/Suppliers/Components/SupplierTable";

export function SupplierTableScreen() {
  return (
    <FullPageScreen>
      <SupplierTable id="suppliers" />
    </FullPageScreen>
  );
}
