import { useMatch } from "@tanstack/react-router";
import { useSuspenseGqlQuery } from "@/lib/GraphQLCodegen/fetcher";
import { integrationRecordQuery } from "@/app/Settings/Settings/GraphQL/integrationQueries";

export function useIntegrationRecord() {
  const { params } = useMatch({
    from: "/_app/settings/integrations/$integrationId",
  });
  const { integrationId } = params;
  const { data } = useSuspenseGqlQuery(integrationRecordQuery, {
    id: integrationId,
  });
  return data.integration;
}
