import { ReactNode } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";
import { GenericForm, GenericFormProps } from "../../../Form/Forms/GenericForm";
import { Button } from "../../../Button/Button";
import { useForm } from "../../../Form/Hooks/useForm";
import { FormationErrorBadge } from "@/lib/Components/Form/Components/FormationErrorBadge";
import { IconButton } from "../../../Button/IconButton";
import { FormationUnsavedChangesBadge } from "@/lib/Components/Form/Components/FormationUnsavedChangesBadge";
import { FormikValues } from "formik";
import { useTenant } from "@/app/Organisations/Hooks/useTenant";
import { Badge } from "@/components/catalyst/badge";

export type RecordScreenFormCardProps<T> = GenericFormProps<T> & {
  title: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
  view?: ReactNode;
  isEditable?: boolean;
  adminOnly?: boolean;
};

export function RecordScreenFormCard<T extends FormikValues>({
  title,
  subtitle,
  children,
  view,
  onSubmit,
  adminOnly,
  isEditable = true,
  ...props
}: RecordScreenFormCardProps<T>) {
  const { isAdmin } = useTenant();

  if (adminOnly && !isAdmin) {
    return null;
  }

  return (
    <GenericForm<T>
      enableReinitialize
      initialEditingState={false}
      shouldToggleEditOnSubmit
      onSubmit={(values, formikHelpers) => {
        return onSubmit?.(values, formikHelpers);
      }}
      {...props}
    >
      <FormInner
        subtitle={subtitle}
        isEditable={isEditable}
        title={title}
        view={view}
        adminOnly={adminOnly}
      >
        {children}
      </FormInner>
    </GenericForm>
  );
}

function FormInner({
  title,
  subtitle,
  children,
  view,
  isEditable,
  adminOnly,
}: {
  title: ReactNode;
  subtitle: ReactNode;
  isEditable: boolean;
  view: ReactNode;
  children: ReactNode;
  adminOnly?: boolean;
}) {
  const { isEditing, toggleEdit, resetForm, isSubmitting } = useForm();

  return (
    <>
      <header className="sticky top-0 isolate z-20 col-span-full flex min-h-[60px] bg-white py-3">
        <div className="flex-grow">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            <div className="flex space-x-4">
              <span>{title}</span>
              {adminOnly ? <Badge color="blue">admin</Badge> : null}
              <FormationErrorBadge />
              <FormationUnsavedChangesBadge />
            </div>
          </h3>
          {subtitle && (
            <p className="mt-1 max-w-2xl text-sm text-gray-500">{subtitle}</p>
          )}
        </div>
        {isEditable ? (
          <div>
            {!isEditing ? (
              <IconButton
                tooltip="Edit"
                onClick={() => toggleEdit(true)}
                Icon={PencilIcon}
              />
            ) : (
              <div className="grid grid-cols-2 gap-2">
                <Button
                  onClick={() => {
                    toggleEdit(false);
                    resetForm();
                  }}
                  type="outline"
                >
                  Cancel
                </Button>
                <Button
                  LeadingIcon={CheckIcon}
                  isLoading={isSubmitting}
                  htmlButtonType="submit"
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        ) : null}
      </header>

      {view && !isEditing ? (
        <div className="col-span-full">{view}</div>
      ) : (
        children
      )}
    </>
  );
}
