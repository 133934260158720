import { GenericDialog } from "@/lib/Components/Dialog/GenericDialog";
import { GenericForm } from "@/lib/Components/Form/Forms/GenericForm";
import { useDialogState } from "@/lib/Components/Dialog/Hooks/useDialogState";
import { OrganisationInput } from "@/app/Organisations/Components/OrganisationInput";
import { DialogSubmitButton } from "@/lib/Components/Dialog/Components/DialogSubmitButton";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { assignOrganisationToUserMutation } from "@/app/Organisations/GraphQL/organisationMutations.graphql";
import { SupplierInput } from "@/app/Suppliers/Components/SupplierInput";
import { toast } from "sonner";

type AssignOrganisationDialogProps = {
  supplierId: string;
};
export function AssignOrganisationDialog({
  supplierId,
}: AssignOrganisationDialogProps) {
  const { close } = useDialogState();
  const { mutateAsync } = useGqlMutation(assignOrganisationToUserMutation);

  return (
    <GenericDialog title={"Assign Organisation"}>
      <GenericForm
        initialValues={{
          tenant_id: null!,
          supplier_id: supplierId,
        }}
        onSubmit={async (values) => {
          await mutateAsync({
            input: values,
          });

          toast("Organisation assigned");

          close();
        }}
      >
        <SupplierInput label="Supplier" name="supplier_id" isEditable={false} />
        <FormDivider />
        <OrganisationInput name="tenant_id" label="Organisation" />
        <DialogSubmitButton text={"Assign"} />
      </GenericForm>
    </GenericDialog>
  );
}
