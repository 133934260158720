import { InvoiceStatus, TaxType } from "@/gql/graphql";

const status: {
  [key in InvoiceStatus]: string;
} = {
  [InvoiceStatus.Draft]: "draft",
  [InvoiceStatus.Issued]: "issued",
  [InvoiceStatus.Overdue]: "overdue",
  [InvoiceStatus.Paid]: "paid",
  [InvoiceStatus.Voided]: "void",
};

const tax_type: {
  [key in TaxType]: string;
} = {
  [TaxType.Gst]: "GST",
  [TaxType.TaxExempt]: "GST Free",
};

export const invoiceMessages = {
  tax_type,
  status,
};
