import { ReportRelocationsQuery } from "@/gql/graphql";
import { DashboardCard } from "@/app/Dashboard/Components/DashboardCard";
import { calculatePercentChange } from "@/app/Dashboard/Utils/calculatePercentChange";

export function RelocationTotalCard({
  data,
}: {
  data: ReportRelocationsQuery["reportRelocations"]["summary_stats"];
}) {
  const current = data.this_period;
  const previous = data.previous_period;

  return (
    <div className="grid grid-cols-3 gap-6">
      <DashboardCard
        percentChange={
          calculatePercentChange({
            current: current.counts.active_count,
            previous: previous.counts.active_count,
          }).percent
        }
        label="Active relocations"
        primaryFigure={`${current.counts.active_count ?? 0}`}
        supplementaryFigure={`${current.counts.paused_count ?? 0} paused`}
      />
      <DashboardCard
        percentChange={
          calculatePercentChange({
            current: current.average_daily_views,
            previous: previous.average_daily_views,
          }).percent
        }
        label="Avg. views per day"
        primaryFigure={`${current.average_daily_views}`}
      />
      <DashboardCard
        percentChange={
          calculatePercentChange({
            current: current.booked_percent,
            previous: previous.booked_percent,
          }).percent
        }
        label="Percent booked"
        primaryFigure={`${(current.booked_percent * 100).toFixed(1)}%`}
      />
    </div>
  );
}
