import { CreditCardIcon } from "@heroicons/react/24/outline";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { billingCreateOffSessionPaymentMethodCheckoutUrlMutation } from "@/app/Stripe/StripePaymentMethods/GraphQL/billingCreateOffSessionPaymentMethodCheckoutUrlMutation";
import { ContactRecordFieldsFragment } from "@/gql/graphql";
import { WarningBanner } from "@/lib/Components/Banners/WarningBanner";

export function StripePaymentMethodEmptyState({
  contact,
}: {
  contact: ContactRecordFieldsFragment;
}) {
  const { mutateAsync } = useGqlMutation(
    billingCreateOffSessionPaymentMethodCheckoutUrlMutation,
  );

  const returnUrl = new URL(
    "/settings/billing",
    import.meta.env.VITE_ADMIN_URL,
  ).toString();

  if (!contact.billingEmail) {
    return (
      <WarningBanner>
        This supplier does not have a billing email. Please add one to manage
        payment methods.
      </WarningBanner>
    );
  }

  return (
    <button
      type="button"
      onClick={async () => {
        const res = await mutateAsync({
          input: {
            contact_id: contact.id,
            cancel_url: returnUrl,
            success_url: returnUrl,
          },
        });

        window.location.href =
          res.billingCreateOffSessionPaymentMethodCheckoutUrl;
      }}
      className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      <CreditCardIcon className="mx-auto h-12 w-12 text-gray-400" />
      <span className="mt-2 block text-sm font-semibold text-gray-900">
        Add a payment method
      </span>
    </button>
  );
}
