import {
  MarketingEventListItem,
  marketingEventTableQuery,
} from "@/app/Marketing/MarketingEvents/GraphQL/marketingEventTableQuery";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Button } from "@/lib/Components/Button/Button";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { CreateMarketingEventDialog } from "@/app/Marketing/MarketingEvents/Components/CreateMarketingEventDialog";
import { formatDateTimeSinceNow } from "@/lib/Formatters/formatDateTimeSinceNow";
import { useMarketingEventActions } from "@/app/Marketing/MarketingEvents/Actions/useMarketingEventActions";
import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";

export function MarketingEventTable() {
  const getActions = useMarketingEventActions();
  const { open } = useDialog(CreateMarketingEventDialog);
  const columns: DataTableColDef<MarketingEventListItem, any, any>[] = [
    {
      id: "name",
      header: "Name",
      accessorFn: (data) => data.name,
    },
    {
      id: "when",
      header: "When",
      accessorFn: (data) => `${data.trigger} / ${data.days_after_trigger} days`,
    },
    {
      id: "created_at",
      header: "Created",
      accessorFn: (data) => formatDateTimeSinceNow(data.created_at),
    },
  ];

  return (
    <DataTable
      rightButtons={
        <Button
          LeadingIcon={PlusIcon}
          onClick={() => {
            open({});
          }}
        >
          Add event
        </Button>
      }
      getActions={getActions}
      columns={columns}
      document={marketingEventTableQuery}
      accessor={(data) => data.marketingEvents}
      getQueryVariables={({ pagination }) => {
        return {
          first: pagination.pageSize,
          page: pagination.pageIndex,
        };
      }}
      id="marketing-events"
    />
  );
}
