import {
  DataTable,
  DataTableColDef,
} from "@/lib/Components/DataTable/DataTable";
import {
  supplierAcknowledgementList,
  SupplierAcknowledgementListItem,
} from "@/app/Suppliers/GraphQL/supplierAcknowledgementList";
import { TimeSinceNowCell } from "@/lib/Components/DataTable/TimeSinceNowCell";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { SupplierAcknowledgementCreateDialog } from "@/app/Suppliers/Components/Dialogs/SupplierAcknowledgementCreateDialog";
import { Button } from "@/lib/Components/Button/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useSupplierAcknowledgementActions } from "@/app/Suppliers/Hooks/useSupplierAcknowledgementActions";

type SupplierAcknowledgementColumnId =
  | "name"
  | "acknowledgement"
  | "created_at";
export function SupplierAcknowledgmentTable({
  supplierId,
}: {
  supplierId: string;
}) {
  const { open } = useDialog(SupplierAcknowledgementCreateDialog);
  const getActions = useSupplierAcknowledgementActions();

  const columns: DataTableColDef<
    SupplierAcknowledgementListItem,
    any,
    SupplierAcknowledgementColumnId
  >[] = [
    {
      id: "name",
      header: "Name",
      accessorFn: (data) => data.name,
    },
    {
      id: "acknowledgement",
      header: "Acknowledgement",
      accessorFn: (data) => data.acknowledgement,
    },
    {
      id: "created_at",
      header: "Created",
      accessorFn: (data) => data.created_at,
      cell: TimeSinceNowCell,
    },
  ];

  return (
    <DataTable
      id="supplier-acknowledgements"
      getActions={getActions}
      rightButtons={
        <Button
          LeadingIcon={PlusIcon}
          onClick={() => {
            open({ supplierId });
          }}
        >
          Add Acknowledgement
        </Button>
      }
      columns={columns}
      document={supplierAcknowledgementList}
      getQueryVariables={({ pagination }) => {
        return {
          supplierId,
          page: pagination.pageIndex,
          first: pagination.pageSize,
        };
      }}
      accessor={(data) => data.supplierAcknowledgements}
    />
  );
}
