import { graphql } from "@/gql";
import { ResultOf } from "@graphql-typed-document-node/core";

export type TaskListItem = ResultOf<
  typeof taskListQuery
>["tasks"]["data"][number];
export const taskListQuery = graphql(`
  query TaskList(
    $page: Int!
    $first: Int!
    $userId: ID
    $status: [TaskStatus!]
    $where: QueryTasksWhereWhereConditions
    $orderBy: [QueryTasksOrderByOrderByClause!]
  ) {
    tasks(
      page: $page
      first: $first
      status: $status
      user_id: $userId
      where: $where
      orderBy: $orderBy
    ) {
      data {
        id
        due_at
        status
        related_to_type
        related_to_id
        relatedTo {
          __typename
          ... on Booking {
            id
            reference
            relocation {
              supplier {
                id
                name
              }
            }
          }
        }
        due_at
        type
        description
        created_at
        updated_at
      }
      paginatorInfo {
        ...PaginationFields
      }
    }
  }
`);

export type TaskListData = ResultOf<
  typeof taskListQuery
>["tasks"]["data"][number];
