import { ActionsHook } from "@/lib/Components/Actions/ActionGroupButtons";
import { ResultOf } from "@graphql-typed-document-node/core";
import { marketingEventTableQuery } from "@/app/Marketing/MarketingEvents/GraphQL/marketingEventTableQuery";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useGqlMutation } from "@/lib/GraphQLCodegen/fetcher";
import { deleteMarketingEventMutation } from "@/app/Marketing/MarketingEvents/GraphQL/deleteMarketingEventMutation";
import { useDialog } from "@/lib/Components/Dialog/Hooks/useDialog";
import { EditMarketingEventDialog } from "@/app/Marketing/MarketingEvents/Components/EditMarketingEventDialog";

type MarketingEventListItem = ResultOf<
  typeof marketingEventTableQuery
>["marketingEvents"]["data"][number];
export function useMarketingEventActions(): ActionsHook<MarketingEventListItem> {
  const { mutateAsync } = useGqlMutation(deleteMarketingEventMutation);
  const { open } = useDialog(EditMarketingEventDialog);

  return (model) => {
    return [
      {
        label: "Actions",
        items: [
          {
            actions: [
              {
                label: "Edit",
                Icon: PencilIcon,
                intent: "neutral",
                handleAction: () => {
                  open({
                    id: model.id,
                  });
                },
              },
            ],
          },
          {
            actions: [
              {
                label: "Delete",
                handleAction: async () => {
                  await mutateAsync({
                    id: model.id,
                  });
                },
                intent: "danger",
                isAdminAction: true,
                Icon: TrashIcon,
              },
            ],
          },
        ],
      },
    ];
  };
}
